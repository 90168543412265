import _ from 'lodash'
import { PrimaryButton, Stack, Text } from 'office-ui-fabric-react'
import React, { useState } from 'react'

import { ScreenTextureMappingStatus } from '../../store/campaign/types'
import { Asset, AssetTexture } from '../../store/doc/types'
import { AssetInput, preview } from '../../components/AssetInput'
import { LayoutMode, Modal } from '../../components/Modal'
import { CompatibleProducts } from './CompatibleProducts'
import { Props as ScreenTexturesProps } from './'

type Props = {
  screenTextures: AssetTexture[]
  compatibleProducts: Asset[]
  closeModals: () => void
  updateScreenTextureMappings: ScreenTexturesProps['updateScreenTextureMappings']
  refreshAssets: ScreenTexturesProps['refreshAssets']
}

export const EditModal = (props: Props) => {
  const { screenTextures, compatibleProducts, closeModals } = props

  const screenTexture =
    screenTextures.length === 1 ? screenTextures[0] : undefined

  const label = `Screen UI${screenTextures.length > 1 ? 's' : ''}`

  const [mappings, setMappings] = useState<ScreenTextureMappingStatus[]>(
    _.map(compatibleProducts, asset => {
      return {
        assetId: asset.id,
        isEnabled: _.every(screenTextures, texture =>
          _.includes(texture.assetIds, asset.id),
        )
          ? true
          : _.some(screenTextures, texture =>
                _.includes(texture.assetIds, asset.id),
              )
            ? null
            : false,
      }
    }),
  )

  return (
    <Modal
      title={`Edit ${label}`}
      onClose={closeModals}
      layoutMode={LayoutMode.fit}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        {screenTexture && (
          <Stack tokens={{ childrenGap: 10 }}>
            <AssetInput
              label="Screen UI Preview"
              placeholder={['Upload']}
              status={preview(screenTexture.name, screenTexture.imageUri)}
            />
            <Text>{screenTexture?.name}</Text>
          </Stack>
        )}

        <CompatibleProducts
          products={compatibleProducts}
          mappings={mappings}
          setMappings={setMappings}
        />
        <PrimaryButton
          onClick={async () => {
            await Promise.all(
              _.map(screenTextures, screenTexture =>
                props.updateScreenTextureMappings({
                  textureId: screenTexture.id,
                  mappings: _.fromPairs(
                    _.map(
                      // Reject when in indeterminate state or not changed.
                      _.reject(
                        mappings,
                        ({ assetId, isEnabled }) =>
                          isEnabled === null ||
                          isEnabled ===
                            _.includes(screenTexture.assetIds, assetId),
                      ),
                      ({ assetId, isEnabled }) => [assetId, isEnabled],
                    ),
                  ) as { [assetId: number]: boolean },
                }),
              ),
            )
            props.refreshAssets()
          }}
          text={`Save ${label}`}
        />
      </Stack>
    </Modal>
  )
}
