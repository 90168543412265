import _ from 'lodash'

import * as dto from '../../dto/api'
import { Doc } from '../doc/state'
import { getRenderJobThumbFromAPI } from '../jobs/dataTransformers'
import { PartialDocRevision } from './types'

export const revisionFromApi = (
  revision: dto.DocumentRevisionWithStatus,
  documentId: number,
): PartialDocRevision => {
  const docData = revision.data as Doc
  return {
    documentId,
    version: revision.version,
    doc: {
      ...docData,
      meta: {
        ...docData.meta,
        id: documentId,
      },
    },
    createdAt: revision.createdAt,
    createdBy: revision.createdBy,
    status: revision.renderStatus,
    proxyThumbnail: getRenderJobThumbFromAPI(revision.renderStatus.preview),
    finalThumbnail: getRenderJobThumbFromAPI(revision.renderStatus.final),
  }
}

export const revisionsFromApi = (
  revisions: dto.DocumentRevisionWithStatus[],
  documentId: number,
): PartialDocRevision[] =>
  _.map(revisions, revision => revisionFromApi(revision, documentId))
