import { connect } from 'react-redux'

import { DropdownInput as Component } from '../components/DropdownInput'
import { Dispatch, State } from '../store'
import { setInDoc } from '../store/doc/actions'
import { Bloc, Option } from '../store/doc/types'
import { pathToId } from '../utils'

type OwnProps<T extends Option> = {
  bloc: Bloc<T>
  label: string
}

const mapStateToProps = <T extends Option>(
  state: State,
  ownProps: OwnProps<T>,
) => {
  const { bloc } = ownProps
  return {
    id: pathToId(bloc.path),
    value: bloc.value,
    options: bloc.options,
    isOptional: bloc.isOptional,
    isUnavailable: bloc.isUnavailable,
    disabled: bloc.isDisabled,
  }
}

const mapDispatchToProps = <T extends Option>(
  dispatch: Dispatch,
  ownProps: OwnProps<T>,
) => {
  const { bloc } = ownProps
  return {
    setValue: (value?: T) => dispatch(setInDoc({ path: bloc.path, value })),
  }
}

const DropdownInput = connect(mapStateToProps, mapDispatchToProps)(Component)

export { DropdownInput }
