import { Admin } from '../../pages/Admin'
import { Assets } from '../../pages/Assets'
import { Campaigns } from '../../pages/Campaigns'
import { Doc } from '../../pages/Doc'
import { Revisions } from '../../pages/Doc/Revisions'
import { NotFound } from '../../pages/NotFound'
import { ScreenTextures } from '../../pages/ScreenTextures'
import { CanvasTextures } from '../../pages/CanvasTextures'
import { Specs } from '../../pages/Specs'
import { Unauthorized } from '../../pages/Unauthorized'
import { Documents } from '../../pages/documents/Documents'
import { Templates } from '../../pages/documents/Templates'
import { UsersPage } from '../../pages/users/UsersPage'
import { RouteDef } from './state'

export const routes: RouteDef[] = [
  {
    path: '/',
    meta: { component: Campaigns },
  },
  {
    path: '/campaign/:campaignId',
    meta: { component: Documents },
  },
  {
    path: '/campaign/:campaignId/templates',
    meta: { component: Templates },
  },
  {
    path: '/campaign/:campaignId/screen-uis',
    meta: { component: ScreenTextures },
  },
  {
    path: '/campaign/:campaignId/foregrounds',
    meta: { component: CanvasTextures, textureType: 'FOREGROUND' },
  },
  {
    path: '/campaign/:campaignId/backgrounds',
    meta: { component: CanvasTextures, textureType: 'BACKGROUND' },
  },
  {
    path: '/campaign/:campaignId/document',
    meta: { component: Doc },
  },
  {
    path: '/campaign/:campaignId/document/template',
    meta: { component: Doc, isNewTemplate: true },
  },
  {
    path: '/campaign/:campaignId/document/:documentId',
    meta: { component: Doc },
  },
  {
    path: '/campaign/:campaignId/document/:documentId/from-template',
    meta: { component: Doc, fromTemplate: true },
  },
  {
    path: '/campaign/:campaignId/document/:documentId/versions',
    meta: { component: Revisions },
  },
  {
    path: '/specs',
    meta: { component: Specs },
  },
  {
    path: '/assets',
    meta: { component: Assets },
  },
  {
    path: '/users',
    meta: { component: UsersPage },
  },
  {
    path: '/403',
    meta: {
      component: Unauthorized,
      isIndependent: true,
    },
  },
  {
    path: '/403/area-restricted',
    meta: {
      component: Unauthorized,
      isIndependent: true,
      contentId: 'areaRestricted',
    },
  },
  {
    path: '/403/account-disabled',
    meta: {
      component: Unauthorized,
      isIndependent: true,
      contentId: 'accountDisabled',
    },
  },
  {
    path: '/403/invite-expired',
    meta: {
      component: Unauthorized,
      isIndependent: true,
      contentId: 'inviteExpired',
    },
  },
  {
    path: '/404',
    meta: {
      component: NotFound,
      isIndependent: true,
    },
  },
  {
    path: '/admin',
    meta: { component: Admin },
  },
]
