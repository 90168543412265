import type { Action as ReduxAction, Dispatch } from 'redux'
import http from '../../http'
import { accountFromApi } from './dataTransformers'
import type { AccountInfo } from './state'

import type * as dto from '../../dto/api'
import { ConfigActions } from '../config/actions'
import { getAssetBucketUri } from '../config/dataTransformers'
import { setSentryUser } from '../../sentry'

export type Action = SetUnauthorized | LoadAccount | LoadedAccount

export enum AuthActions {
  SetUnauthorized = 'SET_UNAUTHORIZED',
  LoadAccount = 'LOAD_ACCOUNT',
  LoadedAccount = 'LOADED_ACCOUNT',
}

export interface SetUnauthorized extends ReduxAction<AuthActions> {
  type: AuthActions.SetUnauthorized
}

export const setUnauthorized = async (
  dispatch: Dispatch,
): Promise<ReduxAction> => {
  return dispatch({
    type: AuthActions.SetUnauthorized,
  })
}

export interface LoadAccount extends ReduxAction<AuthActions> {
  type: AuthActions.LoadAccount
}

export const loadAccount = () => async (dispatch: Dispatch) => {
  dispatch({
    type: AuthActions.LoadAccount,
  })

  const accountResp = await http.get<dto.GetAccountInfoResp>('/account/info')

  const account = accountFromApi(accountResp.data)

  setSentryUser(account)

  dispatch({
    type: AuthActions.LoadedAccount,
    payload: { account },
  })

  const bucket = accountResp.data.bucket

  dispatch({
    type: ConfigActions.SetConfig,
    payload: { assetBucketUri: getAssetBucketUri(bucket) },
  })
}

export interface LoadedAccount extends ReduxAction<AuthActions> {
  type: AuthActions.LoadedAccount
  payload: { account: AccountInfo }
}
