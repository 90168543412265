import {
  FontWeights,
  INavLink,
  INavLinkGroup,
  IStyle,
  Nav,
  Text,
} from 'office-ui-fabric-react'
import React from 'react'

export interface Props {
  groups: INavLinkGroup[]
  onLinkClick: (
    e?: React.MouseEvent<HTMLElement, MouseEvent>,
    item?: INavLink,
  ) => void
}

const styles = {
  nav: {
    groupContent: {
      animation: 'none',
      margin: 0,
    },
    navItems: {
      margin: 0,
    },
    link: {
      backgroundColor: '#F3F2F1',
      paddingLeft: 12,
      selectors: {
        '.is-selected &, .is-selected &:hover': {
          backgroundColor: '#C7E0F4',
          fontWeight: FontWeights.semibold,
          color: '#005A9E',
          selectors: {
            '&:after': {
              display: 'none',
            } as IStyle,
          },
        },
        '& .ms-Button-icon, &:hover .ms-Button-icon': {
          fontSize: 12,
          color: '#323130',
        },
        '.is-selected & .ms-Button-icon': {
          color: '#005A9E',
        },
      },
    },
  },
  group: {
    root: {
      fontSize: 12,
      lineHeight: 21,
      fontWeight: FontWeights.semibold,
      paddingTop: 10,
      paddingBottom: 5,
      marginLeft: 17,
      display: 'block',
    } as IStyle,
  },
}

export const Navigation = (props: Props) => {
  return (
    <Nav
      onLinkClick={props.onLinkClick}
      styles={styles.nav}
      onRenderGroupHeader={renderGroupHeader}
      groups={props.groups}
    />
  )
}

const renderGroupHeader = (group?: INavLinkGroup) => {
  if (!group) {
    return null
  }

  return (
    <div>
      <Text styles={styles.group}>{group.name}</Text>
    </div>
  )
}
