import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { State } from '../../store'
import { duplicateDoc, toggleTemplate } from '../../store/doc/actions'
import { push } from '../../store/routing/actions'
import { Doc, DocumentsPage } from './DocumentsPage'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  route: state.routing.route,
  campaign: state.campaign,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      duplicateDoc,
      toggleTemplate,
    },
    dispatch,
  )

export const Documents = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { campaign } = props

  const [selectedItems, setSelectedItems] = useState<Doc[]>([])
  const [itemsToDelete, setItemsToDelete] = useState<Doc[]>([])
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const campaignDocuments = _.filter(
    campaign.documents,
    document => !document.isTemplate,
  )

  const defaultActionItems = [
    {
      key: 'create_document',
      text: 'Create Document',
      iconProps: {
        iconName: 'add',
      },
      onClick: () => {
        props.push(`/campaign/${campaign.id}/document`)
      },
    },
  ]

  const singleActionItems = (item?: Doc) => {
    const document: Doc | undefined = item ? item : selectedItems[0]
    if (!document) {
      return []
    }
    return [
      {
        key: 'edit_document',
        text: 'Edit Document',
        iconProps: {
          iconName: 'edit',
        },
        onClick: () => {
          edit(document)
        },
      },
      {
        key: 'duplicate_document',
        text: 'Duplicate Document',
        iconProps: {
          iconName: 'copy',
        },
        onClick: () => {
          duplicate(document)
        },
      },
    ]
  }

  const multiActionItems = (items?: Doc[]) => {
    const documents = items ? items : selectedItems
    if (documents.length === 0) {
      return []
    }
    const isDownloadAvailable = _.every(
      documents,
      document => document.renderStatus.download,
    )
    return _.compact([
      {
        key: 'convert_template',
        text: 'Convert to Template',
        iconProps: {
          iconName: 'fileTemplate',
        },
        onClick: () => {
          convertToTemplate(documents)
        },
      },
      isDownloadAvailable && {
        key: 'download',
        text: `Download Document${documents.length === 1 ? '' : 's'}`,
        iconProps: {
          iconName: 'download',
        },
        onClick: () => {
          downloadDocs(documents)
        },
      },
      {
        key: 'delete',
        text: 'Delete',
        iconProps: {
          iconName: 'delete',
        },
        className: 'is-dangerous',
        onClick: () => {
          deleteItems(documents)
        },
      },
    ])
  }

  const convertToTemplate = (docs: Doc[]) => {
    return Promise.all(
      _.map(docs, doc => {
        props.toggleTemplate({
          docId: doc.id,
          value: true,
        })
      }),
    )
  }

  const downloadDocs = (docs: Doc[]) => {
    return Promise.all(
      _.map(docs, doc => {
        if (!doc.renderStatus.download) {
          return
        }
        doc.renderStatus.download()
      }),
    )
  }

  const edit = (doc: Doc) => {
    props.push(`/campaign/${campaign.id}/document/${doc.id}`)
  }

  const duplicate = async (doc: Doc) => {
    const docMeta = await props.duplicateDoc({ docId: doc.id })
    props.push(`/campaign/${campaign.id}/document/${docMeta.id}`)
  }

  const deleteItems = (items: Doc[]) => {
    setItemsToDelete(items)
    setIsDialogVisible(true)
  }

  return (
    <DocumentsPage
      pageName="Document"
      documents={campaignDocuments}
      defaultActionItems={defaultActionItems}
      singleActionItems={(item?: Doc) => singleActionItems(item)}
      multiActionItems={(items?: Doc[]) => multiActionItems(items)}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      itemsToDelete={itemsToDelete}
      isDialogVisible={isDialogVisible}
      setIsDialogVisible={setIsDialogVisible}
      mainAction={edit}
      mainActionTitle="Edit Document"
    />
  )
})
