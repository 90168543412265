import { FontWeights, IStyle } from 'office-ui-fabric-react'

export const label = {
  base: {
    root: {
      minWidth: 100,
      maxWidth: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
      top: 2,
      fontWeight: FontWeights.semibold,
    } as IStyle,
  },
  long: {
    root: {
      width: 148,
    },
  },
}

export const input = {
  root: {
    flexGrow: 1,
  } as IStyle,
}

export const formTitle = {
  root: {
    fontSize: 20,
    fontWeight: FontWeights.semibold,
    // To compensate childrenGap set in modal forms and slightly more.
    marginBottom: -20,
  },
}

export const mediaQuery = {
  isTabletOrMobile: '@media only screen and (max-width: 1024px)',
  isMobile: '@media only screen and (max-width: 430px)',
}
