import _ from 'lodash'
import { Label, Stack, memoizeFunction } from 'office-ui-fabric-react'
import React from 'react'

import { AssetType } from '../../store/assets/types'
import { Asset } from '../../store/doc/types'
import { pathToId } from '../../utils'
import { SceneObjectActions } from '../../containers/SceneObjectActions'
import { SliderInput } from '../../containers/SliderInput'
import { TextGroupInput } from '../../containers/TextGroupInput'
import { SceneObjectBlocs } from '../../store/doc/selectors'
import { Property } from './Property'

type Props = {
  sceneObjectUuid: string
  sceneObjectBloc: SceneObjectBlocs
  setInDoc: (payload: { path: string[]; value: any }) => void
  isAddActionVisible: boolean
  assetType: AssetType
  assets: Asset[]
}

const getStyles = memoizeFunction(isAddActionVisible => {
  return {
    header: {
      root: {
        marginLeft: -10,
        marginRight: -10,
        borderTop: isAddActionVisible ? undefined : 'solid 1px #E5E5E5',
        borderBottom: 'solid 1px #E5E5E5',
      },
    },
    label: {
      root: {
        paddingLeft: 10,
      },
    },
    content: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  }
})

export const EnvironmentObjectPanel = (props: Props) => {
  const {
    sceneObjectUuid,
    sceneObjectBloc,
    isAddActionVisible,
    assetType,
    assets,
  } = props

  const { sceneObject } = sceneObjectBloc

  const styles = getStyles(isAddActionVisible)

  return (
    <Stack>
      <Stack
        styles={styles.header}
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
      >
        <Stack horizontal verticalAlign="center">
          <Label styles={styles.label}>{sceneObject?.name}</Label>
        </Stack>
        <Stack>
          <SceneObjectActions
            sceneObjectUuid={sceneObjectUuid}
            sceneObjectBloc={sceneObjectBloc}
            hiddenActions={['duplicate']}
            assetType={assetType}
            assets={assets}
          />
        </Stack>
      </Stack>
      <Stack styles={styles.content} tokens={{ childrenGap: 10 }}>
        <TextGroupInput
          blocs={[
            sceneObjectBloc.position.x,
            sceneObjectBloc.position.y,
            sceneObjectBloc.position.z,
          ]}
          label="Position"
          unit='"'
          labels={['X', 'Y', 'Z']}
        />
        <SliderInput bloc={sceneObjectBloc.rotation.x} label="Rotation X" />
        <SliderInput bloc={sceneObjectBloc.rotation.y} label="Rotation Y" />
        <SliderInput bloc={sceneObjectBloc.rotation.z} label="Rotation Z" />
        {_.map(sceneObjectBloc.properties, (bloc: any) => {
          // TODO use selector-based type for SceneObjects in IProps to the hack with `any`
          return <Property key={pathToId(bloc.path)} bloc={bloc} />
        })}
      </Stack>
    </Stack>
  )
}
