import {
  IStyle,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import React, { useEffect, useRef, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { Loader } from '../../components/Loader'
import * as dto from '../../dto/api'
import http from '../../http'
import { FileType, download } from '../../lib/fileUtils'
import { State } from '../../store'
import { renderStatusFromApi } from '../../store/campaign/dataTransformers'
import { saveDoc } from '../../store/doc/actions'
import { getDocBlocs } from '../../store/doc/selectors'
import { isDocUndoAvailableSelector } from '../../store/docHistory/selectors'
import { initiateRender, copyRenderJobData } from '../../store/jobs/actions'
import {
  getCompositeUrl,
  getRenderDuration,
  getRenderFilename,
  getZipUrl,
} from '../../store/jobs/utils'
import { LoadState } from '../../store/loaders/types'
import { push } from '../../store/routing/actions'
import { uiSelector } from '../../store/ui/selectors'
import { Rejuvenate } from '../../components/Rejuvenate'

const RELOAD_INTERVAL = 3 * 1000

type OwnProps = {
  documentId: number
  version: number
  containerWidth: number
  containerHeight: number
  renderType: dto.RenderType
  docError?: string
  setSaving: (isSaving: boolean) => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    ...ownProps,
    docBlocs: getDocBlocs(state),
    isNewDoc: state.routing.route.params.documentId === undefined,
    isDocUndoAvailable: isDocUndoAvailableSelector(state),
    campaign: state.campaign,
    isArtistMode: uiSelector(state).isArtistMode,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      initiateRender,
      copyRenderJobData,
      saveDoc,
      push,
    },
    dispatch,
  )

const styles = {
  downloadWrapper: {
    root: {
      position: 'absolute',
      bottom: 40,
      maxWidth: 450,
    } as IStyle,
  },
  button: {
    root: {
      width: 200,
    },
  },
  renderTime: {
    root: {
      color: '#FFFFFF',
    },
  },
}

const loadStatus = async (documentId: number, version: number) => {
  const response = await http.get<dto.GetDocumentRenderStatusResp>(
    `/api/document/${documentId}/${version}/renders`,
  )
  return renderStatusFromApi(response.data) as dto.RenderStatus
}

type DownloadActionsProps = {
  renderType: dto.RenderType
  docName: string
  version: number
  imageUrl: string
  zipUrl?: string
}

const DownloadActions = (props: DownloadActionsProps) => {
  const { renderType, docName, version, imageUrl, zipUrl } = props

  const fileName = getRenderFilename(docName, version, renderType)

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      wrap
      styles={styles.downloadWrapper}
      tokens={{ childrenGap: 10 }}
    >
      <PrimaryButton
        styles={styles.button}
        onClick={() => {
          download(imageUrl, fileName, FileType.Png)
        }}
      >
        Download Composite
      </PrimaryButton>
      {zipUrl && (
        <PrimaryButton
          styles={styles.button}
          onClick={() => {
            download(zipUrl, fileName, FileType.Zip)
          }}
        >
          Download Layers
        </PrimaryButton>
      )}
    </Stack>
  )
}

type ContentProps = Props & {
  jobStatus?: dto.RenderJob
  renderStatus?: dto.RenderStatus
  setRenderStatus: (status: dto.RenderStatus) => void
}

const Content = (props: ContentProps) => {
  let { documentId, version } = props
  const {
    renderType,
    isDocUndoAvailable,
    isNewDoc,
    docError,
    setSaving,
    campaign,
    jobStatus,
    renderStatus,
    setRenderStatus,
    push,
    docBlocs,
    isArtistMode,
  } = props

  const docName = docBlocs.meta.name.value

  const isDocValid = !docError

  const needsSaving = isNewDoc || isDocUndoAvailable

  const [clipBoardState, copyToClipboard] = useCopyToClipboard()
  let clipboardIconName = 'ClipboardSolid'
  if (clipBoardState.error) {
    clipboardIconName = 'StatusCircleErrorX'
  } else if (clipBoardState.value) {
    clipboardIconName = 'StatusCircleCheckmark'
  }

  if (!jobStatus || isDocUndoAvailable) {
    return (
      <>
        {isArtistMode && (
          <PrimaryButton
            iconProps={{
              iconName: clipboardIconName,
            }}
            disabled={!isDocValid}
            styles={styles.button}
            onClick={() => {
              props.copyRenderJobData(renderType, copyToClipboard)
            }}
          >
            Copy Commands
          </PrimaryButton>
        )}
        <PrimaryButton
          disabled={!isDocValid}
          styles={styles.button}
          onClick={async () => {
            if (!isDocValid) {
              return
            }

            if (needsSaving) {
              setSaving(true)
              const docMeta = await props.saveDoc()
              setSaving(false)
              documentId = docMeta.id
              version = docMeta.version
            }

            await props.initiateRender(documentId, version, renderType)

            setRenderStatus({
              ...(needsSaving ? {} : renderStatus), // Reset status for other renderType if it's a new version.
              [renderType === 'PREVIEW' ? 'preview' : 'final']: {
                state: 'QUEUED',
                type: renderType,
                documentId,
                documentVersion: version,
                metadata: {},
                retries: 0,
                attempts: 0,
                createdAt: new Date().toISOString(),
                delayedTo: new Date().toISOString(),
              },
            })

            if (isNewDoc) {
              push(`/campaign/${campaign.id}/document/${documentId}`)
            }
          }}
        >
          {needsSaving ? 'Save & Initiate Render' : 'Initiate Render'}
        </PrimaryButton>
        {!isDocValid && (
          <MessageBar messageBarType={MessageBarType.error}>
            {docError}
          </MessageBar>
        )}
      </>
    )
  }

  if (jobStatus.state === 'QUEUED') {
    return (
      <Stack horizontalAlign="center">
        <PrimaryButton disabled styles={styles.button}>
          Render Queued
        </PrimaryButton>
        <Rejuvenate>
          {() => (
            <Text styles={styles.renderTime}>
              {getRenderDuration(jobStatus)}
            </Text>
          )}
        </Rejuvenate>
      </Stack>
    )
  }

  if (jobStatus.state === 'FAILED') {
    return (
      <Stack horizontalAlign="center">
        <PrimaryButton
          styles={styles.button}
          onClick={() => {
            props.initiateRender(documentId, version, renderType)
          }}
        >
          Render Failed
        </PrimaryButton>
        <Rejuvenate>
          {() => (
            <Text styles={styles.renderTime}>
              {getRenderDuration(jobStatus)}
            </Text>
          )}
        </Rejuvenate>
      </Stack>
    )
  }

  if (jobStatus.state === 'SUCCEEDED' && jobStatus.resultUri) {
    const imageUri = getCompositeUrl(jobStatus.resultUri, renderType)
    const zipUri =
      renderType === 'FINAL' ? getZipUrl(jobStatus.resultUri) : undefined
    return (
      <>
        <img
          src={imageUri}
          alt=""
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: props.containerWidth,
            maxHeight: props.containerHeight,
            cursor: 'pointer',
          }}
        />
        <DownloadActions
          renderType={renderType}
          docName={docName}
          version={version}
          imageUrl={imageUri}
          zipUrl={zipUri}
        />
      </>
    )
  }

  return null
}

type LoadStatusEffectArgs = {
  documentId: number
  version: number
  isNewDoc: boolean
  loadState: LoadState
  setLoadState: (state: LoadState) => void
  renderStatus?: dto.RenderStatus
  setRenderStatus: (status?: dto.RenderStatus) => void
  jobStatus?: dto.RenderJob
}

const useLoadStatusEffect = ({
  documentId,
  version,
  isNewDoc,
  loadState,
  setLoadState,
  renderStatus,
  setRenderStatus,
  jobStatus,
}: LoadStatusEffectArgs) => {
  const timeoutIdRef = useRef<number>()

  useEffect(() => {
    // When version has changed, reset everything.
    setRenderStatus({})
    setLoadState(LoadState.NotLoading)
  }, [version, setRenderStatus, setLoadState])

  useEffect(() => {
    const run = async () => {
      if (isNewDoc) {
        return
      }

      if (loadState === LoadState.NotLoading) {
        setLoadState(LoadState.Loading)
        const nextStatus = await loadStatus(documentId, version)
        setRenderStatus(nextStatus)
        setLoadState(LoadState.Loaded)
        return
      }

      // Do nothing if it's not in queue because state can no longer change.
      if (jobStatus?.state !== 'QUEUED') {
        return
      }

      timeoutIdRef.current = window.setTimeout(async () => {
        const nextStatus = await loadStatus(documentId, version)
        setRenderStatus(nextStatus)
      }, RELOAD_INTERVAL)
    }

    run()

    return function () {
      window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined
    }
  }, [
    documentId,
    version,
    isNewDoc,
    loadState,
    setLoadState,
    renderStatus,
    setRenderStatus,
    jobStatus,
  ])
}

export const RemoteRender = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { documentId, version, renderType, isNewDoc } = props

  const [loadState, setLoadState] = useState<LoadState>(LoadState.NotLoading)
  const [renderStatus, setRenderStatus] = useState<dto.RenderStatus>()

  const jobStatus =
    renderStatus?.[renderType === 'PREVIEW' ? 'preview' : 'final']

  useLoadStatusEffect({
    documentId,
    version,
    isNewDoc,
    loadState,
    setLoadState,
    renderStatus,
    setRenderStatus,
    jobStatus,
  })

  if (!isNewDoc && loadState !== LoadState.Loaded) {
    return <Loader />
  }

  return (
    <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center">
      <Content
        {...props}
        jobStatus={jobStatus}
        renderStatus={renderStatus}
        setRenderStatus={setRenderStatus}
      />
    </Stack>
  )
})
