import { connect } from 'react-redux'
import type { Action, Dispatch } from 'redux'

import { Panel as PanelComponent } from '../components/Panel'
import type { State } from '../store'
import { togglePanelState } from '../store/ui/actions'
import { PanelState } from '../store/ui/types'

type OwnProps = {
  id: string
  label?: string
  isForcedOpen?: boolean
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const panelState =
    state.ui.isCollapsed[ownProps.id] && !ownProps.isForcedOpen
      ? PanelState.Closed
      : PanelState.Open

  return {
    ...ownProps,
    panelState,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps) => {
  return {
    togglePanelState: () => {
      dispatch(togglePanelState(ownProps.id))
    },
  }
}

const Panel = connect(mapStateToProps, mapDispatchToProps)(PanelComponent)

export { Panel }
