import {
  FontWeights,
  IStyle,
  IconButton,
  Modal as FabricModal,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import React from 'react'

interface Props {
  title: string
  onClose: () => void
  layoutMode?: LayoutMode
  children?: React.ReactNode
  actions?: JSX.Element
}

export enum LayoutMode {
  large = 0,
  fit = 1,
}

const contentStyles = {
  padding: 20,
  height: 'calc(100% - 40px)',
}

const styles = {
  layout: {
    large: {
      root: {
        width: '90vw',
        height: '80vh',
        maxWidth: 900,
        maxHeight: 600,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      } as IStyle,
    },
    fit: {
      root: {
        minWidth: 360,
        maxWidth: 900,
        maxHeight: 600,
        overflow: 'auto',
      } as IStyle,
    },
  },
  header: {
    root: {
      height: 40,
      backgroundColor: '#0078D4',
      paddingLeft: 20,
    },
  },
  title: {
    root: {
      color: '#FFFFFF',
      fontSize: 20,
      fontWeight: FontWeights.semibold,
    },
  },
  close: {
    root: {
      color: '#FFFFFF',
      marginRight: 4,
    },
  },
  content: {
    default: {
      root: {
        ...contentStyles,
      },
    },
    actions: {
      root: {
        ...contentStyles,
        paddingBottom: 72,
      },
    },
  },
  actions: {
    root: {
      position: 'absolute',
      bottom: 0,
      padding: 20,
      paddingRight: 0,
      background: '#FFFFFF',
      width: 'calc(100% - 40px)',
    } as IStyle,
  },
}

export const Modal = (props: Props) => {
  const {
    title,
    onClose,
    children,
    actions,
    layoutMode = LayoutMode.large,
  } = props

  return (
    <FabricModal
      isOpen={true}
      onDismiss={onClose}
      layerProps={{
        // Fixes Dropzone not working in a Modal. This might cause issues if we nest modals.
        // https://github.com/OfficeDev/office-ui-fabric-react/issues/8491#issuecomment-477358905
        eventBubblingEnabled: true,
      }}
    >
      <Stack
        styles={styles.header}
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Text styles={styles.title}>{title}</Text>
        <IconButton
          iconProps={{ iconName: 'ChromeClose' }}
          title="Esc"
          ariaLabel="Close"
          onClick={onClose}
          styles={styles.close}
        />
      </Stack>
      <Stack
        styles={
          layoutMode === LayoutMode.large
            ? styles.layout.large
            : styles.layout.fit
        }
      >
        <Stack
          styles={actions ? styles.content.actions : styles.content.default}
        >
          {children}
        </Stack>
        {actions && (
          <Stack
            tokens={{ childrenGap: 10 }}
            horizontal
            styles={styles.actions}
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </FabricModal>
  )
}
