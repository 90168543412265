import _ from 'lodash'
import React from 'react'

import { SceneObjectBlocs } from '../../../store/doc/selectors'
import { pathToId } from '../../../utils'
import { Property } from '../Property'
import { Panel } from './../../../containers/Panel'
import { Label } from '../../../components/Label'
import { Stack } from 'office-ui-fabric-react'

type Props = {
  selectedObject: SceneObjectBlocs
}

export const SettingsPanel = (props: Props) => {
  const { selectedObject } = props

  const visibleProperties = _.filter(
    selectedObject.properties,
    ({ property }) => {
      // Hide lidJoint from UI. Remove this when renderer supports it.
      if (property.id === 'lidJoint') {
        return false
      }

      // Nothing to show here.
      if (
        property.type === 'isotropicScaling' ||
        property.type === 'anisotropicScaling'
      ) {
        return false
      }

      return true
    },
  )

  const children = _.isEmpty(visibleProperties) ? (
    <Stack
      horizontal
      horizontalAlign="center"
      styles={{ root: { height: 32 } }}
    >
      <Label text="No Settings for selected Object" />
    </Stack>
  ) : (
    _.map(visibleProperties, (bloc: any) => {
      return <Property key={pathToId(bloc.path)} bloc={bloc} />
    })
  )

  return (
    <Panel id="settings" label="Settings" isScrollable={true}>
      {children}
    </Panel>
  )
}
