import {
  DefaultButton,
  Icon,
  memoizeFunction,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import React from 'react'
import { HOME_URL } from '../utils'

interface IProps {
  isFullscreen?: boolean
  isBackgroundDark?: boolean
}

const getStyles = memoizeFunction((isBackgroundDark?: boolean) => {
  return {
    wrapper: {
      root: {
        backgroundColor: isBackgroundDark ? '#444444' : undefined,
        color: isBackgroundDark ? '#ffffff' : undefined,
        width: '100%',
      },
    },
    errorIcon: {
      root: {
        fontSize: 21,
      },
    },
    buttons: {
      root: {
        maxWidth: 200,
        width: '100%',
        paddingTop: 10,
      },
    },
  }
})

export const ErrorMessage = (props: IProps) => {
  const { isBackgroundDark, isFullscreen } = props
  const styles = getStyles(isBackgroundDark)

  return (
    <Stack
      styles={styles.wrapper}
      verticalFill
      verticalAlign="center"
      horizontalAlign="center"
      tokens={{ childrenGap: 10 }}
    >
      <Icon styles={styles.errorIcon} iconName="Error"></Icon>
      <Text>Oops! Something went wrong.</Text>
      {isFullscreen && (
        <Stack styles={styles.buttons}>
          <DefaultButton href={HOME_URL}>Return</DefaultButton>
        </Stack>
      )}
    </Stack>
  )
}
