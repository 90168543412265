import {
  CommandBar,
  ICommandBarItemProps,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'

interface IProps {
  quickActions?: ICommandBarItemProps[]
  overflowActions?: ICommandBarItemProps[]
  isSelected?: boolean
}

const getStyles = memoizeFunction((isSelected?: boolean) => {
  return {
    root: {
      height: 38,
      padding: 0,
      background: 'inherit',
      color: isSelected ? '#FFFFFF' : '#323130',
      selectors: {
        '& .ms-Button': {
          background: 'inherit',
        },
        '& .ms-Button-icon, & .ms-Button-menuIcon': {
          opacity: 0.2,
          color: '#323130',
        },
        '& .ms-Button:hover .ms-Button-icon, & .ms-Button:hover .ms-Button-menuIcon':
          {
            color: isSelected ? '#FFFFFF' : '#323130',
            opacity: 1,
          },
        '& .active .ms-Button-icon': {
          opacity: 1,
        },
      },
    },
  }
})

export const ActionsMenu = (props: IProps) => {
  const { quickActions = [], overflowActions = [], isSelected } = props

  return (
    <CommandBar
      styles={getStyles(isSelected)}
      items={quickActions}
      overflowItems={overflowActions}
    />
  )
}
