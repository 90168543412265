import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { Modal } from '../../components/Modal'
import { Table } from '../../components/Table'
import { ImageInput } from '../../containers/ImageInput'
import { State } from '../../store'
import { uploadImage } from '../../store/buckets/actions'
import { createCustomAssetTexture, setInDoc } from '../../store/doc/actions'
import {
  SceneObjectPropertyBloc,
  SceneObjectPropertyType,
} from '../../store/doc/types'

type OwnProps = {
  bloc: SceneObjectPropertyBloc<SceneObjectPropertyType.ImageTexture>
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  campaign: state.campaign,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInDoc,
      uploadImage,
      createCustomAssetTexture,
    },
    dispatch,
  )

export const ImageTextureProperty = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { bloc, setInDoc } = props

  const { property } = bloc

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const tableItems = _.map(bloc.options, texture => ({
    id: texture.id,
    texture,
    thumbnail: texture.imageUri,
    name: texture.name,
    // created: timeago.format(texture.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  return (
    <>
      <ImageInput
        bloc={bloc}
        label={bloc.label || ''}
        placeholder={[
          `No content`,
          `${property.xRatio} x ${property.yRatio} ratio`,
        ]}
        onSelect={() => {
          setModalOpen(true)
        }}
        isCanvasTexture={false}
      />
      {isModalOpen && (
        <Modal
          title={bloc.label}
          onClose={() => {
            setModalOpen(false)
          }}
        >
          <Table<TItem>
            columns={[
              { key: 'thumbnail', name: 'Thumbnail', width: 128 },
              { key: 'name', name: 'Name', maxWidth: 9999 },
              // { key: 'created', name: 'Created', width: 100 },
            ]}
            defaultSort={null}
            items={tableItems}
            onItemClick={({ texture }) => {
              setInDoc({ path: bloc.path, value: texture })
              setModalOpen(false)
            }}
          />
        </Modal>
      )}
    </>
  )
})
