import _ from 'lodash'

import type { Action } from '../actions'
import { DocActions } from '../doc/actions'
import { CampaignActions } from './actions'
import { initialState } from './state'
import type { Campaign } from './types'
import { produce } from 'immer'

export const reducers = (
  state: Campaign = initialState(),
  action: Action,
): Campaign => {
  switch (action.type) {
    case CampaignActions.LoadCampaign:
      return state

    case CampaignActions.LoadedCampaign: {
      const { payload } = action
      return {
        ...(payload.base || state),
        assets: payload.assets || state.assets,
        documents: payload.documents || state.documents,
      }
    }

    case DocActions.SavedDoc: {
      const { payload } = action
      const { documentId, nextVersion } = payload

      return produce(state, nextState => {
        const doc = _.find(nextState.documents, doc => doc.id === documentId)
        if (!doc) {
          return
        }
        doc.version = nextVersion
      })
    }
  }

  return state
}
