import {
  DirectionalHint,
  IStyle,
  Icon,
  Text,
  TooltipHost,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'
import { mediaQuery } from '../styles'

type Props = {
  itemName: string
  tooltip: string | JSX.Element
  iconName?: string
  closeDelay?: number
  isDisabled?: boolean
  hideTooltip?: boolean
}

const getStyles = memoizeFunction(
  (iconName?: string, isDisabled?: boolean) => ({
    tooltipWrapper: {
      root: {
        width: '100%',
        display: 'flex',
        height: 36,
        lineHeight: 36,
        cursor: 'pointer',
        selectors: {
          '& .ms-ContextualMenu-item, &:hover': {
            backgroundColor: !iconName ? '#f3f2f1' : undefined,
          },
        },
      } as IStyle,
    },
    icon: {
      root: {
        fontSize: 16,
        margin: '0 4px',
        color: isDisabled ? '#c8c6c4' : '#0078d4',
        selectors: {
          [mediaQuery.isMobile]: {
            fontSize: 20,
          },
        },
      } as IStyle,
    },
    itemName: {
      root: {
        margin: '0 4px',
      },
    },
  }),
)

export const TooltipItem = (props: Props) => {
  const {
    itemName,
    iconName,
    tooltip,
    closeDelay = 500,
    isDisabled,
    hideTooltip,
  } = props

  const styles = getStyles(iconName, isDisabled)

  return (
    <TooltipHost
      id={itemName}
      styles={styles.tooltipWrapper}
      content={hideTooltip ? undefined : tooltip}
      closeDelay={closeDelay}
      directionalHint={DirectionalHint.bottomCenter}
      calloutProps={{ isBeakVisible: false, gapSpace: 10 }}
    >
      {iconName && <Icon styles={styles.icon} iconName={iconName}></Icon>}
      <Text styles={styles.itemName}>{itemName}</Text>
    </TooltipHost>
  )
}
