import * as Sentry from '@sentry/react'

import { store } from './store'
import type { AccountInfo } from './store/auth/state'

export const configureSentry = () => {
  Sentry.init({
    dsn: store.getState().config.sentryDsn,
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration,
      Sentry.captureConsoleIntegration,
    ],
    tracesSampleRate: 1.0,
  })
}
export const setSentryUser = (account: AccountInfo) =>
  Sentry.setUser({
    id: `${account.id}`,
    email: account.email,
    permissions: account.permissions,
  })

export const addSentryBreadcrumb = (breadcrumb: Sentry.Breadcrumb) =>
  Sentry.addBreadcrumb(breadcrumb)
