import _ from 'lodash'
import { IStyle, SpinButton as FabricSpinButton } from 'office-ui-fabric-react'
import React, { FormEvent } from 'react'

type Event = FormEvent<HTMLInputElement | HTMLElement>

export interface IProps {
  id: string
  value: string
  disabled: boolean
  onValueChange: (value: string) => void
  step?: number
  min?: number
  max?: number
}

export interface IState {
  isFocused: boolean
}

const styles = {
  root: {
    width: '100%',
    minWidth: 48,
    selectors: {
      '& > :last-child': {
        minWidth: 16,
      },
    },
  } as IStyle,
  arrowButtonsContainer: {
    display: 'none',
  } as IStyle,
  input: {
    minWidth: 16,
    width: '100%',
  },
}

export const SpinButton = (props: IProps) => {
  const {
    id,
    value,
    disabled,
    onValueChange,
    step = 1,
    min = -Infinity,
    max = Infinity,
  } = props

  const changeValue = _.debounce((inputText: string) => {
    const cleanText = inputText.replace(/,/g, '.').replace(/[^-0-9,.]/g, '')
    const newValue = Number(cleanText)
    const outputText = newValue.toString()

    if (cleanText !== outputText) {
      console.log(
        'ignoring inputText - cleanText does not match outputText',
        inputText,
        cleanText,
        outputText,
      )
      return
    }

    if (newValue < min || newValue > max) {
      console.log(
        'ignoring newValue - is not within bounds',
        newValue,
        min,
        max,
      )
      return
    }

    if (inputText === value) {
      console.log('ignoring inputText - equals to existing value', inputText)
      return
    }

    onValueChange(outputText)
  }, 1000)

  const onKeyUp = (event: Event): void => {
    const value = (event.target as HTMLInputElement).value
    changeValue(value)
  }

  const onBlur = (event: Event): void => {
    changeValue.flush()
  }

  return (
    <FabricSpinButton
      styles={styles}
      value={`${value}`}
      step={step}
      min={min}
      max={max}
      disabled={disabled as boolean}
      inputProps={{ id, onKeyUp }}
      onBlur={onBlur}
    />
  )
}
