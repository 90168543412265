import _ from 'lodash'
import { SelectionMode, PrimaryButton, Stack } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'

import { State } from '../store'
import { loadCampaigns } from '../store/campaigns/actions'
import { createCampaign } from '../store/campaign/actions'
import { isCampaignsReloadNeededSelector } from '../store/loaders/selectors'
import { LoadState } from '../store/loaders/types'
import { push } from '../store/routing/actions'
import { Header } from '../containers/Header'
import { HomeNavigation } from '../containers/Navigation'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { CommandBar } from '../components/CommandBar'
import { Page } from '../components/Page'
import { ColumnDef, SearchBoxPlacement, Table } from '../components/Table'
import { LayoutMode, Modal } from '../components/Modal'
import { TextInput } from '../components/TextInput'
import { filterByPermissions } from './users/utils'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  account: state.auth.account,
  campaigns: state.campaigns,
  isCampaignsReloadNeeded: isCampaignsReloadNeededSelector(state),
  loaders: state.loaders,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      loadCampaigns,
      createCampaign,
    },
    dispatch,
  )

const CampaignsDumb = (props: Props) => {
  const {
    account,
    campaigns,
    isCampaignsReloadNeeded,
    loadCampaigns,
    loaders,
    createCampaign,
  } = props

  const [isCreateCampaignModalOpen, setCreateCampaignModalOpen] =
    useState(false)
  const [campaignName, setCampaignName] = useState('')

  const tableItems = _.map(campaigns, partialCampaign => ({
    id: partialCampaign.id,
    name: partialCampaign.name,
    thumbnail: partialCampaign.thumbnail,
    documentCount: partialCampaign.documentCount,
    created: partialCampaign.createdAt,
    createdAgo: timeago.format(partialCampaign.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  useEffect(() => {
    if (isCampaignsReloadNeeded) {
      loadCampaigns()
    }
  }, [isCampaignsReloadNeeded, loadCampaigns])

  const commandBarItems = [
    {
      key: 'create_campaign',
      text: 'Create Campaign',
      iconProps: {
        iconName: 'add',
      },
      onClick: () => {
        setCreateCampaignModalOpen(true)
      },
      permissions: ['GLOBAL_ADMIN'],
    },
  ]

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'thumbnail', name: 'Thumbnail', width: 128 },
    { key: 'name', name: 'Name', minWidth: 100 },
    { key: 'documentCount', name: 'Documents', maxWidth: 9999 },
    { key: 'created', name: 'Created', width: 100, fieldName: 'createdAgo' },
  ]

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        props.push('/')
      },
    },
    {
      text: 'Campaigns',
      key: 'campaigns',
      onClick: () => {
        props.push('/')
      },
    },
    {
      text: 'Active',
      key: 'active',
      onClick: () => {
        props.push('/')
      },
    },
  ]

  const isLoading = loaders.campaignsState !== LoadState.Loaded

  return (
    <Page
      header={<Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />}
      sidebar={<HomeNavigation />}
      commandBar={
        <CommandBar
          items={filterByPermissions(commandBarItems, account.permissions)}
          requiresLeftOffset={true}
        />
      }
      body={
        <>
          <Table<TItem>
            columns={columnItems}
            defaultSort={{ key: 'created', desc: true }}
            items={tableItems}
            onItemClick={item => {
              props.push(`/campaign/${item.id}`)
            }}
            selectionMode={SelectionMode.none}
            searchBoxPlacement={SearchBoxPlacement.right}
          />

          {isCreateCampaignModalOpen && (
            <Modal
              title="New Campaign"
              onClose={() => {
                setCreateCampaignModalOpen(false)
              }}
              layoutMode={LayoutMode.fit}
            >
              <Stack tokens={{ childrenGap: 20 }}>
                <TextInput
                  id="name"
                  label="Name"
                  value={campaignName}
                  setValue={(value: string) => {
                    setCampaignName(value)
                  }}
                  autoFocus
                />
                <PrimaryButton
                  onClick={async () => {
                    await createCampaign({ name: campaignName })

                    setCreateCampaignModalOpen(false)
                    setCampaignName('')
                  }}
                >
                  Create Campaign
                </PrimaryButton>
              </Stack>
            </Modal>
          )}
        </>
      }
      isLoading={isLoading}
    />
  )
}

export const Campaigns = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignsDumb)
