import { Location } from 'history'

export interface Route {
  path: string
  meta: { [key: string]: any }
  queryOrder: string[]
  params: { [key: string]: string }
  query: { [key: string]: any }
}

export interface RouteDef {
  path: Route['path']
  meta?: Route['meta']
  queryOrder?: Route['queryOrder']
}

export interface Routing {
  route: Route
  location: Location
  isNotFound: boolean
}

export const initialState = (): Routing => ({
  route: {
    path: '',
    params: {},
    meta: {},
    query: {},
    queryOrder: [],
  },
  location: {
    pathname: '',
    search: '',
    hash: '',
    key: '',
    state: null,
  },
  isNotFound: false,
})
