import { LoadState } from './types'

export type Loaders = {
  campaignsState: LoadState
  isCampaignsReloadNeeded: boolean
  campaignState: { [campaignId: string]: LoadState }
  isCampaignReloadNeeded: boolean
  docState: LoadState
  isDocReloadNeeded: boolean
  isCampaignDocsReloadNeeded: boolean
  isCampaignAssetsReloadNeeded: boolean
  canvasPresetsState: LoadState
  isCanvasPresetsReloadNeeded: boolean
  assetsState: LoadState
  isAssetsReloadNeeded: boolean
  revisionsState: LoadState
  isRevisionsReloadNeeded: boolean
  authState: LoadState
}

export const initialState = (): Loaders => ({
  campaignsState: LoadState.NotLoading,
  isCampaignsReloadNeeded: false,
  campaignState: {},
  isCampaignReloadNeeded: false,
  docState: LoadState.NotLoading,
  isDocReloadNeeded: false,
  isCampaignDocsReloadNeeded: false,
  isCampaignAssetsReloadNeeded: false,
  canvasPresetsState: LoadState.NotLoading,
  isCanvasPresetsReloadNeeded: false,
  assetsState: LoadState.NotLoading,
  isAssetsReloadNeeded: false,
  revisionsState: LoadState.NotLoading,
  isRevisionsReloadNeeded: false,
  authState: LoadState.NotLoading,
})
