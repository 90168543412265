import { Action } from '../actions'
import { AssetsActions } from './actions'
import { Assets, initialState } from './state'

export const reducers = (
  state: Assets = initialState(),
  action: Action,
): Assets => {
  switch (action.type) {
    case AssetsActions.LoadedAssets:
      const { payload } = action
      return payload.assets
  }

  return state
}
