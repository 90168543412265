import _ from 'lodash'
import { createSelector } from 'reselect'

import { campaignSelector } from '../../../store/campaign/selectors'
import { CanvasTextureType } from '../../doc/types'
import { docSelector } from './doc'

export const visualsBackground = createSelector(
  [docSelector, campaignSelector],
  (doc, campaign) => {
    const path = ['visuals', 'background']
    const value = doc.visuals.background
    const options = _.filter(campaign.assets.canvasTextures, {
      type: CanvasTextureType.Background,
    })
    return {
      path,
      value,
      options,
    }
  },
)

export const visualsForeground = createSelector(
  [docSelector, campaignSelector],
  (doc, campaign) => {
    const path = ['visuals', 'foreground']
    const value = doc.visuals.foreground
    const options = _.filter(campaign.assets.canvasTextures, {
      type: CanvasTextureType.Foreground,
    })
    return {
      path,
      value,
      options,
    }
  },
)

export const visualsEnvironmentScene = createSelector([], () => {
  const path = ['visuals', 'environmentScene']
  const value = {
    id: 'holidayFY20',
    name: 'HolidayFY20 default',
    type: 'environmentScene',
    version: 0,
    thumbnail: '',
    isApproved: true,
  }
  return {
    path,
    value,
  }
})
