import _ from 'lodash'

import * as dto from '../../dto/api'
import * as dtoAssets from '../../dto/assets'
import { Assets } from './state'
import { Asset, AssetRevision, getAssetType, UnknownSettings } from './types'

export const asAssetSettings = (
  data: UnknownSettings,
): dtoAssets.DeviceSettings => {
  const { $schema, version, id } = data
  if (
    $schema !== 'https://rt.olvdev.com/schemas/v1/asset.json' ||
    version !== 1
  ) {
    console.error(
      'Unsupported Asset:\n' +
        JSON.stringify({
          $schema,
          version,
          id,
        }),
    )
  }
  return data as unknown as dtoAssets.DeviceSettings
}

export const assetFromApi = ($: dto.Device): Asset => {
  const dtoRevisions = _.chain($.revisions)
    .filter(revision => !!revision.settings)
    .sortBy(revision => revision.id)
    .value()
  const revisions: AssetRevision[] = _.map(dtoRevisions, (revision, index) => {
    const settings = asAssetSettings(revision.settings)
    return {
      id: revision.id,
      type: getAssetType(settings.type).name,
      version: index + 1,
      assetUri: revision.assetUri,
      uploadUri: revision.uploadUri,
      thumbnail: revision.assetUri + settings.thumbnail,
      thumbnailName: settings.thumbnail,
      createdAt: revision.createdAt,
    }
  })

  const lastDtoRevision = _.last(dtoRevisions)!
  const settings = asAssetSettings(lastDtoRevision!.settings)
  const lastRevision = _.last(revisions)!

  return {
    id: $.id,
    version: lastRevision.version,
    type: lastRevision.type,
    name: settings.name || $.name,
    thumbnail: lastRevision.thumbnail,
    revisions,
    createdAt: lastRevision.createdAt,
    createdBy: $.createdBy,
    assignedCampaigns: $.assignedCampaigns,
  }
}

export const assetsFromApi = ($: dto.Device[]): Assets => _.map($, assetFromApi)
