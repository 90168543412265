import { connect } from 'react-redux'

import {
  AssetInput as Component,
  type AssetStatus,
} from '../components/AssetInput'
import type { State } from '../store'
import type { Bloc } from '../store/doc/types'

type OwnProps = {
  bloc: Bloc<any>
  label: string
  onSelect?: () => void
  onUpload?: (file: File) => void
  onRemove?: () => void
  status?: AssetStatus
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { bloc } = ownProps
  const { value } = bloc
  return {
    id: bloc.path,
    value: bloc.value,
    preview: value?.imageUri ?? undefined,
    filename: value?.name ?? undefined,
    size: value?.size
      ? { width: value.size.width.value, height: value.size.height.value }
      : undefined,
    disabled: {
      select: bloc.isDisabled,
      upload: bloc.isDisabled,
    },
  }
}

const AssetInput = connect(mapStateToProps)(Component)

export { AssetInput }
