import { CanvasMedium, FocalLength } from '../doc/types'
import { PrintBleed, PrintResolution } from './types'

export const canvasMediumMap: { [key in CanvasMedium['id']]: CanvasMedium } = {
  print: {
    id: 'print',
    name: 'Print',
    props: {
      min: 1,
      max: 1,
      unit: '"',
      hasBleed: true,
    },
  },
  digital: {
    id: 'digital',
    name: 'Digital',
    props: {
      min: 1,
      max: 1,
      unit: 'px',
      hasBleed: true,
    },
  },
}

export const canvasMediums: CanvasMedium[] = [
  canvasMediumMap.digital,
  canvasMediumMap.print,
]

export const printResolutionMap: { [key in string]: PrintResolution } = {
  '72dpi': {
    id: '72dpi',
    name: '72 dpi',
    unit: 'dpi',
    value: 72,
  },
  '144dpi': {
    id: '144dpi',
    name: '144 dpi',
    unit: 'dpi',
    value: 144,
  },
  '300dpi': {
    id: '300dpi',
    name: '300 dpi',
    unit: 'dpi',
    value: 300,
  },
  '600dpi': {
    id: '600dpi',
    name: '600 dpi',
    unit: 'dpi',
    value: 600,
  },
}

export const printResolutions: PrintResolution[] = [
  printResolutionMap['72dpi'],
  printResolutionMap['144dpi'],
  printResolutionMap['300dpi'],
]

export const printBleedMap: { [key: string]: PrintBleed } = {
  '0.125"': {
    id: '0.125"',
    name: '1/8"',
    unit: '"',
    value: 1 / 8,
  },
  '4mm': {
    id: '4mm',
    name: '4mm',
    unit: 'mm',
    value: 4,
  },
  '1"': {
    id: '1"',
    name: '1"',
    unit: '"',
    value: 1,
  },
}

export const printBleeds: PrintBleed[] = [
  printBleedMap['0.125"'],
  printBleedMap['4mm'],
  printBleedMap['1"'],
]

export const focalLengths: FocalLength[] = [
  { id: '24mm', name: '24mm', unit: 'mm', value: 24 },
  { id: '35mm', name: '35mm', unit: 'mm', value: 35 },
  { id: '50mm', name: '50mm', unit: 'mm', value: 50 },
  { id: '90mm', name: '90mm', unit: 'mm', value: 90 },
  { id: '105mm', name: '105mm', unit: 'mm', value: 105 },
]
