import _ from 'lodash'

import * as dto from '../../dto/api'

export type Role = {
  id: dto.AccountRole
  name: string
}
export const roles: Role[] = [
  {
    id: 'EDITOR',
    name: 'Editor',
  },
  {
    id: 'CAMPAIGN_ADMIN',
    name: 'Campaign Admin',
  },
  {
    id: 'GLOBAL_ADMIN',
    name: 'Global Admin',
  },
]
export const roleMap = _.keyBy(roles, role => role.id)
export const roleNameMap = _.chain(roles)
  .keyBy(role => role.id)
  .mapValues(role => role.name)
  .value()
