import _ from 'lodash'
import { IContextualMenuProps, SelectionMode } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'

import { getAspectRatio } from '../../utils'
import { download } from '../../lib/fileUtils'
import { State } from '../../store'
import { AssetType } from '../../store/assets/types'
import {
  createScreenTexture,
  updateScreenTextureMappings,
  deleteScreenTextures,
  loadCampaign,
  reloadAssets,
  refreshAssets,
} from '../../store/campaign/actions'
import {
  isCampaignAssetsReloadNeededSelector,
  isCampaignReloadNeededSelector,
  campaignStateSelector,
} from '../../store/loaders/selectors'
import { LoadState } from '../../store/loaders/types'
import { push } from '../../store/routing/actions'
import { campaignIdSelector } from '../../store/routing/selectors'
import { Header } from '../../containers/Header'
import { CampaignNavigation } from '../../containers/Navigation'
import { AssetStatus, selectAsset } from '../../components/AssetInput'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import {
  CommandBar,
  CommandBarItem,
  setCommandBarItems,
} from '../../components/CommandBar'
import { Page } from '../../components/Page'
import { ColumnDef, SearchBoxPlacement, Table } from '../../components/Table'
import { CreateModal } from './CreateModal'
import { EditModal } from './EditModal'
import { DeleteDialog } from '../../components/DeleteDialog'
import { filterByPermissions, hasPermission } from '../users/utils'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

export type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  account: state.auth.account,
  campaignId: campaignIdSelector(state),
  campaign: state.campaign,
  isCampaignReloadNeeded: isCampaignReloadNeededSelector(state),
  isCampaignAssetsReloadNeeded: isCampaignAssetsReloadNeededSelector(state),
  campaignState: campaignStateSelector(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      loadCampaign,
      reloadAssets,
      refreshAssets,
      createScreenTexture,
      updateScreenTextureMappings,
      deleteScreenTextures,
    },
    dispatch,
  )

const ScreenTexturesDumb = (props: Props) => {
  const {
    account,
    campaignId,
    isCampaignReloadNeeded,
    isCampaignAssetsReloadNeeded,
    campaignState,
    campaign,
    loadCampaign,
    reloadAssets,
  } = props

  const { screenTextures } = campaign.assets

  useEffect(() => {
    const run = async () => {
      if (isCampaignReloadNeeded) {
        await loadCampaign({ campaignId })
      } else if (isCampaignAssetsReloadNeeded) {
        await reloadAssets({ campaignId })
        closeModals()
      }
    }
    run()
  }, [
    isCampaignReloadNeeded,
    campaignId,
    loadCampaign,
    isCampaignAssetsReloadNeeded,
    reloadAssets,
  ])

  const compatibleProducts = _.filter(
    campaign.assets.assets,
    asset => asset.type === AssetType.Product && asset.hasScreen,
  )

  const getMappedProductNames = (assetIds: number[]) => {
    if (!assetIds.length) {
      return '-'
    }

    const productNames = _.map(
      _.filter(
        compatibleProducts,
        asset => asset.type === 'product' && assetIds.includes(asset.id),
      ),
      asset => `${asset.name}, `,
    )

    const lastProductName = productNames[productNames.length - 1]

    if (!lastProductName) {
      return '-'
    }

    productNames[productNames.length - 1] = lastProductName.replace(',', '')
    return productNames
  }

  const tableItems = _.map(screenTextures, screenTexture => ({
    id: screenTexture.id,
    name: screenTexture.name,
    thumbnail: screenTexture.imageUri,
    products: getMappedProductNames(screenTexture.assetIds),
    aspectRatio: getAspectRatio(screenTexture.width, screenTexture.height),
    updated: screenTexture.createdAt,
    updatedAgo: timeago.format(screenTexture.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [itemIdsToEdit, setItemIdsToEdit] = useState<number[]>([])

  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const [itemsToDelete, setItemsToDelete] = useState<TItem[]>([])

  const [uploadState, setUploadState] = useState<AssetStatus>(selectAsset)

  const [selectedItems, setSelectedItems] = useState<TItem[]>([])
  const [contextualMenuProps, setContextualMenuProps] =
    useState<IContextualMenuProps>()

  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined)

  const defaultActionItems: CommandBarItem[] = [
    {
      key: 'add_screen_ui',
      text: 'Add Screen UI',
      iconProps: {
        iconName: 'add',
      },
      className: '',
      onClick: () => {
        setCreateModalOpen(true)
      },
      permissions: ['GLOBAL_ADMIN'],
    },
  ]

  const singleActionItems = (item?: TItem): CommandBarItem[] => {
    const selectedItem = item ? item : selectedItems[0]

    return [
      {
        key: 'download',
        text: 'Download',
        iconProps: {
          iconName: 'download',
        },
        className: '',
        onClick: () => {
          const screenTexture = _.find(
            screenTextures,
            img => img.id === selectedItem.id,
          )
          if (!screenTexture) {
            return
          }

          const imageUri = screenTexture.imageUri
          // get file type and remove it from filename
          const splitFileName = screenTexture.name.split('.').reverse()
          const fileType = splitFileName[0]
          const filename = splitFileName.slice(1).reverse().join('.')

          download(imageUri, `${filename}`, fileType)
        },
      },
    ]
  }

  const multiActionItems = (items?: TItem[]): CommandBarItem[] => {
    const screenTextures = items ? items : selectedItems
    return [
      {
        key: 'edit',
        text: 'Edit',
        iconProps: {
          iconName: 'edit',
        },
        className: '',
        onClick: () => {
          setItemIdsToEdit(_.map(screenTextures, 'id'))
        },
        permissions: ['GLOBAL_ADMIN'],
      },
      {
        key: 'delete',
        text: 'Delete',
        iconProps: {
          iconName: 'delete',
        },
        className: 'is-dangerous',
        onClick: () => {
          deleteItems(screenTextures)
        },
        permissions: ['GLOBAL_ADMIN'],
      },
    ]
  }

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'thumbnail', name: 'Thumbnail', width: 128 },
    { key: 'name', name: 'Name', width: 400 },
    { key: 'products', name: 'Products', width: 250 },
    { key: 'aspectRatio', name: 'Aspect Ratio', minWidth: 100, maxWidth: 9999 },
    { key: 'updated', name: 'Updated', width: 100, fieldName: 'updatedAgo' },
  ]

  const commandBarItems = setCommandBarItems(selectedItems.length, {
    none: defaultActionItems,
    single: _.concat(
      defaultActionItems,
      singleActionItems(),
      multiActionItems(),
    ),
    multi: _.concat(defaultActionItems, multiActionItems()),
  })

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        props.push('/')
      },
    },
    {
      text: props.campaign.name,
      key: props.campaign.name,
      onClick: () => {
        props.push(`/campaign/${campaign.id}`)
      },
    },
    {
      text: 'Screen UIs',
      key: 'screenUIs',
      onClick: () => {},
    },
  ]

  const isLoading =
    isCampaignAssetsReloadNeeded || campaignState !== LoadState.Loaded

  const onItemContextMenu = (item: TItem, index?: number, ev?: Event): void => {
    const contextualMenuProperties: IContextualMenuProps = {
      target: ev as MouseEvent,
      items: filterByPermissions(
        _.concat(singleActionItems(item), multiActionItems([item])),
        account.permissions,
      ),
      onDismiss: () => {
        setContextualMenuProps(undefined)
      },
    }

    if (index !== undefined && index > -1) {
      setContextualMenuProps(contextualMenuProperties)
    }
  }

  const deleteItems = (items: TItem[]) => {
    setItemsToDelete(items)
    setIsDialogVisible(true)
  }

  const closeModals = () => {
    setCreateModalOpen(false)
    setItemIdsToEdit([])
    setUploadState(selectAsset)
  }

  return (
    <>
      {isCreateModalOpen && (
        <CreateModal
          campaignId={campaignId}
          compatibleProducts={compatibleProducts}
          closeModals={closeModals}
          uploadState={uploadState}
          setUploadState={setUploadState}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          createScreenTexture={props.createScreenTexture}
          updateScreenTextureMappings={props.updateScreenTextureMappings}
          refreshAssets={props.refreshAssets}
        />
      )}
      {itemIdsToEdit.length > 0 && (
        <EditModal
          screenTextures={_.filter(screenTextures, screenTexture =>
            _.includes(itemIdsToEdit, screenTexture.id),
          )}
          compatibleProducts={compatibleProducts}
          closeModals={closeModals}
          updateScreenTextureMappings={props.updateScreenTextureMappings}
          refreshAssets={props.refreshAssets}
        />
      )}
      {isDialogVisible && (
        <DeleteDialog
          itemName="screen UI"
          onClose={() => {
            setIsDialogVisible(false)
          }}
          onSuccess={async () => {
            await props.deleteScreenTextures({
              screenTextureIds: _.map(itemsToDelete, item =>
                item.id.toFixed(0),
              ),
            })
            props.refreshAssets()
          }}
          itemsToDelete={itemsToDelete}
        />
      )}
      <Page
        header={
          <Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />
        }
        sidebar={<CampaignNavigation campaignId={campaign.id} />}
        commandBar={
          <CommandBar
            items={filterByPermissions(commandBarItems, account.permissions)}
            requiresLeftOffset={true}
          />
        }
        body={
          <Table<TItem>
            columns={columnItems}
            defaultSort={{ key: 'updated', desc: true }}
            items={tableItems}
            onItemClick={
              hasPermission(account.permissions, ['GLOBAL_ADMIN'])
                ? item => setItemIdsToEdit([item.id])
                : undefined
            }
            selectionMode={SelectionMode.multiple}
            searchBoxPlacement={SearchBoxPlacement.right}
            onSelectionChanged={setSelectedItems}
            contextualMenuProps={contextualMenuProps}
            onItemContextMenu={onItemContextMenu}
          />
        }
        isLoading={isLoading}
      />
    </>
  )
}

export const ScreenTextures = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScreenTexturesDumb)
