import { connect } from 'react-redux'
import { Header as HeaderBase } from '../components/Header'
import { State } from '../store'
import { AccountInfo } from '../store/auth/state'
import { push } from '../store/routing/actions'

type StateProps = {
  isFullscreenMode: boolean
  account: AccountInfo
}

type DispatchProps = {
  push: (path: string) => void
}

const mapStateToProps = (state: State): StateProps => {
  return {
    isFullscreenMode: state.ui.isFullscreenMode,
    account: state.auth.account,
  }
}

const mapDispatchToProps: DispatchProps = {
  push,
}

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderBase)
