import _ from 'lodash'
import { Label, Stack } from 'office-ui-fabric-react'
import { default as React, useMemo } from 'react'
import {
  CheckboxInput,
  LayoutMode as CheckboxLayoutMode,
} from '../components/CheckboxInput'

type SelectionItem = {
  id: number
  name: string
}

export type SelectionMappings = { [key: string]: boolean | null }

type SelectionLogic<T extends SelectionItem> = {
  getItem: (item: T) => boolean | null
  setItem: (item: T, checked: boolean) => void
  getAll: () => boolean | null
  setAll: (checked: boolean) => void
}

type Props<T extends SelectionItem> = {
  label: string
  items: T[]
  selectionLogic: SelectionLogic<T>
  disabled?: boolean
}

export const useSelectionLogic = <T extends SelectionItem>(
  mappings: SelectionMappings,
  setMappings: (mappings: SelectionMappings) => void,
) => {
  return useMemo(() => {
    const getItem = (item: T): boolean | null => {
      return mappings[item.id]
    }

    const setItem = (item: T, isChecked: boolean): void => {
      setMappings(_.defaults({ [item.id]: isChecked }, mappings))
    }

    const getAll = (): boolean | null => {
      return _.every(mappings, mapping => mapping === true)
        ? true
        : _.every(mappings, mapping => mapping === false)
          ? false
          : null
    }

    const setAll = (isChecked: boolean): void => {
      setMappings(_.mapValues(mappings, () => isChecked))
    }

    return {
      getItem,
      setItem,
      getAll,
      setAll,
    }
  }, [mappings, setMappings])
}

export const CheckboxList = <T extends SelectionItem>(props: Props<T>) => {
  const { label, items, selectionLogic, disabled } = props
  const { getItem, setItem, getAll, setAll } = selectionLogic
  const isCheckedRaw = getAll()
  const isChecked = isCheckedRaw !== null ? isCheckedRaw : true
  const isIndeterminate = isCheckedRaw === null
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Label>{label}</Label>
        {items.length > 1 && !disabled && (
          <CheckboxInput
            id="select-all"
            label="Select All"
            isChecked={isChecked}
            isIndeterminate={isIndeterminate}
            setValue={setAll}
            layoutMode={CheckboxLayoutMode.fixed}
          />
        )}
      </Stack>
      <Stack tokens={{ childrenGap: 10 }}>
        {_.map(items, item => {
          const isCheckedRaw = getItem(item)
          const isChecked = isCheckedRaw !== null ? isCheckedRaw : true
          const isIndeterminate = isCheckedRaw === null
          return (
            <CheckboxInput
              disabled={disabled}
              id={`${item.id}`}
              key={item.id}
              label={item.name}
              isChecked={isChecked}
              isIndeterminate={isIndeterminate}
              setValue={isChecked => setItem(item, isChecked)}
              layoutMode={CheckboxLayoutMode.fill}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
