import { EditorView, FitMode, UI } from './types'

const getViewportSize = () => {
  const sizes =
    typeof window === 'object' ? window : { innerWidth: 0, innerHeight: 0 }

  return { width: sizes.innerWidth, height: sizes.innerHeight }
}

const isSidebarVisible = () => {
  return getViewportSize().width >= 768
}

export const initialState = (): UI => ({
  fitMode: FitMode.Fit,
  isFullscreenMode: false,
  isNavigationSidebarVisible: isSidebarVisible(),
  isDocumentSidebarVisible: isSidebarVisible(),
  isSceneObjectsSidebarVisible: isSidebarVisible(),
  isCollapsed: {},
  isGizmoAdvancedMode: false,
  selectedPivot: EditorView.PreviewRender,
  viewport: getViewportSize(),
  isGridVisible: true,
  isArtistMode: false,
  _persist: { version: 0, rehydrated: false },
})
