import * as dto from '../../dto/assets'
import { PrintBleed, PrintResolution } from '../canvasPresets/types'
import { AssetType } from '../assets/types'

export interface Option {
  id: string | number
  name: string
}

export interface CanvasMedium extends Option {
  id: 'print' | 'digital'
  props: {
    min: number
    max: number
    unit: string
    hasBleed: boolean
  }
}

export interface Canvas {
  medium: CanvasMedium
  size: {
    width: UnitValue
    height: UnitValue
  }
  print: {
    resolution?: PrintResolution
    bleed?: PrintBleed
  }
}

export interface CanvasBgMapping {
  canvasId: string
  assetIds: string[]
}

export enum CanvasTextureType {
  Background = 'background',
  Foreground = 'foreground',
}

export interface CanvasTexture extends Option {
  id: number
  type: CanvasTextureType
  name: string
  imageUri: string
  isCustom: boolean
  createdAt: string
  width: number
  height: number
  canvasPresetId?: number
}

export interface AssetTexture extends Option {
  id: number
  name: string
  imageUri: string
  assetIds: number[]
  width: number
  height: number
  isCustom: boolean
  createdAt: string
}

export interface EnvironmentScene extends Option {
  type: string
  version: number
  thumbnail: string
  isApproved: boolean
}

export interface PresetOptionValue {
  object: string
  visible: boolean
}

export type MaterialOption = dto.MaterialOption

export interface PresetOption {
  id: string
  name: string
  thumbnail: string
  description?: string
  values: [PresetOptionValue, ...PresetOptionValue[]]
}

export enum SceneObjectPropertyType {
  Joint = 'joint',
  Emission = 'emission',
  ImageTexture = 'imageTexture',
  Preset = 'preset',
  Material = 'material',
  Hdri = 'hdri',
  IsotropicScaling = 'isotropicScaling',
  AnisotropicScaling = 'anisotropicScaling',
  Unknown = 'unknown',
}

interface SceneObjectPropertyBase {
  id: string
  type: SceneObjectPropertyType
  name: string
  description?: string
}

export enum ImageTextureSubtype {
  Screen = 'screen',
  ScreenReflection = 'screenReflection',
}

export interface SceneObjectPropertyJoint
  extends SceneObjectPropertyBase,
    dto.JointProperty {
  type: SceneObjectPropertyType.Joint
  subtype: 'rotationX' | 'rotationY' | 'rotationZ'
  object: string
  min: number
  max: number
  default: number
  minName: string
  maxName: string
  thumbnail: string
}

export interface SceneObjectPropertyEmission
  extends SceneObjectPropertyBase,
    dto.EmissionProperty {
  type: SceneObjectPropertyType.Emission
  material: string
  node: string
  min: number
  max: number
  default: number
}

export interface SceneObjectPropertyTexture
  extends SceneObjectPropertyBase,
    dto.ImageTextureProperty {
  type: SceneObjectPropertyType.ImageTexture
  subtype: ImageTextureSubtype
  material: string
  object: string
  node: string
  xRatio: number
  yRatio: number
}

export interface SceneObjectPropertyMaterial
  extends SceneObjectPropertyBase,
    dto.MaterialProperty {
  type: SceneObjectPropertyType.Material
  default: string
  options: [dto.MaterialOption, dto.MaterialOption, ...dto.MaterialOption[]]
}

export interface SceneObjectPropertyHdri
  extends SceneObjectPropertyBase,
    dto.HdriProperty {
  type: SceneObjectPropertyType.Hdri
}

export interface SceneObjectPropertyIsotropicScaling
  extends SceneObjectPropertyBase,
    dto.IsotropicScalingProperty {
  type: SceneObjectPropertyType.IsotropicScaling
}

export interface SceneObjectPropertyAnisotropicScaling
  extends SceneObjectPropertyBase,
    dto.AnisotropicScalingProperty {
  type: SceneObjectPropertyType.AnisotropicScaling
}

export interface SceneObjectPropertyPreset
  extends SceneObjectPropertyBase,
    dto.PresetProperty {
  type: SceneObjectPropertyType.Preset
  default: string
  options: [PresetOption, PresetOption, ...PresetOption[]]
}

export type SceneObjectProperty<
  T extends SceneObjectPropertyType = SceneObjectPropertyType.Unknown,
> = T extends SceneObjectPropertyType.Joint
  ? SceneObjectPropertyJoint
  : T extends SceneObjectPropertyType.Emission
    ? SceneObjectPropertyEmission
    : T extends SceneObjectPropertyType.ImageTexture
      ? SceneObjectPropertyTexture
      : T extends SceneObjectPropertyType.Material
        ? SceneObjectPropertyMaterial
        : T extends SceneObjectPropertyType.Hdri
          ? SceneObjectPropertyHdri
          : T extends SceneObjectPropertyType.IsotropicScaling
            ? SceneObjectPropertyIsotropicScaling
            : T extends SceneObjectPropertyType.AnisotropicScaling
              ? SceneObjectPropertyAnisotropicScaling
              : T extends SceneObjectPropertyType.Preset
                ? SceneObjectPropertyPreset
                :
                    | SceneObjectPropertyJoint
                    | SceneObjectPropertyEmission
                    | SceneObjectPropertyTexture
                    | SceneObjectPropertyMaterial
                    | SceneObjectPropertyHdri
                    | SceneObjectPropertyIsotropicScaling
                    | SceneObjectPropertyAnisotropicScaling
                    | SceneObjectPropertyPreset

export interface AssetRevision {
  id: number
  name: string
  assetId: string
  assetUri: string
  properties: SceneObjectProperty[]
  createdAt: string
}

export interface Asset {
  id: number
  type: 'product' | 'fixture' | 'light' | 'environment' | 'baseScene'
  name: string
  thumbnail: string
  isApproved: boolean
  hasScreen: boolean
  createdAt: string
  revisions: AssetRevision[]
}

export interface UnitValue {
  unit: string
  value: number
}

export interface FocalLength extends UnitValue {
  id: string
  name: string
}

export type SceneObjectPropertyValue<T extends SceneObjectPropertyType> =
  T extends SceneObjectPropertyType.Joint
    ? UnitValue
    : T extends SceneObjectPropertyType.Emission
      ? UnitValue
      : T extends SceneObjectPropertyType.ImageTexture
        ? AssetTexture
        : T extends SceneObjectPropertyType.Preset
          ? PresetOption
          : never

export type SceneObjectProperties = {
  [key: string]: SceneObjectPropertyValue<SceneObjectPropertyType>
}

export type SceneObject = {
  id: string
  type: AssetType
  assetId: number
  revisionId: number
  isLocked: boolean
  isHidden: boolean
  isSelected: boolean
  position: {
    x: UnitValue
    y: UnitValue
    z: UnitValue
  }
  rotation: {
    x: UnitValue
    y: UnitValue
    z: UnitValue
  }
  scale: {
    x: number
    y: number
    z: number
  }
  properties: SceneObjectProperties
}

export type SceneObjects = { [key: string]: SceneObject }

// -------
// GENERIC
// -------
export type BaseValue =
  | string
  | number
  | boolean
  | Option
  | UnitValue
  | PresetOption

export interface Bloc<T extends BaseValue = BaseValue> {
  path: string[]
  value: T | undefined
  isUnavailable?: boolean
  isOptional?: boolean
  isDisabled?: boolean
  min?: number
  max?: number
  step?: number
  options?: T[]
}

export type SceneObjectPropertyBloc<
  MP extends SceneObjectPropertyType = SceneObjectPropertyType,
> = Bloc<SceneObjectPropertyValue<MP>> & {
  property: SceneObjectProperty<MP>
  label: string
  path: string[]
  value: SceneObjectPropertyValue<MP>
  min?: number
  max?: number
  options?: SceneObjectPropertyValue<MP>[]
}

export type BlocGuard<B extends Bloc> = B
export type BlocArrayGuard<BA extends Bloc[]> = BA
export type BlocMapGuard<BM extends { [key: string]: Bloc }> = BM
