export enum PanelState {
  Open = 'open',
  Closed = 'closed',
}

export interface IPanel {
  state: PanelState
  label?: string
}

export enum FitMode {
  Fit = 'fit',
  Contain = 'contain',
  Max = 'max',
}

export enum EditorView {
  PlanView = 'planView',
  PreviewRender = 'previewRender',
  ProxyRender = 'proxyRender',
  FinalRender = 'finalRender',
}

export enum SidebarType {
  Navigation = 'navigation',
  Document = 'document',
  SceneObjects = 'sceneObjects',
}

export type Persist = {
  version: number
  rehydrated: boolean
}

export type UI = {
  fitMode: FitMode
  isFullscreenMode: boolean
  isNavigationSidebarVisible: boolean
  isDocumentSidebarVisible: boolean
  isSceneObjectsSidebarVisible: boolean
  isCollapsed: { [key: string]: boolean }
  isGizmoAdvancedMode: boolean
  selectedPivot: EditorView
  viewport: { width: number; height: number }
  isGridVisible: boolean
  isArtistMode: boolean
  _persist: Persist
}
