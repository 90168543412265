import React from 'react'
import { Dialog } from './Dialog'

type ItemToDelete = {
  id: number
}

interface IProps {
  itemName: string
  onClose: () => void
  onSuccess: () => void
  itemsToDelete: ItemToDelete[]
  action?: string
}

export const DeleteDialog = (props: IProps) => {
  const {
    itemName,
    onClose,
    onSuccess,
    itemsToDelete,
    action = 'Delete',
  } = props

  return (
    <Dialog
      title={`Confirm ${action}`}
      onClose={onClose}
      onSuccess={onSuccess}
      subText={`Are you sure you want to ${action.toLowerCase()} the selected ${itemName}${
        itemsToDelete.length === 1 ? '' : 's'
      }?`}
    />
  )
}
