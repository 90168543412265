export type UnknownSettings = {
  [k: string]: unknown
}

export enum AssetType {
  Product = 'product',
  Fixture = 'fixture',
  Environment = 'environment',
  Light = 'light',
  BaseScene = 'baseScene',
}

export const getAssetType = (type: string) => {
  switch (type) {
    case AssetType.Product:
      return { id: 'product', name: 'Product' }
    case AssetType.Fixture:
      return { id: 'fixture', name: 'Fixture' }
    case AssetType.Environment:
      return { id: 'environment', name: 'Environment' }
    case AssetType.Light:
      return { id: 'light', name: 'Light' }
    case AssetType.BaseScene:
      return { id: 'baseScene', name: 'Base Scene' }
    default:
      throw Error(`Unknown Asset Type: ${type}`)
  }
}

export const assetTypes = [
  getAssetType(AssetType.Product),
  getAssetType(AssetType.Fixture),
  getAssetType(AssetType.Environment),
  getAssetType(AssetType.Light),
  getAssetType(AssetType.BaseScene),
]

export type AssetRevision = {
  id: number
  type: string
  version: number
  assetUri: string
  uploadUri: string
  thumbnail: string
  thumbnailName: string
  createdAt: string
}

export type Asset = {
  id: number
  version: number
  type: string
  name: string
  thumbnail: string
  revisions: AssetRevision[]
  createdAt: string
  createdBy: number
  assignedCampaigns: number[]
}
