import React from 'react'
import { connect } from 'react-redux'

import { Panel } from '../../../containers/Panel'
import { TextInput } from '../../../containers/TextInput'
import { State } from '../../../store'
import { getDocBlocs } from '../../../store/doc/selectors'
import { DocActions } from '../DocActions'

type OwnProps = {
  isErrorVisible: boolean
  setErrorVisible: (isVisible: boolean) => void
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = OwnProps & StateProps

const mapStateToProps = (state: State) => ({
  docBlocs: getDocBlocs(state),
})

export const DocPanel = connect(mapStateToProps)((props: Props) => {
  const { docBlocs, isErrorVisible, setErrorVisible } = props

  const isTemplate = docBlocs.meta.isTemplate.value

  return (
    <Panel
      key="document"
      id="document"
      label={`${isTemplate ? 'Document Template' : 'Document'}`}
      actions={<DocActions />}
      isForcedOpen={isErrorVisible}
    >
      <TextInput
        bloc={docBlocs.meta.name}
        label="Name"
        error={isErrorVisible ? docBlocs.meta.name.error : undefined}
        onBlur={() => {
          setErrorVisible(false)
        }}
      />
    </Panel>
  )
})
