import _ from 'lodash'
import { useEffect, useState, useMemo } from 'react'

// Uses the local state for storing value to avoid updating global state on every change.
export const useDebouncedValue = <T>(
  globalValue: T,
  setGlobalValue: (value: T) => void,
  waitMs: number = 500,
): [T, (value: T) => void] => {
  const [localValue, setLocalValue] = useState<T>(globalValue)

  const setGlobalValueDebounced = useMemo(
    () =>
      _.debounce((value: T) => {
        setGlobalValue(value)
      }, waitMs),
    [setGlobalValue, waitMs],
  )

  useEffect(() => {
    setGlobalValueDebounced.cancel()
    setLocalValue(globalValue)
  }, [globalValue, setGlobalValueDebounced])

  const setValue = (value: T) => {
    setLocalValue(value)
    setGlobalValueDebounced(value)
  }

  return [localValue, setValue]
}
