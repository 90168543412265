import _ from 'lodash'
import { IStyle, Selection, SelectionZone, Stack } from 'office-ui-fabric-react'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { State } from '../../../store'
import { sidebarVisibleAssetTypes } from '../../../store/assets/state'
import {
  addSceneObject,
  selectGivenSceneObjects,
  setInDoc,
} from '../../../store/doc/actions'
import { sidebarVisibleSceneObjectsSelector } from '../../../store/doc/selectors'
import { Asset } from '../../../store/doc/types'
import { AssetType } from '../../../store/assets/types'
import { Panel } from '../../../containers/Panel'
import { AddSceneObjectAction } from '../AddSceneObjectAction'
import { SceneObjectItem } from './SceneObjectItem'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const styles = {
  listOrder: {
    root: {
      display: 'flex',
      flexDirection: 'column-reverse',
    } as IStyle,
  },
}

const mapStateToProps = (state: State) => ({
  sidebarVisibleSceneObjects: sidebarVisibleSceneObjectsSelector(state),
  campaign: state.campaign,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addSceneObject,
      selectGivenSceneObjects,
      setInDoc,
    },
    dispatch,
  )

export const SceneObjectsPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { campaign, sidebarVisibleSceneObjects, selectGivenSceneObjects } =
    props

  const assets = _.filter(campaign.assets.assets, asset =>
    _.some(sidebarVisibleAssetTypes, assetType => assetType === asset.type),
  )

  const selectionRef = useRef(
    new Selection({
      onSelectionChanged: () => {
        const sceneObjectIds = _.compact(
          _.map(selection.getSelection(), 'key'),
        ) as string[]
        selectGivenSceneObjects(sceneObjectIds)
      },
    }),
  )
  const selection = selectionRef.current

  useEffect(() => {
    const prevItems = selection.getItems()
    const nextItems = _.map(
      sidebarVisibleSceneObjects,
      ({ id: key, isSelected: { value: isSelected } }) => ({
        key,
        isSelected,
      }),
    )

    if (_.isEqual(prevItems, nextItems)) {
      return
    }

    selection.setItems(nextItems, false)
    _.forEach(nextItems, ({ key, isSelected }) => {
      selection.setKeySelected(key, isSelected, false)
    })
  }, [sidebarVisibleSceneObjects, selection])

  return (
    <Panel
      id="objects"
      label="Objects"
      isScrollable={true}
      mainAction={
        <AddSceneObjectAction
          assetType={AssetType.Product}
          assets={assets}
          label="Add Object"
          onSelect={(asset: Asset) => {
            const lastRevision = _.last(asset.revisions)!
            props.addSceneObject({
              assetId: asset.id,
              type: asset.type,
              revisionId: lastRevision.id,
            })
          }}
        />
      }
    >
      {sidebarVisibleSceneObjects.length > 0 && (
        <Stack styles={styles.listOrder}>
          <SelectionZone
            selection={selection}
            selectionPreservedOnEmptyClick={true}
          >
            {_.map(sidebarVisibleSceneObjects, (sceneObject, index) => {
              return (
                <SceneObjectItem
                  key={sceneObject.id}
                  sceneObjectBloc={sceneObject}
                  index={index}
                  assetType={sceneObject.type}
                  assets={assets}
                />
              )
            })}
          </SelectionZone>
        </Stack>
      )}
    </Panel>
  )
})
