import _ from 'lodash'
import { IStyle, Label as FabricLabel, Stack } from 'office-ui-fabric-react'
import React from 'react'
import * as commonStyles from './../styles'
import { Label } from './Label'
import { SpinButton } from './SpinButton'

interface Props {
  id: string
  label: string
  labels: string[]
  blocs: any[]
  setValue: (path: string[], value: number) => void
  unit?: string
}

const styles = {
  container: {
    root: {
      width: '100%',
    },
  },
  input: {
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      ...commonStyles.input,
      selectors: {
        [commonStyles.mediaQuery.isMobile]: {
          flexDirection: 'column',
          selectors: {
            '> :not(:first-child)': {
              marginLeft: 0,
              marginTop: 12,
            },
          },
        },
      },
    } as IStyle,
  },
  field: {
    root: {
      position: 'relative',
      top: 2,
    } as IStyle,
  },
}

const TextGroupInput = (props: Props) => {
  const tokens = {
    root: {
      childrenGap: 20,
    },
    field: {
      childrenGap: 8,
    },
    input: {
      childrenGap: 12,
    },
  }

  const fields = _.map(props.blocs, (bloc, i: number) => {
    const { value: blocValue, isDisabled } = bloc

    const hasUnit = blocValue.unit !== undefined

    return (
      <Stack
        key={bloc.path}
        horizontal
        tokens={tokens.field}
        styles={styles.container}
      >
        <FabricLabel styles={styles.field} htmlFor={bloc.path}>
          {props.labels[i]}
        </FabricLabel>
        <SpinButton
          id={bloc.path}
          value={hasUnit ? blocValue.value : blocValue}
          onValueChange={nextValue => {
            props.setValue(
              bloc.path,
              hasUnit
                ? {
                    ...blocValue,
                    value: Number(nextValue),
                  }
                : Number(nextValue),
            )
          }}
          disabled={isDisabled}
        />
      </Stack>
    )
  })

  return (
    <Stack horizontal horizontalAlign="space-between" tokens={tokens.root}>
      <Label
        text={props.label}
        unit={props.unit}
        htmlFor={`${props.id}-width`}
      />
      <Stack styles={styles.input} tokens={tokens.input} horizontal>
        {fields}
      </Stack>
    </Stack>
  )
}

export { TextGroupInput }
