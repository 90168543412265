import {
  CommandBar as FabricCommandBar,
  ICommandBarItemProps,
  IStyle,
  Stack,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'
import { IHasPermissions } from '../pages/users/utils'

export interface CommandBarItem extends ICommandBarItemProps, IHasPermissions {}

type CommandBarItems = {
  none: CommandBarItem[]
  single: CommandBarItem[]
  multi: CommandBarItem[]
}

interface Props {
  items: ICommandBarItemProps[]
  farItems?: ICommandBarItemProps[]
  requiresLeftOffset?: boolean
  disableCollapse?: boolean
}

const styles = {
  wrapper: {
    root: {
      height: 41,
      minHeight: 41,
      width: '100%',
      backgroundColor: '#F3F2F1',
      borderBottom: '1px solid #EDEBE9',
      paddingLeft: 7,
      paddingRight: 7,
    } as IStyle,
  },
  commandBar: {
    root: {
      height: 40,
      backgroundColor: '#F3F2F1',
      paddingLeft: 0,
      paddingRight: 0,
      selectors: {
        '& .ms-CommandBarItem-link': {
          backgroundColor: '#F3F2F1',
          color: '#0078D4',
        },
        '& .ms-CommandBarItem-link:hover': {
          backgroundColor: '#FAF9F8',
          color: '#0078D4',
        },
        '& .ms-CommandBarItem-link.is-disabled': {
          color: '#a19f9d',
        },
        '& .is-dangerous *, & .ms-CommandBarItem-link.is-dangerous:hover *': {
          color: '#AE2828',
        },
      },
    } as IStyle,
  },
}

const getWrapperStyles = memoizeFunction((requiresLeftOffset?: boolean) => {
  let wrapperStyles = styles.wrapper.root
  if (requiresLeftOffset) {
    wrapperStyles = {
      ...(styles.wrapper.root as object),
      paddingLeft: 47,
    } as IStyle
  }
  return { root: wrapperStyles }
})

export const setCommandBarItems = (
  selectedCount: number,
  givenCommandBarItems: CommandBarItems,
) => {
  let commandBarItems = givenCommandBarItems.none
  if (selectedCount === 1) {
    commandBarItems = givenCommandBarItems.single
  } else if (selectedCount > 1) {
    commandBarItems = givenCommandBarItems.multi
  }
  return commandBarItems
}

export const CommandBar = (props: Props) => {
  const { items, farItems, requiresLeftOffset, disableCollapse } = props

  return (
    <Stack styles={getWrapperStyles(requiresLeftOffset)}>
      <FabricCommandBar
        styles={styles.commandBar}
        items={items}
        farItems={farItems}
        onReduceData={disableCollapse ? () => undefined : undefined}
        // Temp fix for bug where ToggleCommandBar collapses even when it has enough space
        // FabricUI issue: https://github.com/microsoft/fluentui/issues/13238
      />
    </Stack>
  )
}
