import _ from 'lodash'
import * as timeago from 'timeago.js'
import * as dto from '../../dto/api'
import http from '../../http'
import { getAssignedCampaignNames } from '../../lib/getAssignedCampaignNames'
import { roleNameMap } from './UserRoles'

// =============================================================================
// Types
// =============================================================================
export type User = {
  key: string
  thumbnail?: string
  name: string
  email: string
  isEnabled: boolean
  status: string
  role: dto.AccountRole
  roleName: string
  assignedCampaigns: number[]
  assignedCampaignNames: string[] | string
  lastLoginAt?: string
  lastLoginAtAgo?: string
  invitedAt?: string
  invitedAtAgo?: string
  inviteId: number | null
  accountId: number | null
}

// =============================================================================
// Utils
// =============================================================================
const getEmailReqData = (email: string): dto.EmailReq => ({ email })

export const fetcher = (url: string) =>
  http.get(url).then(response => response.data)

export const createInviteReq = (request: dto.CreateInviteReq) =>
  http.post(`/api/invite`, request)

const deleteInviteReq = (email: string) =>
  http.delete(`/api/invite`, { data: getEmailReqData(email) })

const enableAccountCampaignReq = (accountId: number, campaignId: number) =>
  http.post(`/api/account_campaign/mapping`, {
    accountId,
    campaignId,
  } as dto.AccountToCampaignMappingReq)

const disableAccountCampaignReq = (accountId: number, campaignId: number) =>
  http.delete(`/api/account_campaign/mapping`, {
    data: { accountId, campaignId } as dto.AccountToCampaignMappingReq,
  })

export const updateAccountRoleReq = (
  accountId: number,
  role: dto.AccountRole,
) => http.post(`/api/account/role`, { accountId, role } as dto.UpdateRoleReq)

export const updateAccountCampaignMappingReq = (
  accountId: number,
  campaignId: number,
  isAssigned: boolean,
) =>
  isAssigned
    ? enableAccountCampaignReq(accountId, campaignId)
    : disableAccountCampaignReq(accountId, campaignId)

const enableAccountReq = (email: string) =>
  http.post(`/api/account/enable`, getEmailReqData(email))

const disableAccountReq = (email: string) =>
  http.post(`/api/account/disable`, getEmailReqData(email))

export const enableUsersReq = async (users: User[]) => {
  return Promise.allSettled(
    _.map(users, user => {
      if (user.isEnabled) {
        return Promise.resolve()
      }
      if (user.accountId) {
        return enableAccountReq(user.email)
      }
      return Promise.resolve()
    }),
  )
}
export const disableUsersReq = async (users: User[]) => {
  return Promise.allSettled(
    _.map(users, user => {
      if (!user.isEnabled) {
        return Promise.resolve()
      }
      if (user.accountId) {
        return disableAccountReq(user.email)
      }
      if (user.inviteId) {
        return deleteInviteReq(user.email)
      }
      return Promise.resolve()
    }),
  )
}

export const mergeUsers = (
  accounts: dto.Account[] | undefined,
  invites: dto.Invite[] | undefined,
  campaigns: dto.Campaign[] | undefined,
): User[] => {
  const accountsWithoutSeed = _.reject(accounts, account => account.id === 1)
  const users: User[] = _.map(accountsWithoutSeed, account => {
    const {
      id: accountId,
      email: key,
      pictureUri: thumbnail,
      displayName: name,
      email,
      role,
      disabledAt,
      campaigns: assignedCampaigns,
      lastLoginAt,
    } = account
    const isEnabled = !disabledAt
    const status = disabledAt ? 'Disabled' : 'Active'
    const lastLoginAtAgo = timeago.format(lastLoginAt)

    const user: User = {
      key,
      thumbnail,
      name,
      email,
      isEnabled,
      status,
      role,
      roleName: roleNameMap[role],
      assignedCampaigns,
      assignedCampaignNames: getAssignedCampaignNames(
        campaigns,
        assignedCampaigns,
      ),
      invitedAt: '0000-01-01',
      invitedAtAgo: '-',
      lastLoginAt,
      lastLoginAtAgo,
      inviteId: null,
      accountId,
    }
    return user
  })
  _.each(invites, invite => {
    const {
      id: inviteId,
      email: key,
      email: name,
      email,
      consumedBy,
      assignedRole,
      assignedCampaigns,
      invitedAt,
    } = invite
    const invitedAtAgo = timeago.format(invitedAt)

    const user = _.find(users, user => user.email === invite.email)
    if (user != null) {
      user.invitedAt = invitedAt
      user.invitedAtAgo = invitedAtAgo
      user.inviteId = inviteId
    } else {
      const isEnabled = true
      const status = consumedBy ? 'Used' : 'Pending'
      users.push({
        key,
        thumbnail: undefined,
        name,
        email,
        isEnabled,
        status,
        role: assignedRole,
        roleName: roleNameMap[assignedRole],
        assignedCampaigns,
        assignedCampaignNames: getAssignedCampaignNames(
          campaigns,
          assignedCampaigns,
        ),
        invitedAt,
        invitedAtAgo,
        lastLoginAt: '0000-01-01',
        lastLoginAtAgo: '-',
        inviteId,
        accountId: null,
      })
    }
  })
  return users
}

export interface IHasPermissions {
  permissions?: string[]
}

export const hasPermission = (
  userPermissions: string[],
  acceptedPermissions: string[] | undefined,
) => {
  // if no acceptedPermissions, then no restirctions for this area/feature
  if (!acceptedPermissions) {
    return true
  }

  return _.some(userPermissions, permission =>
    _.includes(acceptedPermissions, permission),
  )
}

export const filterByPermissions = <T extends IHasPermissions>(
  items: T[],
  userPermissions: string[],
) => {
  return _.filter(items, item =>
    hasPermission(userPermissions, item.permissions),
  )
}
