import {
  Callout,
  FontWeights,
  Link,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import React, { useRef, useState } from 'react'
import type { AccountInfo } from '../store/auth/state'
import { LOG_OUT_URL } from '../utils'

type Props = {
  account: AccountInfo
}

const styles = {
  container: { height: 32 },
  wrapper: {
    root: {
      padding: 10,
    },
  },
  callout: {
    root: {
      minWidth: 240,
    },
  },
  name: {
    root: {
      fontWeight: FontWeights.semibold,
    },
  },
}

export const ActivePersona = (props: Props) => {
  const { account } = props
  const { name, email, imageUri } = account

  const containerRef = useRef<HTMLDivElement>(null)

  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <div style={styles.container} ref={containerRef}>
      <Link
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <Persona
          text={name}
          size={PersonaSize.size32}
          imageUrl={imageUri}
          hidePersonaDetails
        />
      </Link>
      {isOpen && (
        <Callout
          target={containerRef.current}
          onDismiss={() => {
            setOpen(false)
          }}
          beakWidth={10}
          styles={styles.callout}
        >
          <Stack styles={styles.wrapper} tokens={{ childrenGap: 10 }}>
            <Stack>
              <Text styles={styles.name}>{name}</Text>
              <Text>{email}</Text>
            </Stack>
            <Link autoFocus={true} href={LOG_OUT_URL}>
              Log Out
            </Link>
          </Stack>
        </Callout>
      )}
    </div>
  )
}
