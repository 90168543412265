import React from 'react'
import { IconButton } from 'office-ui-fabric-react'
import { download, FileType } from '../lib/fileUtils'

type DownloadActionProps = {
  id: number
  uploadUri: string
  fileName: string
  fileType: FileType
}

export const DownloadAction = (props: DownloadActionProps) => {
  const { id, uploadUri, fileName, fileType } = props
  return (
    <IconButton
      key={id}
      iconProps={{ iconName: 'Download' }}
      title="Download"
      ariaLabel="Download"
      onClick={() => {
        download(uploadUri, fileName, fileType)
      }}
    />
  )
}
