import {
  DetailsList as FabricDetailsList,
  DetailsListLayoutMode,
  FontWeights,
  IColumn,
  IStyle,
  SelectionMode,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import React from 'react'
import { ColumnDef } from './Table'

type BaseItem = {
  key: string
  version: number
  created: string
  createdAgo: string
  actions: JSX.Element
}

interface Props<T> {
  columns: ColumnDef<T>[]
  tableItems: T[]
}

const styles = {
  none: {},
  current: {
    root: {
      fontWeight: FontWeights.bold,
    },
  },
  actionsCell: {
    root: {
      selectors: {
        '& .ms-Button--icon': {
          width: 20,
          height: 20,
          margin: 'auto',
        },
      },
    } as IStyle,
  },
}

export const RevisionsList = <T extends BaseItem>(props: Props<T>) => {
  const { columns, tableItems } = props
  return (
    <FabricDetailsList
      columns={constructColumns<T>(columns)}
      items={tableItems}
      compact={true}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      onRenderItemColumn={renderItemColumn}
    />
  )
}

const constructColumns = <T extends BaseItem>(
  columns: ColumnDef<T>[],
): IColumn[] => {
  return columns.map(col => {
    const minWidth = col.width || col.minWidth || 50
    const maxWidth = col.width || col.maxWidth || 250
    const { key, name = '', fieldName } = col

    return {
      minWidth,
      maxWidth,
      key,
      fieldName: fieldName || key,
      name,
    }
  })
}

const renderItemColumn = <T extends BaseItem>(
  item: T,
  index?: number,
  column?: IColumn,
) => {
  if (!column) {
    return
  }

  const fieldName: keyof T = column.fieldName as keyof T
  const content = column.fieldName ? item[fieldName] : null

  switch (column.fieldName) {
    case 'actions':
      return <Stack styles={styles.actionsCell}>{content}</Stack>

    default:
      return (
        <Text styles={index === 0 ? styles.current : styles.none}>
          {content}
        </Text>
      )
  }
}
