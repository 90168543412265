import { Action as ReduxAction } from 'redux'
import { EditorView, FitMode, SidebarType } from './types'

export type Action =
  | SetFitMode
  | ToggleFullscreen
  | ToggleSidebar
  | TogglePanelState
  | ToggleGizmoAdvancedMode
  | SelectPivot
  | SetViewportSize
  | SetFullscreenState
  | ToggleIsGridVisible
  | SetArtistMode

// -- ACTION TYPES

export enum UIActions {
  SetFitMode = 'SET_FIT_MODE',
  ToggleFullscreen = 'TOGGLE_FULLSCREEN',
  ToggleSidebar = 'TOGGLE_SIDEBAR',
  TogglePanelState = 'TOGGLE_PANEL_STATE',
  ToggleGizmoAdvancedMode = 'TOGGLE_GIZMO_ADVANCED_MODE',
  SelectPivot = 'SELECT_PIVOT',
  SetViewportSize = 'SET_WINDOW_SIZE',
  SetFullscreenState = 'SET_FULLSCREEN_STATE',
  ToggleIsGridVisible = 'TOGGLE_IS_GRID_VISIBLE',
  SetArtistMode = 'SET_ARTIST_MODE',
}

// -- ACTION INTERFACES

export interface SetFitMode extends ReduxAction<UIActions> {
  type: UIActions.SetFitMode
  fitMode: FitMode
}

export interface ToggleFullscreen extends ReduxAction<UIActions> {
  type: UIActions.ToggleFullscreen
}

export interface ToggleIsGridVisible extends ReduxAction<UIActions> {
  type: UIActions.ToggleIsGridVisible
}

export interface ToggleSidebar extends ReduxAction<UIActions> {
  type: UIActions.ToggleSidebar
  sidebarType: SidebarType
}

export interface TogglePanelState extends ReduxAction<UIActions> {
  type: UIActions.TogglePanelState
  id: string
}

export interface ToggleGizmoAdvancedMode extends ReduxAction<UIActions> {
  type: UIActions.ToggleGizmoAdvancedMode
}

export interface SelectPivot extends ReduxAction<UIActions> {
  type: UIActions.SelectPivot
  pivot: EditorView
}

export interface SetViewportSize extends ReduxAction<UIActions> {
  type: UIActions.SetViewportSize
  width: number
  height: number
}

export interface SetFullscreenState extends ReduxAction<UIActions> {
  type: UIActions.SetFullscreenState
  isFullscreen: boolean
}

export interface SetArtistMode extends ReduxAction<UIActions> {
  type: UIActions.SetArtistMode
  payload: boolean
}

// -- ACTION CONSTRUCTORS

export const setFitMode = (fitMode: FitMode): SetFitMode => ({
  fitMode,
  type: UIActions.SetFitMode,
})

export const toggleFullscreen = (): ToggleFullscreen => ({
  type: UIActions.ToggleFullscreen,
})

export const toggleSidebar = (sidebarType: SidebarType): ToggleSidebar => ({
  type: UIActions.ToggleSidebar,
  sidebarType,
})

export const togglePanelState = (id: string): TogglePanelState => ({
  id,
  type: UIActions.TogglePanelState,
})

export const toggleGizmoAdvancedMode = () => ({
  type: UIActions.ToggleGizmoAdvancedMode,
})

export const toggleIsGridVisible = (): ToggleIsGridVisible => ({
  type: UIActions.ToggleIsGridVisible,
})

export const selectPivot = (pivot: EditorView): SelectPivot => ({
  pivot,
  type: UIActions.SelectPivot,
})

export const setViewportSize = (
  width: number,
  height: number,
): SetViewportSize => ({
  width,
  height,
  type: UIActions.SetViewportSize,
})

export const setFullscreenState = (
  isFullscreen: boolean,
): SetFullscreenState => ({
  isFullscreen,
  type: UIActions.SetFullscreenState,
})

export const setArtistMode = (isArtistMode: boolean): SetArtistMode => ({
  type: UIActions.SetArtistMode,
  payload: isArtistMode,
})
