import _ from 'lodash'
import { Stack, Text } from 'office-ui-fabric-react'
import React, { useState, useEffect } from 'react'

import { Props as AssetsProps } from './'
import {
  AssetStatus,
  error,
  uploading,
  preview,
  processing,
  AssetInput,
} from '../../components/AssetInput'
import {
  getCampaignMappings,
  CampaignList,
} from '../../components/CampaignList'
import { Assets } from '../../store/assets/state'
import { Modal, LayoutMode } from '../../components/Modal'
import { AssetsRevisionList } from './RevisionList'
import * as commonStyles from '../../styles'

type Props = {
  assets: Assets
  campaigns: AssetsProps['campaigns']
  uploadAsset: AssetsProps['uploadAsset']
  reloadAssets: AssetsProps['reloadAssets']
  createRevision: AssetsProps['createRevision']
  closeModals: () => void
  uploadState: AssetStatus
  setUploadState: (value: AssetStatus) => void
  updateDeviceCampaignMappings: AssetsProps['updateDeviceCampaignMappings']
}

const styles = {
  modal: {
    title: commonStyles.formTitle,
  },
}

const allAssetsAssignedToCampaign = (
  assets: Assets,
  campaignId: number,
): boolean | null => {
  return _.every(assets, asset =>
    _.includes(asset.assignedCampaigns, campaignId),
  )
    ? true
    : _.some(assets, asset => _.includes(asset.assignedCampaigns, campaignId))
      ? null
      : false
}

export const EditModal = (props: Props) => {
  const {
    assets,
    campaigns,
    closeModals,
    uploadState,
    setUploadState,
    reloadAssets,
    updateDeviceCampaignMappings,
  } = props

  const asset = assets.length === 1 ? assets[0] : undefined
  const revisions = asset?.revisions

  useEffect(() => {
    const currentVersion = _.last(revisions)
    const thumbnailName = currentVersion?.thumbnailName ?? ''

    setUploadState(preview(thumbnailName, asset?.thumbnail))
  }, [asset, revisions, setUploadState])

  const label = `Edit Asset${asset ? '' : 's'}`

  const [isUpdatingDeviceCampaignMapping, setUpdatingDeviceCampaignMapping] =
    useState(false)

  const [draft, updateDraft] = useState({
    campaignMappings: getCampaignMappings(campaigns, id =>
      allAssetsAssignedToCampaign(assets, id),
    ),
  })

  return (
    <Modal title={label} onClose={closeModals} layoutMode={LayoutMode.fit}>
      <Stack tokens={{ childrenGap: 20 }}>
        {asset && (
          <Stack tokens={{ childrenGap: 10 }}>
            <Text styles={styles.modal.title}>{asset?.name}</Text>
            <AssetInput
              label={`Version ${asset.version}`}
              placeholder={['Select a ZIP file']}
              onUpload={async file => {
                const filename = file.name
                setUploadState(uploading(filename))
                const uploadResp = await props.uploadAsset({ file })
                if (!uploadResp) {
                  setUploadState(error(filename, 'Upload Failed'))
                }

                setUploadState(processing(filename, uploadResp.token))

                const createResp = await props.createRevision({
                  id: asset.id,
                  uploadResp,
                })

                if (!createResp) {
                  return setUploadState(error(filename, 'Edit asset failed'))
                }
              }}
              status={uploadState}
            />
          </Stack>
        )}
        <CampaignList
          label="Campaigns"
          items={campaigns}
          mappings={draft.campaignMappings}
          setMappings={async campaignMappings => {
            setUpdatingDeviceCampaignMapping(true)

            updateDraft(item => ({
              ...item!,
              campaignMappings,
            }))

            const changedMappings = _.pickBy(
              campaignMappings,
              (isSelected, campaignId) =>
                isSelected !== null &&
                draft.campaignMappings[campaignId] !== isSelected,
            ) as { [key: string]: boolean }

            await Promise.all(
              _.map(assets, asset => {
                return updateDeviceCampaignMappings({
                  assetId: asset.id,
                  mappings: changedMappings,
                })
              }),
            )

            setUpdatingDeviceCampaignMapping(false)
            reloadAssets()
          }}
          disabled={isUpdatingDeviceCampaignMapping}
        />
        {asset && revisions && (
          <AssetsRevisionList asset={asset} revisions={revisions} />
        )}
      </Stack>
    </Modal>
  )
}
