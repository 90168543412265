import { connect } from 'react-redux'

import { TextGroupInput as Component } from '../components/TextGroupInput'
import { Dispatch, State } from '../store'
import { setInDoc } from '../store/doc/actions'
import { Bloc } from '../store/doc/types'
import { pathToId } from '../utils'

type OwnProps = {
  blocs: Bloc<any>[]
  labels: string[]
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { blocs, labels } = ownProps
  return {
    blocs,
    labels,
    id: pathToId(blocs[0].path),
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    setValue: (path: string[], value: number) => {
      dispatch(setInDoc({ path, value }))
    },
  }
}

const TextGroupInput = connect(mapStateToProps, mapDispatchToProps)(Component)

export { TextGroupInput }
