import { connect } from 'react-redux'

import { TextInput as Component } from '../components/TextInput'
import { Dispatch, State } from '../store'
import { setInDoc } from '../store/doc/actions'
import { Bloc } from '../store/doc/types'
import { pathToId } from '../utils'

type OwnProps = {
  bloc: Bloc<any>
  label: string
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { bloc } = ownProps
  return {
    id: pathToId(bloc.path),
    value: bloc.value,
    disabled: bloc.isDisabled,
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { bloc } = ownProps
  return {
    setValue: (value: string) => dispatch(setInDoc({ path: bloc.path, value })),
  }
}

const TextInput = connect(mapStateToProps, mapDispatchToProps)(Component)

export { TextInput }
