import { Stack, Text } from 'office-ui-fabric-react'
import React from 'react'

export const NotFound = () => (
  <Stack verticalFill verticalAlign="center" horizontalAlign="center">
    <Text>
      <strong>404</strong>: Not Found
    </Text>
  </Stack>
)
