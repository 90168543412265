import { connect } from 'react-redux'
import { ImageInput as Component } from '../components/ImageInput'
import { UploadImageRes } from '../lib/fileUtils'
import { Dispatch } from '../store'
import { uploadImage } from '../store/buckets/actions'
import {
  createCustomAssetTexture,
  createCustomCanvasTexture,
  setInDoc,
} from '../store/doc/actions'
import { Bloc } from '../store/doc/types'

type OwnProps = {
  label: string
  bloc: Bloc<any>
  onSelect?: () => void
  textureType?: 'BACKGROUND' | 'FOREGROUND'
  placeholder?: string[]
  isCanvasTexture: boolean
}

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  return {
    ...ownProps,
    campaign: state.campaign,
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { bloc, isCanvasTexture } = ownProps
  return {
    setValue: (value: any) => dispatch(setInDoc({ path: bloc.path, value })),
    upload: (file: File) => {
      const uploadUrl = isCanvasTexture
        ? '/api/canvas_texture/upload_url'
        : '/api/device_texture/upload_url'

      return dispatch(uploadImage({ uploadUrl, file }))
    },
    createCustomTexture: (
      uploadResp: UploadImageRes,
      campaignId: number,
      textureType?: 'BACKGROUND' | 'FOREGROUND',
    ) => {
      return isCanvasTexture && textureType
        ? dispatch(
            createCustomCanvasTexture(
              { textureType, ...uploadResp },
              campaignId,
            ),
          )
        : dispatch(createCustomAssetTexture({ uploadResp, campaignId }))
    },
  }
}

export const ImageInput = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component)
