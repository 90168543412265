import { INavLink } from 'office-ui-fabric-react'
import React from 'react'
import { Navigation as Nav } from '../components/Navigation'

interface IProps {
  campaignId: number
  push: (path: string) => void
}

type Props = IProps

export const CampaignNavigation = ({ campaignId, push }: Props) => {
  const campaignGroup = [
    {
      name: 'Campaign',
      links: [
        {
          name: 'Documents',
          icon: 'page',
          url: `/campaign/${campaignId}`,
          key: 'docs',
        },
        {
          name: 'Templates',
          icon: 'page',
          url: `/campaign/${campaignId}/templates`,
          key: 'templates',
        },
        {
          name: 'Screen UIs',
          icon: 'tvmonitor',
          url: `/campaign/${campaignId}/screen-uis`,
          key: 'screen-uis',
        },
        {
          name: 'Foregrounds',
          icon: 'picturefill',
          url: `/campaign/${campaignId}/foregrounds`,
          key: 'foregrounds',
        },
        {
          name: 'Backgrounds',
          icon: 'picturefill',
          url: `/campaign/${campaignId}/backgrounds`,
          key: 'backgrounds',
        },
      ],
    },
  ]

  return (
    <Nav
      onLinkClick={(
        e?: React.MouseEvent<HTMLElement, MouseEvent>,
        x?: INavLink,
      ) => {
        if (e && x) {
          e.preventDefault()
          push(x.url)
        }
      }}
      groups={campaignGroup}
    />
  )
}
