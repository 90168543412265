import React from 'react'

import { SceneObjectBlocs } from '../../../store/doc/selectors'
import { SliderInput } from '../../../containers/SliderInput'
import { TextGroupInput } from '../../../containers/TextGroupInput'
import { Panel } from './../../../containers/Panel'
import { ScaleInput } from './ScaleInput'

type Props = {
  selectedObject: SceneObjectBlocs
}

export const TransformPanel = (props: Props) => {
  const { selectedObject } = props

  return (
    <Panel id="transform" label="Transform">
      <TextGroupInput
        blocs={[
          selectedObject.position.x,
          selectedObject.position.y,
          selectedObject.position.z,
        ]}
        label="Position"
        unit='"'
        labels={['X', 'Y', 'Z']}
      />
      <SliderInput bloc={selectedObject.rotation.x} label="Rotation X" />
      <SliderInput bloc={selectedObject.rotation.y} label="Rotation Y" />
      <SliderInput bloc={selectedObject.rotation.z} label="Rotation Z" />
      <ScaleInput selectedObject={selectedObject} />
    </Panel>
  )
}
