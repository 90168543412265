import type { Action as ReduxAction, Dispatch } from 'redux'

import type { GetState } from '..'
import type * as dto from '../../dto/api'
import http from '../../http'
import { jobToApi } from './dataTransformers'
import { commandsSelector } from './selectors'
import type { Commands } from './types'

export type Action = InitiateRender | CopyRenderJobData

export enum JobsActions {
  InitiateRender = 'INITIATE_RENDER',
  CopyRenderJobData = 'COPY_RENDER_JOB_DATA',
}

export interface InitiateRender extends ReduxAction<JobsActions> {
  type: JobsActions.InitiateRender
  payload: {
    renderType: dto.RenderType
    commands: Commands
  }
}
export interface CopyRenderJobData extends ReduxAction<JobsActions> {
  type: JobsActions.CopyRenderJobData
  payload: {
    renderType: dto.RenderType
    commands: Commands
  }
}

export const initiateRender =
  (documentId: number, version: number, renderType: dto.RenderType) =>
  async (dispatch: Dispatch<Action>, getState: GetState) => {
    const state = getState()
    const commands = commandsSelector(state, renderType)

    dispatch({
      type: JobsActions.InitiateRender,
      payload: {
        renderType,
        commands,
      },
    } as InitiateRender)

    const req: dto.CreateRenderJobReq = jobToApi(renderType, commands)
    await http.post<void>(`/api/document/${documentId}/${version}/render`, req)
  }

export const copyRenderJobData =
  (renderType: dto.RenderType, copyToClipboard: (value: string) => void) =>
  (dispatch: Dispatch<Action>, getState: GetState) => {
    const state = getState()
    const commands = commandsSelector(state, renderType)
    const commandsJson = JSON.stringify(commands, null, 2)

    dispatch({
      type: JobsActions.CopyRenderJobData,
      payload: {
        renderType,
        commands,
      },
    } as CopyRenderJobData)

    copyToClipboard(commandsJson)
  }
