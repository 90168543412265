import { connect } from 'react-redux'
import { CampaignNavigation as CampaignNav } from '../components/CampaignNavigation'
import { HomeNavigation as HomeNav } from '../components/HomeNavigation'
import { push } from '../store/routing/actions'

type DispatchProps = {
  push: (path: string) => void
}

const mapStateToProps = () => ({})

const mapDispatchToProps: DispatchProps = {
  push,
}

export const HomeNavigation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeNav)

export const CampaignNavigation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignNav)
