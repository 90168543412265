import screenfull from 'screenfull'

import type { Action } from '../actions'
import {
  type SelectPivot,
  type SetViewportSize,
  type TogglePanelState,
  type ToggleSidebar,
  UIActions,
} from './actions'
import { initialState } from './state'
import { SidebarType, type UI } from './types'
import { produce } from 'immer'

export const reducers = (
  currentState: UI = initialState(),
  action: Action,
): UI => {
  switch (action.type) {
    case UIActions.SetFitMode:
      return Object.assign({}, currentState, {
        fitMode: action.fitMode,
      })

    case UIActions.ToggleFullscreen:
      if (screenfull.isFullscreen) {
        screenfull.exit()
      } else {
        screenfull.request()
      }

      return currentState

    case UIActions.SetFullscreenState:
      return Object.assign({}, currentState, {
        isFullscreenMode: action.isFullscreen,
      })

    case UIActions.ToggleSidebar:
      return handleToggleSidebar(currentState, action)

    case UIActions.TogglePanelState:
      return handleTogglePanelState(currentState, action)

    case UIActions.ToggleGizmoAdvancedMode:
      return produce(currentState, nextState => {
        nextState.isGizmoAdvancedMode = !nextState.isGizmoAdvancedMode
      })

    case UIActions.SelectPivot:
      return handleSelectPivot(currentState, action)

    case UIActions.SetViewportSize:
      return handleSetViewportSize(currentState, action)

    case UIActions.ToggleIsGridVisible:
      return produce(currentState, nextState => {
        nextState.isGridVisible = !nextState.isGridVisible
      })

    case UIActions.SetArtistMode:
      return produce(currentState, nextState => {
        nextState.isArtistMode = action.payload
      })

    default:
      return currentState
  }
}

// -- HANDLERS

const handleTogglePanelState = (currentState: UI, action: TogglePanelState) => {
  return {
    ...currentState,
    isCollapsed: {
      ...currentState.isCollapsed,
      [action.id]: !currentState.isCollapsed[action.id],
    },
  }
}

const handleSelectPivot = (currentState: UI, { pivot }: SelectPivot) => {
  return Object.assign({}, currentState, { selectedPivot: pivot })
}

const handleToggleSidebar = (
  currentState: UI,
  { sidebarType }: ToggleSidebar,
) => {
  switch (sidebarType) {
    case SidebarType.Navigation:
      return Object.assign({}, currentState, {
        isNavigationSidebarVisible: !currentState.isNavigationSidebarVisible,
      })

    case SidebarType.Document:
      return Object.assign({}, currentState, {
        isDocumentSidebarVisible: !currentState.isDocumentSidebarVisible,
      })

    case SidebarType.SceneObjects:
      return Object.assign({}, currentState, {
        isSceneObjectsSidebarVisible:
          !currentState.isSceneObjectsSidebarVisible,
      })

    default:
      return currentState
  }
}

const handleSetViewportSize = (
  currentState: UI,
  { width, height }: SetViewportSize,
) => {
  const viewport = { width, height }
  return Object.assign({}, currentState, { viewport })
}
