import _ from 'lodash'
import { useState } from 'react'

type Query = string

export const useInlineSearch = <T>(
  items: T[],
  keys: (keyof T)[],
): [Query, (query: Query) => void, T[]] => {
  const [searchQuery, setSearchQuery] = useState<Query>('')

  let foundItems = items

  if (searchQuery && items.length) {
    const queryNormalized = _.lowerCase(searchQuery)

    foundItems = _.filter(items, item => {
      return _.some(keys, key => {
        return _.includes(_.lowerCase(`${item[key]}`), queryNormalized)
      })
    })
  }

  return [searchQuery, setSearchQuery, foundItems]
}
