import { State } from '..'
import { createSelector } from 'reselect'

export const routeSelector = (state: State) => state.routing.route

export const campaignIdSelector = createSelector([routeSelector], route =>
  Number(route.params.campaignId),
)

export const documentIdSelector = createSelector([routeSelector], route =>
  Number(route.params.documentId),
)
