import { createSelector } from 'reselect'

import { docSelector } from './doc'

export const metaId = createSelector([docSelector], doc => {
  const path = ['meta', 'id']
  const value = doc.meta.id
  return {
    path,
    value,
  }
})

export const metaVersion = createSelector([docSelector], doc => {
  const path = ['meta', 'version']
  const value = doc.meta.version
  return {
    path,
    value,
  }
})

export const metaName = createSelector([docSelector], doc => {
  const path = ['meta', 'name']
  const value = doc.meta.name
  return {
    path,
    value,
    error: value === '' ? 'Please provide a name for this document' : undefined,
  }
})

export const metaIsTemplate = createSelector([docSelector], doc => {
  const path = ['meta', 'isTemplate']
  const value = doc.meta.isTemplate
  return {
    path,
    value,
  }
})

export const metaIsApproved = createSelector([docSelector], doc => {
  const path = ['meta', 'isApproved']
  const value = doc.meta.isApproved
  return {
    path,
    value,
  }
})
