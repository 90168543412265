import _ from 'lodash'

import { ArcRotateCamera, NullEngine, Scene, Vector3 } from '@babylonjs/core'
import { State } from '../'
import * as dto from '../../dto/api'
import {
  getHdriUrl,
  getPresetMeshVisibilities,
  getRenderSize,
  unitToDegree,
  unitToM,
  unitToRadian,
} from '../../viewer/RenderData'
import { DocBlocs, SceneObjectBlocs, getDocBlocs } from '../doc/selectors'
import {
  AssetTexture,
  MaterialOption,
  SceneObjectPropertyBloc,
  SceneObjectPropertyType,
  UnitValue,
} from '../doc/types'
import { Commands } from './types'
import { AssetType } from '../assets/types'

// Creates a function for getting a short label for each object.
// Not using long UUID4 cause Blender has max length of 63 chars.
const createGetSceneObjectLabel = (sceneObjects: {
  [key: string]: SceneObjectBlocs
}) => {
  const sceneObjectLabels: { [key: string]: string } = {}

  let index = 1
  _.forEach(sceneObjects, sceneObject => {
    sceneObjectLabels[sceneObject.id] = index.toString()
    index += 1
  })

  return (sceneObject: SceneObjectBlocs) => sceneObjectLabels[sceneObject.id]
}

const getBaseScene = (docBlocs: DocBlocs) => {
  let filePath = `FallbackBaseScene.blend`

  const baseSceneObject = _.find(docBlocs.sceneObjects, {
    type: AssetType.BaseScene,
  })

  if (baseSceneObject && baseSceneObject.revision) {
    const { assetUri, assetId } = baseSceneObject.revision
    filePath = `${assetUri}${assetId}.blend`
  }

  return {
    op: 'load_file',
    args: [filePath],
  }
}

const getHdri = (docBlocs: DocBlocs) => {
  const hdriUrl = getHdriUrl(docBlocs.sceneObjects, '.exr')
  if (!hdriUrl) {
    return
  }

  return {
    op: 'change_env_texture',
    args: [hdriUrl],
  }
}

const getResolution = (docBlocs: DocBlocs, renderType: dto.RenderType) => {
  const requestSize = getRenderSize(docBlocs)

  if (renderType === 'PREVIEW') {
    // Clamp to Full HD size
    const maxPixels = 1920 * 1080
    const requestPixels = requestSize.width * requestSize.height

    if (requestPixels > maxPixels) {
      const scale = Math.sqrt(maxPixels / requestPixels)
      const width = Math.floor(requestSize.width * scale)
      const height = Math.floor(requestSize.height * scale)

      return [
        {
          op: 'set_resolution',
          args: [width, height, 100],
        },
      ]
    }
  }

  return {
    op: 'set_resolution',
    args: [requestSize.width, requestSize.height, 100],
  }
}

const getPPI = (docBlocs: DocBlocs, renderType: dto.RenderType) => {
  if (renderType === 'PREVIEW') {
    return
  }

  const PPI = docBlocs.canvas.print.resolution.value?.value ?? null
  if (!PPI) {
    return
  }
  return {
    op: 'set_ppi',
    args: [PPI],
  }
}

const getCamera = (docBlocs: DocBlocs) => {
  const { camera } = docBlocs

  const bjsEngine = new NullEngine()
  const bjsScene = new Scene(bjsEngine)
  const bjsTarget = new Vector3(
    unitToM(camera.position.x.value),
    unitToM(camera.position.y.value),
    unitToM(camera.position.z.value),
  )
  const bjsCamera = new ArcRotateCamera(
    'job',
    unitToRadian(camera.rotation.y.value) - Math.PI / 2,
    unitToRadian(camera.rotation.x.value),
    unitToM(camera.distance.value),
    bjsTarget,
    bjsScene,
    false,
  )
  bjsEngine.dispose()

  // TODO Edgar How do we apply the FOV? cc @MaikuMori
  return [
    {
      op: 'set_camera',
      args: [
        bjsCamera.position.x,
        bjsCamera.position.z,
        bjsCamera.position.y,
        unitToM(camera.position.x.value),
        unitToM(camera.position.z.value),
        unitToM(camera.position.y.value),
        0, // tilt
      ],
    },
    {
      op: 'set_focal_length',
      args: [camera.focalLength.value.value],
    },
  ]
}

const getBgFg = (op: string, imageUri: string) => {
  return {
    op,
    args: [imageUri],
  }
}

export const commandsSelector = (
  state: State,
  renderType: dto.RenderType,
): Commands => {
  const docBlocs = getDocBlocs(state)
  const getSceneObjectLabel = createGetSceneObjectLabel(docBlocs.sceneObjects)

  return _.compact(
    _.flatMapDeep([
      getBaseScene(docBlocs),
      getHdri(docBlocs),

      {
        op: 'set_frame_count',
        args: [1, 1],
      },
      {
        op: 'toggle_preview_layer',
        args: [renderType === 'PREVIEW'],
      },
      getResolution(docBlocs, renderType),
      getPPI(docBlocs, renderType),

      getCamera(docBlocs),

      _.flatMapDeep(docBlocs.sceneObjects, sceneObject => {
        const { revision, type } = sceneObject

        if (!revision) {
          return
        }
        if (type === AssetType.BaseScene) {
          return
        }

        const { assetUri, assetId } = revision

        const ctrlName = assetId

        if (sceneObject.isHidden.value) {
          return []
        }

        return [
          {
            op: 'append',
            args: [
              getSceneObjectLabel(sceneObject),
              `${assetUri}${assetId}.blend`,
            ],
          },

          {
            op: 'object_move',
            args: [
              getSceneObjectLabel(sceneObject),
              `${ctrlName}MainCtrl`,
              unitToM(sceneObject.position.x.value),
              unitToM(sceneObject.position.z.value),
              unitToM(sceneObject.position.y.value),
            ],
          },

          {
            op: 'object_rotate',
            args: [
              getSceneObjectLabel(sceneObject),
              `${ctrlName}MainCtrl`,
              -unitToDegree(sceneObject.rotation.x.value),
              -unitToDegree(sceneObject.rotation.z.value),
              -unitToDegree(sceneObject.rotation.y.value),
            ],
          },

          {
            op: 'object_scale',
            args: [
              getSceneObjectLabel(sceneObject),
              `${ctrlName}MainCtrl`,
              sceneObject.scale.x.value,
              sceneObject.scale.z.value,
              sceneObject.scale.y.value,
            ],
          },

          _.map(sceneObject.properties, propertyBloc => {
            const { value, property } = propertyBloc

            if (value == null) {
              return
            }

            if (property.type === 'imageTexture') {
              const { imageUri } = value as AssetTexture

              return {
                op: 'set_material_image',
                args: [
                  getSceneObjectLabel(sceneObject),
                  property.material,
                  'Image Texture',
                  imageUri,
                ],
              }
            }

            if (property.type === 'emission') {
              return {
                op: 'set_emission',
                args: [
                  getSceneObjectLabel(sceneObject),
                  property.material,
                  property.node,
                  (value as UnitValue).value,
                ],
              }
            }

            if (property.type === 'preset') {
              const visibilities = getPresetMeshVisibilities(
                propertyBloc as SceneObjectPropertyBloc<SceneObjectPropertyType.Preset>,
              )

              // Simulates `setEnabled` from BJS.
              //
              // Assumes that all nodes are visible from start and we run
              // commands just once.
              //
              // Needed to support multiple properties enabling/disabling nodes
              // at various levels of hierarchy.
              const hiddenMap = _.pickBy(visibilities, visible => !visible)
              return _.map(hiddenMap, (_, object) => ({
                op: 'object_set_visibility',
                args: [getSceneObjectLabel(sceneObject), object, false],
              }))
            }

            if (property.type === 'material') {
              const { values } = value as MaterialOption

              return _.flatMap(values, ({ meshes, material }) =>
                _.map(meshes, mesh => ({
                  op: 'object_replace_materials',
                  args: [getSceneObjectLabel(sceneObject), mesh, [material]],
                })),
              )
            }

            return null
          }),
        ] as Commands
      }),

      docBlocs.visuals.background.value &&
        getBgFg(
          'change_background',
          docBlocs.visuals.background.value.imageUri,
        ),

      docBlocs.visuals.foreground.value &&
        getBgFg(
          'change_foreground',
          docBlocs.visuals.foreground.value.imageUri,
        ),
    ]),
  ) as Commands
}

const w = window as any
w.commandsSelector = commandsSelector
