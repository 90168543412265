import type { Action as ReduxAction, Dispatch } from 'redux'

import _ from 'lodash'
import http from '../../http'
import { canvasPresetToApi, canvasPresetsFromApi } from './dataTransformers'
import type { CanvasPresets } from './state'
import type { CanvasPreset } from './types'

export type Action =
  | LoadCanvasPresets
  | LoadedCanvasPresets
  | DeleteCanvasPresets
  | CreateCanvasPreset
  | ReloadCanvasPresets

export enum CanvasPresetsActions {
  LoadCanvasPresets = 'LOAD_CANVAS_PRESETS',
  LoadedCanvasPresets = 'LOADED_CANVAS_PRESETS',
  CreateCanvasPreset = 'CREATE_CANVAS_PRESET',
  DeleteCanvasPresets = 'DELETE_CANVAS_PRESETS',
  ReloadCanvasPresets = 'RELOAD_CANVAS_PRESETS',
}

export interface LoadCanvasPresets extends ReduxAction<CanvasPresetsActions> {
  type: CanvasPresetsActions.LoadCanvasPresets
}

export interface LoadedCanvasPresets extends ReduxAction<CanvasPresetsActions> {
  type: CanvasPresetsActions.LoadedCanvasPresets
  payload: { canvasPresets: CanvasPresets }
}

export const loadCanvasPresets = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CanvasPresetsActions.LoadCanvasPresets,
  })

  const response = await http.get('/api/canvas_presets')
  const canvasPresets = canvasPresetsFromApi(response.data)

  dispatch({
    type: CanvasPresetsActions.LoadedCanvasPresets,
    payload: { canvasPresets },
  })
}

export interface ReloadCanvasPresets extends ReduxAction<CanvasPresetsActions> {
  type: CanvasPresetsActions.ReloadCanvasPresets
}

export const reloadCanvasPresets = () => ({
  type: CanvasPresetsActions.ReloadCanvasPresets,
})

export interface CreateCanvasPreset extends ReduxAction<CanvasPresetsActions> {
  type: CanvasPresetsActions.CreateCanvasPreset
  payload: { canvasPreset: CanvasPreset }
}

export const createCanvasPreset =
  (payload: CreateCanvasPreset['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CanvasPresetsActions.CreateCanvasPreset,
      payload,
    })

    const { canvasPreset } = payload
    await http.post('/api/canvas_preset', canvasPresetToApi(canvasPreset))

    dispatch({
      type: CanvasPresetsActions.ReloadCanvasPresets,
    })
  }

export interface DeleteCanvasPresets extends ReduxAction<CanvasPresetsActions> {
  type: CanvasPresetsActions.DeleteCanvasPresets
  payload: { canvasPresetIds: number[] }
}

export const deleteCanvasPresets =
  (payload: DeleteCanvasPresets['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CanvasPresetsActions.DeleteCanvasPresets,
      payload,
    })

    const { canvasPresetIds } = payload

    const promises = _.map(canvasPresetIds, async presetId => {
      return http.post(`/api/canvas_preset/${presetId}/hide`)
    })

    await Promise.all(promises)

    dispatch({
      type: CanvasPresetsActions.ReloadCanvasPresets,
    })
  }
