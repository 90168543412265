import _ from 'lodash'
import * as dto from '../../dto/api'
import { RenderJobStatus, RenderStatus } from '../campaign/types'
import { canvasPresetFromApi } from '../canvasPresets/dataTransformers'
import {
  Asset,
  AssetRevision,
  AssetTexture,
  CanvasTexture,
  CanvasTextureType,
} from '../doc/types'
import { getRenderStatusThumbFromAPI } from '../jobs/dataTransformers'
import { permissions } from './state'
import {
  CampaignAssets,
  CampaignBase,
  CampaignDocuments,
  PartialDoc,
} from './types'
import { asAssetSettings } from '../assets/dataTransformers'

// const UNIT_TO_API: { [key: string]: string } = {
//   px: 'PIXEL',
//   '"': 'INCH',
//   "'": 'FOOT',
//   cm: 'CENTIMETER',
//   m: 'METER',
// }

// const BLEED_TO_API: {
//   [key in dto.LengthUnit]: (value: number) => number
// } = {
//   PIXEL: value => value,
//   INCH: value => value,
//   FOOT: value => value * 12,
//   CENTIMETER: value => value * 10,
//   METER: value => value * 1000,
// }

// export const canvasPresetToApi = (
//   $: CanvasPreset,
//   campaignId: string,
// ): ApiReqCanvasPreset => {
//   const unitType = UNIT_TO_API[$.size.width.unit]
//   return {
//     campaignId: parseFloat(campaignId),
//     name: $.name,
//     unitType,
//     width: $.size.width.value.toString(10),
//     height: $.size.height.value.toString(10),
//     resolution: $.print ? $.print.resolution.value : 72,
//     bleed: $.print ? BLEED_TO_API[unitType]($.print.bleed.value) : undefined,
//   }
// }

export const canvasTextureFromApi = ($: dto.CanvasTexture): CanvasTexture => {
  return {
    id: $.id,
    type:
      $.textureType === 'BACKGROUND'
        ? CanvasTextureType.Background
        : CanvasTextureType.Foreground,
    name: $.name,
    imageUri: $.imageUri,
    isCustom: $.isCustom,
    canvasPresetId: $.canvasPresetId,
    createdAt: $.createdAt,
    width: $.width,
    height: $.height,
  }
}

export const assetFromApi = ($: dto.Device): Asset => {
  const dtoRevisions = _.chain($.revisions)
    .filter(revision => !!revision.settings)
    .sortBy(revision => revision.id)
    .value()

  // Can not be empty.
  const lastDtoRevision = _.last(dtoRevisions)!
  const settings = asAssetSettings(lastDtoRevision.settings)
  const thumbnailUri = lastDtoRevision.assetUri + settings.thumbnail
  const assetType = settings.type

  return {
    id: $.id,
    type: assetType,
    name: settings.name,
    thumbnail: thumbnailUri,
    createdAt: lastDtoRevision.createdAt,
    isApproved: true, // TODO: Get isApproved.
    hasScreen: _.some(
      settings.properties,
      property => property.type === 'imageTexture',
    ),
    revisions: _.map(dtoRevisions, revision => {
      const settings = asAssetSettings(revision.settings)

      return {
        id: revision.id,
        type: assetType,
        name: settings.name,
        assetId: settings.id,
        assetUri: revision.assetUri,
        properties: settings.properties,
        createdAt: revision.createdAt,
      } as AssetRevision
    }),
  }
}

export const screenTextureFromApi = ($: dto.DeviceTexture): AssetTexture => {
  return {
    id: $.id,
    name: $.name,
    imageUri: $.imageUri,
    assetIds: $.deviceIds,
    width: $.width,
    height: $.height,
    isCustom: $.isCustom,
    createdAt: $.createdAt,
  }
}

export const screenTextureToApi = (
  assetTexture: AssetTexture,
): dto.DeviceTexture => {
  return {
    id: assetTexture.id,
    name: assetTexture.name,
    imageUri: assetTexture.imageUri,
    deviceIds: assetTexture.assetIds,
    width: assetTexture.width,
    height: assetTexture.height,
    isCustom: assetTexture.isCustom,
  } as dto.DeviceTexture
}

export const campaignAssetsFromApi = (
  $: dto.GetCampaignAssetsResp,
  $$: dto.GetCanvasPresetsResp,
): CampaignAssets => {
  return {
    canvasPresets: _.keyBy(_.map($$.canvasPresets, canvasPresetFromApi), 'id'),
    canvasTextures: _.keyBy(
      _.map(
        _.filter($.canvasTextures, ['isCustom', false]),
        canvasTextureFromApi,
      ),
      'id',
    ),
    assets: _.map($.devices, assetFromApi),
    screenTextures: _.map(
      _.filter($.deviceTextures, ['isCustom', false]),
      screenTextureFromApi,
    ),
  }
}

export const campaignBaseFromApi = ($: dto.Campaign): CampaignBase => {
  return {
    id: $.id,
    name: $.name,
    // TODO: Use thumbnail from API when it's there.
    thumbnail: 'https://via.placeholder.com/100',
    permissions,
  }
}

export const partialDocFromApi = ($: dto.PartialDocument): PartialDoc => {
  const thumbnail = getRenderStatusThumbFromAPI($.renderStatus)
  return {
    id: $.id,
    version: $.version,
    name: $.name,
    thumbnail,
    isTemplate: $.isTemplate,
    modifiedAt: $.updatedAt,
    canvasPresetId: $.canvasPresetId,
    renderStatus: $.renderStatus,
  }
}

export const campaignDocumentsFromApi = (
  $: dto.GetCampaignDocumentsResp,
): CampaignDocuments => {
  return _.map($.documents, partialDocFromApi)
}

export const renderJobStatusFromApi = (job: dto.RenderJob): RenderJobStatus => {
  return job
}

export const renderStatusFromApi = (
  response: dto.GetDocumentRenderStatusResp,
): RenderStatus => {
  return _.pickBy(
    _.mapValues(response.status, renderJobStatusFromApi),
    _.identity,
  )
}
