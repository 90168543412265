import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'
import { IContextualMenuProps, SelectionMode } from 'office-ui-fabric-react'

import { State } from '../../store'
import {
  createAsset,
  createRevision,
  deleteAssets,
  loadAssets,
  uploadAsset,
  reloadAssets,
} from '../../store/assets/actions'
import {
  isAssetsReloadNeededSelector,
  isCampaignsReloadNeededSelector,
} from '../../store/loaders/selectors'
import { push } from '../../store/routing/actions'
import { loadCampaigns } from '../../store/campaigns/actions'
import { updateDeviceCampaignMappings } from '../../store/campaign/actions'
import { getAssignedCampaignNames } from '../../lib/getAssignedCampaignNames'
import { defaultFilter, ColumnDef, Table } from '../../components/Table'
import { AssetStatus, selectAsset } from '../../components/AssetInput'
import { download, FileType } from '../../lib/fileUtils'
import { setCommandBarItems, CommandBar } from '../../components/CommandBar'
import { LoadState } from '../../store/loaders/types'
import { assetTypes } from '../../store/assets/types'
import { DeleteDialog } from '../../components/DeleteDialog'
import { Header } from '../../containers/Header'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Page } from '../../components/Page'
import { HomeNavigation } from '../../containers/Navigation'
import { CreateModal } from './CreateModal'
import { EditModal } from './EditModal'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

export type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  assets: state.assets,
  campaigns: state.campaigns,
  isAssetsReloadNeeded: isAssetsReloadNeededSelector(state),
  isCampaignsReloadNeeded: isCampaignsReloadNeededSelector(state),
  loaders: state.loaders,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      uploadAsset,
      loadAssets,
      loadCampaigns,
      deleteAssets,
      createAsset,
      createRevision,
      updateDeviceCampaignMappings,
      reloadAssets,
    },
    dispatch,
  )

const AssetsContainer = (props: Props) => {
  const {
    assets,
    campaigns,
    isAssetsReloadNeeded,
    isCampaignsReloadNeeded,
    loadAssets,
    loadCampaigns,
    deleteAssets,
    loaders,
  } = props

  const tableItems = _.map(assets, partialAsset => ({
    id: partialAsset.id,
    name: partialAsset.name,
    thumbnail: partialAsset.thumbnail,
    type: partialAsset.type,
    assignedCampaigns: partialAsset.assignedCampaigns,
    assignedCampaignNames: getAssignedCampaignNames(
      campaigns,
      partialAsset.assignedCampaigns,
    ),
    version: partialAsset.version,
    updated: partialAsset.createdAt,
    updatedAgo: timeago.format(partialAsset.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)

  const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter)
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const [uploadState, setUploadState] = useState<AssetStatus>(selectAsset)

  useEffect(() => {
    const run = async () => {
      if (isAssetsReloadNeeded) {
        await loadAssets()
      }
    }
    run()
  }, [isAssetsReloadNeeded, loadAssets])

  useEffect(() => {
    const run = async () => {
      if (isCampaignsReloadNeeded) {
        await loadCampaigns()
      }
    }
    run()
  }, [isCampaignsReloadNeeded, loadCampaigns])

  const [selectedItems, setSelectedItems] = useState<TItem[]>([])
  const [contextualMenuProps, setContextualMenuProps] =
    useState<IContextualMenuProps>()

  const defaultActionItems = [
    {
      key: 'create_asset',
      text: 'Create Asset',
      iconProps: {
        iconName: 'add',
      },
      className: '',
      onClick: () => {
        setCreateModalOpen(true)
      },
    },
  ]

  const multiActionItems = () => {
    const assets = selectedItems
    return [
      {
        key: 'download',
        text: 'Download',
        iconProps: {
          iconName: 'download',
        },
        className: '',
        onClick: () => {
          downloadAssets(assets)
        },
      },
      {
        key: 'edit',
        text: 'Edit',
        iconProps: {
          iconName: 'edit',
        },
        className: '',
        onClick: () => {
          setEditModalOpen(true)
        },
      },
      {
        key: 'delete',
        text: 'Delete',
        iconProps: {
          iconName: 'delete',
        },
        className: 'is-dangerous',
        onClick: () => {
          setIsDialogVisible(true)
        },
      },
    ]
  }

  const downloadAssets = (items: TItem[]) => {
    const assetsToDownload = getAssetsToEdit(items)

    _.map(assetsToDownload, asset => {
      const lastRevision = _.last(asset.revisions)
      if (!lastRevision) {
        return
      }
      const uploadUri = lastRevision.uploadUri
      download(
        uploadUri,
        `${asset.name} - v${lastRevision.version}`,
        FileType.Zip,
      )
    })
  }

  const commandBarItems = setCommandBarItems(selectedItems.length, {
    none: defaultActionItems,
    single: _.concat(defaultActionItems, multiActionItems()),
    multi: _.concat(defaultActionItems, multiActionItems()),
  })

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'thumbnail', name: 'Thumbnail', width: 128 },
    { key: 'name', name: 'Name', minWidth: 128 },
    { key: 'type', name: 'Type', width: 100 },
    {
      key: 'assignedCampaignNames',
      name: 'Campaigns',
      minWidth: 100,
      maxWidth: 9999,
    },
    { key: 'version', name: 'Version', width: 60 },
    { key: 'updated', name: 'Updated', width: 100, fieldName: 'updatedAgo' },
  ]

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        props.push('/')
      },
    },
    {
      text: 'Global Content',
      key: 'global content',
      onClick: () => {
        props.push('/specs')
      },
    },
    {
      text: 'Assets',
      key: 'assets',
      onClick: () => {
        props.push('/assets')
      },
    },
  ]

  const isLoading =
    loaders.assetsState !== LoadState.Loaded ||
    loaders.campaignsState !== LoadState.Loaded

  const onItemContextMenu = (item: TItem, index?: number, ev?: Event): void => {
    setSelectedItems(selectedItems.concat([item]))
    const contextualMenuProperties: IContextualMenuProps = {
      target: ev as MouseEvent,
      items: multiActionItems(),
      onDismiss: () => {
        setContextualMenuProps(undefined)
      },
    }

    if (index !== undefined && index > -1) {
      setContextualMenuProps(contextualMenuProperties)
    }
  }

  const closeModals = () => {
    setCreateModalOpen(false)
    setEditModalOpen(false)
    setUploadState(selectAsset)
  }

  const getFilters = () => {
    const assetFilters = _.map(assetTypes, type => type.name)
    return _.concat(defaultFilter, assetFilters)
  }

  const getAssetsToEdit = (items: TItem[]) => {
    return _.filter(assets, asset =>
      _.includes(
        _.map(items, item => item.id),
        asset.id,
      ),
    )
  }

  return (
    <>
      {isCreateModalOpen && (
        <CreateModal
          assets={assets}
          closeModals={closeModals}
          uploadState={uploadState}
          setUploadState={setUploadState}
          uploadAsset={props.uploadAsset}
          createAsset={props.createAsset}
        />
      )}
      {isEditModalOpen && (
        <EditModal
          assets={getAssetsToEdit(selectedItems)}
          campaigns={campaigns}
          createRevision={props.createRevision}
          closeModals={closeModals}
          uploadAsset={props.uploadAsset}
          reloadAssets={props.reloadAssets}
          uploadState={uploadState}
          setUploadState={setUploadState}
          updateDeviceCampaignMappings={props.updateDeviceCampaignMappings}
        />
      )}
      {isDialogVisible && (
        <DeleteDialog
          itemName="asset"
          onClose={() => {
            setIsDialogVisible(false)
          }}
          onSuccess={() => {
            deleteAssets({
              assetIds: _.map(selectedItems, item => item.id),
            })
          }}
          itemsToDelete={selectedItems}
        />
      )}
      <Page
        header={
          <Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />
        }
        sidebar={<HomeNavigation />}
        commandBar={
          <CommandBar items={commandBarItems} requiresLeftOffset={true} />
        }
        body={
          <Table<TItem>
            columns={columnItems}
            defaultSort={{ key: 'updated', desc: true }}
            filters={getFilters()}
            selectedFilter={selectedFilter}
            setFilter={setSelectedFilter}
            filterColumn="type"
            items={tableItems}
            onItemClick={() => {
              setEditModalOpen(true)
            }}
            selectionMode={SelectionMode.multiple}
            onSelectionChanged={setSelectedItems}
            contextualMenuProps={contextualMenuProps}
            onItemContextMenu={onItemContextMenu}
          />
        }
        isLoading={isLoading}
      />
    </>
  )
}

export const Assets = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetsContainer)
