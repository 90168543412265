import { ErrorBoundary } from '@sentry/react'
import { IStyle, Stack } from 'office-ui-fabric-react'
import React from 'react'
import useResizeObserver from 'use-resize-observer/polyfilled'
import { ErrorMessage } from './ErrorMessage'

interface Props {
  children: (width: number, height: number) => React.ReactNode
}

const styles = {
  wrapper: {
    root: {
      backgroundColor: '#444444',
      padding: 20,
      width: '100%',
      minWidth: 410,
      position: 'relative',
    } as IStyle,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'auto',
    height: '100%',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',

    flexShrink: 1,
  } as React.CSSProperties,
}

export const Preview = (props: Props) => {
  const { ref, width = 100, height = 100 } = useResizeObserver()
  return (
    <Stack grow styles={styles.wrapper}>
      <ErrorBoundary fallback={<ErrorMessage isBackgroundDark={true} />}>
        <div ref={ref} style={styles.container}>
          {props.children(width, height)}
        </div>
      </ErrorBoundary>
    </Stack>
  )
}
