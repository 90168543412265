import _ from 'lodash'
import { Selector } from 'reselect'

export type SelectorTree<State, Result> = {
  [K in keyof Result]:
    | SelectorTree<State, Result[keyof Result]>
    | Selector<State, Result[keyof Result]>
}

const isSelector = <State, Result>(
  node: SelectorTree<State, Result> | Selector<State, Result>,
): node is Selector<State, Result> => {
  return _.isFunction(node)
}

const mapValuesToState =
  <State, RootResult>(rootTree: SelectorTree<State, RootResult>) =>
  (state: State) => {
    const mapState = <Result>(tree: SelectorTree<State, Result>): Result => {
      return _.mapValues(tree, node => {
        if (isSelector(node)) {
          return node(state)
        }

        return mapState(node as SelectorTree<State, Result[keyof Result]>)
      }) as Result
    }

    return mapState(rootTree)
  }

export default mapValuesToState
