import { Action } from '../actions'
import { CanvasPresetsActions } from './actions'
import { CanvasPresets, initialState } from './state'

export const reducers = (
  state: CanvasPresets = initialState(),
  action: Action,
): CanvasPresets => {
  switch (action.type) {
    case CanvasPresetsActions.LoadedCanvasPresets:
      const { payload } = action
      return payload.canvasPresets
  }

  return state
}
