import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import _ from 'lodash'
import { IStyle, Stack } from 'office-ui-fabric-react'
import { Panel } from '../../containers/Panel'
import { State } from '../../store'
import { AssetType } from '../../store/assets/types'
import {
  addSceneObject,
  removeSceneObject,
  setInDoc,
} from '../../store/doc/actions'
import { getDocBlocs } from '../../store/doc/selectors'
import { Asset } from '../../store/doc/types'
import { EnvironmentObjectPanel } from './EnvironmentObjectPanel'
import { AddSceneObjectAction } from './AddSceneObjectAction'

type OwnProps = {
  assetType: AssetType
  label: string
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  docBlocs: getDocBlocs(state),
  campaign: state.campaign,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addSceneObject,
      setInDoc,
      removeSceneObject,
    },
    dispatch,
  )

const styles = {
  listOrder: {
    root: {
      display: 'flex',
      flexDirection: 'column-reverse',
    } as IStyle,
  },
}

export const EnvironmentSetUpPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { assetType, label, docBlocs, campaign } = props

  const assets = _.filter(
    campaign.assets.assets,
    asset => asset.type === assetType,
  )

  const sceneObjectBlocs = _.filter(
    docBlocs.sceneObjects,
    sceneObject => sceneObject.type === assetType,
  )

  const isAddActionVisible = !sceneObjectBlocs.length || assets.length > 1

  return (
    <Panel
      id={assetType}
      label={label}
      mainAction={
        isAddActionVisible ? (
          <AddSceneObjectAction
            assetType={assetType}
            assets={assets}
            label={sceneObjectBlocs.length ? `Change ${label}` : `Add ${label}`}
            onSelect={(asset: Asset) => {
              if (sceneObjectBlocs.length) {
                props.removeSceneObject(sceneObjectBlocs[0].id)
              }
              const lastRevision = _.last(asset.revisions)!
              props.addSceneObject({
                assetId: asset.id,
                type: asset.type,
                revisionId: lastRevision.id,
              })
            }}
          />
        ) : undefined
      }
    >
      {sceneObjectBlocs.length ? (
        <Stack styles={styles.listOrder}>
          {_.map(sceneObjectBlocs, sceneObjectBloc => {
            return (
              <EnvironmentObjectPanel
                key={sceneObjectBloc.id}
                sceneObjectUuid={sceneObjectBloc.id}
                sceneObjectBloc={sceneObjectBloc}
                setInDoc={props.setInDoc}
                isAddActionVisible={isAddActionVisible}
                assetType={assetType}
                assets={assets}
              />
            )
          })}
        </Stack>
      ) : undefined}
    </Panel>
  )
})
