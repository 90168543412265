import _ from 'lodash'
import { INavLink } from 'office-ui-fabric-react'
import React from 'react'
import { connect } from 'react-redux'
import { Navigation as Nav } from '../components/Navigation'
import { filterByPermissions } from '../pages/users/utils'
import { State } from '../store'

export interface IProps {
  push: (path: string) => void
}

type StateProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  account: state.auth.account,
})

type Props = IProps & StateProps

export const HomeNavigation = connect(mapStateToProps)((props: Props) => {
  const { account } = props

  const navigationGroup = [
    {
      name: 'Campaigns',
      links: [
        {
          name: 'Active',
          icon: 'page',
          url: '/',
          key: '/',
          permissions: ['GLOBAL_ADMIN', 'CAMPAIGN_ADMIN', 'EDITOR'],
        },
      ],
    },
    {
      name: 'Global Content',
      links: [
        {
          name: 'Specs',
          icon: 'QuickNote',
          url: '/specs',
          key: 'specs',
          permissions: ['GLOBAL_ADMIN'],
        },
        {
          name: 'Assets',
          icon: 'System',
          url: '/assets',
          key: 'assets',
          permissions: ['GLOBAL_ADMIN'],
        },
        {
          name: 'Users',
          icon: 'UserFollowed',
          url: '/users',
          key: 'users',
          permissions: ['GLOBAL_ADMIN'],
        },
      ],
    },
    // {
    //   name: 'Settings',
    //   links: [
    //     {
    //       name: 'Permissions',
    //       icon: 'system',
    //       url: '/permissions',
    //       key: 'permissions',
    //     },
    //   ],
    // },
  ]

  return (
    <Nav
      onLinkClick={(
        e?: React.MouseEvent<HTMLElement, MouseEvent>,
        x?: INavLink,
      ) => {
        if (e && x) {
          e.preventDefault()
          props.push(x.url)
        }
      }}
      groups={_.compact(
        _.map(navigationGroup, group => {
          const filteredGroup = {
            ...group,
            links: filterByPermissions(group.links, account.permissions),
          }

          return filteredGroup.links.length > 0 ? filteredGroup : null
        }),
      )}
    />
  )
})
