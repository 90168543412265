import {
  Label as FabricLabel,
  Text,
  mergeStyleSets,
} from 'office-ui-fabric-react'
import React from 'react'
import * as commonStyles from '../styles'

interface IProps {
  text: string
  htmlFor?: string
  unit?: string
  limitLabelWidth?: boolean
}

const styles = {
  label: commonStyles.label,
  unit: {
    root: {
      color: '#8A8886',
    },
  },
}

export const Label = (props: IProps) => {
  const { text, unit, htmlFor, limitLabelWidth } = props
  return (
    <FabricLabel
      styles={mergeStyleSets(
        styles.label.base,
        limitLabelWidth && styles.label.long,
      )}
      htmlFor={htmlFor}
      title={text}
    >
      {text}
      {unit && <Text styles={styles.unit}> {unit}</Text>}
    </FabricLabel>
  )
}
