import {
  Breadcrumb,
  FontWeights,
  IBreadcrumbItem,
  IStyle,
} from 'office-ui-fabric-react'
import React from 'react'

export interface Props {
  items: IBreadcrumbItem[]
}

const styles = {
  root: {
    marginTop: 0,
    marginLeft: 20,
  },
  listItem: {
    // Overriding itemLink styles here to overcome default high specificy.
    selectors: {
      '&:last-child .ms-Breadcrumb-itemLink:not(:hover)': {
        color: '#FFFFFF',
      },
      '&:last-child .ms-Breadcrumb-itemLink': {
        fontWeight: FontWeights.regular,
      },
      '& .ms-Breadcrumb-itemLink:hover, & .ms-Breadcrumb-itemLink:focus': {
        backgroundColor: '#005A9E',
        color: '#FFFFFF',
        textDecoration: 'none',
      },
    },
  },
  overflowButton: {
    color: '#FFFFFF',
  },
  itemLink: {
    height: 40,
    lineHeight: 40,
    color: '#FFFFFF',
    fontSize: 16,
  },
  chevron: {
    color: '#FFFFFF',
    top: 1,
    position: 'relative',
  } as IStyle,
}

export const Breadcrumbs = (props: Props) => {
  return <Breadcrumb styles={styles} items={props.items} />
}
