import { Action as HistoryAction } from 'history'

import { router } from '../../lib/router'
import { Action } from '../actions'
import { Navigated, Push, Replace, RoutingActions } from './actions'
import { Routing, initialState } from './state'

const onNotFound = () => ({
  ...initialState(),
  isNotFound: true,
})

const onNavigated = (state: Routing, payload: Navigated['payload']) => {
  const resolvedRoute = router.resolve(payload.location)

  if (!resolvedRoute) {
    return onNotFound()
  }

  return {
    ...state,
    route: resolvedRoute,
    isNotFound: false,
  }
}

export const reducers = (state: Routing = initialState(), action: Action) => {
  switch (action.type) {
    case RoutingActions.Navigated:
      return onNavigated(state, action.payload as Navigated['payload'])
    case RoutingActions.Push:
      return onNavigated(state, {
        location: action.payload as Push['payload'],
        action: HistoryAction.Push,
      })
    case RoutingActions.Replace:
      return onNavigated(state, {
        location: action.payload as Replace['payload'],
        action: HistoryAction.Replace,
      })
  }

  return state
}
