import { default as React } from 'react'

import * as dto from '../dto/api'
import {
  CheckboxList,
  SelectionMappings,
  useSelectionLogic,
} from './CheckboxList'
import { transform } from 'lodash-es'

export type CampaignMappings = SelectionMappings

export const getCampaignMappings = (
  campaigns: dto.Campaign[],
  isCampaignSelected: false | ((campaignId: number) => boolean | null),
): CampaignMappings => {
  return transform(
    campaigns,
    (result, campaign) => {
      const isSelected = isCampaignSelected && isCampaignSelected(campaign.id)
      result[campaign.id.toString()] = isSelected
    },
    {} as CampaignMappings,
  )
}

type Props = {
  label: string
  items: dto.Campaign[]
  mappings: CampaignMappings
  setMappings: (campaignMappings: CampaignMappings) => void
  disabled?: boolean
}

export const CampaignList = (props: Props) => {
  const { mappings, setMappings, disabled } = props
  const selectionLogic = useSelectionLogic(mappings, setMappings)

  return (
    <>
      <CheckboxList<dto.Campaign>
        label={props.label}
        items={props.items}
        selectionLogic={selectionLogic}
        disabled={disabled}
      />
    </>
  )
}
