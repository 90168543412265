import _ from 'lodash'
import { ICommandBarItemProps } from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { AssetType } from '../store/assets/types'
import { Asset } from '../store/doc/types'
import {
  duplicateSceneObject,
  removeSceneObject,
  replaceSceneObject,
  setInDoc,
} from '../store/doc/actions'
import { getDocBlocs } from '../store/doc/selectors'
import { getInitialSceneObjectState } from '../store/doc/state'
import { ActionsMenu } from '../components/ActionsMenu'
import { TooltipItem } from '../components/TooltipItem'
import { SceneObjectsModal } from '../pages/Doc/SceneObjectsModal'

type Action =
  | 'lock'
  | 'visibility'
  | 'reset'
  | 'replace'
  | 'duplicate'
  | 'remove'

type OwnProps = {
  sceneObjectUuid: string
  sceneObjectBloc: ReturnType<typeof getDocBlocs>['sceneObjects'][0]
  includeOverflowItems?: boolean
  hiddenActions?: Action[]
  assetType: AssetType
  assets: Asset[]
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & DispatchProps

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInDoc,
      duplicateSceneObject,
      removeSceneObject,
      replaceSceneObject,
    },
    dispatch,
  )

export const SceneObjectActions = connect(
  null,
  mapDispatchToProps,
)((props: Props) => {
  const {
    sceneObjectBloc,
    sceneObjectUuid,
    duplicateSceneObject,
    removeSceneObject,
    replaceSceneObject,
    hiddenActions,
    includeOverflowItems = true,
    assetType,
    assets,
  } = props

  const [isReplaceModalOpen, setReplaceModalOpen] = useState(false)

  useHotkeys(
    'delete, backspace',
    () => {
      if (!sceneObjectBloc.isSelected.value) {
        return
      }
      removeSceneObject(sceneObjectUuid)
    },
    {},
    [removeSceneObject, sceneObjectBloc, sceneObjectUuid],
  )

  const isLockedBloc = sceneObjectBloc.isLocked
  const isHiddenBloc = sceneObjectBloc.isHidden

  const quickActions: ICommandBarItemProps[] = [
    {
      key: 'lock',
      title: isLockedBloc.value ? 'Unlock' : 'Lock',
      iconProps: { iconName: isLockedBloc.value ? 'Lock' : 'Unlock' },
      className: isLockedBloc.value ? 'active' : undefined,
      onMouseDown: event => {
        event?.stopPropagation()
        props.setInDoc({ path: isLockedBloc.path, value: !isLockedBloc.value })
      },
    },
    {
      key: 'visibility',
      title: isHiddenBloc.value ? 'Show' : 'Hide',
      iconProps: { iconName: isHiddenBloc.value ? 'Hide3' : 'View' },
      className: isHiddenBloc.value ? 'active' : undefined,
      onMouseDown: event => {
        event?.stopPropagation()
        props.setInDoc({ path: isHiddenBloc.path, value: !isHiddenBloc.value })
      },
    },
  ]

  const overflowActions: ICommandBarItemProps[] = [
    {
      key: 'reset',
      name: 'Reset',
      onRenderIcon: () => <></>,
      onClick: () => {
        props.setInDoc({
          path: ['sceneObjects', sceneObjectUuid],
          value: {
            id: sceneObjectUuid,
            type: sceneObjectBloc.type,
            assetId: sceneObjectBloc.sceneObject?.id,
            revisionId: sceneObjectBloc.revision?.id,
            ...getInitialSceneObjectState(),
          },
        })
      },
    },
    {
      key: 'replace',
      name: 'Replace',
      onRenderIcon: () => <></>,
      onClick: () => {
        setReplaceModalOpen(true)
      },
    },
    {
      key: 'duplicate',
      name: 'Duplicate',
      onRenderIcon: () => <></>,
      onClick: () => {
        duplicateSceneObject({ sceneObjectId: sceneObjectBloc.id })
      },
    },
    {
      key: 'remove',
      iconProps: {},
      onRenderIcon: () => (
        <TooltipItem
          itemName="Remove"
          tooltip="Delete / Backspace"
          hideTooltip={!sceneObjectBloc.isSelected.value}
        />
      ),
      onClick: () => {
        removeSceneObject(sceneObjectUuid)
      },
    },
  ]

  const filterActions = (actions: ICommandBarItemProps[]) => {
    return _.filter(actions, action => !_.includes(hiddenActions, action.key))
  }

  return (
    <>
      <SceneObjectsModal
        title={`Replace ${_.capitalize(assetType)}`}
        assetType={assetType}
        assets={assets}
        onSelect={asset => {
          replaceSceneObject({ sceneObjectUuid, asset })
        }}
        isModalOpen={isReplaceModalOpen}
        setModalOpen={setReplaceModalOpen}
      />

      <ActionsMenu
        quickActions={filterActions(quickActions)}
        overflowActions={
          includeOverflowItems ? filterActions(overflowActions) : undefined
        }
        isSelected={sceneObjectBloc.isSelected.value}
      />
    </>
  )
})
