import * as dto from '../../dto/api'
import { Commands } from './types'
import { getThumbUrl } from './utils'

export const getRenderJobThumbFromAPI = (
  $: dto.RenderJob | undefined,
): string | null => {
  if ($ == null) {
    return null
  }
  const { resultUri, type } = $
  if (resultUri == null) {
    return null
  }

  return getThumbUrl(resultUri, type)
}

export const getRenderStatusThumbFromAPI = (
  $: dto.RenderStatus,
): string | null => {
  return (
    getRenderJobThumbFromAPI($.final) || getRenderJobThumbFromAPI($.preview)
  )
}

export const jobToApi = (
  type: dto.RenderType,
  commands: Commands,
): dto.CreateRenderJobReq => {
  return {
    commands,
    type,
  }
}
