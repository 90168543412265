import { ErrorBoundary } from '@sentry/react'
import { IStyle, Stack } from 'office-ui-fabric-react'
import React from 'react'

import { Sidebar, SidebarType } from '../containers/Sidebar'
import { ErrorMessage } from './ErrorMessage'
import { Loader } from './Loader'

export enum BodyMode {
  padding = 0,
  noPadding = 1,
}

interface IProps {
  header: JSX.Element
  body: JSX.Element
  commandBar?: JSX.Element
  sidebar?: JSX.Element
  bodyMode?: BodyMode
  isLoading?: boolean
}

const styles = {
  minHeight0: {
    root: {
      minHeight: 0,
    } as IStyle,
  },
  minWidth0: {
    root: {
      minWidth: 0,
    } as IStyle,
  },
  hidden: {
    root: { visibility: 'hidden' },
  },
  content: {
    padding: {
      root: {
        overflowY: 'auto',
        padding: 32,
      } as IStyle,
    },
    noPadding: {
      root: {
        overflowY: 'auto',
      } as IStyle,
    },
  },
}

export const Page = (props: IProps) => {
  const { bodyMode = BodyMode.padding, isLoading } = props

  return (
    <>
      {isLoading && <Loader isFullscreen />}

      <Stack verticalFill styles={isLoading ? styles.hidden : undefined}>
        <Stack>{props.header}</Stack>
        <Stack horizontal grow styles={styles.minHeight0}>
          {props.sidebar && (
            <Sidebar sidebarType={SidebarType.Navigation}>
              {props.sidebar}
            </Sidebar>
          )}
          <Stack grow styles={styles.minWidth0}>
            {props.commandBar}
            <Stack
              verticalFill
              styles={
                bodyMode === BodyMode.padding
                  ? styles.content.padding
                  : styles.content.noPadding
              }
            >
              <ErrorBoundary fallback={<ErrorMessage />}>
                {props.body}
              </ErrorBoundary>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
