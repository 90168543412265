import {
  AnimationClassNames,
  FontWeights,
  type IStyle,
  Icon,
  Stack,
  Text,
  mergeStyleSets,
  mergeStyles,
} from 'office-ui-fabric-react'
import type React from 'react'
import type { JSX } from 'react'

interface IProps {
  children?: React.ReactNode
  label?: string
  actions?: JSX.Element
  isCollapsible?: boolean
  isScrollable?: boolean
  panelState?: 'open' | 'closed'
  togglePanelState?: () => void
  mainAction?: JSX.Element
}

const styles = {
  header: {
    root: {
      minHeight: 40,
      height: 40,
      marginRight: 0,
      paddingLeft: 20,
      paddingRight: 4,
      cursor: 'default',
      userSelect: 'none',
      background: '#FFFFFF',
      selectors: {
        ':hover': {
          background: '#FAF9F8',
        },
      },
    } as IStyle,
  },
  iconWrapper: {
    root: {
      width: 16,
    } as IStyle,
  },
  icon: {
    base: {
      root: {
        fontSize: 10,
        fontWeight: FontWeights.semibold,
        transformOrigin: '50% 50%',
        transition: 'transform linear .1s',
        transform: 'rotate(0deg)',
      },
    },
    isOpen: {
      root: {
        transform: 'rotate(90deg)',
      },
    },
  },
  label: {
    root: {
      color: '#323130',
      fontSize: 16,
      paddingLeft: 10,
      fontWeight: FontWeights.semibold,
    },
  },
  mainAction: {
    root: {
      padding: 20,
      paddingTop: 0,
      background: '#FFFFFF',
    },
  },
  fixed: {
    root: {
      padding: 20,
      paddingTop: 5,
      background: '#FFFFFF',
    } as IStyle,
  },
  scroll: {
    root: {
      padding: 20,
      paddingTop: 5,
      paddingRight: 10,
      background: '#FFFFFF',
      flexBasis: 0,
      flexGrow: 1,
      minHeight: 40,
      maxHeight: 'max-content',
      overflowY: 'scroll',
      selectors: {
        '::-webkit-scrollbar': {
          width: 10,
        } as IStyle,
        '::-webkit-scrollbar-thumb': {
          border: '2px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'padding-box',
          borderRadius: 5,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          boxShadow: '0 0 1px rgba(255,255,255,.5',
        } as IStyle,
      },
    } as IStyle,
  },
  separator: {
    root: {
      height: 10,
      selectors: {
        ':last-child': {
          height: 0,
        },
      },
    },
  },
}

const tokens = {
  childrenGap: 10,
}

export const Panel = (props: IProps) => {
  const {
    children,
    label,
    actions,
    panelState = 'open',
    isCollapsible = true,
    isScrollable,
    togglePanelState,
    mainAction,
  } = props

  const isOpen = panelState === 'open'

  return (
    <>
      {label && (
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={styles.header}
          verticalAlign="center"
          onClick={isCollapsible ? togglePanelState : undefined}
        >
          {isCollapsible ? (
            <Stack horizontal verticalAlign="center">
              <Stack
                styles={styles.iconWrapper}
                verticalAlign="center"
                horizontalAlign="center"
              >
                <Icon
                  styles={mergeStyleSets(
                    styles.icon.base,
                    isOpen && styles.icon.isOpen,
                  )}
                  iconName="ChevronRight"
                />
              </Stack>
              <Text styles={styles.label}>{label}</Text>
            </Stack>
          ) : (
            <Text styles={styles.label}>{label}</Text>
          )}
          {actions && <Stack>{actions}</Stack>}
        </Stack>
      )}
      {isOpen && mainAction && (
        <Stack
          styles={styles.mainAction}
          className={mergeStyles(
            AnimationClassNames.fadeIn100,
            AnimationClassNames.slideDownIn10,
          )}
        >
          {mainAction}
        </Stack>
      )}
      {isOpen && children && (
        <Stack
          styles={isScrollable ? styles.scroll : styles.fixed}
          className={mergeStyles(
            AnimationClassNames.fadeIn100,
            AnimationClassNames.slideDownIn10,
          )}
          tokens={tokens}
        >
          {children}
        </Stack>
      )}
      <Stack styles={styles.separator} />
    </>
  )
}
