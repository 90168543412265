import {
  DefaultButton,
  type IStyle,
  Icon,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  assertNever,
} from 'office-ui-fabric-react'
import type React from 'react'
import { useDropzone } from 'react-dropzone'

import type { UnitValue } from '../store/doc/types'
import { AssetPreview, size, thumbnail } from './AssetPreview'
import { Label } from './Label'

export interface IProps {
  label: string
  onSelect?: () => void
  onUpload?: (file: File) => Promise<void>
  onRemove?: () => void
  disabled?: {
    general?: boolean
    select?: boolean
    upload?: boolean
  }
  placeholder?: string[]
  status?: AssetStatus
}

export interface Uploading {
  tag: 'uploading'
  filename: string
}

export interface Processing {
  tag: 'processing'
  filename: string
  id: string
}

export interface SelectAsset {
  tag: 'select_asset'
}

export interface Preview {
  tag: 'preview'
  filename: string
  thumbnail?: string
  size?: { width: UnitValue; height: UnitValue }
}

export interface Error {
  tag: 'error'
  filename: string
  message: string
}

export const uploading = (filename: string): Uploading => ({
  tag: 'uploading',
  filename,
})

export const processing = (filename: string, id: string): Processing => ({
  tag: 'processing',
  filename,
  id,
})

export const selectAsset = (): SelectAsset => ({
  tag: 'select_asset',
})

export const preview = (
  filename: string,
  thumbnail?: string,
  size?: { width: UnitValue; height: UnitValue },
): Preview => ({
  tag: 'preview',
  filename,
  thumbnail,
  size,
})

export const error = (filename: string, message: string): Error => ({
  tag: 'error',
  filename,
  message,
})

export type AssetStatus = SelectAsset | Uploading | Processing | Preview | Error

const styles = {
  previewLabel: {
    empty: {
      root: {
        color: '#C9C9C9',
        fontSize: 14,
      },
    },
    uploading: {
      label: {
        fontSize: 14,
      },
    },
  },
  controls: {
    single: {
      root: {
        width: '100%',
      },
    },
  },
  dropzone: {
    width: '100%',
    height: 160,
    backgroundColor: '#F3F3F3',
  },
  filename: {
    root: {
      marginRight: 'auto',
    },
  },
  errorIcon: {
    root: {
      fontSize: 18,
      textAlign: 'center',
    } as IStyle,
  },
}

const tokens = {
  root: {
    childrenGap: 10,
  },
  controls: {
    childrenGap: 10,
  },
}

type UploadSpinnerProps = {
  label: string
  onSelect?: () => void
  onUpload?: (file: File) => Promise<void>
}

const UploadSpinner = (props: UploadSpinnerProps) => {
  const { label, onUpload, onSelect } = props

  return (
    <>
      <div style={styles.dropzone}>
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
          <Spinner
            styles={styles.previewLabel.uploading}
            label={label}
            ariaLive="assertive"
            labelPosition="bottom"
          />
        </Stack>
      </div>
      <Stack horizontal tokens={tokens.controls}>
        {onSelect && (
          <PrimaryButton disabled={true} styles={styles.controls.single}>
            Select
          </PrimaryButton>
        )}
        {onUpload && (
          <DefaultButton disabled={true} styles={styles.controls.single}>
            Upload
          </DefaultButton>
        )}
      </Stack>
    </>
  )
}

type ErrorProps = {
  status: Error
  onUpload?: (file: File) => Promise<void>
}

const AssetError = (props: ErrorProps) => {
  const { status, onUpload } = props

  const onDropAccepted = async ([file]: File[]) => {
    await onUpload?.(file)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    multiple: false,
  })

  return (
    <>
      <div style={styles.dropzone} {...getRootProps()}>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          verticalFill
          tokens={tokens.root}
        >
          <Icon iconName="ErrorBadge" styles={styles.errorIcon} />
          <Text>{status.message}</Text>
        </Stack>
      </div>
      <input {...getInputProps()} />
      <DefaultButton styles={styles.controls.single} onClick={open}>
        Upload
      </DefaultButton>
    </>
  )
}

type SelectProps = {
  onSelect?: () => void
  onUpload?: (file: File) => Promise<void>
  placeholder?: string[]
  disabled?: {
    general?: boolean
    select?: boolean
    upload?: boolean
  }
}

const Select = (props: SelectProps) => {
  const { onUpload, onSelect, disabled, placeholder } = props

  const onDropAccepted = async ([file]: File[]) => {
    await onUpload?.(file)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    multiple: false,
  })

  return (
    <>
      <div style={styles.dropzone} {...getRootProps()}>
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
          {placeholder
            ?.filter((line: any) => line)
            .map((line: React.ReactNode) => (
              <Text key={`${line}`} styles={styles.previewLabel.empty}>
                {line}
              </Text>
            ))}
        </Stack>
      </div>
      {onUpload && <input {...getInputProps()} />}
      <Stack horizontal tokens={tokens.controls}>
        {onSelect && (
          <PrimaryButton
            disabled={disabled?.select}
            styles={styles.controls.single}
            onClick={onSelect}
          >
            Select
          </PrimaryButton>
        )}
        {onUpload && (
          <DefaultButton
            disabled={disabled?.upload}
            styles={styles.controls.single}
            onClick={open}
          >
            Upload
          </DefaultButton>
        )}
      </Stack>
    </>
  )
}

type PreviewProps = {
  status: Preview
  onSelect?: () => void
  onUpload?: (file: File) => Promise<void>
  onRemove?: () => void
  disabled?: {
    general?: boolean
    select?: boolean
    upload?: boolean
  }
}

const PreviewThing = (props: PreviewProps) => {
  const { status, onUpload, onSelect, onRemove, disabled } = props

  const onDropAccepted = async ([file]: File[]) => {
    await onUpload?.(file)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    multiple: false,
  })

  return (
    <>
      <div style={styles.dropzone} {...getRootProps()}>
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
          {status.thumbnail && (
            <AssetPreview
              width={320}
              height={160}
              asset={thumbnail(status.thumbnail)}
            />
          )}
          {status.size && (
            <AssetPreview
              width={320}
              height={160}
              asset={size(status.size.width, status.size.height)}
            />
          )}
        </Stack>
      </div>
      {onRemove && (
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="baseline"
        >
          <Text styles={styles.filename} block nowrap>
            {status.filename}
          </Text>
          <DefaultButton disabled={disabled?.general} onClick={onRemove}>
            Remove
          </DefaultButton>
        </Stack>
      )}
      {!onRemove && (
        <Stack horizontal tokens={tokens.controls}>
          {onSelect && (
            <Stack
              styles={styles.controls.single}
              verticalAlign="space-around"
              tokens={tokens.root}
            >
              <DefaultButton
                disabled={disabled?.general}
                onClick={onSelect}
                styles={styles.controls.single}
              >
                Change
              </DefaultButton>
              <Text styles={styles.filename} block nowrap>
                {status.filename}
              </Text>
            </Stack>
          )}
          {onUpload && (
            <DefaultButton styles={styles.controls.single} onClick={open}>
              <input {...getInputProps()} />
              Upload
            </DefaultButton>
          )}
        </Stack>
      )}
    </>
  )
}

export const AssetInput = (props: IProps) => {
  const {
    status = selectAsset(),
    label,
    onUpload,
    onSelect,
    onRemove,
    disabled,
    placeholder = [],
  } = props

  const innerContent = () => {
    switch (status.tag) {
      case 'uploading':
        return (
          <UploadSpinner
            label="Uploading..."
            onUpload={onUpload}
            onSelect={onSelect}
          />
        )
      case 'processing':
        return (
          <UploadSpinner
            label="Processing..."
            onUpload={onUpload}
            onSelect={onSelect}
          />
        )
      case 'select_asset':
        return (
          <Select
            onUpload={onUpload}
            onSelect={onSelect}
            disabled={disabled}
            placeholder={placeholder}
          />
        )
      case 'preview':
        return (
          <PreviewThing
            status={status}
            onUpload={onUpload}
            onSelect={onSelect}
            onRemove={onRemove}
            disabled={disabled}
          />
        )
      case 'error':
        return <AssetError status={status} onUpload={onUpload} />
      default:
        return assertNever(status)
    }
  }

  return (
    <Stack tokens={tokens.root}>
      <Label text={label} />
      {innerContent()}
    </Stack>
  )
}
