import { createSelector } from 'reselect'

import { State } from '../'
import { campaignIdSelector, documentIdSelector } from '../routing/selectors'
import { docSelector } from '../doc/selectors'
import { LoadState } from './types'

export const loadersSelectors = (state: State) => state.loaders

export const isCampaignsReloadNeededSelector = createSelector(
  [loadersSelectors],
  loaders =>
    loaders.isCampaignsReloadNeeded ||
    loaders.campaignsState === LoadState.NotLoading,
)

export const campaignStateSelector = createSelector(
  [loadersSelectors, campaignIdSelector],
  (loaders, campaignId) =>
    loaders.campaignState[campaignId] || LoadState.NotLoading,
)

export const isCampaignReloadNeededSelector = createSelector(
  [loadersSelectors, campaignStateSelector],
  (loaders, campaignState) =>
    loaders.isCampaignReloadNeeded || campaignState === LoadState.NotLoading,
)

export const isCampaignAssetsReloadNeededSelector = createSelector(
  [loadersSelectors, campaignStateSelector],
  (loaders, campaignState) =>
    loaders.isCampaignAssetsReloadNeeded ||
    campaignState === LoadState.NotLoading,
)

export const isDocReloadNeededSelector = createSelector(
  [loadersSelectors, campaignStateSelector, documentIdSelector, docSelector],
  (loaders, campaignState, documentId, doc) => {
    // Delay loading the document if campaign hasn't been loaded yet.
    if (campaignState !== LoadState.Loaded) {
      return false
    }

    if (!documentId) {
      return false
    }

    if (documentId !== doc.meta.id) {
      return true
    }

    return (
      loaders.isDocReloadNeeded || loaders.docState === LoadState.NotLoading
    )
  },
)

export const isRevisionsReloadNeededSelector = createSelector(
  [loadersSelectors],
  loaders =>
    loaders.isRevisionsReloadNeeded ||
    loaders.revisionsState === LoadState.NotLoading,
)

export const isCanvasPresetsReloadNeededSelector = createSelector(
  [loadersSelectors],
  loaders =>
    loaders.isCanvasPresetsReloadNeeded ||
    loaders.canvasPresetsState === LoadState.NotLoading,
)

export const isAssetsReloadNeededSelector = createSelector(
  [loadersSelectors],
  loaders =>
    loaders.isAssetsReloadNeeded ||
    loaders.assetsState === LoadState.NotLoading,
)
