import React from 'react'

import {
  Spinner,
  SpinnerSize,
  Stack,
  memoizeFunction,
} from 'office-ui-fabric-react'

type Props = {
  isFullscreen?: boolean
}

const getStyles = memoizeFunction((isFullscreen?: boolean) => ({
  root: isFullscreen ? { width: '100vw', height: '100vh' } : { height: '100%' },
}))

export const Loader = (props: Props) => {
  const { isFullscreen } = props

  return (
    <Stack verticalAlign="center" styles={getStyles(isFullscreen)}>
      <Spinner size={SpinnerSize.large} />
    </Stack>
  )
}
