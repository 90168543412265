import _ from 'lodash'

export const LOG_OUT_URL = '/account/logout'
export const HOME_URL = '/'

// Use this with all switches where matching enums or unions.
export class UnhandledCaseError extends Error {
  constructor(x: never) {
    // This is never printed, though.
    super(`You have an unhandled case — ${x}`)
  }
}

const ACCEPTED_ASPECT_RATIOS = [
  { key: 0.56, value: '9:16' },
  { key: 0.6, value: '3:5' },
  { key: 0.63, value: '5:8' },
  { key: 0.67, value: '2:3' },
  { key: 0.75, value: '3:4' },
  { key: 1, value: '1:1' },
  { key: 1.25, value: '5:4' },
  { key: 1.33, value: '4:3' },
  { key: 1.5, value: '3:2' },
  { key: 1.6, value: '8:5' },
  { key: 1.67, value: '5:3' },
  { key: 1.78, value: '16:9' },
]

export const getAspectRatio = (width: number, height: number) => {
  const calculatedRatio = width / height

  let bestMatch = '1:1'
  let lowestDiff = 999999

  _.forEach(ACCEPTED_ASPECT_RATIOS, ratio => {
    const key = ratio.key
    const value = ratio.value

    const diff = Math.abs(key - calculatedRatio)

    if (diff < lowestDiff) {
      lowestDiff = diff
      bestMatch = value
    }
  })

  return bestMatch
}

export const pathToId = (path: string[]) => path.join('-')

export const sleep = (delay: number) =>
  new Promise(resolve => setTimeout(resolve, delay))

export const roundWhenNeeded = (value: number, decimalPlaces: number) => {
  if (decimalPlaces < 0) {
    throw new Error(
      `Cannot round to negative decimal places. ${decimalPlaces} is invalid`,
    )
  }

  if (!Number.isInteger(decimalPlaces)) {
    throw new Error(
      `Cannot round to a decimal number of decimal places. ${decimalPlaces} is invalid`,
    )
  }
  const powerOfTen = 10 ** decimalPlaces
  return Math.round((value + Number.EPSILON) * powerOfTen) / powerOfTen
}

export const getIsMac = () => {
  return !!window.navigator.platform.match('Mac')
}

export const randomRange = (min: number, max: number) =>
  Math.random() * (max - min) + min
