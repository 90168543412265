import _ from 'lodash'
import {
  ICommandBarItemProps,
  IContextualMenuProps,
  SelectionMode,
} from 'office-ui-fabric-react'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'

import { Breadcrumbs } from '../../components/Breadcrumbs'
import { CommandBar, setCommandBarItems } from '../../components/CommandBar'
import { Page } from '../../components/Page'
import { ColumnDef, SearchBoxPlacement, Table } from '../../components/Table'
import { Header } from '../../containers/Header'
import { CampaignNavigation } from '../../containers/Navigation'
import { State } from '../../store'
import { loadCampaign, reloadDocs } from '../../store/campaign/actions'
import { PartialDoc } from '../../store/campaign/types'
import { deleteDocs } from '../../store/doc/actions'
import { RenderStatus, getRenderStatus } from '../../store/jobs/utils'
import {
  isCampaignReloadNeededSelector,
  campaignStateSelector,
} from '../../store/loaders/selectors'
import { LoadState } from '../../store/loaders/types'
import { push } from '../../store/routing/actions'
import { campaignIdSelector } from '../../store/routing/selectors'
import { DeleteDialog } from '../../components/DeleteDialog'

const RELOAD_DOCS_INTERVAL = 10 * 1000

export type Doc = {
  id: number
  renderStatus: RenderStatus
}

type OwnProps = {
  pageName: string
  documents: PartialDoc[]
  selectedItems: Doc[]
  setSelectedItems: (items: Doc[]) => void
  defaultActionItems: ICommandBarItemProps[]
  singleActionItems: (item?: Doc) => ICommandBarItemProps[]
  multiActionItems: (items?: Doc[]) => ICommandBarItemProps[]
  itemsToDelete: Doc[]
  isDialogVisible: boolean
  setIsDialogVisible: (value: boolean) => void
  mainAction: (doc: Doc) => void
  mainActionTitle: string
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  campaignId: campaignIdSelector(state),
  campaign: state.campaign,
  isCampaignReloadNeeded: isCampaignReloadNeededSelector(state),
  campaignState: campaignStateSelector(state),
  loaders: state.loaders,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      loadCampaign,
      reloadDocs,
      deleteDocs,
    },
    dispatch,
  )

export const DocumentsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const {
    pageName,
    documents,
    defaultActionItems,
    singleActionItems,
    multiActionItems,
    selectedItems,
    campaignId,
    loaders,
    isCampaignReloadNeeded,
    campaignState,
    campaign,
    loadCampaign,
    reloadDocs,
    itemsToDelete,
    isDialogVisible,
    mainActionTitle,
  } = props

  const { isCampaignDocsReloadNeeded } = loaders

  const tableItems = _.map(documents, partialDoc => ({
    id: partialDoc.id,
    thumbnail: partialDoc.thumbnail,
    name: partialDoc.name,
    canvasPreset: partialDoc.canvasPresetId
      ? campaign.assets.canvasPresets[partialDoc.canvasPresetId].name
      : 'Custom',
    renderStatus: getRenderStatus(
      partialDoc.name,
      partialDoc.version,
      partialDoc.renderStatus.final,
    ),
    version: partialDoc.version,
    updated: partialDoc.modifiedAt,
    updatedAgo: timeago.format(partialDoc.modifiedAt),
    mainActionTitle,
  }))
  type TItem = (typeof tableItems)[0]

  const [contextualMenuProps, setContextualMenuProps] =
    useState<IContextualMenuProps>()

  const timeoutIdRef = useRef<number>()
  useEffect(() => {
    let mustCleanup = false
    const reloadDocsAfterTimeout = () => {
      if (mustCleanup) {
        return
      }
      timeoutIdRef.current = window.setTimeout(async () => {
        await reloadDocs({ campaignId })
        reloadDocsAfterTimeout()
      }, RELOAD_DOCS_INTERVAL)
    }

    const fetch = async () => {
      if (isCampaignReloadNeeded) {
        await loadCampaign({ campaignId })
      } else if (isCampaignDocsReloadNeeded) {
        await reloadDocs({ campaignId })
      }
      if (!timeoutIdRef.current) {
        reloadDocsAfterTimeout()
      }
    }
    fetch()

    return function () {
      mustCleanup = true
      window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined
    }
  }, [
    isCampaignReloadNeeded,
    isCampaignDocsReloadNeeded,
    campaignId,
    loadCampaign,
    reloadDocs,
  ])

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        props.push('/')
      },
    },
    {
      text: props.campaign.name,
      key: props.campaign.name,
      onClick: () => {
        props.push(`/campaign/${campaign.id}`)
      },
    },
    {
      text: `${pageName}s`,
      key: `${pageName}s`,
      onClick: () => {},
    },
  ]

  const commandBarItems = _.orderBy(
    setCommandBarItems(selectedItems.length, {
      none: defaultActionItems,
      single: _.concat(
        defaultActionItems,
        singleActionItems(),
        multiActionItems(),
      ),
      multi: _.concat(defaultActionItems, multiActionItems()),
    }),
    'order',
  )

  const tableColumns: ColumnDef<TItem>[] = [
    { key: 'thumbnail', name: 'Thumbnail', width: 128 },
    { key: 'name', name: 'Name', minWidth: 100 },
    { key: 'canvasPreset', name: 'Spec', maxWidth: 192 },
    { key: 'renderStatus', name: 'Render', minWidth: 60, maxWidth: 9999 },
    { key: 'version', name: 'Version', width: 60 },
    { key: 'updated', name: 'Updated', width: 100, fieldName: 'updatedAgo' },
  ]

  const isLoading =
    campaignState !== LoadState.Loaded || isCampaignDocsReloadNeeded

  const onItemContextMenu = (item: TItem, index?: number, ev?: Event): void => {
    const contextualMenuProperties: IContextualMenuProps = {
      target: ev as MouseEvent,
      items: _.orderBy(
        _.concat(singleActionItems(item), multiActionItems([item])),
        'order',
      ),
      onDismiss: () => {
        setContextualMenuProps(undefined)
      },
    }

    if (index !== undefined && index > -1) {
      setContextualMenuProps(contextualMenuProperties)
    }
  }

  return (
    <>
      {isDialogVisible && (
        <DeleteDialog
          itemName={pageName.toLowerCase()}
          onClose={() => {
            props.setIsDialogVisible(false)
          }}
          onSuccess={() => {
            props.deleteDocs({
              docIds: _.map(itemsToDelete, item => item.id.toFixed(0)),
            })
          }}
          itemsToDelete={itemsToDelete}
        />
      )}
      <Page
        header={
          <Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />
        }
        sidebar={<CampaignNavigation campaignId={campaign.id} />}
        commandBar={
          <CommandBar items={commandBarItems} requiresLeftOffset={true} />
        }
        body={
          <Table<TItem>
            columns={tableColumns}
            defaultSort={{ key: 'updated', desc: true }}
            items={tableItems}
            onItemClick={props.mainAction}
            selectionMode={SelectionMode.multiple}
            searchBoxPlacement={SearchBoxPlacement.right}
            onSelectionChanged={props.setSelectedItems}
            searchFields={['name', 'canvasPreset']}
            contextualMenuProps={contextualMenuProps}
            onItemContextMenu={onItemContextMenu}
          />
        }
        isLoading={isLoading}
      />
    </>
  )
})
