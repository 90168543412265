import _ from 'lodash'
import { useState } from 'react'

type Item = {
  [key: string]: any
}

export type Sort = { key: string; desc: boolean } | null

export const useInlineSort = (
  items: Item[],
  initialValue?: Sort,
): [Sort, (sort: Sort) => void, Item[]] => {
  const [sort, setSort] = useState<Sort>(initialValue ?? null)

  if (!sort) {
    return [sort, setSort, items]
  }

  const { key, desc } = sort

  const sortedItems = _.orderBy(items, key, desc ? 'desc' : 'asc')

  return [sort, setSort, sortedItems]
}
