import {
  IStackItemStyles,
  IStyle,
  Stack,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { State } from '../../store'
import { configSelector } from '../../store/config/selectors'
import { mergeDoc } from '../../store/doc/actions'
import { DocBlocs, getDocBlocs } from '../../store/doc/selectors'
import { EditorView, FitMode } from '../../store/ui/types'
import { Size, updateRenderData } from '../../viewer/RenderData'
import {
  RenderState,
  renderStateToDocDiff,
  updateRenderState,
} from '../../viewer/RenderState'

interface OwnProps {
  containerWidth: number
  containerHeight: number
  isVisible: boolean
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const styles: IStyle = {
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  paddingBottom: 0,
}

const getCanvasStackStyles = memoizeFunction(
  (
    fitMode: FitMode,
    canvasSize: Size,
    isVisible: boolean,
  ): IStackItemStyles => {
    let overflowX = 'hidden'
    let overflowY = 'hidden'

    switch (fitMode) {
      case 'fit':
        overflowX = 'hidden'
        overflowY = 'hidden'
        break
      case 'contain':
        overflowX = canvasSize.width >= canvasSize.height ? 'scroll' : 'hidden'
        overflowY = canvasSize.height > canvasSize.width ? 'scroll' : 'hidden'
        break
      case 'max':
        overflowX = 'scroll'
        overflowY = 'scroll'
        break
    }

    const display = isVisible ? 'flex' : 'none'
    return {
      root: {
        ...styles,
        overflowX,
        overflowY,
        display,
      } as IStyle,
    }
  },
)

const mapStateToProps = (state: State) => {
  const { fitMode, isGizmoAdvancedMode, isGridVisible, selectedPivot } =
    state.ui
  return {
    docBlocs: getDocBlocs(state),
    config: configSelector(state),
    fitMode,
    isGizmoAdvancedMode,
    isGridVisible,
    selectedPivot,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      mergeDoc,
    },
    dispatch,
  )

const useCanvas: () => [
  HTMLCanvasElement | null,
  (node: HTMLCanvasElement | null) => void,
] = () => {
  // Use state to kick re-render after canvas is created
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const ref = useCallback(node => {
    if (node !== null) {
      setCanvas(node)
    }
  }, [])
  return [canvas, ref]
}

const useCounter = (): [number, () => void] => {
  // Use state to kick re-render after refresh is requested
  const [counter, updateCounter] = useState<number>(0)
  const increment = () => {
    updateCounter(counter => counter + 1)
  }
  return [counter, increment]
}

export const LivePreview = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const {
    config,
    fitMode,
    mergeDoc,
    containerWidth,
    containerHeight,
    isVisible,
    isGizmoAdvancedMode,
    isGridVisible,
    selectedPivot,
  } = props

  const isLayoutMode = selectedPivot === EditorView.PlanView

  const [canvas, canvasCallback] = useCanvas()

  const [refreshCounter, incrementRefreshCounter] = useCounter()

  const containerSize = useMemo(
    () => ({
      width: containerWidth,
      height: containerHeight,
    }),
    [containerWidth, containerHeight],
  )

  const renderStateRef = useRef<RenderState | null>(null)

  const docBlocsRef = useRef<DocBlocs>(props.docBlocs)
  docBlocsRef.current = props.docBlocs

  if (!renderStateRef.current && canvas) {
    ;(window as any).getRenderState = () => renderStateRef.current

    renderStateRef.current = {
      canvas,
      onLoadUpdate: () => {
        incrementRefreshCounter()
      },
      onInputUpdate: () => {
        const docBlocs = docBlocsRef.current
        const renderState = renderStateRef.current
        if (!renderState) {
          return
        }

        const docDiff = renderStateToDocDiff(renderState, docBlocs)
        mergeDoc(docDiff)
      },
    }
  }

  const docBlocs = docBlocsRef.current
  const renderState = renderStateRef.current

  const bleed = docBlocs.canvas.print.bleed.value
  const resolution = docBlocs.canvas.print.resolution.value

  const renderData = useMemo(() => {
    return updateRenderData(docBlocs, {
      containerSize,
      fitMode,
      config,
      isGizmoAdvancedMode,
      isGridVisible,
      isLayoutMode,
      bleed,
      resolution,
    })
  }, [
    docBlocs,
    containerSize,
    fitMode,
    config,
    isGizmoAdvancedMode,
    isGridVisible,
    isLayoutMode,
    bleed,
    resolution,
  ])
  renderStateRef.current = useMemo(
    () => {
      return renderState
        ? updateRenderState(renderState, renderData)
        : renderState
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderState, renderData, refreshCounter],
  )

  const { canvasSize, bleedSize } = renderData

  return (
    <Stack
      styles={getCanvasStackStyles(fitMode, canvasSize, isVisible)}
      grow
      verticalAlign="center"
    >
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        style={{
          width: canvasSize.width,
          height: canvasSize.height,
        }}
      >
        <canvas
          ref={canvasCallback}
          style={{
            outlineWidth: 0,
            verticalAlign: 'top',
            width: '100%',
            height: '100%',
          }}
        />
        {bleedSize && (
          <div
            style={{
              position: 'absolute',
              width: bleedSize.width,
              height: bleedSize.height,
              border: '1px solid #AE2828',
              pointerEvents: 'none',
            }}
          ></div>
        )}
      </Stack>
    </Stack>
  )
})
