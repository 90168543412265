import { CanvasMedium, Option, UnitValue } from '../doc/types'

export enum CanvasPresetTypes {
  print = 'Print',
  digital = 'Digital',
  web = 'Web',
  custom = 'Custom',
}

export interface CanvasPreset extends Option {
  id: number
  medium: CanvasMedium
  size: {
    width: UnitValue
    height: UnitValue
  }
  print?: {
    resolution: PrintResolution
    bleed: PrintBleed
  }
  createdAt: string
  createdBy: number
}

export interface PrintResolution extends UnitValue {
  id: string
  name: string
}
export interface PrintBleed extends UnitValue {
  id: string
  name: string
}
