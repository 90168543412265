import _ from 'lodash'
import { Dropdown, IDropdownOption, Stack } from 'office-ui-fabric-react'
import React from 'react'

import { Option } from '../store/doc/types'
import * as commonStyles from '../styles'
import { Label } from './Label'

export type Props<T extends Option = Option> = {
  label: string
  id: string
  value: T | undefined
  options: T[] | undefined
  isOptional?: boolean
  isUnavailable?: boolean
  error?: string
  disabled?: boolean
  setValue: (value: T | undefined) => void
  onChanged?: (option: T | undefined) => void
  limitLabelWidth?: boolean
}

const styles = {
  input: commonStyles.input,
}

const tokens = {
  childrenGap: 20,
}

export const DropdownInput = <T extends Option = Option>(props: Props<T>) => {
  const {
    id,
    value,
    label,
    isUnavailable,
    isOptional,
    error,
    disabled,
    limitLabelWidth,
  } = props

  if (isUnavailable) {
    return null
  }

  const options = isOptional
    ? _.concat({ id: 0, name: '' } as T, props.options || [])
    : props.options

  return (
    <Stack horizontal horizontalAlign="space-between" tokens={tokens}>
      <Label htmlFor={id} text={label} limitLabelWidth={limitLabelWidth} />
      <Stack styles={styles.input}>
        <Dropdown
          id={id}
          selectedKey={value ? value.id : undefined}
          options={
            _.map(options, (option, optionKey) => ({
              ...option,
              key: option.id || optionKey,
              text: option.name,
            })) as IDropdownOption[]
          }
          onChange={(ev, dropdownOption) => {
            const option = dropdownOption as any as T | undefined

            props.setValue(!option || option.id === 0 ? undefined : option)

            props.onChanged?.(option)
          }}
          errorMessage={error}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  )
}
