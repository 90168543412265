import React from 'react'
import { Stack } from 'office-ui-fabric-react'

import { Modal, LayoutMode } from '../../components/Modal'
import {
  AssetInput,
  AssetStatus,
  uploading,
  error,
  processing,
} from '../../components/AssetInput'
import { Props as AssetsProps } from './'

type Props = {
  assets: AssetsProps['assets']
  closeModals: () => void
  uploadState: AssetStatus
  createAsset: AssetsProps['createAsset']
  uploadAsset: AssetsProps['uploadAsset']
  setUploadState: (value: AssetStatus) => void
}

export const CreateModal = (props: Props) => {
  const { closeModals, uploadAsset, createAsset, uploadState, setUploadState } =
    props

  return (
    <Modal
      title="Create Asset"
      onClose={closeModals}
      layoutMode={LayoutMode.fit}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <AssetInput
          label="Asset Upload"
          placeholder={['Select a ZIP file']}
          onUpload={async file => {
            const filename = file.name
            setUploadState(uploading(filename))
            const uploadResp = await uploadAsset({ file })

            if (!uploadResp) {
              return setUploadState(error(filename, 'Upload Failed'))
            }

            setUploadState(processing(filename, uploadResp.token))

            const createResp = await createAsset({
              uploadResp,
            })

            if (!createResp) {
              setUploadState(error(filename, 'Create asset failed'))
            }

            closeModals()
          }}
          status={uploadState}
        />
      </Stack>
    </Modal>
  )
}
