import _ from 'lodash'
import {
  ICommandBarItemProps,
  IContextualMenuProps,
  SelectionMode,
} from 'office-ui-fabric-react'
import { default as React, useMemo, useState } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { CommandBar } from '../../components/CommandBar'
import { DeleteDialog } from '../../components/DeleteDialog'
import { Page } from '../../components/Page'
import {
  ColumnDef,
  SearchBoxPlacement,
  Table,
  defaultFilter,
  useEffectToUpdateSelectedItems,
} from '../../components/Table'
import { Header } from '../../containers/Header'
import { HomeNavigation } from '../../containers/Navigation'
import * as dto from '../../dto/api'
import { UserDraft, UserForm, UserFormLogic } from './UserForm'
import { User } from './utils'

export type TableItemLogic = {
  enableUsers: (items: User[]) => Promise<void>
  disableUsers: (items: User[]) => Promise<void>
}

type Props = {
  push: (path: string) => void
  campaigns: dto.Campaign[] | undefined
  tableItems: User[]
  tableItemLogic: TableItemLogic
  draft: UserDraft | undefined
  draftLogic: UserFormLogic
  isLoading: boolean
}

const defaultActionItems = (draftLogic: UserFormLogic) => [
  {
    key: 'add_invite',
    text: 'Invite',
    iconProps: {
      iconName: 'AddFriend',
    },
    className: '',
    onClick: () => {
      draftLogic.openDialog(undefined)
    },
  },
]

const useMemoToUpdateActionItems = (
  items: User[],
  tableItemLogic: TableItemLogic,
  draftLogic: UserFormLogic,
  setActionItems: (actions: ICommandBarItemProps[]) => void,
  setIsDialogVisible: (value: boolean) => void,
) => {
  return useMemo(() => {
    if (items.length === 0) {
      return setActionItems(defaultActionItems(draftLogic))
    }

    const canEdit = _.every(items, item => item.accountId)
    const canDelete = _.every(items, item => !item.accountId)
    const canDisable = _.every(items, item => item.accountId && item.isEnabled)
    const canDeleteDisable =
      !canDelete &&
      !canDisable &&
      _.some(items, item => !item.accountId || item.isEnabled)
    const canEnable = _.some(items, item => item.accountId && !item.isEnabled)

    setActionItems(
      _.compact([
        canEdit && {
          key: 'edit',
          text: 'Edit',
          iconProps: {
            iconName: 'EditContact',
          },
          onClick: () => {
            draftLogic.openDialog(items)
          },
        },
        (canDelete || canDisable || canDeleteDisable) && {
          key: 'disable',
          text: canDelete
            ? 'Delete'
            : canDisable
              ? 'Disable'
              : 'Delete or Disable',
          iconProps: {
            iconName: canDelete ? 'UserRemove' : 'UserPause',
          },
          className: 'is-dangerous',
          onClick: () => {
            setIsDialogVisible(true)
          },
        },
        canEnable && {
          key: 'enable',
          text: 'Enable',
          iconProps: {
            iconName: 'UserFollowed',
          },
          onClick: () => {
            tableItemLogic.enableUsers(items)
          },
        },
      ]),
    )
  }, [draftLogic, items, setActionItems, setIsDialogVisible, tableItemLogic])
}

export const UsersPageDumb = (props: Props) => {
  const {
    push,
    campaigns,
    tableItems,
    tableItemLogic,
    draft,
    draftLogic,
    isLoading,
  } = props

  const filters = [defaultFilter, 'Active', 'Pending', 'Disabled']

  const [selectedFilter, setSelectedFilter] = useState<string>('Active')

  const [selectedItems, setSelectedItems] = useState<User[]>([])
  const [contextualMenuProps, setContextualMenuProps] =
    useState<IContextualMenuProps>()

  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const [actionItems, setActionItems] = useState<ICommandBarItemProps[]>(
    defaultActionItems(draftLogic),
  )

  useEffectToUpdateSelectedItems(
    tableItems,
    selectedItems,
    'email',
    setSelectedItems,
  )

  useMemoToUpdateActionItems(
    selectedItems,
    tableItemLogic,
    draftLogic,
    setActionItems,
    setIsDialogVisible,
  )

  const commandBarItems = actionItems

  const onItemContextMenu = (item: User, index?: number, ev?: Event): void => {
    if (selectedItems.length === 0) {
      setSelectedItems([item])
    }
    const contextualMenuProperties: IContextualMenuProps = {
      target: ev as MouseEvent,
      items: actionItems,
      onDismiss: () => {
        setContextualMenuProps(undefined)
      },
    }

    if (index !== undefined && index > -1) {
      setContextualMenuProps(contextualMenuProperties)
    }
  }

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        push('/')
      },
    },
    {
      text: 'Users',
      key: 'users',
      onClick: () => {
        push('/users')
      },
    },
  ]

  const columnItems: ColumnDef<User>[] = [
    { key: 'thumbnail', name: 'Icon', width: 42 },
    { key: 'name', name: 'Name', titleKey: 'email', minWidth: 200 },
    { key: 'roleName', name: 'Role', width: 100 },
    {
      key: 'assignedCampaignNames',
      name: 'Campaigns',
      minWidth: 100,
      maxWidth: 9999,
    },
    { key: 'status', name: 'Status', width: 80 },
    {
      key: 'invitedAt',
      name: 'Invited',
      width: 100,
      fieldName: 'invitedAtAgo',
    },
    {
      key: 'lastLoginAt',
      name: 'Last Login',
      width: 100,
      fieldName: 'lastLoginAtAgo',
    },
  ]

  return (
    <>
      {draft && (
        <UserForm
          users={selectedItems.length > 0 ? selectedItems : undefined}
          campaigns={campaigns}
          draft={draft}
          draftLogic={draftLogic}
        />
      )}
      {isDialogVisible && (
        <DeleteDialog
          itemName="user"
          onClose={() => {
            setIsDialogVisible(false)
          }}
          onSuccess={() => {
            tableItemLogic.disableUsers(selectedItems)
          }}
          itemsToDelete={_.map(selectedItems, (_, index) => {
            return { id: index }
          })}
          action={_.find(actionItems, action => action.key === 'disable')?.text}
        />
      )}
      <Page
        header={
          <Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />
        }
        sidebar={<HomeNavigation />}
        commandBar={
          <CommandBar items={commandBarItems} requiresLeftOffset={true} />
        }
        body={
          <Table<User>
            columns={columnItems}
            defaultSort={{ key: 'lastLoginAt', desc: true }}
            filters={filters}
            selectedFilter={selectedFilter}
            setFilter={setSelectedFilter}
            filterColumn="status"
            items={tableItems}
            selectionMode={SelectionMode.multiple}
            searchBoxPlacement={SearchBoxPlacement.right}
            searchFields={['name', 'email']}
            onSelectionChanged={setSelectedItems}
            contextualMenuProps={contextualMenuProps}
            onItemContextMenu={onItemContextMenu}
          />
        }
        isLoading={isLoading}
      />
    </>
  )
}
