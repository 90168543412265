import type { Location } from 'history'
import _ from 'lodash'
import { type Key, pathToRegexp } from 'path-to-regexp'
import qs from 'qs'

import { routes } from '../store/routing/routes'
import type { Route, RouteDef } from '../store/routing/state'

type CompiledRoute = {
  route: Route
  regexp: RegExp
  keys: Key[]
}

class Router {
  compiledRoutes: CompiledRoute[]

  constructor(routes: RouteDef[]) {
    this.compiledRoutes = _.map(routes, (route): CompiledRoute => {
      route.meta = route.meta || {}
      route.queryOrder = route.queryOrder || []

      const { regexp, keys } = pathToRegexp(route.path)

      return {
        route: {
          path: route.path,
          meta: route.meta || {},
          queryOrder: route.queryOrder || [],
          params: {},
          query: {},
        },
        regexp,
        keys,
      }
    })
  }

  resolve = (location: Location) => {
    let foundMatch: string[] | null = null
    let foundRoute: CompiledRoute | null = null

    for (const route of this.compiledRoutes) {
      const { regexp } = route
      const match = regexp.exec(location.pathname)

      if (match) {
        foundMatch = match
        foundRoute = route
        break
      }
    }

    if (!foundMatch || !foundRoute) {
      return null
    }

    const { route, keys } = foundRoute

    const params = _.fromPairs(
      _.map(foundMatch.slice(1), (value, i) => [keys[i].name, value]),
    )
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    return {
      ...route,
      location,
      params,
      query,
    }
  }
}

const router = new Router(routes)

export { router }
