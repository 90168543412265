import type { Action as ReduxAction } from 'redux'

import type { Dispatch } from '..'
import { type UploadImageRes, uploadImageRaw } from '../../lib/fileUtils'

export type Action = UploadImage | UploadedImage

export enum BucketsActions {
  UploadImage = 'UPLOAD_IMAGE',
  UploadedImage = 'UPLOADED_IMAGE',
}

export interface UploadImage extends ReduxAction<BucketsActions> {
  type: BucketsActions.UploadImage
  payload: {
    uploadUrl: string
    file: File
  }
}

export interface UploadedImage extends ReduxAction<BucketsActions> {
  type: BucketsActions.UploadedImage
  payload: UploadImageRes
}

export const uploadImage =
  (payload: UploadImage['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: BucketsActions.UploadImage,
      payload,
    })

    const { uploadUrl, file } = payload
    const result = await uploadImageRaw(uploadUrl, file)

    dispatch({
      type: BucketsActions.UploadedImage,
      payload: result,
    })

    return result
  }
