import _ from 'lodash'
import React, { useState } from 'react'
import * as timeago from 'timeago.js'

import { getAssetType, AssetType } from '../../store/assets/types'
import { Asset } from '../../store/doc/types'
import { Modal } from '../../components/Modal'
import { ColumnDef, Table, defaultFilter } from '../../components/Table'

type Props = {
  title: string
  assetType: AssetType
  assets: Asset[]
  onSelect: (asset: Asset) => void
  isModalOpen: boolean
  setModalOpen: (isModalOpen: boolean) => void
}

export const SceneObjectsModal = (props: Props) => {
  const { title, assetType, assets } = props

  const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter)

  if (!props.isModalOpen) {
    return null
  }

  const tableItems = _.map(assets, asset => ({
    id: asset.id,
    asset,
    thumbnail: asset.thumbnail,
    name: asset.name,
    type: getAssetType(asset.type).name,
    updated: asset.createdAt,
    updatedAgo: timeago.format(asset.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'thumbnail', name: 'Thumbnail', width: 128 },
    { key: 'name', name: 'Name', maxWidth: 9999 },
    { key: 'type', name: 'Type', width: 100 },
    {
      key: 'updated',
      name: 'Updated',
      width: 100,
      fieldName: 'updatedAgo',
    },
  ]

  return (
    <Modal
      title={title}
      onClose={() => {
        props.setModalOpen(false)
      }}
    >
      <Table<TItem>
        columns={columnItems}
        defaultSort={{ key: 'updated', desc: true }}
        items={tableItems}
        onItemClick={({ asset }) => {
          props.onSelect(asset)
          props.setModalOpen(false)
        }}
        filterColumn="type"
        filters={
          assetType === AssetType.Product || assetType === AssetType.Fixture
            ? [defaultFilter, 'Product', 'Fixture']
            : undefined
        }
        selectedFilter={selectedFilter}
        setFilter={setSelectedFilter}
      />
    </Modal>
  )
}
