import _ from 'lodash'

import { store } from '../'
import type * as dto from '../../dto/api'
import type { Doc } from './state'
import {
  type AssetTexture,
  type CanvasTexture,
  CanvasTextureType,
  type SceneObject,
} from './types'
import { campaignIdSelector } from '../routing/selectors'
import { AssetType } from '../assets/types'
import { produce } from 'immer'

export const canvasTextureFromApi = (
  response: dto.CanvasTexture,
): CanvasTexture => {
  return {
    ...response,
    type:
      // TODO use the same case also in frontend
      response.textureType === 'BACKGROUND'
        ? CanvasTextureType.Background
        : CanvasTextureType.Foreground,
  }
}

export const assetTextureFromApi = (
  response: dto.DeviceTexture,
): AssetTexture => {
  return {
    ...response,
    assetIds: response.deviceIds,
    // TODO generate and expose thumbnail on API
    imageUri: response.imageUri,
  }
}

export const docFromApi = (response: dto.DocumentResp): Doc => {
  return produce(response.revision.data, (doc: Doc) => {
    if (doc.devices) {
      // If the doc still has devices, convert it to the new format.
      doc.sceneObjects = _.mapValues(
        doc.devices,
        (device): SceneObject => ({
          id: device.id,
          type: AssetType.Product,
          assetId: device.deviceId,
          revisionId: device.revisionId,
          isLocked: device.isLocked ?? false,
          isHidden: device.isHidden ?? false,
          isSelected: device.isSelected ?? false,
          position: device.position,
          rotation: device.rotation,
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          properties: device.properties,
        }),
      )
      delete doc.devices
    } else {
      // If there's no scale, add it.
      _.forEach(doc.sceneObjects, sceneObject => {
        if (!sceneObject.scale) {
          sceneObject.scale = {
            x: 1,
            y: 1,
            z: 1,
          }
        }
      })
    }

    doc.meta.id = response.id
    doc.meta.isTemplate = response.isTemplate
    doc.meta.version = response.revision.version
  }) as Doc
}

export const docToApi = (
  doc: Doc,
): dto.CreateDocumentReq | dto.CreateDocumentRevisionReq => {
  const state = store.getState()

  const sceneRevisionId = 1 // TODO: Unhardcode.
  const isTemplate = doc.meta.isTemplate
  const canvasPresetId = doc.canvasPreset?.id
  const backgroundId = doc.visuals.background?.id
  const foregroundId = doc.visuals.foreground?.id

  if (doc.meta.version === 0) {
    return {
      campaignId: campaignIdSelector(state),
      data: doc,
      sceneRevisionId,
      canvasPresetId,
      backgroundId,
      foregroundId,
      isTemplate,
    } as dto.CreateDocumentReq
  }

  // TODO move selection outside Doc
  const data = produce(doc, doc => {
    _.each(doc.sceneObjects, sceneObject => {
      sceneObject.isSelected = false
    })
  })

  return {
    data,
    sceneRevisionId,
    canvasPresetId,
    backgroundId,
    foregroundId,
  } as dto.CreateDocumentRevisionReq
}
