import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { State } from '../../store'
import { duplicateDoc, toggleTemplate } from '../../store/doc/actions'
import { push } from '../../store/routing/actions'
import { Doc, DocumentsPage } from './DocumentsPage'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  campaign: state.campaign,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      duplicateDoc,
      toggleTemplate,
    },
    dispatch,
  )

export const Templates = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { campaign } = props

  const [selectedItems, setSelectedItems] = useState<Doc[]>([])
  const [itemsToDelete, setItemsToDelete] = useState<Doc[]>([])
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const campaignDocuments = _.filter(
    campaign.documents,
    document => document.isTemplate,
  )

  const defaultActionItems = [
    {
      key: 'create_template',
      text: 'Create Template',
      order: 2,
      iconProps: {
        iconName: 'add',
      },
      onClick: () => {
        props.push(`/campaign/${campaign.id}/document/template`)
      },
    },
  ]

  const singleActionItems = (item?: Doc) => {
    const template: Doc | undefined = item ? item : selectedItems[0]
    if (!template) {
      return []
    }

    return [
      {
        key: 'create_document',
        text: 'Create Document',
        order: 1,
        iconProps: {
          iconName: 'add',
        },
        onClick: () => {
          createDocument(template)
        },
      },
      {
        key: 'edit_template',
        text: 'Edit Template',
        order: 3,
        iconProps: {
          iconName: 'edit',
        },
        onClick: () => {
          edit(template.id)
        },
      },
      {
        key: 'duplicate_template',
        text: 'Duplicate Template',
        order: 3,
        iconProps: {
          iconName: 'copy',
        },
        onClick: () => {
          duplicate(template)
        },
      },
    ]
  }

  const multiActionItems = (items?: Doc[]) => {
    const templates = items ? items : selectedItems
    if (templates.length === 0) {
      return []
    }
    const isDownloadAvailable = _.every(
      templates,
      template => template.renderStatus.download,
    )
    return _.compact([
      {
        key: 'convert_doc',
        text: 'Convert to Document',
        order: 3,
        iconProps: {
          iconName: 'fileTemplate',
        },
        onClick: () => {
          convertToDoc(templates)
        },
      },
      isDownloadAvailable && {
        key: 'download_template',
        text: `Download Template${templates.length === 1 ? '' : 's'}`,
        order: 3,
        iconProps: {
          iconName: 'download',
        },
        onClick: () => {
          downloadTemplates(templates)
        },
      },
      {
        key: 'delete',
        text: 'Delete',
        order: 4,
        iconProps: {
          iconName: 'delete',
        },
        className: 'is-dangerous',
        onClick: () => {
          deleteItems(templates)
        },
      },
    ])
  }

  const createDocument = (template: Doc) => {
    props.push(`/campaign/${campaign.id}/document/${template.id}/from-template`)
  }

  const duplicate = async (template: Doc) => {
    const docMeta = await props.duplicateDoc({ docId: template.id })
    edit(docMeta.id)
  }

  const edit = (docId: number) => {
    props.push(`/campaign/${campaign.id}/document/${docId}`)
  }

  const deleteItems = (items: Doc[]) => {
    setItemsToDelete(items)
    setIsDialogVisible(true)
  }

  const downloadTemplates = (templates: Doc[]) => {
    return Promise.all(
      _.map(templates, template => {
        if (!template.renderStatus.download) {
          return
        }
        template.renderStatus.download()
      }),
    )
  }

  const convertToDoc = (templates: Doc[]) => {
    return Promise.all(
      _.map(templates, template => {
        props.toggleTemplate({
          docId: template.id,
          value: false,
        })
      }),
    )
  }

  return (
    <DocumentsPage
      pageName="Template"
      documents={campaignDocuments}
      defaultActionItems={defaultActionItems}
      singleActionItems={(item?: Doc) => singleActionItems(item)}
      multiActionItems={(items?: Doc[]) => multiActionItems(items)}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      itemsToDelete={itemsToDelete}
      isDialogVisible={isDialogVisible}
      setIsDialogVisible={setIsDialogVisible}
      mainAction={createDocument}
      mainActionTitle="Create Document"
    />
  )
})
