import _ from 'lodash'
import React, { useMemo } from 'react'

import { CheckboxList } from '../../components/CheckboxList'
import { ScreenTextureMappingStatus } from '../../store/campaign/types'
import { Asset } from '../../store/doc/types'

type Props = {
  products: Asset[]
  mappings: ScreenTextureMappingStatus[]
  setMappings: (mappings: ScreenTextureMappingStatus[]) => void
}

export const CompatibleProducts = (props: Props) => {
  const { products, mappings, setMappings } = props

  const selectionLogic = useMemo(() => {
    const getItem = (item: Asset): boolean => {
      const mapping = _.find(mappings, mapping => mapping.assetId === item.id)

      if (!mapping) {
        return false
      }
      return mapping.isEnabled!
    }

    const setItem = (item: Asset): void => {
      setMappings(
        _.map(mappings, mapping => {
          if (mapping.assetId === item.id) {
            mapping.isEnabled = !mapping.isEnabled
          }
          return mapping
        }),
      )
    }

    const getAll = (): boolean | null => {
      return _.every(mappings, mapping => mapping.isEnabled)
        ? true
        : _.some(mappings, mapping => mapping.isEnabled)
          ? null
          : false
    }

    const setAll = (isChecked: boolean): void => {
      setMappings(
        _.map(mappings, mapping => ({
          ...mapping,
          isEnabled: isChecked,
        })),
      )
    }

    return {
      getItem,
      setItem,
      getAll,
      setAll,
    }
  }, [mappings, setMappings])

  return (
    <>
      <CheckboxList<Asset>
        label="Compatible Products"
        items={products}
        selectionLogic={selectionLogic}
      />
    </>
  )
}
