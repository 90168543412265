import _ from 'lodash'
import { PrimaryButton, Stack } from 'office-ui-fabric-react'
import { default as React, useMemo, useState } from 'react'
import {
  CampaignList,
  CampaignMappings,
  getCampaignMappings,
} from '../../components/CampaignList'
import { DropdownInput } from '../../components/DropdownInput'
import { LayoutMode, Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import * as dto from '../../dto/api'
import { Role, roleMap, roles } from './UserRoles'
import {
  User,
  createInviteReq,
  updateAccountCampaignMappingReq,
  updateAccountRoleReq,
} from './utils'

export type UserDraft = {
  email: string | undefined
  role: dto.AccountRole | undefined
  campaignMappings: CampaignMappings
}

export type UserFormLogic = {
  updateDraft: React.Dispatch<React.SetStateAction<UserDraft | undefined>>
  applyDraft: (users: User[] | undefined, draft: UserDraft) => Promise<void>
  openDialog: (users: User[] | undefined) => void
  closeDialog: () => void
}

type Props = {
  users?: User[]
  draft: UserDraft
  campaigns?: dto.Campaign[]
  draftLogic: UserFormLogic
}

export const getBlankDraft = (campaigns: dto.Campaign[]): UserDraft => {
  return {
    email: '',
    role: 'EDITOR',
    campaignMappings: getCampaignMappings(campaigns, false),
  }
}

const allUsersAssignedToCampaign = (
  users: User[],
  campaignId: number,
): boolean | null => {
  return _.every(users, user => _.includes(user.assignedCampaigns, campaignId))
    ? true
    : _.some(users, user => _.includes(user.assignedCampaigns, campaignId))
      ? null
      : false
}

export const getPopulatedDraft = (
  users: User[],
  campaigns: dto.Campaign[],
): UserDraft => {
  const email = users.length === 1 ? users[0].email : `${users.length} accounts`
  const roles = _.uniq(_.map(users, 'role'))
  const role = roles.length === 1 ? roles[0] : undefined
  return {
    email,
    role,
    campaignMappings: getCampaignMappings(campaigns, id =>
      allUsersAssignedToCampaign(users, id),
    ),
  }
}

export const useUserFormLogic = (
  campaigns: dto.GetCampaignsResp | undefined,
  setDraft: React.Dispatch<React.SetStateAction<UserDraft | undefined>>,
  mutateInvites: () => void,
  mutateAccounts: () => void,
): UserFormLogic => {
  return useMemo(() => {
    return {
      updateDraft: setDraft,
      applyDraft: async (users: User[] | undefined, draft: UserDraft) => {
        if (users) {
          const { role, campaignMappings } = draft

          if (role) {
            await Promise.allSettled(
              _.map(users, user => {
                if (user.role !== role) {
                  return updateAccountRoleReq(user.accountId!, role)
                }
              }),
            )
          }

          await Promise.allSettled(
            _.map(users, user => {
              return Promise.allSettled(
                _.map(campaignMappings, (isAssignedDraft, campaignIdString) => {
                  const campaignId = parseInt(campaignIdString)
                  const isAssigned = _.includes(
                    user.assignedCampaigns,
                    campaignId,
                  )
                  if (
                    isAssigned !== isAssignedDraft &&
                    isAssignedDraft !== null
                  ) {
                    return updateAccountCampaignMappingReq(
                      user.accountId!,
                      campaignId,
                      isAssignedDraft,
                    )
                  }
                }),
              )
            }),
          )
          // await updateAccountsReq(users, request)
          mutateAccounts()
        } else {
          const { email, role, campaignMappings } = draft
          await createInviteReq({
            email: email!,
            role: role!,
            campaigns: _.chain(campaignMappings)
              .map((isSelected, id) => {
                return isSelected ? parseInt(id) : null
              })
              .compact()
              .value(),
          })
          mutateInvites()
        }
      },
      openDialog: (users: User[] | undefined) => {
        setDraft(
          users
            ? getPopulatedDraft(users, campaigns || [])
            : getBlankDraft(campaigns || []),
        )
      },
      closeDialog: () => {
        setDraft(undefined)
      },
    }
  }, [campaigns, setDraft, mutateInvites, mutateAccounts])
}

export const UserForm = (props: Props) => {
  const { users, campaigns, draft, draftLogic } = props
  const { updateDraft, applyDraft, closeDialog } = draftLogic

  const [error, setError] = useState<string>()

  const title = !users
    ? 'New Invite'
    : users.length === 1
      ? 'Edit User'
      : 'Edit Users'

  const actionTitle = !users
    ? 'Add Invite'
    : users.length === 1
      ? 'Update User'
      : 'Update Users'

  const emailValue = draft.email || ''

  const role = draft.role ? roleMap[draft.role] : undefined

  return (
    <Modal title={title} onClose={closeDialog} layoutMode={LayoutMode.fit}>
      <Stack tokens={{ childrenGap: 20 }}>
        <TextInput
          id="email"
          label="Email"
          value={emailValue}
          setValue={(value: string) => {
            updateDraft(item => ({
              ...item!,
              email: value,
            }))
          }}
          disabled={!!users}
          error={error}
        />
        <DropdownInput
          id="role"
          label="Role"
          options={roles}
          value={role}
          setValue={(role?: Role) => {
            if (!role) {
              return
            }
            updateDraft(item => ({
              ...item!,
              role: role.id,
            }))
          }}
        />
        {campaigns && (
          <CampaignList
            label="Campaigns"
            items={campaigns}
            mappings={draft.campaignMappings}
            setMappings={(campaignMappings: CampaignMappings) =>
              updateDraft(item => ({
                ...item!,
                campaignMappings,
              }))
            }
          />
        )}
        <PrimaryButton
          onClick={async () => {
            await applyDraft(users, draft)
              .catch((error: Error) => {
                setError(error.message)
                throw error
              })
              .then(() => setError(undefined))
            closeDialog()
          }}
        >
          {actionTitle}
        </PrimaryButton>
      </Stack>
    </Modal>
  )
}
