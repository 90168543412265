import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { ErrorMessage } from '../../../components/ErrorMessage'

import { Sidebar, SidebarType } from '../../../containers/Sidebar'
import { AssetType } from '../../../store/assets/types'
import { EnvironmentSetUpPanel } from '../EnvironmentSetUpPanel'
import { CameraPanel } from './CameraPanel'
import { DocPanel } from './DocPanel'
import { SpecPanel } from './SpecPanel'
import { VisualsPanel } from './VisualsPanel'

type Props = {
  isErrorVisible: boolean
  setErrorVisible: (isVisible: boolean) => void
}

export const DocSidebar = (props: Props) => {
  const { isErrorVisible, setErrorVisible } = props
  return (
    <Sidebar sidebarType={SidebarType.Document}>
      <ErrorBoundary fallback={<ErrorMessage />}>
        <DocPanel
          isErrorVisible={isErrorVisible}
          setErrorVisible={setErrorVisible}
        />
        <SpecPanel />
        <CameraPanel />
        <VisualsPanel />
        <EnvironmentSetUpPanel
          assetType={AssetType.BaseScene}
          label="Base Scene"
        />
        <EnvironmentSetUpPanel
          assetType={AssetType.Environment}
          label="Environment"
        />
        <EnvironmentSetUpPanel assetType={AssetType.Light} label="Lights" />
      </ErrorBoundary>
    </Sidebar>
  )
}
