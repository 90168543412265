import React from 'react'

import { DropdownInput } from '../../containers/DropdownInput'
import { SliderInput } from '../../containers/SliderInput'
import {
  Bloc,
  Option,
  SceneObjectPropertyBloc,
  SceneObjectPropertyType,
} from '../../store/doc/types'
import { ImageTextureProperty } from './ImageTextureProperty'

type Props = {
  bloc: SceneObjectPropertyBloc
}

export const Property = ({ bloc }: Props) => {
  const { property } = bloc

  if ('min' in bloc) {
    return (
      <SliderInput
        bloc={bloc as any as Bloc<number>}
        label={bloc.label || ''}
        limitLabelWidth={true}
      />
    )
  }

  if ('options' in bloc) {
    if (property.type === 'imageTexture') {
      const textureBloc =
        bloc as SceneObjectPropertyBloc<SceneObjectPropertyType.ImageTexture>
      return <ImageTextureProperty bloc={textureBloc} />
    }
    const optionBloc = bloc as Bloc<Option>
    return (
      <DropdownInput
        bloc={optionBloc}
        label={bloc.label || ''}
        limitLabelWidth={true}
      />
    )
  }

  console.log({ property })

  return <div>no component for {bloc.path}</div>
}
