import { default as React, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import useSWR from 'swr'
import * as dto from '../../dto/api'
import { push } from '../../store/routing/actions'
import { UserDraft, UserFormLogic, useUserFormLogic } from './UserForm'
import { UsersPageDumb } from './UsersPageDumb'
import {
  User,
  disableUsersReq,
  enableUsersReq,
  fetcher,
  mergeUsers,
} from './utils'

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = DispatchProps

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
    },
    dispatch,
  )

const UsersPageContainer = (props: Props) => {
  const { data: accounts, mutate: mutateAccounts } = useSWR<dto.Account[]>(
    '/api/accounts',
    fetcher,
  )
  const { data: invites, mutate: mutateInvites } = useSWR<dto.Invite[]>(
    '/api/invites',
    fetcher,
  )
  const { data: campaigns } = useSWR<dto.GetCampaignsResp>(
    '/api/campaigns',
    fetcher,
  )

  const tableItemLogic = useMemo(() => {
    return {
      enableUsers: async (items: User[]) => {
        await enableUsersReq(items)
        mutateInvites()
        mutateAccounts()
      },

      disableUsers: async (items: User[]) => {
        await disableUsersReq(items)
        mutateInvites()
        mutateAccounts()
      },
    }
  }, [mutateInvites, mutateAccounts])

  const [draft, setDraft] = useState<UserDraft>()
  const draftLogic: UserFormLogic = useUserFormLogic(
    campaigns,
    setDraft,
    mutateInvites,
    mutateAccounts,
  )

  const isLoading = !invites && !accounts

  const tableItems = useMemo(
    () => mergeUsers(accounts, invites, campaigns),
    [invites, accounts, campaigns],
  )

  return (
    <UsersPageDumb
      {...props}
      campaigns={campaigns}
      tableItems={tableItems}
      tableItemLogic={tableItemLogic}
      draft={draft}
      draftLogic={draftLogic}
      isLoading={isLoading}
    />
  )
}

export const UsersPage = connect(null, mapDispatchToProps)(UsersPageContainer)
