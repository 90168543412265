import {
  IIconStyles,
  IStackStyles,
  Icon,
  Image,
  ImageFit,
  Stack,
  assertNever,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'
import { UnitValue } from '../store/doc/types'

export interface Size {
  tag: 'size'
  width: UnitValue
  height: UnitValue
}

export interface Thumbnail {
  tag: 'thumbnail'
  imageUri: string
}

export const size = (width: UnitValue, height: UnitValue): Size => ({
  tag: 'size',
  width,
  height,
})

export const thumbnail = (imageUri: string): Thumbnail => ({
  tag: 'thumbnail',
  imageUri,
})

export type Asset = Thumbnail | Size

interface Props {
  width?: number
  height?: number
  asset: Asset
}

const getStyles = memoizeFunction((width: number, height: number) => ({
  sizeContainer: {
    root: { width, height, backgroundColor: '#F1F1F1' },
  } as IStackStyles,
  silhouette: {
    fill: '#0078D4',
  } as React.CSSProperties,
  placeholderWrapper: {
    root: { width, height, background: '#E3E3E3' },
  } as IStackStyles,
  placeholder: {
    root: { fontSize: 20, userSelect: 'none' },
  } as IIconStyles,
}))

export const AssetPreview = (props: Props) => {
  const { width = 128, height = 72, asset } = props

  const styles = getStyles(width, height)

  switch (asset.tag) {
    case 'size':
      return (
        <Stack styles={styles.sizeContainer}>
          <svg
            width={width}
            height={height}
            viewBox={`0 0 ${asset.width.value} ${asset.height.value}`}
            preserveAspectRatio="xMidYMid meet"
          >
            <rect
              width={asset.width.value}
              height={asset.height.value}
              style={styles.silhouette}
            />
          </svg>
        </Stack>
      )
    case 'thumbnail':
      return asset.imageUri ? (
        <Image
          src={asset.imageUri}
          width={width}
          height={height}
          imageFit={ImageFit.contain}
        />
      ) : (
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          styles={styles.placeholderWrapper}
        >
          <Icon styles={styles.placeholder} iconName="Photo2" />
        </Stack>
      )
    default:
      return assertNever(asset)
  }
}
