// import _ from 'lodash'
import { combineReducers } from 'redux'
// import { persistReducer, persistStore } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import screenfull from 'screenfull'

import { reducers as assets } from './assets/reducers'
import { type Assets, initialState as initialAssetsState } from './assets/state'
import { reducers as auth } from './auth/reducers'
import { type Auth, initialState as initialAuthState } from './auth/state'
import { reducers as campaign } from './campaign/reducers'
import { initialState as initialCurrentCampaignState } from './campaign/state'
import type { Campaign } from './campaign/types'
import { reducers as campaigns } from './campaigns/reducers'
import {
  type Campaigns,
  initialState as initialCampaignsState,
} from './campaigns/state'
import { reducers as canvasPresets } from './canvasPresets/reducers'
import {
  type CanvasPresets,
  initialState as initialCanvasPresetsState,
} from './canvasPresets/state'
import { reducers as config } from './config/reducers'
import { initialState as initialConfigState } from './config/state'
import type { Config } from './config/types'
import { reducer as doc } from './doc/reducers'
import { type Doc, initialState as initialDocState } from './doc/state'
import { reducers as docHistory } from './docHistory/reducers'
import {
  type DocHistory,
  initialState as initialDocHistoryState,
} from './docHistory/state'
import { reducers as docRevisions } from './docRevisions/reducers'
import {
  type DocRevisions,
  initialState as initialDocRevisionsState,
} from './docRevisions/state'
import { reducers as loaders } from './loaders/reducers'
import {
  type Loaders,
  initialState as initialLoadersState,
} from './loaders/state'
import { reducers as routing } from './routing/reducers'
import {
  type Routing,
  initialState as initialRoutingState,
} from './routing/state'
import { setFullscreenState, setViewportSize } from './ui/actions'
import { reducers as ui } from './ui/reducers'
import { initialState as initialUiState } from './ui/state'
import type { UI } from './ui/types'
import { configureSentry } from './../sentry'
import { configureStore, type ThunkDispatch } from '@reduxjs/toolkit'
import type { Action } from './actions.js'

export interface State {
  ui: UI
  doc: Doc
  docHistory: DocHistory
  docRevisions: DocRevisions
  routing: Routing
  campaigns: Campaigns
  campaign: Campaign
  loaders: Loaders
  auth: Auth
  canvasPresets: CanvasPresets
  assets: Assets
  config: Config
}

export type Dispatch = ThunkDispatch<State, unknown, Action>

export type GetState = () => State

export const initialState = (): State => ({
  doc: initialDocState(),
  docHistory: initialDocHistoryState(),
  docRevisions: initialDocRevisionsState(),
  ui: initialUiState(),
  routing: initialRoutingState(),
  campaigns: initialCampaignsState(),
  campaign: initialCurrentCampaignState(),
  loaders: initialLoadersState(),
  auth: initialAuthState(),
  canvasPresets: initialCanvasPresetsState(),
  assets: initialAssetsState(),
  config: initialConfigState(),
})

// Todo: use https://github.com/zewish/redux-remember

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['ui'],
// }

// const uiPersistConfig = {
//   key: 'ui',
//   storage,
//   blacklist: ['viewport', 'isFullscreenMode'],
// }

const reducer = combineReducers({
  doc,
  docHistory,
  docRevisions,
  ui,
  routing,
  campaigns,
  campaign,
  loaders,
  auth,
  canvasPresets,
  assets,
  config,
})

// const persistedReducer = persistReducer<State, Action>(persistConfig, reducer)
export const store = configureStore({
  reducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
  },
  // preloadedState: initialState(),
})

configureSentry()

// export const persistor = persistStore(store)

window.addEventListener('resize', () => {
  store.dispatch(setViewportSize(window.innerWidth, window.innerHeight))
})

if (screenfull.isEnabled) {
  screenfull.onchange(() => {
    store.dispatch(setFullscreenState(screenfull.isFullscreen))
  })
}

// FOR DEV
declare global {
  interface Window {
    store: any
  }
}
window.store = store
// END
