import _ from 'lodash'
import {
  IContextualMenuProps,
  PrimaryButton,
  SelectionMode,
  Stack,
} from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as timeago from 'timeago.js'

import { Dispatch, bindActionCreators } from 'redux'
import { AssetPreview, size } from '../components/AssetPreview'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { CommandBar, setCommandBarItems } from '../components/CommandBar'
import { DeleteDialog } from '../components/DeleteDialog'
import { DropdownInput } from '../components/DropdownInput'
import { LayoutMode, Modal } from '../components/Modal'
import { Page } from '../components/Page'
import {
  ColumnDef,
  SearchBoxPlacement,
  Table,
  defaultFilter,
} from '../components/Table'
import { TextGroupInput } from '../components/TextGroupInput'
import { TextInput } from '../components/TextInput'
import { Header } from '../containers/Header'
import { HomeNavigation } from '../containers/Navigation'
import { State } from '../store'
import {
  createCanvasPreset,
  deleteCanvasPresets,
  loadCanvasPresets,
} from '../store/canvasPresets/actions'
import {
  canvasMediumMap,
  canvasMediums,
  printBleeds,
  printResolutions,
} from '../store/canvasPresets/staticData'
import {
  CanvasPreset,
  CanvasPresetTypes,
  PrintBleed,
  PrintResolution,
} from '../store/canvasPresets/types'
import { Bloc, CanvasMedium } from '../store/doc/types'
import { isCanvasPresetsReloadNeededSelector } from '../store/loaders/selectors'
import { LoadState } from '../store/loaders/types'
import { push } from '../store/routing/actions'

type StateProps = {
  canvasPresets: State['canvasPresets']
  isCanvasPresetsReloadNeeded: boolean
  loaders: State['loaders']
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  canvasPresets: state.canvasPresets,
  isCanvasPresetsReloadNeeded: isCanvasPresetsReloadNeededSelector(state),
  loaders: state.loaders,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      loadCanvasPresets,
      createCanvasPreset,
      deleteCanvasPresets,
    },
    dispatch,
  )

const SpecsDumb = (props: Props) => {
  const {
    canvasPresets,
    isCanvasPresetsReloadNeeded,
    loadCanvasPresets,
    deleteCanvasPresets,
    loaders,
  } = props

  const [isSaveInitiated, setSaveInitiated] = useState<boolean>(false)
  const [isCanvasPresetValid, setCanvasPresetValid] = useState<boolean>(false)
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const [itemsToDelete, setItemsToDelete] = useState<TItem[]>([])
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false)

  const filters = [
    defaultFilter,
    CanvasPresetTypes.print,
    CanvasPresetTypes.digital,
    // CanvasPresetTypes.web,
  ]

  const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter)

  const resolution =
    _.find(printResolutions, resolution => resolution.value === 300) ||
    printResolutions[0]

  const bleed = printBleeds[0]

  const initiateNewCanvasPreset = (): CanvasPreset => {
    return {
      id: 0,
      name: '',
      medium: canvasMediumMap.digital,
      size: {
        width: { value: 1920, unit: 'px' },
        height: { value: 1080, unit: 'px' },
      },
      print: {
        resolution,
        bleed,
      },
      createdAt: '',
      createdBy: 0,
    }
  }

  const [newCanvasPreset, setNewCanvasPreset] = useState<CanvasPreset>(
    initiateNewCanvasPreset(),
  )

  useEffect(() => {
    if (isCanvasPresetsReloadNeeded) {
      loadCanvasPresets()
    }
  }, [isCanvasPresetsReloadNeeded, loadCanvasPresets])

  const tableItems = _.map(canvasPresets, canvasPreset => ({
    id: canvasPreset.id,
    preview: (
      <AssetPreview
        asset={size(canvasPreset.size.width, canvasPreset.size.height)}
      />
    ),
    name: canvasPreset.name,
    type: canvasPreset.medium.name,
    width: `${canvasPreset.size.width.value}${canvasPreset.size.width.unit}`,
    height: `${canvasPreset.size.height.value}${canvasPreset.size.height.unit}`,
    resolution: canvasPreset.print?.resolution.name ?? '-',
    bleed: canvasPreset.print?.bleed.name ?? '-',
    created: canvasPreset.createdAt,
    createdAgo: timeago.format(canvasPreset.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  const [selectedItems, setSelectedItems] = useState<TItem[]>([])
  const [contextualMenuProps, setContextualMenuProps] =
    useState<IContextualMenuProps>()

  const defaultCommandBarItems = [
    {
      key: 'add_spec',
      text: 'Add Specs',
      iconProps: {
        iconName: 'add',
      },
      className: '',
      onClick: () => {
        setCreateModalOpen(true)
      },
    },
  ]

  const additionalActionItems = (items?: TItem[]) => {
    const specs = items ? items : selectedItems
    return [
      {
        key: 'delete',
        text: 'Delete',
        iconProps: {
          iconName: 'delete',
        },
        className: 'is-dangerous',
        onClick: () => {
          deleteItems(specs)
        },
      },
    ]
  }

  const deleteItems = (items: TItem[]) => {
    setItemsToDelete(items)
    setIsDialogVisible(true)
  }

  const commandBarItems = setCommandBarItems(selectedItems.length, {
    none: defaultCommandBarItems,
    single: _.concat(defaultCommandBarItems, additionalActionItems()),
    multi: _.concat(defaultCommandBarItems, additionalActionItems()),
  })

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'preview', name: 'Preview', width: 156 },
    { key: 'name', name: 'Name', minWidth: 100 },
    { key: 'type', name: 'Type', maxWidth: 100 },
    { key: 'width', name: 'Width', maxWidth: 100 },
    { key: 'height', name: 'Height', maxWidth: 100 },
    { key: 'resolution', name: 'Resolution', width: 100 },
    { key: 'bleed', name: 'Bleed', maxWidth: 9999 },
    { key: 'created', name: 'Created', width: 100, fieldName: 'createdAgo' },
  ]

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        props.push('/')
      },
    },
    {
      text: 'Global Content',
      key: 'global content',
      onClick: () => {
        props.push('/specs')
      },
    },
    {
      text: 'Specs',
      key: 'specs',
      onClick: () => {
        props.push('/specs')
      },
    },
  ]

  const isLoading = loaders.canvasPresetsState !== LoadState.Loaded

  const onItemContextMenu = (item: TItem, index?: number, ev?: Event): void => {
    const contextualMenuProperties: IContextualMenuProps = {
      target: ev as MouseEvent,
      items: additionalActionItems([item]),
      onDismiss: () => {
        setContextualMenuProps(undefined)
      },
    }

    if (index !== undefined && index > -1) {
      setContextualMenuProps(contextualMenuProperties)
    }
  }

  const newCanvasPresetSize: Bloc<any>[] = [
    {
      path: ['width'],
      value: newCanvasPreset.size.width,
    },
    {
      path: ['height'],
      value: newCanvasPreset.size.height,
    },
  ]

  const isPrint = newCanvasPreset.medium.id === canvasMediumMap.print.id

  const closeModal = () => {
    setCreateModalOpen(false)
    setSaveInitiated(false)
  }

  return (
    <>
      {isCreateModalOpen && (
        <Modal
          title="New Specs"
          onClose={closeModal}
          layoutMode={LayoutMode.fit}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <TextInput
              id="name"
              label="Name"
              value={newCanvasPreset.name}
              setValue={(value: string) => {
                if (value) {
                  setCanvasPresetValid(true)
                } else {
                  setCanvasPresetValid(false)
                }

                const updatedNewCanvasPreset = {
                  ...newCanvasPreset,
                  name: value,
                  size: {
                    width: newCanvasPresetSize[0].value,
                    height: newCanvasPresetSize[1].value,
                  },
                }
                setNewCanvasPreset(updatedNewCanvasPreset)
              }}
              error={
                isSaveInitiated && !isCanvasPresetValid
                  ? 'Please provide a name for this spec'
                  : undefined
              }
            />
            <DropdownInput
              id="medium"
              label="Medium"
              options={canvasMediums}
              value={newCanvasPreset.medium}
              setValue={(value?: CanvasMedium) => {
                if (!value) {
                  return
                }
                const updatedNewCanvasPreset = {
                  ...newCanvasPreset,
                  medium: value,
                  size: {
                    width: newCanvasPresetSize[0].value,
                    height: newCanvasPresetSize[1].value,
                  },
                }
                setNewCanvasPreset(updatedNewCanvasPreset)
              }}
            />
            <TextGroupInput
              id="canvas-preset-size"
              blocs={newCanvasPresetSize}
              label="Size"
              labels={['Width', 'Height']}
              unit={newCanvasPreset.size.width.unit}
              setValue={(path: string[], value: any) => {
                if (!value) {
                  return
                }

                const dimension = path[0]

                if (dimension === 'width') {
                  newCanvasPresetSize[0].value = value
                }

                if (dimension === 'height') {
                  newCanvasPresetSize[1].value = value
                }
              }}
            />
            {isPrint && (
              <DropdownInput
                id="resolution"
                label="Resolution"
                options={printResolutions}
                value={newCanvasPreset.print!.resolution}
                setValue={(value?: PrintResolution) => {
                  if (!value) {
                    return
                  }

                  const updatedNewCanvasPreset = {
                    ...newCanvasPreset,
                    print: {
                      resolution: value,
                      bleed: newCanvasPreset.print!.bleed,
                    },
                    size: {
                      width: newCanvasPresetSize[0].value,
                      height: newCanvasPresetSize[1].value,
                    },
                  }
                  setNewCanvasPreset(updatedNewCanvasPreset)
                }}
              />
            )}
            {isPrint && (
              <DropdownInput
                id="bleed"
                label="Bleed"
                options={printBleeds}
                value={newCanvasPreset.print!.bleed}
                setValue={(value?: PrintBleed) => {
                  if (!value) {
                    return
                  }

                  const updatedNewCanvasPreset = {
                    ...newCanvasPreset,
                    print: {
                      resolution: newCanvasPreset.print!.resolution,
                      bleed: value,
                    },
                    size: {
                      width: newCanvasPresetSize[0].value,
                      height: newCanvasPresetSize[1].value,
                    },
                  }
                  setNewCanvasPreset(updatedNewCanvasPreset)
                }}
              />
            )}
            <PrimaryButton
              onClick={async () => {
                setSaveInitiated(true)
                if (!isCanvasPresetValid) {
                  return
                }
                const canvasPreset = {
                  ...newCanvasPreset,
                  size: {
                    width: newCanvasPresetSize[0].value,
                    height: newCanvasPresetSize[1].value,
                  },
                  print: isPrint ? newCanvasPreset.print : undefined,
                }
                await props.createCanvasPreset({ canvasPreset })
                setNewCanvasPreset(initiateNewCanvasPreset())
                closeModal()
              }}
            >
              Add Specs
            </PrimaryButton>
          </Stack>
        </Modal>
      )}
      {isDialogVisible && (
        <DeleteDialog
          itemName="spec"
          onClose={() => {
            setIsDialogVisible(false)
          }}
          onSuccess={() => {
            deleteCanvasPresets({ canvasPresetIds: _.map(itemsToDelete, 'id') })
          }}
          itemsToDelete={itemsToDelete}
        />
      )}
      <Page
        header={
          <Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />
        }
        sidebar={<HomeNavigation />}
        commandBar={
          <CommandBar items={commandBarItems} requiresLeftOffset={true} />
        }
        body={
          <Table<TItem>
            columns={columnItems}
            defaultSort={{ key: 'created', desc: true }}
            filters={filters}
            selectedFilter={selectedFilter}
            setFilter={setSelectedFilter}
            filterColumn="type"
            items={tableItems}
            selectionMode={SelectionMode.multiple}
            searchBoxPlacement={SearchBoxPlacement.right}
            onSelectionChanged={setSelectedItems}
            contextualMenuProps={contextualMenuProps}
            onItemContextMenu={onItemContextMenu}
          />
        }
        isLoading={isLoading}
      />
    </>
  )
}

export const Specs = connect(mapStateToProps, mapDispatchToProps)(SpecsDumb)
