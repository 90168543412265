import React from 'react'
import { connect } from 'react-redux'
import {
  Sidebar as SidebarComponent,
  SidebarSide,
  SidebarType,
} from '../components/Sidebar'
import { State } from '../store'
import { toggleSidebar } from '../store/ui/actions'

export { SidebarType } from '../components/Sidebar'

interface IProps {
  sidebarType: SidebarType
  children?: JSX.Element | (JSX.Element | undefined | boolean)[]
}

type IMappedProps = {
  width: number
  showContent?: boolean
}

interface IMappedActions {
  onToggle: (sidebar: SidebarType) => void
}

const mapStateToProps = (state: State, props: IProps): IMappedProps => {
  let showContent
  if (props.sidebarType === SidebarType.Navigation) {
    showContent = state.ui.isNavigationSidebarVisible
  } else if (props.sidebarType === SidebarType.Document) {
    showContent = state.ui.isDocumentSidebarVisible
  } else if (props.sidebarType === SidebarType.SceneObjects) {
    showContent = state.ui.isSceneObjectsSidebarVisible
  }

  return {
    showContent,
    width: state.ui.viewport.width,
  }
}

const mapDispatchToProps = (dispatch: Function): IMappedActions => {
  return {
    onToggle: (sidebarType: SidebarType) =>
      dispatch(toggleSidebar(sidebarType)),
  }
}

export const Sidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: IProps & IMappedProps & IMappedActions) => {
  let side = SidebarSide.Left
  if (props.sidebarType === SidebarType.SceneObjects) {
    side = SidebarSide.Right
  }

  return (
    <SidebarComponent
      side={side}
      type={props.sidebarType}
      width={props.width}
      showContent={!!props.showContent}
      children={props.children}
      onToggle={() => props.onToggle(props.sidebarType)}
    />
  )
})
