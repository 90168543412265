import _ from 'lodash'
import * as dto from '../dto/api'

export const getAssignedCampaignNames = (
  campaigns: dto.Campaign[] | undefined,
  assignedCampaigns: number[],
): string => {
  const result = _.chain(campaigns)
    .filter(campaign => _.includes(assignedCampaigns, campaign.id))
    .map('name')
    .value()
  if (result.length === 0) {
    return '-'
  }
  return result.join(', ')
}
