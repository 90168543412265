import _ from 'lodash'
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IContextualMenuProps,
  IStyle,
  Link,
  Selection,
  SelectionMode,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import React, { useEffect, useMemo } from 'react'
import { ContextualMenu } from '../components/ContextualMenu'
import { TableHeader } from '../components/TableHeader'
import { useInlineSearch } from '../hooks/useInlineSearch'
import { Sort, useInlineSort } from '../hooks/useInlineSort'
import { AssetPreview, size, thumbnail } from './AssetPreview'

export const defaultFilter = 'All'

export interface ColumnDef<T> {
  key: keyof T & string
  name?: string
  width?: number
  minWidth?: number
  maxWidth?: number
  fieldName?: keyof T & string
  titleKey?: keyof T & string
}

export { SelectionMode }

export enum SearchBoxPlacement {
  fill = 0,
  right = 1,
}

type TableItem = {
  id?: number | string
  name?: string
  key?: string
  mainActionTitle?: string
  download?: any
}

interface Props<T> {
  columns: ColumnDef<T>[]
  contextualMenuProps?: IContextualMenuProps
  defaultSort: Sort
  isSearchBoxHidden?: boolean
  filterColumn?: keyof T & string
  filters?: string[]
  selectedFilter?: string
  setFilter?: (filter: string) => void
  items: T[]
  onItemClick?: (item: T) => void
  onItemContextMenu?: (item: T, index?: number, ev?: Event) => void
  onSelectionChanged?: (items: T[]) => void
  searchBoxPlacement?: SearchBoxPlacement
  searchFields?: (keyof T)[]
  selectionMode?: SelectionMode
}

const styles = {
  table: {
    root: {
      overflow: 'auto',
    } as IStyle,
  },
  list: {
    root: {
      marginBottom: '20px',
    },
  },
  thumbnailLink: {
    root: {
      cursor: 'pointer',
    },
  },
}

type RenderStatusProps = {
  status: string
  renderDuration?: string
  download?: () => void
}

const RenderStatus = (props: RenderStatusProps) => {
  const { status, renderDuration, download } = props
  return (
    <Stack>
      {download ? (
        <Link onClick={download}>{status}</Link>
      ) : (
        <Text>{status}</Text>
      )}
      {renderDuration && <Text>{renderDuration}</Text>}
    </Stack>
  )
}

export const useEffectToUpdateSelectedItems = <T extends TableItem>(
  tableItems: T[],
  selectedItems: T[],
  identityKey: keyof T & string,
  setSelectedItems: (selectedItems: T[]) => void,
) => {
  return useEffect(() => {
    const hasChanges = _.some(selectedItems, selectedItem => {
      const tableItem = _.find(
        tableItems,
        tableItem => tableItem[identityKey] === selectedItem[identityKey],
      )
      return selectedItem !== tableItem
    })
    if (!hasChanges) {
      return
    }
    const newSelectedItems = _.chain(selectedItems)
      .map(selectedItem =>
        _.find(
          tableItems,
          tableItem => tableItem[identityKey] === selectedItem[identityKey],
        ),
      )
      .compact()
      .value()

    setSelectedItems(newSelectedItems)
  }, [tableItems, selectedItems, identityKey, setSelectedItems])
}

const filterItems = <T extends TableItem>(
  items: T[],
  column?: keyof T & string,
  selectedFilter?: string,
) => {
  if (column && selectedFilter !== defaultFilter) {
    return _.filter(items, item => (item[column] as any) === selectedFilter)
  }

  return items
}

export const Table = <T extends TableItem>(props: Props<T>) => {
  const {
    columns,
    contextualMenuProps,
    defaultSort,
    items,
    onItemClick,
    onItemContextMenu,
    onSelectionChanged,
    searchFields = ['name'],
    selectionMode = SelectionMode.none,
    filters,
    filterColumn,
    selectedFilter,
    setFilter,
  } = props

  const filteredItems = filterItems(items, filterColumn, selectedFilter)

  const [searchQuery, setSearchQuery, foundItems] = useInlineSearch<T>(
    filteredItems,
    searchFields,
  )

  const [sort, setSort, sortedItems] = useInlineSort(foundItems, defaultSort)

  const selection = useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        if (onSelectionChanged) {
          onSelectionChanged(selection.getSelection())
        }
      },
      getKey: (item: any, _?: number): string => {
        return item.id?.toString() || item.key || item.name || 'missing key'
      },
    })
  }, [onSelectionChanged])

  const constructColumns = (columns: ColumnDef<T>[], sort: Sort): IColumn[] => {
    return columns.map(col => {
      const minWidth = col.width || col.minWidth || 50
      const maxWidth = col.width || col.maxWidth || 250
      const { key, name = '', fieldName, titleKey } = col

      let isSorted
      let isSortedDescending
      if (sort && key === sort.key) {
        isSorted = true
        isSortedDescending = sort.desc
      }

      return {
        minWidth,
        maxWidth,
        isSorted,
        isSortedDescending,
        key,
        fieldName: fieldName || key,
        name,
        titleKey,
      }
    })
  }

  const onColumnHeaderClick = (
    _?: React.MouseEvent<HTMLElement>,
    column?: IColumn,
  ) => {
    if (!column) {
      return
    }
    let isSortedDescending = column.isSortedDescending || false
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending
    }

    setSort({ key: column.key!, desc: isSortedDescending })
  }

  const renderItemColumn = (item: T, index?: number, column?: IColumn) => {
    if (!column || !column.fieldName) {
      return
    }
    const content: any = column.fieldName
      ? item[column.fieldName as keyof T]
      : null

    const titleKey: keyof T | undefined = (column as any).titleKey
    const itemTitle = titleKey ? item[titleKey] : null

    if (['thumbnail'].includes(column.fieldName)) {
      const width = Math.min(column.minWidth, 128)
      const height = Math.min(column.minWidth, 72)
      return onItemClick ? (
        <Stack
          key={column.key}
          title={item.mainActionTitle}
          styles={styles.thumbnailLink}
          onClick={() => onItemClick(item)}
        >
          <AssetPreview
            asset={thumbnail(content)}
            width={width}
            height={height}
          />
        </Stack>
      ) : (
        <AssetPreview
          asset={thumbnail(content)}
          width={width}
          height={height}
        />
      )
    }

    if (column.fieldName === 'size') {
      return onItemClick ? (
        <Link
          key={column.key}
          title={item.mainActionTitle}
          onClick={() => onItemClick(item)}
        >
          <AssetPreview asset={size(content.width, content.height)} />
        </Link>
      ) : (
        <AssetPreview asset={size(content.width, content.height)} />
      )
    }

    if (column.fieldName === 'name' && onItemClick) {
      return (
        <Link
          key={column.key}
          title={item.mainActionTitle}
          onClick={() => onItemClick(item)}
        >
          <Text>{content}</Text>
        </Link>
      )
    }

    if (/^download/.test(column.fieldName as string)) {
      return (
        <Stack key={column.key} horizontal tokens={{ childrenGap: 10 }}>
          <Stack
            horizontal
            onClick={content.download}
            styles={content.download ? styles.thumbnailLink : undefined}
          >
            <AssetPreview asset={thumbnail(content.thumbnail)} />
          </Stack>
          <RenderStatus
            status={content.status}
            renderDuration={content.renderDuration}
            download={content.download}
          />
        </Stack>
      )
    }

    if (/^render/.test(column.fieldName as string)) {
      return (
        <RenderStatus
          status={content.status}
          renderDuration={content.renderDuration}
          download={content.download}
        />
      )
    }
    const title =
      itemTitle || (_.isArray(content) ? content.join('\n') : content)
    return <Text title={title}>{content}</Text>
  }

  const renderContextMenu = () => {
    if (contextualMenuProps) {
      return <ContextualMenu contextualMenuProps={contextualMenuProps} />
    }
  }

  return (
    <>
      <TableHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setFilter={setFilter}
        filters={filters}
        selectedFilter={selectedFilter}
      />
      <Stack styles={styles.table} data-is-scrollable={true}>
        <DetailsList
          columns={constructColumns(columns, sort)}
          items={sortedItems}
          layoutMode={DetailsListLayoutMode.justified}
          setKey="table"
          selection={selection}
          selectionPreservedOnEmptyClick={true}
          selectionMode={selectionMode}
          onRenderItemColumn={renderItemColumn}
          onColumnHeaderClick={onColumnHeaderClick}
          onItemInvoked={onItemClick}
          onItemContextMenu={onItemContextMenu}
          styles={styles.list}
        />
        {renderContextMenu()}
      </Stack>
    </>
  )
}
