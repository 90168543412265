import { Campaign, Permissions } from './types'

export const permissions: Permissions = {
  meta_version: 'read',
  meta_name: 'write',
  meta_isApproved: 'write',
  meta_showGrid: 'write',
  meta_isTemplate: 'write',
  canvas_name: 'write',
  canvas_medium: 'write',
  canvas_size_width: 'write',
  canvas_size_height: 'write',
  canvas_custom: 'write',
  canvas_stock: 'write',
  canvas_background_custom: 'write',
  canvas_background_stock: 'write',
  canvas_foreground_stock: 'write',
  canvas_foreground_custom: 'write',
  camera_isLocked: 'write',
  camera_focalLength: 'write',
  camera_distance: 'write',
  camera_target_x: 'write',
  camera_target_y: 'write',
  camera_target_z: 'write',
  camera_rotation_x: 'write',
  camera_rotation_y: 'write',
  camera_rotation_z: 'write',
  lightMap_texture: 'write',
  lightMap_rotation: 'write',
  sceneObject_model: 'write',
  sceneObject_isLocked: 'write',
  sceneObject_position_x: 'write',
  sceneObject_position_y: 'write',
  sceneObject_position_z: 'write',
  sceneObject_rotation_x: 'write',
  sceneObject_rotation_y: 'write',
  sceneObject_rotation_z: 'write',
  sceneObject_properties: 'write',
  sceneObject_screenImage_custom: 'write',
  sceneObject_screenImage_stock: 'write',
}

export const initialState = (): Campaign => ({
  id: 0,
  name: '',
  thumbnail: '',
  permissions,
  assets: {
    canvasPresets: {},
    canvasTextures: [],
    screenTextures: [],
    assets: [],
  },
  documents: {},
})
