import axios, { type AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import isRetryAllowed from 'is-retry-allowed'

import { store } from './store'
import { setUnauthorized } from './store/auth/actions'

const http = axios.create()

function retryAfter(error: AxiosError | undefined = undefined): number {
  const retryAfterHeader = error?.response?.headers['retry-after']
  if (!retryAfterHeader) {
    return 0
  }
  // if the retry after header is a number, convert it to milliseconds
  let retryAfterMs = (Number(retryAfterHeader) || 0) * 1000
  // If the retry after header is a date, get the number of milliseconds until that date
  if (retryAfterMs === 0) {
    retryAfterMs = (new Date(retryAfterHeader).valueOf() || 0) - Date.now()
  }
  return Math.max(0, retryAfterMs)
}

axiosRetry(http, {
  retries: Number.POSITIVE_INFINITY,
  // Retry even idempotent requests.
  retryCondition: e => {
    const status = e?.response?.status

    if (!status) {
      return true
    }

    return (
      isRetryAllowed(e) &&
      // Server errors
      ((status >= 500 && status < 600) ||
        // Request Timeout
        status === 408 ||
        // Too Many Requests
        status === 429)
    )
  },
  // Exponential delay for retries.
  retryDelay: (
    retryNumber = 0,
    error: AxiosError | undefined = undefined,
    delayFactor = 100,
  ): number => {
    const calculatedDelay = 2 ** retryNumber * delayFactor
    const delay = Math.max(calculatedDelay, retryAfter(error))
    const randomSum = delay * 0.2 * Math.random() // 0-20% of the delay
    return delay + randomSum
  },
})

http.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { response } = error
    if (response?.status === 401) {
      setUnauthorized(store.dispatch)
    }

    return Promise.reject(error)
  },
)

export default http
