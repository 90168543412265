import type { Action as HistoryAction, Location } from 'history'
import _ from 'lodash'
import qs from 'qs'
import type { Action as ReduxAction, Dispatch } from 'redux'

import { history } from './history'
import { routes } from './routes'
import type { Route } from './state'

export type Action = Navigated | Push | Replace

export enum RoutingActions {
  Navigated = 'NAVIGATED',
  Push = 'PUSH',
  Replace = 'REPLACE',
}

export const navigated = (
  location: Location,
  action: HistoryAction,
): Navigated => ({
  type: RoutingActions.Navigated,
  payload: { location, action },
})

const getNextLocation = (path: string, query?: Route['query']) => {
  let href = path
  let fullQs = ''

  const filteredQuery = _.omitBy(query, v => _.isEmpty(v))

  if (!_.isEmpty(filteredQuery)) {
    const routeDef = _.find(routes, { path })

    const queryOrder = routeDef?.queryOrder ?? []

    const orderedQs = _.map(_.pick(filteredQuery, queryOrder), (v, k) =>
      qs.stringify(_.fromPairs([[k, v]])),
    ).join('&')
    const restQs = qs.stringify(_.omit(filteredQuery, queryOrder))

    const query = _.compact([orderedQs, restQs]).join('&')
    fullQs = `?${query}`

    href += fullQs
  }

  const location = { ...history.location }
  location.pathname = path
  location.search = fullQs

  return { location, href }
}

export const push =
  (path: string, query?: Route['query']) => (dispatch: Dispatch) => {
    const { location, href } = getNextLocation(path, query)

    dispatch({
      type: 'PUSH',
      payload: location,
    })
    history.push(href)
  }

export const replace =
  (path: string, query?: Route['query']) => (dispatch: Dispatch) => {
    const { location, href } = getNextLocation(path, query)

    dispatch({
      type: 'REPLACE',
      payload: location,
    })
    history.replace(href)
  }

export interface Navigated extends ReduxAction<RoutingActions> {
  type: RoutingActions.Navigated
  payload: {
    location: Location
    action: HistoryAction
  }
}

export interface Push extends ReduxAction<RoutingActions> {
  type: RoutingActions.Push
  payload: Location
}

export interface Replace extends ReduxAction<RoutingActions> {
  type: RoutingActions.Replace
  payload: Location
}
