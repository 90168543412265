import { DefaultButton } from 'office-ui-fabric-react'
import React, { useState } from 'react'

import { Asset } from '../../store/doc/types'
import { AssetType } from '../../store/assets/types'
import { SceneObjectsModal } from './SceneObjectsModal'

type Props = {
  assetType: AssetType
  assets: Asset[]
  onSelect: (asset: Asset) => void
  label: string
}

export const AddSceneObjectAction = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <DefaultButton
        onClick={() => {
          setModalOpen(true)
        }}
      >
        {props.label}
      </DefaultButton>

      <SceneObjectsModal
        title={`Select Object`}
        assetType={props.assetType}
        assets={props.assets}
        onSelect={props.onSelect}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  )
}
