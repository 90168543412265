import _ from 'lodash'
import type { Action as ReduxAction } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import type { Dispatch, GetState } from '..'
import type * as dto from '../../dto/api'
import http from '../../http'
import type { UploadImageRes } from '../../lib/fileUtils'
import type { CampaignAssets } from '../campaign/types'
import { snapshotDoc } from '../docHistory/actions'
import {
  assetTextureFromApi,
  canvasTextureFromApi,
  docFromApi,
  docToApi,
} from './dataTransformers'
import { type Doc, type DocDiff, getInitialSceneObjectState } from './state'
import {
  type AssetTexture,
  type CanvasTexture,
  type Asset,
  SceneObjectPropertyType,
  type SceneObjectPropertyAnisotropicScaling,
  type SceneObjectPropertyIsotropicScaling,
  type SceneObject,
} from './types'

export type Action =
  | SetDoc
  | SetInDoc
  | MergeDoc
  | ResetDoc
  | AddSceneObject
  | AddedSceneObject
  | RemoveSceneObject
  | ReplaceSceneObject
  | SelectSceneObject
  | SelectGivenSceneObjects
  | DuplicateSceneObject
  | LoadDoc
  | LoadedDoc
  | SaveDoc
  | SaveDocAsNew
  | SavedDoc
  | NotSavedDoc
  | DeleteDocs
  | DuplicateDoc
  | RefreshDocs
  | ToggleTemplate
  | CreateCustomCanvasTexture
  | CreatedCustomCanvasTexture
  | CreateCustomAssetTexture
  | CreatedCustomAssetTexture

export enum DocActions {
  SetDoc = 'SET_DOC',
  SetInDoc = 'SET_IN_DOC',
  MergeDoc = 'MERGE_DOC',
  ResetDoc = 'RESET_DOC',
  AddSceneObject = 'ADD_SCENE_OBJECT',
  AddedSceneObject = 'ADDED_SCENE_OBJECT',
  RemoveSceneObject = 'REMOVE_SCENE_OBJECT',
  ReplaceSceneObject = 'REPLACE_SCENE_OBJECT',
  SelectSceneObject = 'SELECT_SCENE_OBJECT',
  SelectGivenSceneObjects = 'SELECT_GIVEN_SCENE_OBJECTS',
  DuplicateSceneObject = 'DUPLICATE_SCENE_OBJECT',
  LoadDoc = 'LOAD_DOC',
  LoadedDoc = 'LOADED_DOC',
  SaveDoc = 'SAVE_DOC',
  SaveDocAsNew = 'SAVE_DOC_AS_NEW',
  SavedDoc = 'SAVED_DOC',
  NotSavedDoc = 'NOT_SAVED_DOC',
  DeleteDocs = 'DELETE_DOCS',
  DuplicateDoc = 'DUPLICATE_DOC',
  RefreshDocs = 'REFRESH_DOCS',
  ToggleTemplate = 'TOGGLE_TEMPLATE',
  CreateCustomCanvasTexture = 'CREATE_CUSTOM_CANVAS_TEXTURE',
  CreatedCustomCanvasTexture = 'CREATED_CUSTOM_CANVAS_TEXTURE',
  CreateCustomAssetTexture = 'CREATE_CUSTOM_ASSET_TEXTURE',
  CreatedCustomAssetTexture = 'CREATED_CUSTOM_ASSET_TEXTURE',
}

export interface SetInDoc extends ReduxAction<DocActions> {
  type: DocActions.SetInDoc
  payload: { path: string[]; value: any }
}

export const setInDoc =
  (payload: SetInDoc['payload']) => (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.SetInDoc,
      payload,
    } as const)

    dispatch(snapshotDoc())
  }

export interface MergeDoc extends ReduxAction<DocActions> {
  type: DocActions.MergeDoc
  payload: DocDiff
}

export const mergeDoc =
  (payload: MergeDoc['payload']) => (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.MergeDoc,
      payload,
    })

    dispatch(snapshotDoc())
  }

export interface ResetDoc extends ReduxAction<DocActions> {
  type: DocActions.ResetDoc
  payload: {
    assets: CampaignAssets
  }
}

export const resetDoc = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState()
  const {
    campaign: { assets },
  } = state

  dispatch({
    type: DocActions.ResetDoc,
    payload: { assets },
  })

  dispatch(snapshotDoc({ isInitial: true }))
}

export interface SetDoc extends ReduxAction<DocActions> {
  type: DocActions.SetDoc
  payload: Doc
}

export const setDoc = (payload: SetDoc['payload']) => (dispatch: Dispatch) => {
  dispatch({
    type: DocActions.SetDoc,
    payload,
  })

  dispatch(snapshotDoc({ isInitial: true }))
}

export interface AddSceneObject extends ReduxAction<DocActions> {
  type: DocActions.AddSceneObject
  payload: { assetId: number; type: string; revisionId: number }
}

export interface AddedSceneObject extends ReduxAction<DocActions> {
  type: DocActions.AddedSceneObject
  payload: SceneObject
}

export const addSceneObject =
  (payload: AddSceneObject['payload']) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState()

    dispatch({
      type: DocActions.AddSceneObject,
      payload,
    })

    const asset = _.find(state.campaign.assets.assets, {
      type: payload.type,
      id: payload.assetId,
    }) as Asset
    const revision = _.find(asset.revisions, { id: payload.revisionId })

    const isotropicScalingProperty = _.find(revision!.properties, {
      type: SceneObjectPropertyType.IsotropicScaling,
    }) as SceneObjectPropertyIsotropicScaling | undefined
    const anisotropicScalingProperty = _.find(revision!.properties, {
      type: SceneObjectPropertyType.AnisotropicScaling,
    }) as SceneObjectPropertyAnisotropicScaling | undefined

    // Set the default scale.
    const scale = {
      x:
        isotropicScalingProperty?.default ??
        anisotropicScalingProperty?.defaultX ??
        1,
      y:
        isotropicScalingProperty?.default ??
        anisotropicScalingProperty?.defaultY ??
        1,
      z:
        isotropicScalingProperty?.default ??
        anisotropicScalingProperty?.defaultZ ??
        1,
    }

    const id = uuidv4()
    dispatch({
      type: DocActions.AddedSceneObject,
      payload: {
        id,
        type: payload.type,
        assetId: payload.assetId,
        revisionId: payload.revisionId,
        ...getInitialSceneObjectState(scale),
      },
    } as AddedSceneObject)

    dispatch(snapshotDoc())
  }

export interface DuplicateSceneObject extends ReduxAction<DocActions> {
  type: DocActions.DuplicateSceneObject
  payload: { sceneObjectId: string }
}

export const duplicateSceneObject =
  (payload: DuplicateSceneObject['payload']) => (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.DuplicateSceneObject,
      payload,
    })

    dispatch(snapshotDoc())
  }

export interface RemoveSceneObject extends ReduxAction<DocActions> {
  type: DocActions.RemoveSceneObject
  payload: string
}

export const removeSceneObject =
  (payload: RemoveSceneObject['payload']) => (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.RemoveSceneObject,
      payload,
    })

    dispatch(snapshotDoc())
  }

export interface ReplaceSceneObject extends ReduxAction<DocActions> {
  type: DocActions.ReplaceSceneObject
  payload: { sceneObjectUuid: string; asset: Asset }
}

export const replaceSceneObject =
  (payload: ReplaceSceneObject['payload']) => (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.ReplaceSceneObject,
      payload,
    } as ReplaceSceneObject)

    dispatch(snapshotDoc())
  }

export interface SelectSceneObject extends ReduxAction<DocActions> {
  type: DocActions.SelectSceneObject
  payload: { uuid: string; value: boolean }
}

export const selectSceneObject = (payload: SelectSceneObject['payload']) =>
  ({
    type: DocActions.SelectSceneObject,
    payload,
  }) as SelectSceneObject

export interface SelectGivenSceneObjects extends ReduxAction<DocActions> {
  type: DocActions.SelectGivenSceneObjects
  payload: string[]
}

export const selectGivenSceneObjects = (
  payload: SelectGivenSceneObjects['payload'],
) =>
  ({
    type: DocActions.SelectGivenSceneObjects,
    payload,
  }) as SelectGivenSceneObjects

export interface LoadDoc extends ReduxAction<DocActions> {
  type: DocActions.LoadDoc
  payload: { campaignId: number; documentId: number }
}

export interface LoadedDoc extends ReduxAction<DocActions> {
  type: DocActions.LoadedDoc
  payload: Doc
}

export const loadDoc =
  (payload: LoadDoc['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.LoadDoc,
      payload,
    })

    const response = await http.get<dto.DocumentResp>(
      `/api/document/${payload.documentId}`,
    )
    const doc = docFromApi(response.data)

    dispatch({
      type: DocActions.SetDoc,
      payload: doc,
    })
    dispatch({
      type: DocActions.LoadedDoc,
      payload: doc,
    })

    dispatch(snapshotDoc({ isInitial: true }))
  }

export interface SaveDoc extends ReduxAction<DocActions> {
  type: DocActions.SaveDoc
  payload: { doc: Doc }
}

export interface SaveDocAsNew extends ReduxAction<DocActions> {
  type: DocActions.SaveDocAsNew
  payload: { doc: Doc }
}

export interface SavedDoc extends ReduxAction<DocActions> {
  type: DocActions.SavedDoc
  payload: { documentId: number; nextVersion: number; doc: Doc }
}

export interface NotSavedDoc extends ReduxAction<DocActions> {
  type: DocActions.NotSavedDoc
  payload: { documentId: number; nextVersion: number }
}

export const saveDoc = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState()
  const doc = state.doc

  dispatch({
    type: DocActions.SaveDoc,
    payload: { doc },
  })
  let { id } = doc.meta

  const currentVersion = doc.meta.version
  const nextVersion = currentVersion + 1

  try {
    if (currentVersion === 0) {
      // New document.
      const response = await http.post<dto.IdResp>(
        '/api/document',
        docToApi(doc),
      )
      const responseData = response.data
      id = responseData.id
    } else {
      // New document revision.
      await http.post<void>(
        `/api/document/${doc.meta.id}/${nextVersion}`,
        docToApi(doc),
      )
    }
  } catch (error) {
    dispatch({
      type: DocActions.NotSavedDoc,
      payload: { documentId: id, nextVersion },
    })
    return { id, version: currentVersion, error }
  }

  dispatch({
    type: DocActions.SavedDoc,
    payload: { documentId: id, nextVersion, doc },
  })

  return { id, version: nextVersion }
}

export const saveDocAsNew =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState()
    const doc = state.doc

    dispatch({
      type: DocActions.SaveDocAsNew,
      payload: { doc },
    })

    const docClone: Doc = {
      ...doc,
      meta: {
        ...doc.meta,
        version: 0,
      },
    }

    const response = await http.post<dto.IdResp>(
      '/api/document',
      docToApi(docClone),
    )
    return { id: response.data.id, version: 1 }
  }

export interface DeleteDocs extends ReduxAction<DocActions> {
  type: DocActions.DeleteDocs
  payload: { docIds: string[] }
}

export const deleteDocs =
  (payload: DeleteDocs['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.DeleteDocs,
      payload,
    })

    const promises = _.map(payload.docIds, async docId => {
      return http.delete(`/api/document/${docId}`)
    })

    await Promise.all(promises)

    dispatch({
      type: DocActions.RefreshDocs,
    })
  }

export interface RefreshDocs extends ReduxAction<DocActions> {
  type: DocActions.RefreshDocs
}

export const refreshDocs = () =>
  ({
    type: DocActions.RefreshDocs,
  }) as RefreshDocs

export interface DuplicateDoc extends ReduxAction<DocActions> {
  type: DocActions.DuplicateDoc
  payload: { docId: number }
}

export const duplicateDoc =
  (payload: DuplicateDoc['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.DuplicateDoc,
      payload,
    })

    const { docId } = payload

    const getResponse = await http.get<dto.DocumentResp>(
      `/api/document/${docId}`,
    )
    const doc = docFromApi(getResponse.data)

    const newDoc: Doc = {
      ...doc,
      meta: {
        ...doc.meta,
        id: 0,
        name: `Copy of ${doc.meta.name}`,
        version: 0,
      },
    }

    const saveResponse = await http.post<dto.IdResp>(
      '/api/document',
      docToApi(newDoc),
    )
    const responseData = saveResponse.data
    const id = responseData.id

    dispatch({
      type: DocActions.SavedDoc,
      payload: { documentId: id, nextVersion: 1, doc: newDoc },
    })

    return { id }
  }

export interface ToggleTemplate extends ReduxAction<DocActions> {
  type: DocActions.ToggleTemplate
  payload: { docId: number; value: boolean }
}

export const toggleTemplate =
  (payload: ToggleTemplate['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.ToggleTemplate,
      payload,
    })

    const { docId, value } = payload

    await http.patch<void>(`/api/document/${docId}/is_template`, value)

    dispatch({
      type: DocActions.RefreshDocs,
    })
  }

export interface CreateCustomCanvasTexture extends ReduxAction<DocActions> {
  type: DocActions.CreateCustomCanvasTexture
  payload: {
    textureType: dto.TextureType
    name: string
    width: number
    height: number
    imageUri: string
    token: string
  }
}

export interface CreatedCustomCanvasTexture extends ReduxAction<DocActions> {
  type: DocActions.CreatedCustomCanvasTexture
  payload: CanvasTexture
}

export const createCustomCanvasTexture =
  (payload: CreateCustomCanvasTexture['payload'], campaignId: number) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.CreateCustomCanvasTexture,
      payload,
    })

    const data: dto.CreateCanvasTextureReq = {
      campaignId,
      textureType: payload.textureType,
      name: payload.name,
      width: payload.width,
      height: payload.height,
      isCustom: true,
      token: payload.token,
    }
    const response = await http.post<dto.CanvasTexture>(
      '/api/canvas_texture',
      data,
    )
    const result: CanvasTexture = canvasTextureFromApi(response.data)

    dispatch({
      type: DocActions.CreatedCustomCanvasTexture,
      payload: result,
    })

    return result
  }

export interface CreateCustomAssetTexture extends ReduxAction<DocActions> {
  type: DocActions.CreateCustomAssetTexture
  payload: {
    uploadResp: UploadImageRes
    campaignId: number
  }
}

export interface CreatedCustomAssetTexture extends ReduxAction<DocActions> {
  type: DocActions.CreatedCustomAssetTexture
  payload: AssetTexture
}

export const createCustomAssetTexture =
  (payload: CreateCustomAssetTexture['payload']) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DocActions.CreateCustomAssetTexture,
      payload,
    })

    const { campaignId, uploadResp } = payload

    const req: dto.CreateDeviceTextureReq = {
      campaignId,
      name: uploadResp.name,
      width: uploadResp.width,
      height: uploadResp.height,
      isCustom: true,
      token: uploadResp.token,
    }

    const response = await http.post<dto.DeviceTexture>(
      '/api/device_texture',
      req,
    )
    const result: AssetTexture = assetTextureFromApi(response.data)

    dispatch({
      type: DocActions.CreatedCustomAssetTexture,
      payload: result,
    })

    return result
  }
