export type UnauthorizedType =
  | 'default'
  | 'areaRestricted'
  | 'accountDisabled'
  | 'inviteExpired'

export type AccountInfo = {
  id: number
  email: string
  name: string
  permissions: string[]
  imageUri: string
}

export type Auth = {
  isUnauthorized: boolean
  account: AccountInfo
}

export const initialState = (): Auth => ({
  isUnauthorized: false,
  account: {
    id: 0,
    name: 'Unknown Account',
    email: '',
    permissions: [],
    imageUri: '',
  },
})
