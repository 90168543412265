import { Action } from '../actions'
import { CampaignsActions } from './actions'
import { Campaigns, initialState } from './state'

export const reducers = (
  state: Campaigns = initialState(),
  action: Action,
): Campaigns => {
  switch (action.type) {
    case CampaignsActions.LoadedCampaigns:
      const { payload } = action
      return payload.campaigns
  }

  return state
}
