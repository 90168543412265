import { produce } from 'immer'
import { Action } from '../actions'
import { DocActions } from '../doc/actions'
import { DocHistoryActions } from './actions'
import { DocHistory, initialState } from './state'

const MAX_LENGTH = 50

export const reducers = (
  currentState: DocHistory = initialState(),
  action: Action,
): DocHistory => {
  switch (action.type) {
    case DocHistoryActions.SnapshotDoc: {
      const { isInitial, doc } = action.payload

      if (isInitial) {
        return {
          position: 0,
          stack: [doc],
        }
      }

      let nextPosition = currentState.position
      let nextStack = currentState.stack

      // Slice off the tail when we are in the middle of stack.
      if (nextPosition !== nextStack.length - 1) {
        nextStack = nextStack.slice(0, nextPosition + 1)
      }

      // Slice of the oldest entry when stack is too large.
      if (nextStack.length === MAX_LENGTH) {
        nextPosition -= 1
        nextStack = nextStack.slice(1)
      }

      nextPosition += 1
      nextStack.push(doc)

      return {
        position: nextPosition,
        stack: nextStack,
      }
    }

    case DocHistoryActions.UndoDoc:
      return produce(currentState, (nextState: DocHistory) => {
        nextState.position -= 1
      })

    case DocHistoryActions.RedoDoc:
      return produce(currentState, (nextState: DocHistory) => {
        nextState.position += 1
      })

    case DocActions.SavedDoc: {
      const { doc } = action.payload

      return {
        position: 0,
        stack: [doc],
      }
    }
  }

  return currentState
}
