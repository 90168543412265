import _ from 'lodash'
import { IContextualMenuProps, SelectionMode } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { CommandBar, setCommandBarItems } from '../../components/CommandBar'
import { Page } from '../../components/Page'
import { ColumnDef, SearchBoxPlacement, Table } from '../../components/Table'
import { Header } from '../../containers/Header'
import { State } from '../../store'
import { loadRevisions, revertRevision } from '../../store/docRevisions/actions'
import { getRenderStatus } from '../../store/jobs/utils'
import { isRevisionsReloadNeededSelector } from '../../store/loaders/selectors'
import { LoadState } from '../../store/loaders/types'
import { push } from '../../store/routing/actions'
import {
  campaignIdSelector,
  documentIdSelector,
} from '../../store/routing/selectors'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  docRevisions: state.docRevisions,
  campaignId: campaignIdSelector(state),
  documentId: documentIdSelector(state),
  campaign: state.campaign,
  isRevisionsReloadNeeded: isRevisionsReloadNeededSelector(state),
  loaders: state.loaders,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      loadRevisions,
      revertRevision,
    },
    dispatch,
  )

export const Revisions = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const {
    docRevisions,
    isRevisionsReloadNeeded,
    campaign,
    loaders,
    loadRevisions,
    campaignId,
    documentId,
  } = props

  const [selectedItems, setSelectedItems] = useState<TItem[]>([])
  const [contextualMenuProps, setContextualMenuProps] =
    useState<IContextualMenuProps>()

  useEffect(() => {
    if (isRevisionsReloadNeeded || documentId) {
      loadRevisions({ documentId })
    }
  }, [isRevisionsReloadNeeded, loadRevisions, documentId])

  const revisions = docRevisions.revisions
  const lastRevision = _.last(revisions)

  const tableItems = _.map(revisions, revision => {
    return {
      id: revision.version,
      revision,
      name: `Version ${revision.version}`,
      download_proxy: getRenderStatus(
        revision.doc.meta.name,
        revision.version,
        revision.status.preview,
        'PREVIEW',
      ),
      download_final: getRenderStatus(
        revision.doc.meta.name,
        revision.version,
        revision.status.final,
        'FINAL',
      ),
      created: revision.createdAt,
      createdAgo: timeago.format(revision.createdAt),
    }
  })
  type TItem = (typeof tableItems)[0]

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'id', name: 'Name', width: 128, fieldName: 'name' },
    {
      key: 'download_proxy',
      name: 'Proxy Render',
      width: 225,
    },
    {
      key: 'download_final',
      name: 'Final Render',
      minWidth: 225,
      maxWidth: 9999,
    },
    { key: 'created', name: 'Created', width: 100, fieldName: 'createdAgo' },
  ]

  const defaultActionItems = [
    {
      key: 'edit_document',
      text: 'Edit Document',
      iconProps: {
        iconName: 'package',
      },
      className: '',
      onClick: () => {
        returnToDoc()
      },
    },
  ]

  const returnToDoc = () => {
    props.push(`/campaign/${campaignId}/document/${documentId}`)
  }

  const singleActionItems = (item?: TItem) => {
    const selectedItem = item ? item : selectedItems[0]

    return [
      {
        key: 'revert_version',
        text: 'Revert To This Version',
        onClick: () => {
          revertVersion(selectedItem)
        },
      },
    ]
  }

  const revertVersion = async (item: TItem) => {
    const revision = item.revision
    if (!revision) {
      return
    }

    await props.revertRevision({ revision })
    returnToDoc()
  }

  const commandBarItems = setCommandBarItems(selectedItems.length, {
    none: defaultActionItems,
    single: _.concat(defaultActionItems, singleActionItems()),
    multi: defaultActionItems,
  })

  const onItemContextMenu = (item: TItem, index?: number, ev?: Event): void => {
    const contextualMenuProperties: IContextualMenuProps = {
      target: ev as MouseEvent,
      items: singleActionItems(item),
      onDismiss: () => {
        setContextualMenuProps(undefined)
      },
    }

    if (index !== undefined && index > -1) {
      setContextualMenuProps(contextualMenuProperties)
    }
  }

  const isLoading = loaders.revisionsState !== LoadState.Loaded

  return (
    <Page
      header={
        <Header
          breadcrumbs={
            <Breadcrumbs
              items={[
                {
                  text: 'Home',
                  key: 'home',
                  onClick: () => {
                    props.push('/')
                  },
                },
                {
                  text: campaign.name,
                  key: 'campaign',
                  onClick: () => {
                    props.push(`/campaign/${campaignId}`)
                  },
                },
                {
                  text: lastRevision?.doc.meta.name ?? '',
                  key: 'document',
                  onClick: () => {
                    props.push(`/campaign/${campaignId}/document/${documentId}`)
                  },
                },
                {
                  text: 'Versions',
                  key: 'versions',
                  onClick: () => {},
                },
              ]}
            />
          }
        />
      }
      commandBar={<CommandBar items={commandBarItems} />}
      body={
        <Table<TItem>
          columns={columnItems}
          items={tableItems}
          selectionMode={SelectionMode.single}
          onSelectionChanged={setSelectedItems}
          searchBoxPlacement={SearchBoxPlacement.right}
          contextualMenuProps={contextualMenuProps}
          onItemContextMenu={onItemContextMenu}
          defaultSort={{ key: 'created', desc: true }}
        />
      }
      isLoading={isLoading}
    />
  )
})
