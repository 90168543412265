import { Doc } from '../doc/state'

export type DocHistory = {
  position: number
  stack: Doc[]
}

export const initialState = (): DocHistory => ({
  position: -1,
  stack: [],
})
