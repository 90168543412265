import { Action as ReduxAction, Dispatch } from 'redux'

import * as dto from '../../dto/api'
import http from '../../http'
import { campaignsFromApi } from './dataTransformers'
import { Campaigns } from './state'

export type Action = LoadCampaigns | LoadedCampaigns

export enum CampaignsActions {
  LoadCampaigns = 'LOAD_CAMPAIGNS',
  LoadedCampaigns = 'LOADED_CAMPAIGNS',
}

export interface LoadCampaigns extends ReduxAction<CampaignsActions> {
  type: CampaignsActions.LoadCampaigns
}

export interface LoadedCampaigns extends ReduxAction<CampaignsActions> {
  type: CampaignsActions.LoadedCampaigns
  payload: { campaigns: Campaigns }
}

export const loadCampaigns = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CampaignsActions.LoadCampaigns,
  })

  const response = await http.get<dto.Campaign[]>('/api/campaigns')
  const campaigns = campaignsFromApi(response.data)

  dispatch({
    type: CampaignsActions.LoadedCampaigns,
    payload: { campaigns },
  })
}
