import { createSelector } from 'reselect'

import {
  canvasMediums,
  printBleeds,
  printResolutions,
} from '../../canvasPresets/staticData'
import { docSelector } from './doc'

export const canvasPreset = createSelector([docSelector], doc => {
  const path = ['canvasPreset']
  return {
    path,
    value: doc.canvasPreset,
    isOptional: true,
  }
})

export const canvasMedium = createSelector([docSelector], doc => {
  const path = ['canvas', 'medium']
  const value = doc.canvas.medium
  return {
    path,
    value,
    options: canvasMediums,
    isDisabled: true,
  }
})

const isPrintSelector = createSelector(
  canvasMedium,
  medium => medium.value.id === 'print',
)

export const canvasSizeWidth = createSelector([docSelector], doc => {
  const path = ['canvas', 'size', 'width']
  const value = doc.canvas.size.width
  return {
    path,
    value,
    isDisabled: true,
  }
})

export const canvasSizeHeight = createSelector([docSelector], doc => {
  const path = ['canvas', 'size', 'height']
  const value = doc.canvas.size.height
  return {
    path,
    value,
    isDisabled: true,
  }
})

export const canvasPrintResolution = createSelector(
  [docSelector, isPrintSelector],
  (doc, isPrint) => {
    const path = ['canvas', 'print', 'resolution']

    if (!isPrint) {
      return {
        isUnavailable: true,
        path,
        value: undefined,
      }
    }

    const value = doc.canvas.print.resolution

    return {
      path,
      value,
      options: printResolutions,
      isDisabled: true,
    }
  },
)

export const canvasPrintBleed = createSelector(
  [docSelector, isPrintSelector],
  (doc, isPrint) => {
    const path = ['canvas', 'print', 'bleed']

    if (!isPrint) {
      return {
        isUnavailable: true,
        path,
        value: undefined,
      }
    }

    const value = doc.canvas.print.bleed

    return {
      path,
      value,
      options: printBleeds,
      isDisabled: true,
    }
  },
)
