import CryptoJS from 'crypto-js'

import { saveAs } from 'file-saver'
import * as dto from '../dto/api'
import http from '../http'
import { UploadClaim } from '../store/buckets/types'
import { jwtDecode } from 'jwt-decode'

export enum FileType {
  Zip = 'zip',
  Png = 'png',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
}

const DOWNLOAD_FILE_TYPES = ['zip', 'png', 'jpg', 'jpeg']

export type UploadImageRes = {
  name: string
  imageUri: string
  width: number
  height: number
  token: string
}

export const uploadFileRaw = (uploadUrl: string, file: File) =>
  new Promise<dto.TokenReq>(resolve => {
    const { type } = file

    const arrayBufferReader = new FileReader()

    arrayBufferReader.onload = async ev => {
      const hash = CryptoJS.MD5(
        // any used due to breaking type change in @types/crypto-js v4.0.0
        CryptoJS.lib.WordArray.create(ev.target?.result as any),
      )
      const md5 = CryptoJS.enc.Base64.stringify(hash)

      const {
        data: { token },
      } = await http.post<dto.TokenReq>(uploadUrl, {
        md5,
        contentType: type,
      })

      const decodedToken = jwtDecode<UploadClaim>(token)

      const { signedUrl } = decodedToken

      await http.put<void>(signedUrl, file, {
        headers: {
          'Content-Type': decodedToken.contentType,
          'Content-MD5': md5,
        },
      })

      const uploadedPayload: dto.TokenReq = {
        token,
      }

      resolve(uploadedPayload)
    }

    arrayBufferReader.readAsArrayBuffer(file)
  })

export const uploadImageRaw = (uploadUrl: string, file: File) =>
  new Promise<UploadImageRes>(resolve => {
    const { name, type } = file

    const arrayBufferReader = new FileReader()
    const dataUrlReader = new FileReader()

    dataUrlReader.onload = ev => {
      const image = new Image()

      image.onload = () => {
        const { width, height } = image

        arrayBufferReader.onload = async ev => {
          const hash = CryptoJS.MD5(
            // any used due to breaking type change in @types/crypto-js v4.0.0
            CryptoJS.lib.WordArray.create(ev.target?.result as any),
          )
          const md5 = CryptoJS.enc.Base64.stringify(hash)

          const {
            data: { token },
          } = await http.post<dto.TokenReq>(uploadUrl, {
            md5,
            contentType: type,
          })

          const decodedToken = jwtDecode<UploadClaim>(token)

          const { signedUrl } = decodedToken

          await http.put<void>(signedUrl, file, {
            headers: {
              'Content-Type': decodedToken.contentType,
              'Content-MD5': md5,
            },
          })

          // Same as signed URL, but without query parameters.
          const imageUri = signedUrl.replace(/\?.+/, '')

          const response: UploadImageRes = {
            name,
            imageUri,
            width,
            height,
            token,
          }

          resolve(response)
        }

        arrayBufferReader.readAsArrayBuffer(file)
      }

      const dataUrl = ev.target?.result as string

      image.src = dataUrl
    }

    dataUrlReader.readAsDataURL(file)
  })

export const download = (
  downloadLink: string,
  filename: string,
  type: string,
) => {
  if (!DOWNLOAD_FILE_TYPES.includes(type)) {
    console.log(`Download failed: Unaccepted file type: ${type}`)
    return
  }

  saveAs(downloadLink, `${filename}.${type}`)
}
