import { produce } from 'immer'
import type { Action } from '../actions'
import { AuthActions } from './actions'
import { type Auth, initialState } from './state'

const reducers = (currentState: Auth = initialState(), action: Action): any => {
  switch (action.type) {
    case AuthActions.SetUnauthorized:
      return produce(currentState, (nextState: Auth) => {
        nextState.isUnauthorized = true
      })
    case AuthActions.LoadedAccount: {
      const { payload } = action
      return produce(currentState, (nextState: Auth) => {
        nextState.account = payload.account
      })
    }
  }

  return currentState
}

export { reducers }
