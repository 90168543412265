import { useEffect, useState } from 'react'

type Props = {
  children: () => JSX.Element
  intervalMs?: number
}

const Rejuvenate = (props: Props) => {
  const { children, intervalMs = 500 } = props

  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setCounter(counter + 1)
    }, intervalMs)

    return function () {
      window.clearInterval(intervalId)
    }
  }, [intervalMs, counter])

  return children && children()
}

export { Rejuvenate }
