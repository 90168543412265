import { Action as HistoryAction, type Location } from 'history'

import { Main } from './containers/Main'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import { navigated } from './store/routing/actions'
import { history } from './store/routing/history'
import ReactDOM from 'react-dom'

const initialLocation: Location = history.location
store.dispatch(navigated(initialLocation, HistoryAction.Push))

history.listen(({ location, action }) => {
  store.dispatch(navigated(location, action))
})

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
