import _ from 'lodash'

import * as dto from '../../dto/api'
import {
  canvasMediumMap,
  printBleedMap,
  printResolutionMap,
  printResolutions,
} from './staticData'
import { CanvasPreset, PrintBleed, PrintResolution } from './types'

export const canvasPresetsFromApi = (
  response: dto.GetCanvasPresetsResp,
): CanvasPreset[] => {
  return _.map(_.filter(response.canvasPresets, ['isDeleted', false]), item =>
    canvasPresetFromApi(item),
  )
}

export const canvasPresetToApi = (
  canvasPreset: CanvasPreset,
): dto.CreateCanvasPresetReq => {
  return {
    name: canvasPreset.name,
    unit: canvasPreset.medium === canvasMediumMap.digital ? 'PIXEL' : 'INCH',
    width: canvasPreset.size.width.value,
    height: canvasPreset.size.height.value,
    resolution:
      canvasPreset.print?.resolution.value ?? printResolutions[0].value,
    bleedUnit: !canvasPreset.print
      ? undefined
      : UNIT_TO_API[canvasPreset.print.bleed.unit],
    bleed: canvasPreset.print?.bleed.value,
  } as dto.CreateCanvasPresetReq
}

export const UNIT_FROM_API: {
  [key in dto.LengthUnit]: string
} = {
  PIXEL: 'px',
  INCH: '"',
  FOOT: "'",
  MILLIMETER: 'mm',
  CENTIMETER: 'cm',
  METER: 'm',
}

const UNIT_TO_API: { [key: string]: string } = {
  px: 'PIXEL',
  '"': 'INCH',
  "'": 'FOOT',
  mm: 'MILLIMETER',
  cm: 'CENTIMETER',
  m: 'METER',
}

export const printResolutionFromApi = (dpi: number): PrintResolution => {
  const result = printResolutionMap[`${dpi}dpi`]
  if (result) {
    return result
  }

  return (printResolutionMap[`${dpi}dpi`] = {
    id: `${dpi}dpi`,
    name: `${dpi} dpi`,
    unit: 'dpi',
    value: dpi,
  })
}

export const printBleedFromApi = (value: number, unit: string): PrintBleed => {
  const result = printBleedMap[value + unit]
  if (result) {
    return result
  }

  return (printBleedMap[value + unit] = {
    id: value + unit,
    name: `${value} ${unit}`,
    unit,
    value,
  })
}

export const BLEED_FROM_API: {
  [key in dto.LengthUnit]: (value: number) => PrintBleed
} = {
  PIXEL: value => printBleedFromApi(value, 'px'),
  INCH: value => printBleedFromApi(value, '"'),
  FOOT: value => printBleedFromApi(value / 12, '"'),
  MILLIMETER: value => printBleedFromApi(value, 'mm'),
  CENTIMETER: value => printBleedFromApi(value / 10, 'cm'),
  METER: value => printBleedFromApi(value / 1000, 'm'),
}

export const canvasPresetFromApi = ($: dto.CanvasPreset): CanvasPreset => {
  const unit = UNIT_FROM_API[$.unit]
  return {
    id: $.id,
    name: $.name,
    medium: canvasMediumMap[$.unit === 'PIXEL' ? 'digital' : 'print'],
    size: {
      width: {
        unit,
        value: $.width,
      },
      height: {
        unit,
        value: $.height,
      },
    },
    print: $.bleed
      ? {
          resolution: printResolutionFromApi($.resolution),
          bleed: BLEED_FROM_API[$.bleedUnit ?? $.unit]($.bleed),
        }
      : undefined,
    createdAt: $.createdAt,
    createdBy: $.createdBy,
  }
}
