import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import type { Action } from '../actions'
import { DocActions } from './actions'
import { type Doc, initialState } from './state'
import {
  type SceneObjectProperties,
  SceneObjectPropertyType,
  type SceneObjectPropertyPreset,
} from './types'
import type { PresetOption } from '../../dto/assets'
import { produce } from 'immer'

export const reducer = (
  currentState: Doc = initialState(),
  action: Action,
): Doc => {
  switch (action.type) {
    case DocActions.SetInDoc:
      return produce(currentState, (nextState: Doc) => {
        _.set(nextState, action.payload.path, action.payload.value)
      })

    case DocActions.SetDoc:
      return { ...action.payload }

    case DocActions.SavedDoc:
      return produce(currentState, (nextState: Doc) => {
        nextState.meta.id = action.payload.documentId
        nextState.meta.version = action.payload.nextVersion
      })

    case DocActions.ResetDoc:
      return initialState(action.payload.assets)

    case DocActions.MergeDoc:
      return _.merge({}, currentState, action.payload)

    case DocActions.AddedSceneObject:
      const sceneObject = action.payload
      return produce(currentState, (nextState: Doc) => {
        _.set(nextState, ['sceneObjects', sceneObject.id], sceneObject)
      })

    case DocActions.DuplicateSceneObject:
      return produce(currentState, (nextState: Doc) => {
        const id = uuidv4()
        const sceneObjectToDuplicate = _.find(
          currentState.sceneObjects,
          sceneObject => sceneObject.id === action.payload.sceneObjectId,
        )
        _.set(nextState, ['sceneObjects', id], {
          ...sceneObjectToDuplicate,
          id,
          isSelected: false,
        })
      })

    case DocActions.RemoveSceneObject:
      return produce(currentState, (nextState: Doc) => {
        _.unset(nextState, ['sceneObjects', action.payload])
      })

    case DocActions.ReplaceSceneObject:
      return produce(currentState, (nextState: Doc) => {
        const { sceneObjectUuid, asset } = action.payload

        const oldObjectProps =
          currentState.sceneObjects[sceneObjectUuid].properties

        const lastRevision = _.last(asset.revisions)!
        const properties: SceneObjectProperties = _.transform(
          lastRevision.properties,
          (result, assetProp) => {
            const { id, type } = assetProp
            const oldValue = oldObjectProps[id]
            if (!oldValue) {
              return
            }

            if (type === SceneObjectPropertyType.Preset) {
              const assetPropTyped = assetProp as SceneObjectPropertyPreset
              const oldValueTyped = oldValue as PresetOption
              _.each(assetPropTyped.options, option => {
                if (option.id === oldValueTyped.id) {
                  result[id] = option
                }
              })
              return
            }

            result[id] = oldValue
          },
          {} as SceneObjectProperties,
        )

        const sceneObject = {
          ...currentState.sceneObjects[sceneObjectUuid],
          assetId: asset.id,
          revisionId: lastRevision.id,
          type: asset.type,
          properties,
        }
        _.set(nextState, ['sceneObjects', sceneObjectUuid], sceneObject)
      })

    case DocActions.SelectSceneObject:
      return produce(currentState, (nextState: Doc) => {
        nextState.sceneObjects[action.payload.uuid].isSelected =
          action.payload.value
      })

    case DocActions.SelectGivenSceneObjects:
      return produce(currentState, (nextState: Doc) => {
        _.forEach(nextState.sceneObjects, sceneObject => {
          nextState.sceneObjects[sceneObject.id].isSelected = _.includes(
            action.payload,
            sceneObject.id,
          )
        })
      })
  }

  return currentState
}
