import React, { useEffect, useState } from 'react'
import { AssetInput } from '../containers/AssetInput'
import { UploadImageRes } from '../lib/fileUtils'
import { Campaign } from '../store/campaign/types'
import { Bloc } from '../store/doc/types'
import {
  AssetStatus,
  error,
  preview,
  processing,
  selectAsset,
  uploading,
} from './AssetInput'

type Props = {
  label: string
  bloc: Bloc<any>
  onSelect?: () => void
  textureType?: 'BACKGROUND' | 'FOREGROUND'
  placeholder?: string[]
  setValue: (value: any) => void
  upload: (file: File) => any
  createCustomTexture: (
    uploadResp: UploadImageRes,
    campaignId: number,
    textureType?: 'BACKGROUND' | 'FOREGROUND',
  ) => any
  campaign: Campaign
}

export const ImageInput = (props: Props) => {
  const { label, bloc, onSelect, textureType, placeholder, campaign } = props

  const [assetState, setAssetState] = useState<AssetStatus>(selectAsset)

  useEffect(() => {
    const asset = bloc.value
    if (asset) {
      setAssetState(preview(asset.name, asset.imageUri))
    } else {
      setAssetState(selectAsset)
    }
  }, [bloc])

  return (
    <AssetInput
      bloc={bloc}
      label={label}
      placeholder={placeholder}
      onSelect={onSelect}
      onRemove={() => {
        props.setValue(undefined)
        setAssetState(selectAsset)
      }}
      onUpload={async (file: File) => {
        const filename = file.name
        setAssetState(uploading(filename))

        const uploadedPayload = await props.upload(file)

        if (!uploadedPayload) {
          return setAssetState(error(filename, 'Upload Failed'))
        }

        setAssetState(processing(filename, uploadedPayload.token))

        const createdPayload = await props.createCustomTexture(
          uploadedPayload,
          campaign.id,
          textureType,
        )

        if (!createdPayload) {
          return setAssetState(error(filename, 'Adding image failed'))
        }

        props.setValue(createdPayload)
      }}
      status={assetState}
    />
  )
}
