import _ from 'lodash'

import { AssetType } from '../store/assets/types'
import { PrintBleed, PrintResolution } from '../store/canvasPresets/types'
import { Config } from '../store/config/types'
import { DocBlocs, SceneObjectBlocs } from '../store/doc/selectors'
import {
  AssetTexture,
  ImageTextureSubtype,
  MaterialOption,
  PresetOption,
  SceneObjectPropertyBloc,
  SceneObjectPropertyEmission,
  SceneObjectPropertyTexture,
  SceneObjectPropertyType,
  UnitValue,
} from '../store/doc/types'
import { FitMode } from '../store/ui/types'
import { roundWhenNeeded } from '../utils'
import { HdriOption } from '../dto/assets'

export interface Size {
  width: number
  height: number
}

export interface CanvasSize {
  width: number
  height: number
  scale: number
}

export interface UnitVector3 {
  x: UnitValue
  y: UnitValue
  z: UnitValue
}

export interface DumbVector3 {
  x: number
  y: number
  z: number
}

export type HdriData = {
  texture: string
  rotation: number
}

export type CameraData = {
  isLocked: boolean
  fov: number
  radius: number
  target: DumbVector3
  rotation: DumbVector3
}

export enum ImageTextureNode {
  Base = 'base',
  Emissive = 'emissive',
  Normal = 'normal',
  Metallic = 'metallic',
  Roughness = 'roughness',
  Clearcoat = 'clearcoat',
}

const nodeMap: { [key: string]: ImageTextureNode | undefined } = {
  baseMap: ImageTextureNode.Base,
  emissiveMap: ImageTextureNode.Emissive,
  normalMap: ImageTextureNode.Normal,
  metallicMap: ImageTextureNode.Metallic,
  roughnessMap: ImageTextureNode.Roughness,
  clearcoatMap: ImageTextureNode.Clearcoat,
}

export type ScreenTexturePayload = {
  imageUri?: string
  material: string
  node: ImageTextureNode
  mesh: string
}

export type MeshVisibilities = {
  [key: string]: boolean
}

export type EmissionPayload = {
  value: number
  unit: string
  material: string
}

export type ScreenTexturePropertyData = {
  type: SceneObjectPropertyType.ImageTexture
  subtype: ImageTextureSubtype
  payload: ScreenTexturePayload
}

export type PresetPropertyData = {
  type: SceneObjectPropertyType.Preset
  payload: {
    visibilities: MeshVisibilities
  }
}

export type EmissionPropertyData = {
  type: SceneObjectPropertyType.Emission
  payload: EmissionPayload
}

export type MaterialPayload = {
  id: string
  values: MaterialOption['values']
}

export type MaterialPropertyData = {
  type: SceneObjectPropertyType.Material
  payload: MaterialPayload
}

export type HdriPayload = {
  id: string
  name: string
}

export type HdriPropertyData = {
  type: SceneObjectPropertyType.Hdri
  payload: HdriPayload
}

export type PropertyData =
  | undefined
  | ScreenTexturePropertyData
  | PresetPropertyData
  | EmissionPropertyData
  | MaterialPropertyData
  | HdriPropertyData

export enum ScalingMode {
  Isotropic = 'isotropic',
  Anisotropic = 'Anisotropic',
}

export type SceneObjectData = {
  uuid: string
  isLocked: boolean
  isHidden: boolean
  isSelected: boolean
  isDraggable: boolean
  hasPlaceholder: boolean
  rootUrl: string
  sceneFilename: string
  position: DumbVector3
  rotation: DumbVector3
  scale: DumbVector3
  scalingMode: ScalingMode | undefined
  properties: {
    [key: string]: PropertyData | undefined
  }
}

export type RenderData = {
  camera: CameraData
  canvasSize: CanvasSize
  bleedSize: Size | null

  background?: string
  foreground?: string
  hdri?: HdriData

  isGridVisible?: boolean

  isGizmoAdvancedMode?: boolean

  isLayoutMode: boolean

  sceneObjects: { [index: string]: SceneObjectData }
}

const MAX_SIZE = 4096

export const IN_TO_M = 0.0254
const DEG_TO_RADIAN = Math.PI / 180

const UNIT_TO_DPI: { [key: string]: number } = {
  dpi: 1,
}
const UNIT_TO_INCH: { [key: string]: number } = {
  px: 1 / 72,
  '"': 1,
  "'": 1 / 12,
  m: 1 / IN_TO_M,
  mm: 1 / IN_TO_M / 1000,
}
const UNIT_TO_M: { [key: string]: number } = {
  '"': IN_TO_M,
  "'": 12 * IN_TO_M,
  m: 1,
}
const UNIT_TO_DEGREE: { [key: string]: number } = {
  rad: 1 / DEG_TO_RADIAN,
  '°': 1,
}
const UNIT_TO_RADIAN: { [key: string]: number } = {
  rad: 1,
  '°': DEG_TO_RADIAN,
}

export const unitToPx = (input: UnitValue, resolution: UnitValue) => {
  const valueToInch = UNIT_TO_INCH[input.unit]
  if (!valueToInch) {
    throw new Error(`Not Supported 'input.unit': "${input.unit}"`)
  }

  const dpi = resolution.value * UNIT_TO_DPI[resolution.unit]
  if (!dpi) {
    throw new Error(`Not Supported 'resolution.unit': "${resolution.unit}"`)
  }

  return input.value * valueToInch * dpi
}

export const unitToInch = (input: UnitValue) => {
  const valueToInch = UNIT_TO_INCH[input.unit]
  if (!valueToInch) {
    throw new Error(`Not Supported 'input.unit': ${input.unit}`)
  }
  return input.value * valueToInch
}

export const unitToDegree = (input: UnitValue) => {
  const valueToDegree = UNIT_TO_DEGREE[input.unit]
  if (!valueToDegree) {
    throw new Error(`Not Supported 'input.unit': "${input.unit}"`)
  }

  return input.value * valueToDegree
}

export const unitToRadian = (input: UnitValue) => {
  const valueToRadian = UNIT_TO_RADIAN[input.unit]
  if (!valueToRadian) {
    throw new Error(`Not Supported 'input.unit': "${input.unit}"`)
  }

  return input.value * valueToRadian
}

export const unitFromRadian = (input: number, output: UnitValue) => {
  const valueToRadian = UNIT_TO_RADIAN[output.unit]
  if (!valueToRadian) {
    throw new Error(`Not Supported 'input.unit': "${output.unit}"`)
  }

  return roundWhenNeeded(input / valueToRadian, 2)
}

export const unitToFov = (input: UnitValue) => {
  if (input.unit !== 'mm') {
    throw new Error(`Not Supported 'input.unit': "${input.unit}"`)
  }

  // http://www.html5gamedevs.com/topic/14949-min-max-range-of-fov/
  return 2 * Math.atan(16 / input.value)
}

export const unitToM = (input: UnitValue) => {
  const valueToM = UNIT_TO_M[input.unit]
  if (!valueToM) {
    throw new Error(`Not Supported 'input.unit': "${input.unit}"`)
  }

  return input.value * valueToM
}

export const unitFromM = (input: number, output: UnitValue) => {
  const valueToM = UNIT_TO_M[output.unit]
  if (!valueToM) {
    throw new Error(`Not Supported 'output.unit': "${output.unit}"`)
  }
  return roundWhenNeeded(input / valueToM, 2)
}

const getVector3 = (
  source: UnitVector3,
  unitConverter: { (input: UnitValue): number },
): DumbVector3 => {
  return {
    x: unitConverter(source.x),
    y: unitConverter(source.y),
    z: unitConverter(source.z),
  }
}

export const getPresetMeshVisibilities = (
  presetBloc: SceneObjectPropertyBloc<SceneObjectPropertyType.Preset>,
) => {
  const visibilities: MeshVisibilities = {}

  const { options, value } = presetBloc

  _.forEach(options, ({ values }) => {
    _.forEach(values, ({ object }) => {
      visibilities[object] = false
    })
  })
  _.forEach(value.values, ({ object, visible }) => {
    visibilities[object] = visible
  })

  return visibilities
}

export const getRenderSize = (docBlocs: DocBlocs): Size => {
  const { size, print } = docBlocs.canvas
  const { resolution, bleed } = print

  if (!resolution.value || !bleed.value) {
    return {
      width: size.width.value.value,
      height: size.height.value.value,
    }
  }

  const bleedPx = unitToPx(bleed.value, resolution.value)

  return {
    width: Math.ceil(
      unitToPx(size.width.value, resolution.value) + 2 * bleedPx,
    ),
    height: Math.ceil(
      unitToPx(size.height.value, resolution.value) + 2 * bleedPx,
    ),
  }
}

const getCanvasSize = (
  isLayoutMode: boolean,
  containerSize: Size,
  renderSize: Size,
  fitMode: FitMode,
): CanvasSize => {
  if (isLayoutMode) {
    return {
      ...containerSize,
      scale: 1,
    }
  }
  const scaleX = containerSize.width / renderSize.width
  const scaleY = containerSize.height / renderSize.height
  let scale = 1

  switch (fitMode) {
    case FitMode.Fit:
      scale = Math.min(scaleX, scaleY)
      break

    case FitMode.Contain:
      scale = Math.max(scaleX, scaleY)
      break

    case FitMode.Max:
      scale = Math.min(
        scale,
        MAX_SIZE / renderSize.width,
        MAX_SIZE / renderSize.height,
      )
      break
  }

  return {
    width: Math.floor(renderSize.width * scale),
    height: Math.floor(renderSize.height * scale),
    scale,
  }
}

const getBleedSize = (
  canvasSize: CanvasSize,
  isLayoutMode: boolean,
  bleed: PrintBleed | undefined,
  resolution: PrintResolution | undefined,
): Size | null => {
  if (isLayoutMode || !bleed || !resolution) {
    return null
  }

  const bleedPx = Math.floor(unitToPx(bleed, resolution) * canvasSize.scale)

  return {
    width: Math.ceil(canvasSize.width - 2 * bleedPx + 2), // + 2 to account for 1px border
    height: Math.ceil(canvasSize.height - 2 * bleedPx + 2),
  }
}

export const getHdriUrl = (
  sceneObjectBlocs: {
    [key: string]: SceneObjectBlocs
  },
  extension: string,
): string | null => {
  const r1 = _.reduce(
    sceneObjectBlocs,
    (result: string | null, sceneObjectBloc) => {
      if (result) {
        return result
      }

      const { revision } = sceneObjectBloc
      if (!revision) {
        return null
      }

      const r2 = _.reduce(
        sceneObjectBloc.properties,
        (result: string | null, propertyBloc) => {
          if (result) {
            return result
          }
          const { property } = propertyBloc
          const { type } = property
          if (type !== SceneObjectPropertyType.Hdri) {
            return null
          }

          const value = propertyBloc.value as HdriOption
          const { assetUri } = revision
          const r3 = `${assetUri}hdri/${value.id}${extension}`
          return r3
        },
        null,
      )
      return r2
    },
    null,
  )
  return r1
}

const getSceneObject = (
  sceneObjectBloc: SceneObjectBlocs,
  uuid: string,
): SceneObjectData | void => {
  const { revision, type } = sceneObjectBloc

  if (!revision) {
    return undefined
  }
  if (type === AssetType.BaseScene) {
    return undefined
  }

  const { assetUri, assetId } = revision

  let scalingMode
  if (sceneObjectBloc.scale.x.isIsotropicScalingAvailable) {
    scalingMode = ScalingMode.Isotropic
  } else if (sceneObjectBloc.scale.x.isAnisotropicScalingAvailable) {
    scalingMode = ScalingMode.Anisotropic
  }

  return {
    uuid,
    rootUrl: assetUri,
    sceneFilename: `${assetId}.gltf`,
    isLocked: sceneObjectBloc.isLocked.value,
    isHidden: sceneObjectBloc.isHidden.value,
    isSelected: sceneObjectBloc.isSelected.value,
    isDraggable: type === AssetType.Product || type === AssetType.Fixture,
    hasPlaceholder: type === AssetType.Product || type === AssetType.Fixture,
    position: getVector3(
      {
        x: sceneObjectBloc.position.x.value,
        y: sceneObjectBloc.position.y.value,
        z: sceneObjectBloc.position.z.value,
      },
      unitToM,
    ),
    rotation: getVector3(
      {
        x: sceneObjectBloc.rotation.x.value,
        y: sceneObjectBloc.rotation.y.value,
        z: sceneObjectBloc.rotation.z.value,
      },
      unitToRadian,
    ),
    scale: {
      x: sceneObjectBloc.scale.x.value,
      y: sceneObjectBloc.scale.y.value,
      z: sceneObjectBloc.scale.z.value,
    },
    scalingMode,
    properties: _.mapValues(sceneObjectBloc.properties, propertyBloc => {
      const { property } = propertyBloc
      const { type } = property

      if (type === SceneObjectPropertyType.ImageTexture) {
        const value = propertyBloc.value as AssetTexture
        const textureProp: SceneObjectPropertyTexture =
          property as SceneObjectPropertyTexture

        // TODO define values in asset settings explicitly
        let node: ImageTextureNode =
          nodeMap[textureProp.node] || ImageTextureNode.Base
        if (textureProp.id === 'screenReflection') {
          node = ImageTextureNode.Emissive
        }

        return {
          type,
          subtype: textureProp.subtype,
          payload: {
            imageUri: value?.imageUri,
            material: textureProp.material,
            node,
            mesh: textureProp.object,
          },
        } as ScreenTexturePropertyData
      }

      if (type === SceneObjectPropertyType.Preset) {
        const value = propertyBloc.value as PresetOption

        const visibilities: MeshVisibilities = {}
        _.forEach(propertyBloc.options as PresetOption[], ({ values }) => {
          _.forEach(values, ({ object }) => {
            visibilities[object] = false
          })
        })
        _.forEach(value.values, ({ object, visible }) => {
          visibilities[object] = visible
        })

        const data: PresetPropertyData = {
          type,
          payload: {
            visibilities,
          },
        }

        return data
      }

      if (type === SceneObjectPropertyType.Emission) {
        const value = propertyBloc.value as UnitValue
        return {
          type,
          payload: {
            ...value,
            material: (property as SceneObjectPropertyEmission).material,
          },
        } as EmissionPropertyData
      }

      if (type === SceneObjectPropertyType.Material) {
        const value = propertyBloc.value as MaterialOption
        return {
          type,
          payload: {
            id: property.id,
            values: value.values,
          },
        } as MaterialPropertyData
      }

      if (type === SceneObjectPropertyType.Hdri) {
        const value = propertyBloc.value as HdriOption
        return {
          type,
          payload: {
            id: value.id,
            name: value.name,
          },
        } as HdriPropertyData
      }

      return undefined
    }),
  }
}

// TODO should use previous state as base to enable efficient reuse with immer
export const updateRenderData = (
  docBlocs: DocBlocs,
  extra: {
    config: Config
    containerSize: Size
    fitMode: FitMode
    isGizmoAdvancedMode: boolean
    isGridVisible: boolean
    isLayoutMode: boolean
    bleed: PrintBleed | undefined
    resolution: PrintResolution | undefined
  },
) => {
  const {
    containerSize,
    fitMode,
    isGizmoAdvancedMode,
    isGridVisible,
    isLayoutMode,
    bleed,
    resolution,
  } = extra

  const { camera } = docBlocs

  const renderSize = getRenderSize(docBlocs)
  const canvasSize = getCanvasSize(
    isLayoutMode,
    containerSize,
    renderSize,
    fitMode,
  )
  const bleedSize = getBleedSize(canvasSize, isLayoutMode, bleed, resolution)

  const sceneObjects = _.pickBy(
    _.mapValues(docBlocs.sceneObjects, getSceneObject),
  ) as {
    [uuid: string]: SceneObjectData
  }
  const hdriUrl = getHdriUrl(docBlocs.sceneObjects, '.env')

  const renderData: RenderData = {
    camera: {
      isLocked: isLayoutMode || camera.isLocked.value,
      fov: unitToFov(camera.focalLength.value),
      radius: unitToM(camera.distance.value),
      rotation: getVector3(
        {
          x: camera.rotation.x.value,
          y: camera.rotation.y.value,
          z: camera.rotation.z.value,
        },
        unitToRadian,
      ),
      target: getVector3(
        {
          x: camera.position.x.value,
          y: camera.position.y.value,
          z: camera.position.z.value,
        },
        unitToM,
      ),
    },
    canvasSize,
    bleedSize,

    background: docBlocs.visuals.background.value?.imageUri,
    foreground: docBlocs.visuals.foreground.value?.imageUri,
    hdri: hdriUrl
      ? {
          texture: hdriUrl,
          rotation: 0,
        }
      : undefined,

    isGridVisible,

    isGizmoAdvancedMode,

    isLayoutMode,

    sceneObjects,
  }

  return renderData
}
