import _ from 'lodash'
import type { Action as ReduxAction } from 'redux'

import type { Dispatch, GetState } from '..'
import type * as dto from '../../dto/api'
import http from '../../http'
import { saveDoc, setDoc } from '../doc/actions'
import { revisionsFromApi } from './dataTransformers'
import type { PartialDocRevision } from './types'

export type Action = LoadRevisions | LoadedRevisions | RevertRevision
export enum DocRevisionsActions {
  LoadRevisions = 'LOAD_REVISIONS',
  LoadedRevisions = 'LOADED_REVISIONS',
  RevertRevision = 'REVERT_REVISION',
}

export interface LoadRevisions extends ReduxAction<DocRevisionsActions> {
  type: DocRevisionsActions.LoadRevisions
  payload: { documentId: number }
}

export interface LoadedRevisions extends ReduxAction<DocRevisionsActions> {
  type: DocRevisionsActions.LoadedRevisions
  payload: { revisions: PartialDocRevision[] }
}

export interface RevertRevision extends ReduxAction<DocRevisionsActions> {
  type: DocRevisionsActions.RevertRevision
  payload: { revision: PartialDocRevision }
}

export const loadRevisions =
  (payload: LoadRevisions['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: DocRevisionsActions.LoadRevisions,
      payload,
    })

    const { documentId } = payload

    const response = await http.get<dto.DocumentRevisionWithStatus[]>(
      `/api/document/${documentId}/revisions`,
    )
    const revisions = revisionsFromApi(response.data, documentId)

    dispatch({
      type: DocRevisionsActions.LoadedRevisions,
      payload: { revisions },
    })
  }

export const revertRevision =
  (payload: RevertRevision['payload']) =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch({
      type: DocRevisionsActions.RevertRevision,
      payload,
    })

    const state = getState()

    const lastRevision = _.last(state.docRevisions.revisions)

    if (!lastRevision) {
      throw Error('No document revisions found!')
    }

    const { revision } = payload

    const nextRevision = _.set(
      _.cloneDeep(revision.doc),
      ['meta', 'version'],
      lastRevision.version,
    )

    dispatch(setDoc(nextRevision))
    await dispatch(saveDoc())
  }
