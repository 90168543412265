import { createSelector } from 'reselect'

import { State } from '..'

export const campaignSelector = (state: State) => state.campaign

export const campaignAssetsSelector = createSelector(
  [campaignSelector],
  campaign => campaign.assets,
)
