import { Slider, Stack } from 'office-ui-fabric-react'
import React from 'react'

import { useDebouncedValue } from '../hooks/useDebouncedValue'
import * as commonStyles from '../styles'
import { Label } from './Label'
import { SpinButton } from './SpinButton'

export interface Props {
  id: string
  label: string
  value?: number
  min?: number
  max?: number
  step?: number
  unit?: string
  error?: string
  disabled?: boolean
  isUnavailable?: boolean
  setValue: (value: number | undefined) => void
  limitLabelWidth?: boolean
}

const styles = {
  input: commonStyles.input,
  slider: {
    root: {
      flexGrow: 1,
    },
  },
  value: {
    root: {
      width: 70,
    },
  },
}

const tokens = {
  root: {
    childrenGap: 20,
  },
  input: {
    childrenGap: 10,
  },
}

export const SliderInput = (props: Props) => {
  const { id, label, unit, disabled, isUnavailable, limitLabelWidth } = props

  const [value, setValue] = useDebouncedValue<Props['value']>(
    props.value,
    props.setValue,
    100,
  )

  if (isUnavailable) {
    return null
  }

  const min = props.min
  const max = props.max
  const step = props.step || 1

  return (
    <Stack horizontal horizontalAlign="space-between" tokens={tokens.root}>
      <Label
        text={label}
        unit={unit}
        htmlFor={id}
        limitLabelWidth={limitLabelWidth}
      />
      <Stack
        horizontal
        styles={styles.input}
        tokens={tokens.input}
        disableShrink
      >
        <Slider
          styles={styles.slider}
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={value => {
            setValue(value)
          }}
          disabled={disabled}
          showValue={false}
        />
        <Stack styles={styles.value}>
          <SpinButton
            id={id}
            value={`${value}`}
            onValueChange={value => {
              setValue(Number(value))
            }}
            disabled={disabled as boolean}
            step={step}
            min={min}
            max={max}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
