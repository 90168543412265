import type React from 'react'
import { useEffect } from 'react'
import { Provider, connect } from 'react-redux'
import { type Dispatch, bindActionCreators } from 'redux'
import { ErrorBoundary } from '@sentry/react'
// import { PersistGate } from 'redux-persist/integration/react'
import { mergeStyles } from 'office-ui-fabric-react'
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons'
import { registerIcons } from 'office-ui-fabric-react/lib/Styling'

import { type State, store } from '../store'
import { loadAccount } from '../store/auth/actions'
import { isLoggedInSelector } from '../store/auth/selectors'
import type { Route } from '../store/routing/state'
import { Login } from '../pages/Login'
import { NotFound } from '../pages/NotFound'
import { Unauthorized } from '../pages/Unauthorized'
import customIcons from '../customIcons'
import { ErrorMessage } from './../components/ErrorMessage'

// Load all Fabric icons.
initializeIcons()
// Register our custom icons.
registerIcons({
  icons: customIcons,
})

mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh',
      overflow: 'hidden',
      backgroundColor: '#FFFFFF',
      // Added because SpinButton inherits the wrong font-family: https://github.com/microsoft/fluentui/issues/15362
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    },
    ':global(.ms-Stack > span.ms-layer)': {
      margin: 0,
    },
  },
})

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadAccount,
    },
    dispatch,
  )

const mapStateToProps = (state: State) => ({
  isNotFound: state.routing.isNotFound,
  isLoggedIn: isLoggedInSelector(),
  route: state.routing.route,
  isUnauthorized: state.auth.isUnauthorized,
})

type Props = StateProps & DispatchProps

const PageComponent = (props: {
  component: React.ComponentType<{ meta: Route['meta'] }>
  meta: Route['meta']
}) => {
  const PageComponent = props.component
  return <PageComponent meta={props.meta} />
}

const MainConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { route, isNotFound, isLoggedIn, isUnauthorized, loadAccount } = props
  const { meta } = route
  const { component, isIndependent } = meta

  useEffect(() => {
    const run = async () => {
      if (isLoggedIn) {
        await loadAccount()
      }
    }
    run()
  }, [isLoggedIn, loadAccount])

  if (isIndependent) {
    // Show page without other checks.
    return <PageComponent component={component} meta={meta} />
  }

  if (isNotFound) {
    return <NotFound />
  }

  if (!isLoggedIn) {
    return <Login />
  }

  if (isUnauthorized) {
    return <Unauthorized meta={{ contentId: 'areaRestricted' }} />
  }

  return <PageComponent component={component} meta={meta} />
})

export const Main = () => {
  return (
    <ErrorBoundary fallback={<ErrorMessage isFullscreen={true} />}>
      <Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
        <MainConnected />
        {/* </PersistGate> */}
      </Provider>
    </ErrorBoundary>
  )
}
