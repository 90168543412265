import _ from 'lodash'
import React, { useState } from 'react'
import { PrimaryButton, Stack } from 'office-ui-fabric-react'

import { Asset } from '../../store/doc/types'
import { ScreenTextureMappingStatus } from '../../store/campaign/types'
import {
  AssetInput,
  AssetStatus,
  error,
  preview,
  processing,
  uploading,
} from '../../components/AssetInput'
import { LayoutMode, Modal } from '../../components/Modal'
import { CompatibleProducts } from './CompatibleProducts'
import { Props as ScreenTexturesProps } from './'

type Props = {
  campaignId: number
  compatibleProducts: Asset[]
  closeModals: () => void
  uploadState: AssetStatus
  uploadedFile: File | undefined
  setUploadState: (value: AssetStatus) => void
  setUploadedFile: (value: File) => void
  createScreenTexture: ScreenTexturesProps['createScreenTexture']
  updateScreenTextureMappings: ScreenTexturesProps['updateScreenTextureMappings']
  refreshAssets: ScreenTexturesProps['refreshAssets']
}

export const CreateModal = (props: Props) => {
  const {
    campaignId,
    compatibleProducts,
    closeModals,
    uploadState,
    uploadedFile,
  } = props

  const [mappings, setMappings] = useState<ScreenTextureMappingStatus[]>(
    _.map(compatibleProducts, asset => {
      return {
        assetId: asset.id,
        isEnabled: true,
      }
    }),
  )

  return (
    <Modal
      title="Create Screen UI"
      onClose={closeModals}
      layoutMode={LayoutMode.fit}
    >
      <Stack tokens={{ childrenGap: 20 }}>
        <AssetInput
          label="Screen UI Upload"
          placeholder={['Upload']}
          onUpload={async file => {
            const filename = file.name
            props.setUploadState(uploading(filename))

            const thumbnail = URL.createObjectURL(file)
            if (!thumbnail) {
              return props.setUploadState(error(filename, 'Upload failed'))
            }
            props.setUploadedFile(file)
            props.setUploadState(preview(filename, thumbnail))
          }}
          status={uploadState}
        />
        <CompatibleProducts
          products={compatibleProducts}
          mappings={mappings}
          setMappings={setMappings}
        />
        <PrimaryButton
          disabled={!uploadedFile}
          onClick={async () => {
            if (!uploadedFile) {
              return
            }

            props.setUploadState(processing(uploadedFile.name, `${campaignId}`))

            const textureId = await props.createScreenTexture({
              campaignId,
              file: uploadedFile,
            })

            if (!textureId) {
              return error(uploadedFile.name, 'Create Screen UI Failed')
            }

            const enabledMappings = _.fromPairs(
              _.map(
                _.filter(mappings, 'isEnabled'),
                ({ assetId, isEnabled }) => [assetId, isEnabled],
              ),
            ) as { [assetId: string]: true }

            await props.updateScreenTextureMappings({
              textureId,
              mappings: enabledMappings,
            })

            props.refreshAssets()
          }}
          text="Save Screen UI"
        />
      </Stack>
    </Modal>
  )
}
