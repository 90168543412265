import { ErrorBoundary } from '@sentry/react'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { ErrorMessage } from '../../../components/ErrorMessage'
import { Sidebar, SidebarType } from '../../../containers/Sidebar'
import { State } from '../../../store'
import { sidebarVisibleSceneObjectsSelector } from '../../../store/doc/selectors'
import { SceneObjectsPanel } from './SceneObjectsPanel'
import { SettingsPanel } from './SettingsPanel'
import { TransformPanel } from './TransformPanel'

type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps

const mapStateToProps = (state: State) => ({
  sidebarVisibleSceneObjects: sidebarVisibleSceneObjectsSelector(state),
})

export const SceneObjectsSidebar = connect(mapStateToProps)((props: Props) => {
  const { sidebarVisibleSceneObjects } = props
  const selectedObjects = _.filter(
    sidebarVisibleSceneObjects,
    object => object.isSelected.value,
  )

  return (
    <Sidebar sidebarType={SidebarType.SceneObjects}>
      <ErrorBoundary fallback={<ErrorMessage />}>
        <SceneObjectsPanel />
        {selectedObjects.length === 1 && (
          <>
            <SettingsPanel selectedObject={selectedObjects[0]} />
            <TransformPanel selectedObject={selectedObjects[0]} />
          </>
        )}
      </ErrorBoundary>
    </Sidebar>
  )
})
