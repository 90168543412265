import { produce } from 'immer'
import type { Action } from '../actions'
import { DocRevisionsActions } from './actions'
import { type DocRevisions, initialState } from './state'

export const reducers = (
  currentState: DocRevisions = initialState(),
  action: Action,
): DocRevisions => {
  switch (action.type) {
    case DocRevisionsActions.LoadedRevisions:
      return produce(currentState, (nextState: DocRevisions) => {
        nextState.revisions = action.payload.revisions
      })
  }
  return currentState
}
