import { produce } from 'immer'
import type { Action } from '../actions'
import { AssetsActions } from '../assets/actions'
import { AuthActions } from '../auth/actions'
import { CampaignActions } from '../campaign/actions'
import { CampaignsActions } from '../campaigns/actions'
import { CanvasPresetsActions } from '../canvasPresets/actions'
import { DocActions } from '../doc/actions'
import { DocRevisionsActions } from '../docRevisions/actions'
import { JobsActions } from '../jobs/actions'
import { type Loaders, initialState } from './state'
import { LoadState } from './types'

export const reducers = (state = initialState(), action: Action): Loaders => {
  switch (action.type) {
    case CampaignsActions.LoadCampaigns:
      return produce(state, nextState => {
        nextState.campaignsState = LoadState.Loading
        nextState.isCampaignsReloadNeeded = false
      })

    case CampaignsActions.LoadedCampaigns:
      return produce(state, nextState => {
        nextState.campaignsState = LoadState.Loaded
      })

    case CampaignActions.CreatedCampaign:
      return produce(state, nextState => {
        nextState.isCampaignsReloadNeeded = true
      })

    case CampaignActions.LoadCampaign:
      return produce(state, nextState => {
        const { campaignId } = action.payload

        nextState.campaignState = {
          [campaignId]: LoadState.Loading,
        }
        nextState.isCampaignReloadNeeded = false
      })

    case CampaignActions.LoadedCampaign:
      return produce(state, nextState => {
        const { campaignId } = action.payload

        nextState.campaignState = {
          [campaignId]: LoadState.Loaded,
        }
        nextState.isCampaignDocsReloadNeeded = false
        nextState.isCampaignAssetsReloadNeeded = false
      })

    case CampaignActions.RefreshAssets:
      return produce(state, nextState => {
        nextState.isCampaignAssetsReloadNeeded = true
      })

    case DocActions.LoadDoc:
      return produce(state, nextState => {
        nextState.docState = LoadState.Loading
      })

    case DocActions.LoadedDoc:
      return produce(state, nextState => {
        nextState.docState = LoadState.Loaded
        nextState.isDocReloadNeeded = false
      })

    case DocActions.SavedDoc:
      return produce(state, nextState => {
        nextState.docState = LoadState.Loaded
        nextState.isCampaignDocsReloadNeeded = true
        nextState.isRevisionsReloadNeeded = true
      })

    case DocActions.SaveDocAsNew:
      return produce(state, nextState => {
        nextState.isCampaignDocsReloadNeeded = true
      })

    case DocActions.DeleteDocs:
      return produce(state, nextState => {
        nextState.docState = LoadState.Loading
      })

    case DocActions.RefreshDocs:
      return produce(state, nextState => {
        nextState.docState = LoadState.Loaded
        nextState.isCampaignDocsReloadNeeded = true
        nextState.isDocReloadNeeded = true
      })

    case JobsActions.InitiateRender:
      return produce(state, nextState => {
        nextState.isCampaignDocsReloadNeeded = true
      })

    case CanvasPresetsActions.LoadCanvasPresets:
      return produce(state, nextState => {
        nextState.isCanvasPresetsReloadNeeded = false
        nextState.canvasPresetsState = LoadState.Loading
      })

    case CanvasPresetsActions.LoadedCanvasPresets:
      return produce(state, nextState => {
        nextState.canvasPresetsState = LoadState.Loaded
      })

    case CanvasPresetsActions.ReloadCanvasPresets:
      return produce(state, nextState => {
        nextState.isCanvasPresetsReloadNeeded = true
        nextState.isCampaignReloadNeeded = true
      })

    case AssetsActions.LoadAssets:
      return produce(state, nextState => {
        nextState.assetsState = LoadState.Loading
      })

    case AssetsActions.LoadedAssets:
      return produce(state, nextState => {
        nextState.isAssetsReloadNeeded = false
        nextState.assetsState = LoadState.Loaded
      })

    case AssetsActions.ReloadAssets:
      return produce(state, nextState => {
        nextState.isAssetsReloadNeeded = true
        nextState.isCampaignAssetsReloadNeeded = true
      })

    case DocRevisionsActions.LoadRevisions:
      return produce(state, nextState => {
        nextState.revisionsState = LoadState.Loading
      })

    case DocRevisionsActions.LoadedRevisions:
      return produce(state, nextState => {
        nextState.revisionsState = LoadState.Loaded
        nextState.isRevisionsReloadNeeded = false
      })

    case AuthActions.SetUnauthorized:
      return produce(state, nextState => {
        nextState.authState = LoadState.Loaded
      })

    case AuthActions.LoadAccount:
      return produce(state, nextState => {
        nextState.authState = LoadState.Loading
      })

    case AuthActions.LoadedAccount:
      return produce(state, nextState => {
        nextState.authState = LoadState.Loaded
      })
  }

  return state
}
