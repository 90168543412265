import _ from 'lodash'
import { PrimaryButton, SelectionMode, Stack } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { preview } from '../../../components/AssetInput'
import { AssetPreview, size } from '../../../components/AssetPreview'
import { DropdownInput as DropdownInputComponent } from '../../../components/DropdownInput'
import { Modal } from '../../../components/Modal'
import { Table, defaultFilter } from '../../../components/Table'
import { TableHeader } from '../../../components/TableHeader'
import { TextGroupInput as TextGroupInputComponent } from '../../../components/TextGroupInput'
import { AssetInput } from '../../../containers/AssetInput'
import { DropdownInput } from '../../../containers/DropdownInput'
import { Panel } from '../../../containers/Panel'
import { TextGroupInput } from '../../../containers/TextGroupInput'
import { State } from '../../../store'
import { loadCanvasPresets } from '../../../store/canvasPresets/actions'
import {
  canvasMediumMap,
  canvasMediums,
  printBleeds,
  printResolutions,
} from '../../../store/canvasPresets/staticData'
import {
  CanvasPresetTypes,
  PrintBleed,
  PrintResolution,
} from '../../../store/canvasPresets/types'
import { mergeDoc, setInDoc } from '../../../store/doc/actions'
import { getDocBlocs } from '../../../store/doc/selectors'
import { Bloc, Canvas, CanvasMedium } from '../../../store/doc/types'
import { isCanvasPresetsReloadNeededSelector } from '../../../store/loaders/selectors'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  canvasPresets: state.canvasPresets,
  isCanvasPresetsReloadNeeded: isCanvasPresetsReloadNeededSelector(state),
  docBlocs: getDocBlocs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInDoc,
      loadCanvasPresets,
      mergeDoc,
    },
    dispatch,
  )

const styles = {
  customForm: {
    root: {
      paddingTop: 20,
    },
  },
  action: {
    root: {
      width: 300,
    },
  },
}

type CustomContentProps = {
  customPreset: Canvas
  setCustomPreset: (customPreset: Canvas) => void
  customSize: Bloc<any>[]
}

const CustomContent = (props: CustomContentProps) => {
  const { customPreset, setCustomPreset, customSize } = props

  const isPrint = customPreset.medium.id === canvasMediumMap.print.id
  return (
    <Stack
      tokens={{
        childrenGap: 10,
      }}
    >
      <DropdownInputComponent
        id="medium"
        label="Medium"
        options={canvasMediums}
        value={customPreset.medium}
        setValue={(value?: CanvasMedium) => {
          if (!value) {
            return
          }
          const updatedCustomPreset = {
            ...customPreset,
            medium: value,
            size: {
              width: customSize[0].value,
              height: customSize[1].value,
            },
          }

          setCustomPreset(updatedCustomPreset)
        }}
      />
      <TextGroupInputComponent
        blocs={customSize}
        id="custom-size"
        label="Size"
        labels={['Width', 'Height']}
        unit={customPreset.medium.props.unit}
        setValue={(path: string[], newValue: any) => {
          if (!newValue) {
            return
          }
          const dimension = path[0]

          if (dimension === 'width') {
            customSize[0].value = newValue
          }

          if (dimension === 'height') {
            customSize[1].value = newValue
          }
        }}
      />
      {isPrint && (
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <DropdownInputComponent
            id="resolution"
            label="Resolution"
            options={printResolutions}
            value={customPreset.print.resolution}
            setValue={(value?: PrintResolution) => {
              if (!value) {
                return
              }

              const updatedCustomPreset = {
                ...customPreset,
                print: {
                  resolution: value,
                  bleed: customPreset.print.bleed,
                },
                size: {
                  width: customSize[0].value,
                  height: customSize[1].value,
                },
              }
              setCustomPreset(updatedCustomPreset)
            }}
          />
          <DropdownInputComponent
            id="bleed"
            label="Bleed"
            options={printBleeds}
            value={customPreset.print.bleed}
            setValue={(value?: PrintBleed) => {
              if (!value) {
                return
              }

              const updatedCustomPreset = {
                ...customPreset,
                print: {
                  resolution: customPreset.print.resolution,
                  bleed: value,
                },
                size: {
                  width: customSize[0].value,
                  height: customSize[1].value,
                },
              }
              setCustomPreset(updatedCustomPreset)
            }}
          />
        </Stack>
      )}
    </Stack>
  )
}

export const SpecPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const {
    canvasPresets,
    isCanvasPresetsReloadNeeded,
    loadCanvasPresets,
    docBlocs,
    setInDoc,
  } = props

  const [selectedPivot, setSelectedPivot] = useState<string>(defaultFilter)
  const [isCanvasPresetModalOpen, setCanvasPresetModalOpen] = useState(false)

  const [customPreset, setCustomPreset] = useState<Canvas>({
    medium: canvasMediumMap.digital,
    size: {
      width: { value: 1920, unit: canvasMediumMap.digital.props.unit },
      height: { value: 1080, unit: canvasMediumMap.digital.props.unit },
    },
    print: {
      resolution: printResolutions[0],
      bleed: printBleeds[0],
    },
  })

  const customSize: Bloc<any>[] = [
    {
      path: ['width'],
      value: customPreset.size.width,
    },
    {
      path: ['height'],
      value: customPreset.size.height,
    },
  ]

  useEffect(() => {
    setCustomPreset({
      medium: docBlocs.canvas.medium.value,
      size: {
        width: docBlocs.canvas.size.width.value,
        height: docBlocs.canvas.size.height.value,
      },
      print: {
        resolution:
          docBlocs.canvas.print.resolution.value ?? printResolutions[0],
        bleed: docBlocs.canvas.print.bleed.value ?? printBleeds[0],
      },
    })
  }, [docBlocs])

  useEffect(() => {
    if (isCanvasPresetsReloadNeeded) {
      loadCanvasPresets()
    }
  }, [isCanvasPresetsReloadNeeded, loadCanvasPresets])

  const tableItems = _.map(canvasPresets, canvasPreset => ({
    id: canvasPreset.id,
    canvasPreset,
    preview: (
      <AssetPreview
        asset={size(canvasPreset.size.width, canvasPreset.size.height)}
      />
    ),
    name: canvasPreset.name,
    type: canvasPreset.medium.name,
    width: `${canvasPreset.size.width.value}${canvasPreset.size.width.unit}`,
    height: `${canvasPreset.size.height.value}${canvasPreset.size.height.unit}`,
    resolution: canvasPreset.print?.resolution.name ?? '-',
    bleed: canvasPreset.print?.bleed.name ?? '-',
  }))

  type TItem = (typeof tableItems)[0]

  const CustomAction = () => {
    return (
      <PrimaryButton
        styles={styles.action}
        onClick={() => {
          setInDoc({
            path: ['canvasPreset'],
            value: { undefined },
          })
          setInDoc({
            path: ['canvas'],
            value: {
              medium: customPreset.medium,
              size: {
                width: {
                  ...customSize[0].value,
                  unit: customPreset.medium.props.unit,
                },
                height: {
                  ...customSize[1].value,
                  unit: customPreset.medium.props.unit,
                },
              },
              print: customPreset.print,
            },
          })
          setCanvasPresetModalOpen(false)
        }}
      >
        Select
      </PrimaryButton>
    )
  }

  const filters = [
    defaultFilter,
    CanvasPresetTypes.print,
    CanvasPresetTypes.digital,
    // CanvasPresetTypes.web,
    CanvasPresetTypes.custom,
  ]

  return (
    <Panel id="spec" label="Spec">
      <AssetInput
        bloc={docBlocs.canvasPreset}
        label="Preset"
        onSelect={() => {
          setCanvasPresetModalOpen(true)
        }}
        status={preview(docBlocs.canvasPreset.value?.name ?? '', undefined, {
          width: docBlocs.canvas.size.width.value,
          height: docBlocs.canvas.size.height.value,
        })}
      />
      {isCanvasPresetModalOpen && (
        <Modal
          title="Select Spec"
          onClose={() => {
            setCanvasPresetModalOpen(false)
          }}
          actions={
            selectedPivot === CanvasPresetTypes.custom
              ? CustomAction()
              : undefined
          }
        >
          {selectedPivot === CanvasPresetTypes.custom && (
            <>
              <TableHeader
                filters={filters}
                selectedFilter={selectedPivot}
                setFilter={setSelectedPivot}
                isSearchBarHidden={true}
              />
              <Stack styles={styles.customForm}>
                <CustomContent
                  customPreset={customPreset}
                  setCustomPreset={setCustomPreset}
                  customSize={customSize}
                />
              </Stack>
            </>
          )}
          {selectedPivot !== CanvasPresetTypes.custom && (
            <Table<TItem>
              columns={[
                { key: 'preview', name: 'Preview', width: 130 },
                { key: 'name', name: 'Name', minWidth: 100 },
                { key: 'type', name: 'Type', maxWidth: 70 },
                { key: 'width', name: 'Width', maxWidth: 70 },
                { key: 'height', name: 'Height', maxWidth: 70 },
                { key: 'resolution', name: 'Resolution', width: 70 },
                { key: 'bleed', name: 'Bleed', maxWidth: 70 },
              ]}
              defaultSort={null}
              filters={filters}
              selectedFilter={selectedPivot}
              setFilter={(value: string) => setSelectedPivot(value)}
              filterColumn="type"
              items={tableItems}
              onItemClick={({ canvasPreset }) => {
                props.mergeDoc({
                  canvasPreset,
                  canvas: {
                    medium: canvasPreset.medium,
                    size: canvasPreset.size,
                    print: canvasPreset.print,
                  },
                })
                setCanvasPresetModalOpen(false)
              }}
              selectionMode={SelectionMode.none}
            />
          )}
        </Modal>
      )}

      <DropdownInput
        bloc={docBlocs.canvas.medium}
        label="Medium"
        onChanged={mediumAny => {
          const medium = mediumAny as CanvasMedium
          if (!medium) {
            return
          }
          setInDoc({
            path: ['canvas', 'size', 'width'],
            value: {
              ...docBlocs.canvas.size.width.value,
              unit: medium.props.unit,
            },
          })
          setInDoc({
            path: ['canvas', 'size', 'height'],
            value: {
              ...docBlocs.canvas.size.height.value,
              unit: medium.props.unit,
            },
          })
        }}
      />
      <TextGroupInput
        blocs={[docBlocs.canvas.size.width, docBlocs.canvas.size.height]}
        label="Size"
        unit={docBlocs.canvas.medium.value.props.unit}
        labels={['Width', 'Height']}
      />
      <DropdownInput
        bloc={docBlocs.canvas.print.resolution}
        label="Resolution"
      />
      <DropdownInput bloc={docBlocs.canvas.print.bleed} label="Bleed" />
    </Panel>
  )
})
