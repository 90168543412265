import type { PartialDocRevision } from './types'

export type DocRevisions = {
  revisions: PartialDocRevision[]
}

export const initialState = (): DocRevisions => {
  return {
    revisions: [],
  }
}
