import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'

import { Modal } from '../../../components/Modal'
import { ColumnDef, Table } from '../../../components/Table'
import { ImageInput } from '../../../containers/ImageInput'
import { Panel } from '../../../containers/Panel'
import { State } from '../../../store'
import { canvasMediumMap } from '../../../store/canvasPresets/staticData'
import { setInDoc } from '../../../store/doc/actions'
import { getDocBlocs } from '../../../store/doc/selectors'
import { UnitValue } from '../../../store/doc/types'
import { roundWhenNeeded } from '../../../utils'
import { unitToInch } from '../../../viewer/RenderData'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  docBlocs: getDocBlocs(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInDoc,
    },
    dispatch,
  )

const VisualsPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { docBlocs } = props

  const [isBackgroundModalOpen, setBackgroundModalOpen] =
    useState<boolean>(false)
  const tableItems = _.map(docBlocs.visuals.background.options, background => ({
    id: background.id,
    background,
    thumbnail: background.imageUri,
    name: background.name,
    width: `${background.width} px`,
    height: `${background.height} px`,
    created: timeago.format(background.createdAt),
  }))
  type TItem = (typeof tableItems)[0]

  const convertBleedToSizeUnits = (size: UnitValue, bleed: UnitValue) => {
    if (size.unit === bleed.unit) {
      return bleed.value
    }

    if (size.unit === '"') {
      return unitToInch(bleed)
    }
  }

  const getInputDimensionsPlaceholder = () => {
    const canvas = docBlocs.canvas
    const medium = canvas.medium.value
    const unit = medium.props.unit

    const isPrint = medium.id === canvasMediumMap.print.id

    const dpi = canvas.print.resolution.value?.value
    let width = canvas.size.width.value.value
    let height = canvas.size.height.value.value

    if (isPrint) {
      const bleed = canvas.print.bleed.value
      if (bleed) {
        const bleedValue = convertBleedToSizeUnits(canvas.size.width.value, {
          value: bleed.value,
          unit: bleed.unit,
        })

        const bleedToAdd = bleedValue ? bleedValue * 2 : 0
        width = roundWhenNeeded(width + bleedToAdd, 4)
        height = roundWhenNeeded(height + bleedToAdd, 4)
      }
    }

    return `${width}${unit} x ${height}${unit} ${isPrint ? `${dpi}dpi` : ''}`
  }

  return (
    <Panel id="visuals" label="Visuals">
      <ImageInput
        bloc={docBlocs.visuals.foreground}
        label="Foreground"
        placeholder={['No foreground content', getInputDimensionsPlaceholder()]}
        textureType={'FOREGROUND'}
        isCanvasTexture={true}
      />
      <ImageInput
        bloc={docBlocs.visuals.background}
        label="Background"
        placeholder={['No background content', getInputDimensionsPlaceholder()]}
        onSelect={() => {
          setBackgroundModalOpen(true)
        }}
        textureType={'BACKGROUND'}
        isCanvasTexture={true}
      />
      {isBackgroundModalOpen && (
        <Modal
          title="Select Background"
          onClose={() => {
            setBackgroundModalOpen(false)
          }}
        >
          <Table<TItem>
            columns={
              [
                { key: 'thumbnail', name: 'Thumbnail', width: 128 },
                { key: 'name', name: 'Name', maxWidth: 9999 },
                { key: 'width', name: 'Width', maxWidth: 70 },
                { key: 'height', name: 'Height', maxWidth: 70 },
                {
                  key: 'created',
                  name: 'Created',
                  width: 100,
                  fieldName: 'createdAgo',
                },
              ] as ColumnDef<TItem>[]
            }
            defaultSort={{ key: 'created', desc: true }}
            items={tableItems}
            onItemClick={({ background }) => {
              props.setInDoc({
                path: docBlocs.visuals.background.path,
                value: background,
              })
              setBackgroundModalOpen(false)
            }}
          />
        </Modal>
      )}
    </Panel>
  )
})

export { VisualsPanel }
