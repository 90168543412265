import type { Action as ReduxAction } from 'redux'

import type { Dispatch, GetState } from '..'
import { DocActions } from '../doc/actions'
import type { Doc } from '../doc/state'

export type Action = SnapshotDoc | UndoDoc | RedoDoc

export enum DocHistoryActions {
  SnapshotDoc = 'SNAPSHOT_DOC',
  UndoDoc = 'UNDO_DOC',
  RedoDoc = 'REDO_DOC',
}

export interface SnapshotDoc extends ReduxAction<DocHistoryActions> {
  type: DocHistoryActions.SnapshotDoc
  payload: { isInitial: boolean; doc: Doc }
}

export const snapshotDoc =
  ({ isInitial } = { isInitial: false }) =>
  (dispatch: Dispatch, getState: GetState) => {
    const doc = getState().doc

    dispatch({
      type: DocHistoryActions.SnapshotDoc,
      payload: {
        isInitial,
        doc,
      },
    })
  }

export interface UndoDoc extends ReduxAction<DocHistoryActions> {
  type: DocHistoryActions.UndoDoc
}

export const undoDoc = () => (dispatch: Dispatch, getState: GetState) => {
  const { position, stack } = getState().docHistory

  dispatch({
    type: DocHistoryActions.UndoDoc,
  })

  dispatch({
    type: DocActions.SetDoc,
    payload: stack[position - 1],
  })
}

export interface RedoDoc extends ReduxAction<DocHistoryActions> {
  type: DocHistoryActions.RedoDoc
}

export const redoDoc = () => (dispatch: Dispatch, getState: GetState) => {
  const { position, stack } = getState().docHistory

  dispatch({
    type: DocHistoryActions.RedoDoc,
  })

  dispatch({
    type: DocActions.SetDoc,
    payload: stack[position + 1],
  })
}
