import _ from 'lodash'
import { connect } from 'react-redux'

import { SliderInput as Component } from '../components/SliderInput'
import { Dispatch, State } from '../store'
import { setInDoc } from '../store/doc/actions'
import { Bloc, UnitValue } from '../store/doc/types'
import { pathToId } from '../utils'

type OwnProps = {
  bloc: Bloc<number | UnitValue>
  label: string
}

const isUnitValue = (
  value: number | UnitValue | undefined,
): value is UnitValue => !_.isNumber(value)

const isPercentage = (unit: string) => unit === '%'

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { bloc } = ownProps

  let { min, max, step } = bloc

  let value: number | undefined
  let unit: string | undefined

  if (isUnitValue(bloc.value)) {
    const blocValue = bloc.value
    value = blocValue.value
    unit = blocValue.unit

    if (isPercentage(unit)) {
      value = _.round(value * 100)
      min = 0
      max = 100
      step = 1
    }
  } else {
    value = bloc.value
    unit = undefined
  }

  return {
    id: pathToId(bloc.path),
    value,
    unit,
    min,
    max,
    step,
    disabled: bloc.isDisabled,
    isUnavailable: bloc.isUnavailable,
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { bloc } = ownProps
  return {
    setValue: (value: number | undefined = 0) => {
      let payloadValue: number | UnitValue | undefined = value
      if (isUnitValue(bloc.value)) {
        payloadValue = { ...bloc.value, value }
        if (isPercentage(payloadValue.unit)) {
          payloadValue = { ...bloc.value, value: value / 100 }
        }
      }
      dispatch(
        setInDoc({
          path: bloc.path,
          value: payloadValue,
        }),
      )
    },
  }
}

const SliderInput = connect(mapStateToProps, mapDispatchToProps)(Component)

export { SliderInput }
