import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { State } from '../../../store'
import { SceneObjectBlocs } from '../../../store/doc/selectors'
import { mergeDoc } from '../../../store/doc/actions'
import {
  sceneObjectIsotropicScalingPropertySelector,
  sceneObjectAnisotropicScalingPropertySelector,
} from '../../../store/doc/blocs/sceneObjects'
import { TextGroupInput } from '../../../containers/TextGroupInput'
import { SliderInput as SliderInputDumb } from '../../../components/SliderInput'

type OwnProps = {
  selectedObject: SceneObjectBlocs
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & DispatchProps & StateProps

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { selectedObject } = ownProps

  return {
    isotropicScalingProperty: sceneObjectIsotropicScalingPropertySelector(
      selectedObject.id,
    )(state),
    anisotropicScalingProperty: sceneObjectAnisotropicScalingPropertySelector(
      selectedObject.id,
    )(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      mergeDoc,
    },
    dispatch,
  )

export const ScaleInputDumb = (props: Props) => {
  const {
    selectedObject,
    isotropicScalingProperty,
    anisotropicScalingProperty,
    mergeDoc,
  } = props
  const { scale } = selectedObject

  if (isotropicScalingProperty) {
    return (
      <SliderInputDumb
        id="isotropicScale"
        label="Scale"
        value={scale.x.value}
        setValue={value => {
          mergeDoc({
            sceneObjects: {
              [selectedObject.id]: {
                scale: {
                  x: value,
                  y: value,
                  z: value,
                },
              },
            },
          })
        }}
        min={isotropicScalingProperty.min}
        max={isotropicScalingProperty.max}
        step={
          (isotropicScalingProperty.max - isotropicScalingProperty.min) / 100
        }
      />
    )
  }

  if (anisotropicScalingProperty) {
    return (
      <TextGroupInput
        blocs={[scale.x, scale.y, scale.z]}
        label="Scale"
        labels={['X', 'Y', 'Z']}
        unit=""
      />
    )
  }

  return null
}

export const ScaleInput = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScaleInputDumb)
