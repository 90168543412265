import * as dto from '../../dto/api'
import { AccountInfo } from './state'

export const accountFromApi = ($: dto.GetAccountInfoResp): AccountInfo => {
  return {
    id: $.id,
    email: $.email,
    name: $.displayName,
    imageUri: $.pictureUri,
    permissions: [$.role], // TODO rename
  }
}
