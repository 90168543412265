import _ from 'lodash'
import * as dto from '../../dto/api'
import { FileType, download } from '../../lib/fileUtils'
import { RenderJobStatus } from '../campaign/types'

type FileNameMap = {
  FINAL: string
  PREVIEW: string
}

const DIRS: FileNameMap = {
  FINAL: 'Final',
  PREVIEW: 'Preview',
}
const COMPOSITE_FILENAMES: FileNameMap = {
  FINAL: 'Composite',
  PREVIEW: 'Preview',
}
const THUMB_FILENAMES: FileNameMap = {
  FINAL: 'Thumbnail',
  PREVIEW: 'Thumbnail',
}
const FILENAME_SUFFIXES: FileNameMap = {
  FINAL: '',
  PREVIEW: ' PROXY',
}

const getUrl = (
  resultUri: string,
  renderType: dto.RenderType,
  fileNameMap: FileNameMap,
): string => {
  const dir = DIRS[renderType]
  const fileName = fileNameMap[renderType]
  return `${resultUri}${dir}/${fileName}_0001.png`
}

export const getThumbUrl = (resultUri: string, renderType: dto.RenderType) => {
  return getUrl(resultUri, renderType, THUMB_FILENAMES)
}

export const getCompositeUrl = (
  resultUri: string,
  renderType: dto.RenderType,
) => {
  return getUrl(resultUri, renderType, COMPOSITE_FILENAMES)
}

export const getZipUrl = (resultUri: string) => {
  return `${resultUri}Final.zip`
}

export const getRenderFilename = (
  docName: string,
  version: number | undefined,
  renderType: dto.RenderType,
) => {
  const suffix = FILENAME_SUFFIXES[renderType]
  return `${docName}${version ? ' - v' : ''}${version || ''}${suffix}`
}

export type RenderStatus = {
  status: dto.RenderJobState | string
  thumbnail?: string
  renderDuration?: string
  download?: () => void
}

export const getRenderStatus = (
  docName: string,
  version: number,
  status?: RenderJobStatus,
  renderType: dto.RenderType = 'FINAL',
): RenderStatus => {
  if (!status) {
    return {
      status: '-',
      download: undefined,
    }
  }
  const { resultUri } = status
  return {
    status: status.state ?? '-',
    thumbnail: resultUri && getThumbUrl(resultUri, renderType),
    renderDuration: getRenderDuration(status),
    download: resultUri
      ? () => {
          const imageUri = getCompositeUrl(resultUri, renderType)
          const filename = getRenderFilename(docName, version, renderType)
          download(imageUri, filename, FileType.Png)
        }
      : undefined,
  }
}

export const getRenderDuration = (status: RenderJobStatus) => {
  const { createdAt, startedAt, finishedAt, retriedAt } = status

  const now = new Date().toString()

  switch (status.state) {
    case 'SUCCEEDED':
      return getTimeDifference(retriedAt || startedAt, finishedAt)
    case 'FAILED':
      return getTimeDifference(retriedAt || startedAt, finishedAt)
    case 'STARTED':
      return `${getTimeDifference(retriedAt || startedAt, now)} ago`
    case 'QUEUED':
      return `${getTimeDifference(createdAt, now)} ago`
    default:
      return undefined
  }
}

const getTimeDifference = (startTime?: string, endTime?: string) => {
  if (!startTime || !endTime) {
    return '0s'
  }
  const difference = Date.parse(endTime) - Date.parse(startTime)
  return humanizeMillisecondsDateTime(difference)
}

const DURATION_MAPPING = {
  d: 1000 * 60 * 60 * 24,
  h: 1000 * 60 * 60,
  m: 1000 * 60,
  s: 1000,
}

// Turns `12345678` into `3h 25m 45s`.
const humanizeMillisecondsDateTime = (delta: number) => {
  let d = delta
  const durations: string[] = []
  _.forEach(DURATION_MAPPING, (duration, durationKey) => {
    if (duration <= d) {
      const count = _.floor(d / duration)
      d -= duration * count

      durations.push(`${count}${durationKey}`)
    }
  })
  if (durations.length === 0) {
    return '0s'
  }

  return _.join(durations, ' ')
}
