import {
  ContextualMenu as FabricContextualMenu,
  IContextualMenuProps,
  IStyle,
} from 'office-ui-fabric-react'
import React from 'react'

interface Props {
  contextualMenuProps: IContextualMenuProps
}

const styles = {
  contextualMenu: {
    root: {
      selectors: {
        '& .ms-ContextualMenuItem-link': {
          backgroundColor: '#F3F2F1',
          color: '#0078D4',
        },
        '& .ms-ContextualMenuItem-link:hover': {
          backgroundColor: '#FAF9F8',
          color: '#0078D4',
        },
        '& .ms-ContextualMenuItem-link.is-disabled': {
          color: '#a19f9d',
        },
        '& .is-dangerous *, & .ms-ContextualMenuItem-link.is-dangerous:hover *':
          {
            color: '#AE2828',
          },
      },
    } as IStyle,
  },
}
export const ContextualMenu = (props: Props) => {
  return (
    <FabricContextualMenu
      styles={styles.contextualMenu}
      {...props.contextualMenuProps}
    />
  )
}
