import _ from 'lodash'
import { Stack } from 'office-ui-fabric-react'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as timeago from 'timeago.js'

import * as dto from '../dto/api'
import { State } from '../store'
import { push } from '../store/routing/actions'
import { campaignIdSelector } from '../store/routing/selectors'
import {
  loadCampaign,
  reloadAssets,
  createCanvasTexture,
} from '../store/campaign/actions'
import { campaignAssetsSelector } from '../store/campaign/selectors'
import { CanvasTextureType } from '../store/doc/types'
import {
  isCampaignReloadNeededSelector,
  isCampaignAssetsReloadNeededSelector,
  campaignStateSelector,
} from '../store/loaders/selectors'
import { LoadState } from '../store/loaders/types'
import { Header } from '../containers/Header'
import { CampaignNavigation } from '../containers/Navigation'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { CommandBar, setCommandBarItems } from '../components/CommandBar'
import { Page } from '../components/Page'
import { ColumnDef, Table } from '../components/Table'
import { Modal, LayoutMode } from '../components/Modal'
import {
  AssetInput,
  AssetStatus,
  selectAsset,
  uploading,
} from '../components/AssetInput'

type OwnProps = {
  meta: {
    textureType: dto.TextureType
  }
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  campaignId: campaignIdSelector(state),
  campaignAssets: campaignAssetsSelector(state),
  isCampaignReloadNeeded: isCampaignReloadNeededSelector(state),
  isCampaignAssetsReloadNeeded: isCampaignAssetsReloadNeededSelector(state),
  campaignState: campaignStateSelector(state),
  campaign: state.campaign,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      loadCampaign,
      reloadAssets,
      createCanvasTexture,
    },
    dispatch,
  )

const CanvasTexturesDumb = (props: Props) => {
  const {
    meta,
    campaignId,
    campaignAssets,
    isCampaignReloadNeeded,
    isCampaignAssetsReloadNeeded,
    campaignState,
    campaign,
    push,
    loadCampaign,
    reloadAssets,
    createCanvasTexture,
  } = props
  const { textureType } = meta

  const label = textureType === 'FOREGROUND' ? 'Foreground' : 'Background'

  useEffect(() => {
    const run = async () => {
      if (isCampaignReloadNeeded) {
        await loadCampaign({ campaignId })
      } else if (isCampaignAssetsReloadNeeded) {
        await reloadAssets({ campaignId })
      }
    }
    run()
  }, [
    isCampaignReloadNeeded,
    campaignId,
    loadCampaign,
    isCampaignAssetsReloadNeeded,
    reloadAssets,
  ])

  const [isModalOpen, setModalOpen] = useState(false)

  const [uploadState, setUploadState] = useState<AssetStatus>(selectAsset)

  const [selectedItems, setSelectedItems] = useState<TItem[]>([])

  const tableItems = _.map(
    _.filter(
      campaignAssets.canvasTextures,
      ({ type }) =>
        type ===
        (textureType === 'FOREGROUND'
          ? CanvasTextureType.Foreground
          : CanvasTextureType.Background),
    ),
    canvasTexture => ({
      id: canvasTexture.id,
      thumbnail: canvasTexture.imageUri,
      name: canvasTexture.name,
      created: canvasTexture.createdAt,
      createdAgo: timeago.format(canvasTexture.createdAt),
    }),
  )
  type TItem = (typeof tableItems)[0]

  const defaultActionItems = [
    {
      key: 'add',
      text: `Add ${label}`,
      iconProps: {
        iconName: 'add',
      },
      className: '',
      onClick: () => {
        setModalOpen(true)
      },
    },
  ]

  const commandBarItems = setCommandBarItems(selectedItems.length, {
    none: defaultActionItems,
    single: defaultActionItems,
    multi: defaultActionItems,
  })

  const columnItems: ColumnDef<TItem>[] = [
    { key: 'thumbnail', name: 'Thumbnail', width: 128 },
    { key: 'name', name: 'Name', maxWidth: 9999 },
    { key: 'created', name: 'Created', width: 100, fieldName: 'createdAgo' },
  ]

  const breadcrumbItems = [
    {
      text: 'Home',
      key: 'home',
      onClick: () => {
        push('/')
      },
    },
    {
      text: campaign.name,
      key: 'campaign',
      onClick: () => {
        push(`/campaign/${campaignId}`)
      },
    },
    {
      text: `${label}s`,
      key: 'canvasTextures',
      onClick: () => {},
    },
  ]

  const isLoading =
    isCampaignAssetsReloadNeeded || campaignState !== LoadState.Loaded

  return (
    <>
      {isModalOpen && (
        <Modal
          title={`Add ${label}`}
          onClose={() => {
            setModalOpen(false)
          }}
          layoutMode={LayoutMode.fit}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <AssetInput
              label={`${label} Upload`}
              placeholder={['Upload']}
              onUpload={async file => {
                const filename = file.name
                setUploadState(uploading(filename))

                await createCanvasTexture({
                  campaignId,
                  file,
                  textureType,
                })

                setModalOpen(false)
                setUploadState(selectAsset)

                reloadAssets({ campaignId })
              }}
              status={uploadState}
            />
          </Stack>
        </Modal>
      )}

      <Page
        header={
          <Header breadcrumbs={<Breadcrumbs items={breadcrumbItems} />} />
        }
        sidebar={<CampaignNavigation campaignId={campaignId} />}
        commandBar={
          <CommandBar items={commandBarItems} requiresLeftOffset={true} />
        }
        body={
          <Table<TItem>
            columns={columnItems}
            defaultSort={{ key: 'created', desc: true }}
            items={tableItems}
            onSelectionChanged={setSelectedItems}
          />
        }
        isLoading={isLoading}
      />
    </>
  )
}

export const CanvasTextures = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CanvasTexturesDumb)
