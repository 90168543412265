import { DefaultButton, FontWeights, Stack, Text } from 'office-ui-fabric-react'
import React from 'react'
import { connect } from 'react-redux'
import { State } from '../store'
import { UnauthorizedType } from '../store/auth/state'
import { HOME_URL, LOG_OUT_URL } from '../utils'

interface IProps {
  meta?: {
    contentId?: UnauthorizedType
  }
}

type StateProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  account: state.auth.account,
})

type Props = IProps & StateProps

const styles = {
  wrapper: {
    root: {
      maxWidth: 400,
      width: '100%',
    },
  },
  title: {
    root: {
      color: '#323130',
      fontSize: 28,
      fontWeight: FontWeights.bold,
      textAlign: 'center',
    },
  },
  text: {
    root: {
      color: '#323130',
      fontSize: 14,
      textAlign: 'center',
    },
  },
  boldText: {
    root: {
      color: '#323130',
      fontSize: 14,
      textAlign: 'center',
      fontWeight: FontWeights.bold,
    },
  },
  buttons: {
    root: {
      maxWidth: 300,
      width: '100%',
      paddingTop: 10,
    },
  },
}

export const Unauthorized = connect(mapStateToProps)((props: Props) => {
  const { account, meta } = props

  const { email } = account

  const CONTENT_MAPPING = {
    default: {
      header: <>No Permission</>,
      text1: (
        <>
          Account <Text styles={styles.boldText}>{email}</Text> does not have
          permission to access this site.
        </>
      ),
      text2: <>Ask your manager to authorize your email address.</>,
    },
    areaRestricted: {
      header: <>No Permission</>,
      text1: (
        <>
          Account <Text styles={styles.boldText}>{email}</Text> does not have
          permission to access this area.
        </>
      ),
      text2: <>Ask your manager to authorize your email address.</>,
    },
    accountDisabled: {
      header: <>Account Disabled</>,
      text1: (
        <>
          Account <Text styles={styles.boldText}>{email}</Text> is disabled and
          no longer has access to this site.
        </>
      ),
      text2: <>Ask your manager to enable your email address.</>,
    },
    inviteExpired: {
      header: <>Invite Expired</>,
      text1: (
        <>
          Account <Text styles={styles.boldText}>{email}</Text> cannot access
          this site.
        </>
      ),
      text2: <>Ask your manager to send you another invite.</>,
    },
  }

  let content = CONTENT_MAPPING.default
  if (meta?.contentId) {
    content = CONTENT_MAPPING[meta.contentId]
  }

  return (
    <>
      <Stack verticalFill verticalAlign="center" horizontalAlign="center">
        <Stack
          styles={styles.wrapper}
          tokens={{ childrenGap: 10 }}
          horizontalAlign="center"
        >
          <Text styles={styles.title}>{content.header}</Text>
          <Text styles={styles.text}>{content.text1}</Text>
          <Text styles={styles.text}>{content.text2}</Text>
          <Stack styles={styles.buttons} tokens={{ childrenGap: 20 }}>
            {meta?.contentId === 'areaRestricted' ? (
              <DefaultButton href={HOME_URL}>Return</DefaultButton>
            ) : (
              <DefaultButton href={LOG_OUT_URL}>Log Out</DefaultButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
})
