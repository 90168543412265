import { ICommandBarItemProps } from 'office-ui-fabric-react'
import React from 'react'
import { connect } from 'react-redux'

import { Dispatch, bindActionCreators } from 'redux'
import { ActionsMenu } from '../../components/ActionsMenu'
import { State } from '../../store'
import { saveDocAsNew, toggleTemplate } from '../../store/doc/actions'
import { getDocBlocs } from '../../store/doc/selectors'
import { push } from '../../store/routing/actions'
import { campaignIdSelector } from '../../store/routing/selectors'

const mapStateToProps = (state: State) => ({
  docBlocs: getDocBlocs(state),
  route: state.routing.route,
  campaignId: campaignIdSelector(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      saveDocAsNew,
      toggleTemplate,
      push,
    },
    dispatch,
  )

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

export const DocActions = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const { docBlocs, route, campaignId, saveDocAsNew, push } = props

  const docId = docBlocs.meta.id.value
  const isTemplate = docBlocs.meta.isTemplate.value
  const fromTemplate = route.meta.fromTemplate

  const saveAsNew = async () => {
    const docMeta = await saveDocAsNew()
    push(`/campaign/${campaignId}/document/${docMeta.id}`)
  }

  const overflowActions: ICommandBarItemProps[] = [
    {
      key: 'save_as_new',
      name: 'Save As New',
      onClick: () => {
        saveAsNew()
      },
    },
    {
      key: 'is_template',
      name: isTemplate ? 'Convert to Document' : 'Convert to Template',
      disabled: !docId || fromTemplate,
      onClick: () => {
        props.toggleTemplate({ docId, value: !isTemplate })
      },
    },
  ]
  return <ActionsMenu overflowActions={overflowActions} />
})
