import _ from 'lodash'
import type { Action as ReduxAction } from 'redux'

import type * as dto from '../../dto/api'
import {
  campaignAssetsFromApi,
  campaignBaseFromApi,
  campaignDocumentsFromApi,
} from './dataTransformers'
import http from '../../http'
import { uploadImageRaw, type UploadImageRes } from '../../lib/fileUtils'
import type { Dispatch, GetState } from '..'
import { resetDoc } from '../doc/actions'
import type {
  CampaignAssets,
  CampaignBase,
  CampaignDocuments,
  CreateScreenTextureRequest,
} from './types'
import { campaignStateSelector } from '../loaders/selectors'
import { LoadState } from '../loaders/types'

export type Action =
  | CreateCampaign
  | CreatedCampaign
  | LoadCampaign
  | LoadedCampaign
  | ReloadAssets
  | ReloadDocs
  | RefreshAssets
  | ReloadRenderStatus
  | CreateCanvasTexture
  | CreateScreenTexture
  | DeleteScreenTextures
  | UpdateScreenTextureMappings
  | UpdateDeviceCampaignMappings

export enum CampaignActions {
  CreateCampaign = 'CREATE_CAMPAIGN',
  CreatedCampaign = 'CREATED_CAMPAIGN',
  LoadCampaign = 'LOAD_CAMPAIGN',
  LoadedCampaign = 'LOADED_CAMPAIGN',
  ReloadAssets = 'RELOAD_ASSETS',
  ReloadDocs = 'RELOAD_DOCS',
  RefreshAssets = 'REFRESH_ASSETS',
  ReloadRenderStatus = 'RELOAD_RENDER_STATUS',
  CreateCanvasTexture = 'CREATE_CANVAS_TEXTURE',
  CreateScreenTexture = 'CREATE_SCREEN_TEXTURE',
  DeleteScreenTextures = 'DELETE_SCREEN_TEXTURES',
  UpdateScreenTextureMappings = 'UPDATE_SCREEN_TEXTURE_MAPPINGS',
  UpdateDeviceCampaignMappings = 'UPDATE_DEVICE_CAMPAIGN_MAPPINGS',
}

export interface CreateCampaign extends ReduxAction<CampaignActions> {
  type: CampaignActions.CreateCampaign
  payload: { name: string }
}

export interface CreatedCampaign extends ReduxAction<CampaignActions> {
  type: CampaignActions.CreatedCampaign
  payload: { id: number; name: string }
}

export const createCampaign =
  (payload: CreateCampaign['payload']) => async (dispatch: Dispatch) => {
    const { name } = payload

    dispatch({
      type: CampaignActions.CreateCampaign,
      payload,
    })

    await http.post('/api/campaign', { name })

    dispatch({
      type: CampaignActions.CreatedCampaign,
      payload: {
        id: 1,
        name,
      },
    })
  }

export interface LoadCampaign extends ReduxAction<CampaignActions> {
  type: CampaignActions.LoadCampaign
  payload: { campaignId: number }
}

export interface LoadedCampaign extends ReduxAction<CampaignActions> {
  type: CampaignActions.LoadedCampaign
  payload: {
    campaignId: number
    base?: CampaignBase
    assets?: CampaignAssets
    documents?: CampaignDocuments
  }
}

export interface RefreshAssets extends ReduxAction<CampaignActions> {
  type: CampaignActions.RefreshAssets
}

export const refreshAssets = () => (dispatch: Dispatch) => {
  dispatch({
    type: CampaignActions.RefreshAssets,
  })
}

export interface ReloadAssets extends ReduxAction<CampaignActions> {
  type: CampaignActions.ReloadAssets
  payload: { campaignId: number }
}

export interface ReloadDocs extends ReduxAction<CampaignActions> {
  type: CampaignActions.ReloadDocs
  payload: { campaignId: number }
}

export interface ReloadRenderStatus extends ReduxAction<CampaignActions> {
  type: CampaignActions.ReloadRenderStatus
}

export interface CreateCanvasTexture extends ReduxAction<CampaignActions> {
  type: CampaignActions.CreateCanvasTexture
  payload: {
    campaignId: number
    file: File
    textureType: dto.TextureType
  }
}

export const createCanvasTexture =
  (payload: CreateCanvasTexture['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.CreateCanvasTexture,
      payload,
    })

    const { campaignId, file } = payload

    const uploadResp = await uploadImageRaw(
      '/api/canvas_texture/upload_url',
      file,
    )

    const data: dto.CreateCanvasTextureReq = {
      campaignId,
      textureType: payload.textureType,
      name: uploadResp.name,
      width: uploadResp.width,
      height: uploadResp.height,
      isCustom: false,
      token: uploadResp.token,
    }

    await http.post<dto.CanvasTexture>('/api/canvas_texture', data)
  }

const getCanvasPresetsResponse = () =>
  http.get<dto.GetCanvasPresetsResp>('/api/canvas_presets')

const getCampaignBaseResponse = (campaignId: number) =>
  http.get<dto.Campaign>(`/api/campaign/${campaignId}`)

const getCampaignAssetsResponse = (campaignId: number) =>
  http.get<dto.GetCampaignAssetsResp>(`/api/campaign/${campaignId}/assets`)

const getCampaignDocumentsResponse = (campaignId: number) =>
  http.get<dto.GetCampaignDocumentsResp>(
    `/api/campaign/${campaignId}/documents`,
  )

export const loadCampaign =
  (payload: LoadCampaign['payload']) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState()

    const campaignState = campaignStateSelector(state)

    const isInitial = campaignState === LoadState.NotLoading

    dispatch({
      type: CampaignActions.LoadCampaign,
      payload,
    })

    const [
      canvasPresetsResponse,
      baseResponse,
      assetsResponse,
      documentsResponse,
    ] = await Promise.all([
      getCanvasPresetsResponse(),
      getCampaignBaseResponse(payload.campaignId),
      getCampaignAssetsResponse(payload.campaignId),
      getCampaignDocumentsResponse(payload.campaignId),
    ])
    const base = campaignBaseFromApi(baseResponse.data)
    const assets = campaignAssetsFromApi(
      assetsResponse.data,
      canvasPresetsResponse.data,
    )
    const documents = campaignDocumentsFromApi(documentsResponse.data)

    dispatch({
      type: CampaignActions.LoadedCampaign,
      payload: {
        campaignId: payload.campaignId,
        base,
        assets,
        documents,
      },
    })

    // Force the new doc state now when we have the initial assets.
    if (isInitial) {
      dispatch(resetDoc())
    }
  }

export const reloadDocs =
  (payload: ReloadDocs['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.ReloadDocs,
      payload,
    })

    const response = await getCampaignDocumentsResponse(payload.campaignId)
    const documents = campaignDocumentsFromApi(response.data)

    dispatch({
      type: CampaignActions.LoadedCampaign,
      payload: {
        campaignId: payload.campaignId,
        documents,
      },
    })
  }

export const reloadAssets =
  (payload: ReloadAssets['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.ReloadAssets,
      payload,
    })

    const [canvasPresetsResponse, assetsResponse] = await Promise.all([
      getCanvasPresetsResponse(),
      getCampaignAssetsResponse(payload.campaignId),
    ])

    const assets = campaignAssetsFromApi(
      assetsResponse.data,
      canvasPresetsResponse.data,
    )

    dispatch({
      type: CampaignActions.LoadedCampaign,
      payload: {
        campaignId: payload.campaignId,
        assets,
      },
    })
  }

export interface CreateScreenTexture extends ReduxAction<CampaignActions> {
  type: CampaignActions.CreateScreenTexture
  payload: CreateScreenTextureRequest
}

export const createScreenTexture =
  (payload: CreateScreenTexture['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.CreateScreenTexture,
      payload,
    })

    const { campaignId, file } = payload

    if (!file) {
      return undefined
    }

    let uploadResp: UploadImageRes
    try {
      uploadResp = await uploadImageRaw('/api/device_texture/upload_url', file)
    } catch (error) {
      return undefined
    }

    if (!uploadResp) {
      return undefined
    }

    const req: dto.CreateDeviceTextureReq = {
      campaignId,
      name: uploadResp.name,
      width: uploadResp.width,
      height: uploadResp.height,
      isCustom: false,
      token: uploadResp.token,
    }

    let texture
    try {
      texture = await http.post<dto.IdResp>('/api/device_texture', req)
    } catch (error) {
      return undefined
    }

    const textureId = texture.data.id

    if (!textureId) {
      return undefined
    }

    return textureId
  }

export interface UpdateScreenTextureMappings
  extends ReduxAction<CampaignActions> {
  type: CampaignActions.UpdateScreenTextureMappings
  payload: {
    textureId: number
    mappings: { [assetId: string]: boolean }
  }
}

export const updateScreenTextureMappings =
  (payload: UpdateScreenTextureMappings['payload']) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.UpdateScreenTextureMappings,
      payload,
    })

    await Promise.all(
      _.map(payload.mappings, (isSelected, assetId) => {
        const requestData = {
          deviceId: Number(assetId),
          deviceTextureId: payload.textureId,
        }

        if (isSelected) {
          return http.post('/api/device_texture/mapping', requestData)
        }
        return http.delete('/api/device_texture/mapping', { data: requestData })
      }),
    )
  }

export interface DeleteScreenTextures extends ReduxAction<CampaignActions> {
  type: CampaignActions.DeleteScreenTextures
  payload: { screenTextureIds: string[] }
}

export const deleteScreenTextures =
  (payload: DeleteScreenTextures['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.DeleteScreenTextures,
      payload,
    })

    const { screenTextureIds } = payload

    const promises = _.map(screenTextureIds, async screenTextureId => {
      return http.delete(`/api/device_texture/${screenTextureId}`)
    })

    await Promise.all(promises)
  }

export interface UpdateDeviceCampaignMappings
  extends ReduxAction<CampaignActions> {
  type: CampaignActions.UpdateDeviceCampaignMappings
  payload: {
    assetId: number
    mappings: { [campaignId: string]: boolean }
  }
}

export const updateDeviceCampaignMappings =
  (payload: UpdateDeviceCampaignMappings['payload']) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: CampaignActions.UpdateDeviceCampaignMappings,
      payload,
    })

    await Promise.all(
      _.map(payload.mappings, (isSelected, campaignId) => {
        const requestData = {
          deviceId: payload.assetId,
          campaignId: Number(campaignId),
        }

        if (isSelected) {
          return http.post('/api/device_campaign/mapping', requestData)
        }
        return http.delete('/api/device_campaign/mapping', {
          data: requestData,
        })
      }),
    )
  }
