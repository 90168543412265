import { createSelector } from 'reselect'

import { State } from '..'

const docHistorySelector = (state: State) => state.docHistory

export const isDocUndoAvailableSelector = createSelector(
  docHistorySelector,
  ({ position }) => position > 0,
)

export const isDocRedoAvailableSelector = createSelector(
  docHistorySelector,
  ({ position, stack }) => position < stack.length - 1,
)
