import {
  IStyle,
  Pivot,
  PivotItem,
  Stack,
  ICommandBarItemProps,
  IButtonStyles,
} from 'office-ui-fabric-react'
import React, { useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import screenfull from 'screenfull'

import { CommandBar } from '../../components/CommandBar'
import { TooltipItem } from '../../components/TooltipItem'
import { State } from '../../store'
import { saveDoc, setInDoc } from '../../store/doc/actions'
import { getDocBlocs } from '../../store/doc/selectors'
import { redoDoc, undoDoc } from '../../store/docHistory/actions'
import {
  isDocRedoAvailableSelector,
  isDocUndoAvailableSelector,
} from '../../store/docHistory/selectors'
import { push } from '../../store/routing/actions'
import {
  selectPivot,
  toggleFullscreen,
  toggleGizmoAdvancedMode,
  toggleIsGridVisible,
} from '../../store/ui/actions'
import { EditorView } from '../../store/ui/types'
import { getIsMac } from '../../utils'

const styles = {
  wrapper: {
    root: {
      height: 41,
      minHeight: 41,
      width: '100%',
      backgroundColor: '#F3F2F1',
      borderBottom: '1px solid #EDEBE9',
      paddingLeft: 0,
    } as IStyle,
  },
  pivot: {
    root: {
      height: 40,
      backgroundColor: '#F3F2F1',
      paddingLeft: 0,
      paddingRight: 0,
      selectors: {
        '& .ms-Pivot-link': {
          backgroundColor: '#F3F2F1',
          color: '#0078D4',
          height: 41,
        },
        '& .ms-Pivot-link:hover': {
          backgroundColor: '#FAF9F8',
          color: '#0078D4',
        },
      },
    } as IStyle,
  },
  commandBar: {
    root: {
      width: 'calc(100vw / 2 - 160px)',
    },
  },
  pivotBar: {
    root: {
      width: 300,
    },
  },
  toggleBar: {
    root: {
      width: 'calc(100vw / 2 - 160px)',
    },
  },
}

const DocumentCommandBar = (props: Props) => {
  const {
    isSaving,
    isDocValid,
    docBlocs,
    setSaving,
    saveDoc,
    push,
    campaign,
    isDocUndoAvailable,
    undoDoc,
    isDocRedoAvailable,
    redoDoc,
    isNewDoc,
    setErrorVisible,
  } = props

  const docId = docBlocs.meta.id.value
  const isTemplate = docBlocs.meta.isTemplate.value

  const hasChangesToSave = isNewDoc || isDocUndoAvailable
  const shouldDisableSave = isSaving || !hasChangesToSave

  const saveDocHandler = useMemo(
    () => () => {
      const run = async () => {
        if (isSaving) {
          return
        }

        if (!isDocValid) {
          setErrorVisible(true)
          return
        }

        setSaving(true)
        const docMeta = await saveDoc()
        setSaving(false)

        if (!docMeta.error) {
          push(`/campaign/${campaign.id}/document/${docMeta.id}`)
        }
      }

      run()
    },
    [isDocValid, setErrorVisible, isSaving, setSaving, saveDoc, push, campaign],
  )

  const controlKey = getIsMac() ? '⌘' : 'Ctrl'

  useHotkeys(
    `${controlKey}+s`,
    (event: Event) => {
      event.preventDefault()
      if (shouldDisableSave) {
        return
      }
      saveDocHandler()
    },
    {},
    [shouldDisableSave, saveDocHandler],
  )

  useHotkeys(
    `${controlKey}+z`,
    (event: Event) => {
      event.preventDefault()
      if (!isDocUndoAvailable) {
        return
      }
      undoDoc()
    },
    {},
    [isDocUndoAvailable, undoDoc],
  )

  useHotkeys(
    `${controlKey}+shift+z`,
    (event: Event) => {
      event.preventDefault()
      if (!isDocRedoAvailable) {
        return
      }
      redoDoc()
    },
    {},
    [isDocRedoAvailable, redoDoc],
  )

  return (
    <CommandBar
      items={[
        {
          key: 'save_document',
          onRenderIcon: () => (
            <TooltipItem
              itemName={`Save ${isTemplate ? 'Template' : 'Document'}`}
              iconName="save"
              tooltip={`${controlKey}+S`}
              isDisabled={shouldDisableSave}
            />
          ),
          disabled: shouldDisableSave,
          onClick: () => saveDocHandler(),
        },
        {
          key: 'undo',
          onRenderIcon: () => (
            <TooltipItem
              itemName="Undo"
              iconName="undo"
              tooltip={`${controlKey}+Z`}
              isDisabled={!isDocUndoAvailable}
            />
          ),
          disabled: !isDocUndoAvailable,
          onClick: () => undoDoc(),
        },
        {
          key: 'redo',
          onRenderIcon: () => (
            <TooltipItem
              itemName="Redo"
              iconName="redo"
              tooltip={`${controlKey}+Shift+Z`}
              isDisabled={!isDocRedoAvailable}
            />
          ),
          disabled: !isDocRedoAvailable,
          onClick: () => redoDoc(),
        },
        {
          key: 'version_history',
          text: 'Version History',
          iconProps: {
            iconName: 'package',
          },
          disabled: !docId,
          onClick: () => {
            push(`/campaign/${campaign.id}/document/${docId}/versions`)
          },
        },
      ]}
    />
  )
}

const PivotBar = (props: Props) => {
  return (
    <Pivot
      selectedKey={props.ui.selectedPivot}
      onLinkClick={(item: any) => {
        props.selectPivot(item.props.itemKey)
      }}
      headersOnly={true}
      styles={styles.pivot}
    >
      <PivotItem headerText="Plan View" itemKey={EditorView.PlanView} />
      <PivotItem
        headerText="Preview Render"
        itemKey={EditorView.PreviewRender}
      />
      <PivotItem headerText="Proxy Render" itemKey={EditorView.ProxyRender} />
      <PivotItem headerText="Final Render" itemKey={EditorView.FinalRender} />
    </Pivot>
  )
}

type OwnProps = {
  isDocValid: boolean
  isSaving: boolean
  setSaving: (isSaving: boolean) => void
  setErrorVisible: (isErrorVisible: boolean) => void
}

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = OwnProps & StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  ui: state.ui,
  docBlocs: getDocBlocs(state),
  campaign: state.campaign,
  isDocUndoAvailable: isDocUndoAvailableSelector(state),
  isDocRedoAvailable: isDocRedoAvailableSelector(state),
  isNewDoc: state.routing.route.params.documentId === undefined,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      saveDoc,
      toggleFullscreen,
      setInDoc,
      selectPivot,
      undoDoc,
      redoDoc,
      toggleGizmoAdvancedMode,
      toggleIsGridVisible,
    },
    dispatch,
  )

const ToggleBar = (props: Props) => {
  const { isFullscreenMode, isGizmoAdvancedMode, viewport, isGridVisible } =
    props.ui

  const viewportWidth = viewport.width

  const grid = {
    iconName: 'GridViewSmall',
    name: isGridVisible ? 'Hide Grid' : 'Show Grid',
  }
  const fullscreen = {
    iconName: isFullscreenMode ? 'BackToWindow' : 'FullScreen',
    name: isFullscreenMode ? 'Exit Fullscreen' : 'Enter Fullscreen',
  }
  const controls = {
    iconName: isGizmoAdvancedMode ? 'controls-advanced' : 'controls-simple',
    name: isGizmoAdvancedMode ? 'Advanced Controls' : 'Easy Controls',
  }

  let toggleItems: ICommandBarItemProps[] = [
    {
      key: 'gizmo',
      text: controls.name,
      iconProps: {
        iconName: controls.iconName,
      },
      onClick: () => {
        props.toggleGizmoAdvancedMode()
      },
      buttonStyles: {
        icon: { width: 20, height: 20, lineHeight: 20 },
      } as IButtonStyles,
    },
    {
      key: 'grid',
      text: grid.name,
      iconProps: {
        iconName: grid.iconName,
      },
      iconOnly: true,
      onClick: () => {
        props.toggleIsGridVisible()
      },
    },
  ]

  if (screenfull.isEnabled) {
    toggleItems = toggleItems.concat([
      {
        key: 'fullscreen',
        text: fullscreen.name,
        iconProps: {
          iconName: fullscreen.iconName,
        },
        iconOnly: true,
        onClick: () => {
          props.toggleFullscreen()
        },
      },
    ])
  }
  return (
    <CommandBar items={toggleItems} disableCollapse={viewportWidth > 900} />
  )
}

export const EditorBar = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  return (
    <Stack horizontal horizontalAlign="space-between" styles={styles.wrapper}>
      <Stack styles={styles.commandBar}>
        <DocumentCommandBar {...props} />
      </Stack>
      <Stack styles={styles.pivotBar} horizontalAlign="center">
        <PivotBar {...props} />
      </Stack>
      <Stack horizontal horizontalAlign="end" styles={styles.toggleBar}>
        <Stack>
          <ToggleBar {...props} />
        </Stack>
      </Stack>
    </Stack>
  )
})
