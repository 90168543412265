import React from 'react'

const customIcons = {
  'controls-simple': (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Controls Simple Icon</title>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.0921 33.3157V46.7229C47.0921 48.5012 45.8715 49.4687 45.2613 49.7301C44.651 49.9916 33.5187 56.4337 33.5187 56.4337C33.0136 56.747 32.5086 56.8723 31.9404 56.8723C31.4353 56.8723 30.8672 56.747 30.3621 56.4337L18.6827 49.7301C18.0651 49.4687 16.8469 48.5012 16.915 46.7229V33.3157C16.915 32.188 17.4832 31.1229 18.4933 30.559L30 24V16L27.458 15.8988C27.0161 15.8988 26.6373 15.6482 26.3848 15.2723C26.1323 14.8964 26.1323 14.3952 26.3848 14.0193L30.9303 6.56386C31.3722 5.81205 32.6349 5.81205 33.0768 6.56386L37.6223 13.9566C37.8117 14.3325 37.8117 14.8337 37.6223 15.2096C37.4329 15.5855 36.991 15.8361 36.549 15.8361L34 16V24L45.5138 30.559C46.4608 31.1229 47.0921 32.188 47.0921 33.3157ZM30 28.0171V31C30 32.1046 30.8954 33 32 33C33.1046 33 34 32.1046 34 31V28.0003L41.789 32.4386L32.0035 38.0771L22.2181 32.5012L30 28.0171ZM30.2359 41.0843V52.2988L20.4504 46.7229V35.5084L30.2359 41.0843ZM43.5567 35.5711L33.7712 41.147V52.3614L43.5567 46.7855V35.5711Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),

  'controls-advanced': (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Controls Advanced Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6351 48.3518L61.865 55.9952C62.0544 56.3711 62.0544 56.8096 61.8019 57.1855C61.5493 57.5614 61.1705 57.812 60.7286 57.812L51.9533 58C51.5113 58 51.1325 57.7494 50.88 57.3735C50.6275 56.9976 50.6275 56.4964 50.88 56.1205L52.2689 53.7398L45.2613 49.7301L33.5187 56.4337C33.0136 56.747 32.5086 56.8723 31.9404 56.8723C31.4353 56.8723 30.8671 56.747 30.3621 56.4337L18.6827 49.7301L11.675 53.7398L13.0639 56.1205C13.3164 56.4964 13.3164 56.9976 13.0639 57.3735C12.8114 57.7494 12.4326 58 11.9907 58L3.21529 57.812C2.77337 57.812 2.33144 57.5614 2.14205 57.1855C1.95265 56.8096 1.95265 56.3711 2.14205 55.9952L6.3719 48.3518C6.56129 47.9759 7.00322 47.7253 7.44514 47.7253C7.88707 47.7253 8.26586 47.9759 8.51839 48.3518L9.90729 50.7325L16.915 46.7229V33.3157C16.915 32.188 17.4831 31.1229 18.4933 30.559L30 24V16L27.458 15.8988C27.0161 15.8988 26.6373 15.6482 26.3848 15.2723C26.1322 14.8964 26.1322 14.3952 26.3848 14.0193L30.9303 6.56386C31.3722 5.81205 32.6348 5.81205 33.0768 6.56386L37.6223 13.9566C37.8117 14.3325 37.8117 14.8337 37.6223 15.2096C37.4329 15.5855 36.991 15.8361 36.549 15.8361L34 16V24L45.5138 30.559C46.4608 31.1229 47.0921 32.188 47.0921 33.3157V46.7229L54.0998 50.7325L55.4887 48.3518C55.6781 47.9759 56.12 47.7253 56.5619 47.7253C57.0038 47.7253 57.4458 47.9759 57.6351 48.3518ZM30 28.0171V31C30 32.1046 30.8954 33 32 33C33.1046 33 34 32.1046 34 31V28.0003L41.789 32.4386L32.0035 38.0771L22.2181 32.5012L30 28.0171ZM30.2358 41.0843L20.4504 35.5084V44.7113L23.3868 43.0159C24.2128 42.5391 25.2689 42.822 25.7457 43.648C26.2226 44.4739 25.9396 45.53 25.1137 46.0069L22.1732 47.7046L30.2358 52.2988V41.0843ZM43.5567 35.5711L33.7712 41.147V52.3614L41.8212 47.7745L38.4955 45.8544C37.6696 45.3776 37.3866 44.3214 37.8635 43.4955C38.3403 42.6696 39.3964 42.3866 40.2224 42.8634L43.5567 44.7885V35.5711Z"
        fill="currentColor"
      />
    </svg>
  ),
}

export default customIcons
