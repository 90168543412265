import _ from 'lodash'
import {
  IStyle,
  Pivot,
  PivotItem,
  SearchBox,
  Stack,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'
import { mediaQuery } from '../styles'

type Props = {
  searchQuery?: string
  setSearchQuery?: (query: string) => void
  setFilter?: (filter: string) => void
  filters?: string[]
  selectedFilter?: string
  isSearchBarHidden?: boolean
}

export const getStyles = memoizeFunction((filters?: string[]) => {
  return {
    header: {
      root: {
        borderBottom: filters ? '1px solid #EDEBE9' : undefined,
        marginBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        selectors: {
          [mediaQuery.isTabletOrMobile]: {
            border: 'none',
            flexDirection: 'column',
          },
        },
      } as IStyle,
    },
    pivot: {
      root: {
        height: 42,
        zIndex: 1,
        selectors: {
          [mediaQuery.isTabletOrMobile]: {
            borderBottom: '1px solid #EDEBE9',
            marginBottom: 10,
          },
        },
      } as IStyle,
    },
    searchBox: {
      container: {
        root: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        } as IStyle,
      },
      placement: {
        root: {
          width: 360,
          marginRight: 10,
          selectors: {
            [mediaQuery.isTabletOrMobile]: {
              width: '100%',
              margin: 'auto',
            },
          },
        } as IStyle,
      },
    },
  }
})

export const TableHeader = (props: Props) => {
  const {
    filters,
    setFilter,
    selectedFilter,
    searchQuery,
    setSearchQuery,
    isSearchBarHidden,
  } = props

  const styles = getStyles(filters)

  return (
    <Stack horizontal styles={styles.header}>
      {filters && setFilter && (
        <Pivot
          selectedKey={selectedFilter}
          onLinkClick={(item?: PivotItem) => {
            if (!item) {
              return
            }
            const props = item.props
            const filterValue = props.itemKey ?? ''
            setFilter(filterValue)
          }}
          headersOnly={true}
          styles={styles.pivot}
        >
          {_.map(filters, filter => {
            if (!filter) {
              return
            }

            return (
              <PivotItem key={filter} itemKey={filter} headerText={filter} />
            )
          })}
        </Pivot>
      )}
      {!isSearchBarHidden && setSearchQuery && (
        <Stack styles={styles.searchBox.container} horizontalAlign="end">
          <SearchBox
            styles={styles.searchBox.placement}
            placeholder="Search"
            onChange={(_, term: string = '') => setSearchQuery(term)}
            value={searchQuery}
          />
        </Stack>
      )}
    </Stack>
  )
}
