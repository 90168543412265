import _ from 'lodash'
import { IStyle, Stack } from 'office-ui-fabric-react'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import { Breadcrumbs } from '../../components/Breadcrumbs'
import { BodyMode, Page } from '../../components/Page'
import { Preview } from '../../components/Preview'
import { Header } from '../../containers/Header'
import { State } from '../../store'
import { loadCampaign, reloadAssets } from '../../store/campaign/actions'
import { loadDoc, mergeDoc, resetDoc, setInDoc } from '../../store/doc/actions'
import { getDocBlocs, DocBlocs } from '../../store/doc/selectors'
import {
  isCampaignReloadNeededSelector,
  isCampaignAssetsReloadNeededSelector,
  isDocReloadNeededSelector,
  campaignStateSelector,
} from '../../store/loaders/selectors'
import { LoadState } from '../../store/loaders/types'
import { push } from '../../store/routing/actions'
import {
  campaignIdSelector,
  documentIdSelector,
} from '../../store/routing/selectors'
import { EditorView } from '../../store/ui/types'
import { DocSidebar } from './DocSidebar'
import { EditorBar } from './EditorBar'
import { LivePreview } from './LivePreview'
import { RemoteRender } from './RemoteRender'
import { SceneObjectsSidebar } from './SceneObjectsSidebar'

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = StateProps & DispatchProps

const mapStateToProps = (state: State) => ({
  route: state.routing.route,
  campaignId: campaignIdSelector(state),
  documentId: documentIdSelector(state),
  campaign: state.campaign,
  docBlocs: getDocBlocs(state),
  isCampaignReloadNeeded: isCampaignReloadNeededSelector(state),
  isCampaignAssetsReloadNeeded: isCampaignAssetsReloadNeededSelector(state),
  isDocReloadNeeded: isDocReloadNeededSelector(state),
  loaders: state.loaders,
  campaignState: campaignStateSelector(state),
  selectedPivot: state.ui.selectedPivot,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      resetDoc,
      setInDoc,
      mergeDoc,
      loadCampaign,
      reloadAssets,
      loadDoc,
    },
    dispatch,
  )

const useDocumentTemplateEffect = (props: Props) => {
  const { docBlocs, setInDoc, mergeDoc, route } = props
  const { isNewTemplate, fromTemplate } = route.meta
  const { path, value } = docBlocs.meta.isTemplate

  useEffect(() => {
    if (isNewTemplate && !value) {
      setInDoc({ path, value: true })
    }

    if (fromTemplate && value) {
      mergeDoc({
        meta: {
          name: '',
          version: 0,
          isTemplate: false,
        },
      })
    }
  }, [fromTemplate, isNewTemplate, mergeDoc, path, props, setInDoc, value])
}

type UpdateRevisionEffectArgs = {
  documentId: number
  isLoading: boolean
  docBlocs: DocBlocs
  mergeDoc: Props['mergeDoc']
}

const useUpdateRevisionEffect = ({
  documentId,
  isLoading,
  docBlocs,
  mergeDoc,
}: UpdateRevisionEffectArgs) => {
  const [isRevisionUpdateRun, setRevisionUpdateRun] = useState(false)

  useEffect(() => {
    const run = async () => {
      if (isRevisionUpdateRun) {
        return
      }
      if (!documentId || isLoading) {
        return
      }

      setRevisionUpdateRun(true)

      const outdatedRevisions = _.pickBy(
        docBlocs.sceneObjects,
        ({ revision, latestRevision }) =>
          !!(revision && latestRevision && revision.id !== latestRevision.id),
      )

      if (_.isEmpty(outdatedRevisions)) {
        return
      }

      mergeDoc({
        sceneObjects: _.mapValues(outdatedRevisions, ({ latestRevision }) => ({
          revisionId: latestRevision!.id,
        })),
      })
    }

    run()
  }, [
    documentId,
    isLoading,
    docBlocs,
    isRevisionUpdateRun,
    setRevisionUpdateRun,
    mergeDoc,
  ])
}

export const Doc = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: Props) => {
  const {
    docBlocs,
    campaign,
    selectedPivot,
    campaignId,
    documentId,
    isCampaignReloadNeeded,
    isCampaignAssetsReloadNeeded,
    isDocReloadNeeded,
    loaders,
    campaignState,
    loadCampaign,
    reloadAssets,
    loadDoc,
    resetDoc,
    mergeDoc,
  } = props

  const styles = {
    stack: {
      root: {
        overflowY: 'hidden',
      } as IStyle,
    },
  }

  const isLivePreviewRender = _.includes(
    [EditorView.PlanView, EditorView.PreviewRender],
    selectedPivot,
  )
  const isProxyPreviewRender = _.includes(
    [EditorView.ProxyRender, EditorView.FinalRender],
    selectedPivot,
  )

  // Always render LivePreview component so it doesn't lose RenderState & canvas.
  const isLivePreviewEverRendererRef = useRef<boolean>()
  if (isLivePreviewRender) {
    isLivePreviewEverRendererRef.current = true
  }

  // Load campaign and its assets when needed.
  useEffect(() => {
    if (isCampaignReloadNeeded) {
      loadCampaign({ campaignId })
    } else if (isCampaignAssetsReloadNeeded) {
      reloadAssets({ campaignId })
    }
  }, [
    isCampaignReloadNeeded,
    isCampaignAssetsReloadNeeded,
    campaignId,
    loadCampaign,
    reloadAssets,
  ])

  // Load doc when needed
  useEffect(() => {
    if (isDocReloadNeeded) {
      loadDoc({ campaignId, documentId })
    }
  }, [isDocReloadNeeded, campaignId, documentId, loadDoc])

  // Reset doc when component unmounts so next render starts with clean state.
  useEffect(() => {
    return () => {
      resetDoc()
    }
  }, [resetDoc])

  const [isSaving, setSaving] = useState<boolean>(false)
  const [isErrorVisible, setErrorVisible] = useState<boolean>(false)

  useDocumentTemplateEffect(props)

  const isLoading =
    campaignState !== LoadState.Loaded ||
    (!!documentId && loaders.docState !== LoadState.Loaded)

  useUpdateRevisionEffect({
    documentId,
    isLoading,
    docBlocs,
    mergeDoc,
  })

  const version = docBlocs.meta.version.value

  const isDocValid = !docBlocs.meta.name.error

  const isTemplate = docBlocs.meta.isTemplate.value

  const renderPreviews = (width: number, height: number) => {
    if (isLoading) {
      return null
    }

    const renderType =
      selectedPivot === EditorView.ProxyRender ? 'PREVIEW' : 'FINAL'

    return (
      <>
        {isLivePreviewEverRendererRef.current && (
          <LivePreview
            containerWidth={width}
            containerHeight={height}
            isVisible={isLivePreviewRender}
          />
        )}
        {isProxyPreviewRender && (
          <RemoteRender
            containerWidth={width}
            containerHeight={height}
            documentId={documentId}
            version={version}
            renderType={renderType}
            docError={docBlocs.meta.name.error}
            setSaving={setSaving}
          />
        )}
      </>
    )
  }

  return (
    <Page
      bodyMode={BodyMode.noPadding}
      header={
        <Header
          breadcrumbs={
            <Breadcrumbs
              items={[
                {
                  text: 'Home',
                  key: 'home',
                  onClick: () => {
                    props.push('/')
                  },
                },
                {
                  text: campaign.name,
                  key: 'campaign',
                  onClick: () => {
                    props.push(`/campaign/${campaignId}`)
                  },
                },
                {
                  text: docBlocs.meta.name.value
                    ? docBlocs.meta.name.value
                    : `New ${isTemplate ? 'Template' : 'Document'}`,
                  key: 'document',
                  onClick: () => {},
                },
              ]}
            />
          }
        />
      }
      commandBar={
        <EditorBar
          isDocValid={isDocValid}
          isSaving={isSaving}
          setSaving={setSaving}
          setErrorVisible={setErrorVisible}
        />
      }
      body={
        <Stack grow horizontal styles={styles.stack}>
          <DocSidebar
            isErrorVisible={isErrorVisible}
            setErrorVisible={setErrorVisible}
          />
          <Preview>{renderPreviews}</Preview>
          <SceneObjectsSidebar />
        </Stack>
      }
      isLoading={isLoading}
    />
  )
})
