import { Stack, TextField } from 'office-ui-fabric-react'
import React from 'react'

import { useDebouncedValue } from '../hooks/useDebouncedValue'
import * as commonStyles from '../styles'
import { Label } from './Label'

export interface Props {
  id: string
  label: string
  value: string
  error?: string
  disabled?: boolean
  autoFocus?: boolean
  onBlur?: () => void
  setValue: (value: string) => void
}

const styles = {
  input: commonStyles.input,
}

const tokens = {
  childrenGap: 20,
}

export const TextInput = (props: Props) => {
  const [value, setValue] = useDebouncedValue<Props['value']>(
    props.value,
    props.setValue,
  )

  return (
    <Stack horizontal horizontalAlign="space-between" tokens={tokens}>
      <Label htmlFor={props.id} text={props.label} />
      <Stack styles={styles.input}>
        <TextField
          id={props.id}
          value={value}
          onChange={(_: any, value: string | undefined) => {
            setValue(value ?? '')
          }}
          errorMessage={props.error}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          onBlur={props.onBlur}
        />
      </Stack>
    </Stack>
  )
}
