import { Action as ReduxAction } from 'redux'
import { Config } from './types'

export type Action = SetConfig

export enum ConfigActions {
  SetConfig = 'SET_CONFIG',
}

export interface SetConfig extends ReduxAction<ConfigActions> {
  type: ConfigActions.SetConfig
  payload: Config
}
