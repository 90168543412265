import _ from 'lodash'
import { createSelector } from 'reselect'

import { assertNever } from '../../../assertNever'
import { campaignSelector } from '../../campaign/selectors'
import {
  AssetTexture,
  ImageTextureSubtype,
  PresetOption,
  SceneObjectProperty,
  SceneObjectPropertyType,
  UnitValue,
  SceneObjectPropertyIsotropicScaling,
  SceneObjectPropertyAnisotropicScaling,
} from '../types'
import { docSelector } from './doc'

const sceneObjectSelector = (uuid: string) =>
  createSelector([docSelector], doc => {
    return doc.sceneObjects[uuid]
  })

export const sceneObjectModelSelector = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), campaignSelector],
    (sceneObject, campaign) => {
      const sceneObjectModel = _.find(campaign.assets.assets, {
        id: sceneObject.assetId,
      })

      if (!sceneObjectModel) {
        return
      }

      return sceneObjectModel
    },
  )

export const sceneObjectRevisionSelector = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectModelSelector(uuid)],
    (sceneObject, sceneObjectModel) => {
      if (!sceneObjectModel) {
        return
      }

      const revision = _.find(sceneObjectModel.revisions, {
        id: sceneObject.revisionId,
      })

      if (!revision) {
        return
      }

      return revision
    },
  )

export const sceneObjectLatestRevisionSelector = (uuid: string) =>
  createSelector([sceneObjectModelSelector(uuid)], sceneObjectModel => {
    if (!sceneObjectModel) {
      return
    }

    const latestRevision = _.last(sceneObjectModel.revisions)

    if (!latestRevision) {
      return
    }

    return latestRevision
  })

export const sceneObjectIsLocked = (uuid: string) =>
  createSelector([sceneObjectSelector(uuid)], sceneObject => {
    const path = ['sceneObjects', uuid, 'isLocked']
    const value = sceneObject.isLocked
    return {
      path,
      value,
    }
  })

export const sceneObjectIsHidden = (uuid: string) =>
  createSelector([sceneObjectSelector(uuid)], sceneObject => {
    const path = ['sceneObjects', uuid, 'isHidden']
    const value = sceneObject.isHidden
    return {
      path,
      value,
    }
  })

export const sceneObjectIsSelected = (uuid: string) =>
  createSelector([sceneObjectSelector(uuid)], sceneObject => {
    const path = ['sceneObjects', uuid, 'isSelected']
    const value = sceneObject.isSelected
    return {
      path,
      value,
    }
  })

export const sceneObjectPositionX = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectIsLocked(uuid)],
    (sceneObject, isLocked) => {
      const path = ['sceneObjects', uuid, 'position', 'x']
      const value = sceneObject.position.x
      return {
        path,
        value,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectPositionY = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectIsLocked(uuid)],
    (sceneObject, isLocked) => {
      const path = ['sceneObjects', uuid, 'position', 'y']
      const value = sceneObject.position.y
      return {
        path,
        value,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectPositionZ = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectIsLocked(uuid)],
    (sceneObject, isLocked) => {
      const path = ['sceneObjects', uuid, 'position', 'z']
      const value = sceneObject.position.z
      return {
        path,
        value,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectRotationX = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectIsLocked(uuid)],
    (sceneObject, isLocked) => {
      const path = ['sceneObjects', uuid, 'rotation', 'x']
      const value = sceneObject.rotation.x
      return {
        path,
        value,
        min: -180,
        max: 180,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectRotationY = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectIsLocked(uuid)],
    (sceneObject, isLocked) => {
      const path = ['sceneObjects', uuid, 'rotation', 'y']
      const value = sceneObject.rotation.y
      return {
        path,
        value,
        min: -180,
        max: 180,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectRotationZ = (uuid: string) =>
  createSelector(
    [sceneObjectSelector(uuid), sceneObjectIsLocked(uuid)],
    (sceneObject, isLocked) => {
      const path = ['sceneObjects', uuid, 'rotation', 'z']
      const value = sceneObject.rotation.z
      return {
        path,
        value,
        min: -180,
        max: 180,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectIsotropicScalingPropertySelector = (uuid: string) =>
  createSelector(
    [sceneObjectProperties(uuid)],
    properties =>
      _.find(
        properties,
        ({ property }) =>
          property.type === SceneObjectPropertyType.IsotropicScaling,
      )?.property as SceneObjectPropertyIsotropicScaling | undefined,
  )

export const sceneObjectAnisotropicScalingPropertySelector = (uuid: string) =>
  createSelector(
    [sceneObjectProperties(uuid)],
    properties =>
      _.find(
        properties,
        ({ property }) =>
          property.type === SceneObjectPropertyType.AnisotropicScaling,
      )?.property as SceneObjectPropertyAnisotropicScaling | undefined,
  )

export const sceneObjectScaleX = (uuid: string) =>
  createSelector(
    [
      sceneObjectSelector(uuid),
      sceneObjectIsotropicScalingPropertySelector(uuid),
      sceneObjectAnisotropicScalingPropertySelector(uuid),
      sceneObjectIsLocked(uuid),
    ],
    (
      sceneObject,
      isotropicScalingProperty,
      anisotropicScalingProperty,
      isLocked,
    ) => {
      const path = ['sceneObjects', uuid, 'scale', 'x']
      const value = sceneObject.scale.x
      const { minX, maxX } = anisotropicScalingProperty || { minX: 1, maxX: 1 }
      return {
        path,
        value,
        min: minX,
        max: maxX,
        step: (maxX - minX) / 100,
        isDisabled: isLocked.value,
        isIsotropicScalingAvailable: isotropicScalingProperty !== undefined,
        isAnisotropicScalingAvailable: anisotropicScalingProperty !== undefined,
      }
    },
  )

export const sceneObjectScaleY = (uuid: string) =>
  createSelector(
    [
      sceneObjectSelector(uuid),
      sceneObjectAnisotropicScalingPropertySelector(uuid),
      sceneObjectIsLocked(uuid),
    ],
    (sceneObject, anisotropicScalingProperty, isLocked) => {
      const path = ['sceneObjects', uuid, 'scale', 'y']
      const value = sceneObject.scale.y
      const { minY, maxY } = anisotropicScalingProperty || { minY: 1, maxY: 1 }
      return {
        path,
        value,
        min: minY,
        max: maxY,
        step: (maxY - minY) / 100,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectScaleZ = (uuid: string) =>
  createSelector(
    [
      sceneObjectSelector(uuid),
      sceneObjectAnisotropicScalingPropertySelector(uuid),
      sceneObjectIsLocked(uuid),
    ],
    (sceneObject, anisotropicScalingProperty, isLocked) => {
      const path = ['sceneObjects', uuid, 'scale', 'z']
      const value = sceneObject.scale.z
      const { minZ, maxZ } = anisotropicScalingProperty || { minZ: 1, maxZ: 1 }
      return {
        path,
        value,
        min: minZ,
        max: maxZ,
        step: (maxZ - minZ) / 100,
        isDisabled: isLocked.value,
      }
    },
  )

export const sceneObjectProperties = (uuid: string) =>
  createSelector(
    [
      sceneObjectSelector(uuid),
      sceneObjectRevisionSelector(uuid),
      campaignSelector,
    ],
    (sceneObject, sceneObjectRevision, campaign) => {
      if (!sceneObjectRevision) {
        return {}
      }

      const { assetId } = sceneObject
      const { properties } = sceneObjectRevision

      const propertiesMap = _.keyBy(properties, 'id')

      return _.mapValues(propertiesMap, (property: SceneObjectProperty) => {
        const path = ['sceneObjects', uuid, 'properties', property.id]
        // TODO use map type for state
        const value = sceneObject.properties[property.id]
        const label = property.name
        switch (property.type) {
          case SceneObjectPropertyType.Joint:
          case SceneObjectPropertyType.Emission:
            return {
              path,
              value: (value === undefined
                ? { unit: '%', value: property.default }
                : value) as UnitValue,
              property,
              label,
              min: property.min,
              max: property.max,
              isDisabled: false,
            }

          case SceneObjectPropertyType.ImageTexture:
            let options: AssetTexture[] = []
            if (property.subtype === ImageTextureSubtype.Screen) {
              options = _.filter(
                campaign.assets.screenTextures,
                screenTexture => _.includes(screenTexture.assetIds, assetId),
              )
            } else if (
              property.subtype === ImageTextureSubtype.ScreenReflection
            ) {
              // TODO once supported in API. Until then support only upload
              // options = campaign.assets.screenReflectionTextures
            }

            return {
              path,
              value,
              property,
              label,
              options,
              isDisabled: false,
            }

          case SceneObjectPropertyType.Preset:
            return {
              path,
              value:
                value === undefined
                  ? _.find(
                      property.options,
                      option => option.id === property.default,
                    )
                  : (value as PresetOption),
              property,
              label,
              options: property.options,
              isDisabled: false,
            }

          case SceneObjectPropertyType.Material:
            return {
              path,
              value:
                value === undefined
                  ? _.find(property.options, { id: property.default })
                  : value,
              property,
              label,
              options: property.options,
              isDisabled: false,
            }

          case SceneObjectPropertyType.Hdri:
            return {
              path,
              value:
                value === undefined
                  ? _.find(property.options, { id: property.default })
                  : value,
              property,
              label,
              options: property.options,
              isDisabled: false,
            }

          case SceneObjectPropertyType.IsotropicScaling:
          case SceneObjectPropertyType.AnisotropicScaling:
            return {
              path,
              value: true,
              property,
            }

          default:
            return assertNever(property)
        }
      })
    },
  )
