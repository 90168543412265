import { Action as ReduxAction, Dispatch } from 'redux'

import _ from 'lodash'
import * as dto from '../../dto/api'
import http from '../../http'
import { uploadFileRaw } from '../../lib/fileUtils'
import { assetsFromApi } from './dataTransformers'
import { Assets } from './state'

export type Action =
  | LoadAssets
  | LoadedAssets
  | CreateAsset
  | CreateRevision
  | ReloadAssets
  | DeleteAssets
  | UploadAsset

export enum AssetsActions {
  LoadAssets = 'LOAD_ASSETS',
  LoadedAssets = 'LOADED_ASSETS',
  CreateAsset = 'CREATE_ASSET',
  CreateRevision = 'CREATE_REVISION',
  ReloadAssets = 'RELOAD_ASSETS',
  DeleteAssets = 'DELETE_ASSETS',
  UploadAsset = 'UPLOAD_ASSET',
}

export interface LoadAssets extends ReduxAction<AssetsActions> {
  type: AssetsActions.LoadAssets
}

export interface LoadedAssets extends ReduxAction<AssetsActions> {
  type: AssetsActions.LoadedAssets
  payload: { assets: Assets }
}

export interface UploadAsset extends ReduxAction<AssetsActions> {
  type: AssetsActions.UploadAsset
  payload: {
    file: File
  }
}

export interface CreateAsset extends ReduxAction<AssetsActions> {
  type: AssetsActions.CreateAsset
  payload: { uploadResp: dto.TokenReq }
}

export interface CreateRevision extends ReduxAction<AssetsActions> {
  type: AssetsActions.CreateRevision
  payload: {
    id: number
    uploadResp: dto.TokenReq
  }
}

export const loadAssets = () => async (dispatch: Dispatch) => {
  dispatch({
    type: AssetsActions.LoadAssets,
  })

  const response = await http.get<dto.Device[]>('/api/devices')
  const assets = assetsFromApi(response.data)

  dispatch({
    type: AssetsActions.LoadedAssets,
    payload: { assets },
  })
}

export const uploadAsset =
  (payload: UploadAsset['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: AssetsActions.UploadAsset,
      payload,
    })

    return await uploadFileRaw('/api/device/upload_url', payload.file)
  }

export const createAsset =
  (payload: CreateAsset['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: AssetsActions.CreateAsset,
      payload,
    })

    try {
      await http.post<dto.IdResp>('/api/device', payload.uploadResp)
    } catch (error) {
      return false
    }

    dispatch({
      type: AssetsActions.ReloadAssets,
    })

    return true
  }

export const createRevision =
  (payload: CreateRevision['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: AssetsActions.CreateRevision,
      payload,
    })

    const { id, uploadResp } = payload

    try {
      await http.post<dto.IdResp>(`/api/device/${id}`, uploadResp)
    } catch (error) {
      return undefined
    }

    dispatch({
      type: AssetsActions.ReloadAssets,
    })

    return true
  }

export interface DeleteAssets extends ReduxAction<AssetsActions> {
  type: AssetsActions.DeleteAssets
  payload: { assetIds: number[] }
}

export const deleteAssets =
  (payload: DeleteAssets['payload']) => async (dispatch: Dispatch) => {
    dispatch({
      type: AssetsActions.DeleteAssets,
      payload,
    })

    const promises = _.map(payload.assetIds, async assetId => {
      return http.post(`/api/device/${assetId}/delete`)
    })

    await Promise.all(promises)

    dispatch({
      type: AssetsActions.ReloadAssets,
    })
  }

export interface ReloadAssets extends ReduxAction<AssetsActions> {
  type: AssetsActions.ReloadAssets
}

export const reloadAssets = () => (dispatch: Dispatch) => {
  dispatch({
    type: AssetsActions.ReloadAssets,
  })
}
