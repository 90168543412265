import {
  IStyle,
  Icon,
  Label,
  Stack,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'

import { AssetType } from '../../../store/assets/types'
import { Asset } from '../../../store/doc/types'
import { SceneObjectBlocs } from '../../../store/doc/selectors'
import { SceneObjectActions } from './../../../containers/SceneObjectActions'

type Props = {
  sceneObjectBloc: SceneObjectBlocs
  index: number
  assetType: AssetType
  assets: Asset[]
}

const getStyles = memoizeFunction((isSelected: boolean) => {
  return {
    wrapper: {
      root: {
        cursor: 'pointer',
        marginLeft: -10,
        marginRight: -10,
        borderBottom: 'solid 1px #E5E5E5',
        background: isSelected ? '#0078D4' : '#FFFFFF',
        selectors: {
          ':hover': {
            background: isSelected ? '#0078D4' : '#F3F2F1',
          },
          ':first-child': {
            borderTop: 'solid 1px #E5E5E5',
          },
        },
      } as IStyle,
    },
    label: {
      root: {
        cursor: 'pointer',
        paddingLeft: 10,
        color: isSelected ? '#FFFFFF' : '#323130',
        userSelect: 'none',
      } as IStyle,
    },
  }
})

export const SceneObjectItem = (props: Props) => {
  const { sceneObjectBloc, index, assetType, assets } = props
  const {
    id,
    sceneObject,
    isSelected: { value: isSelected },
  } = sceneObjectBloc

  const styles = getStyles(isSelected)

  return (
    <Stack
      data-selection-index={index}
      data-selection-select={true}
      styles={styles.wrapper}
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
    >
      <Stack horizontal verticalAlign="center">
        <Icon styles={styles.label} iconName="cubeShape"></Icon>
        <Label styles={styles.label}>{sceneObject?.name}</Label>
      </Stack>
      <Stack>
        <SceneObjectActions
          sceneObjectUuid={id}
          sceneObjectBloc={sceneObjectBloc}
          assetType={assetType}
          assets={assets}
        />
      </Stack>
    </Stack>
  )
}
