import _ from 'lodash'

import * as dto from '../../dto/api'
import { Campaigns } from './state'

const baseUrl =
  'https://storage.googleapis.com/olv-rt-production/images/campaign'
const thumbnails: { [key: string]: string | void } = {
  'FY21 BTS Toolkit': `${baseUrl}/FY21 BTS Toolkit.jpg`,
  'FY21 Holiday': `${baseUrl}/FY21 Holiday.png`,
  'Rawlings Experiments': `${baseUrl}/Rawlings.jpeg`,
}

export const campaignsFromApi = (response: dto.Campaign[]): Campaigns =>
  _.map(response, item => ({
    id: item.id,
    name: item.name,
    // TODO keep it hardcoded, even when we have multiple campaigns, until we have a way to upload image from UI
    thumbnail: thumbnails[item.name] || 'NO_CAMPAIGN_THUMBNAIL',
    // TODO: Use documentCount from API when it's there.
    documentCount: 1,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
  }))
