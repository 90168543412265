import { Action } from '../actions'
import { ConfigActions } from './actions'
import { initialState } from './state'
import { Config } from './types'

export const reducers = (
  currentState: Config = initialState(),
  action: Action,
): Config => {
  switch (action.type) {
    case ConfigActions.SetConfig:
      return {
        ...currentState,
        ...action.payload,
      }
  }
  return currentState
}
