import {
  DefaultButton,
  Dialog as FabricDialog,
  DialogFooter,
  DialogType,
  type IDialogContentProps,
  PrimaryButton,
} from 'office-ui-fabric-react'
import React from 'react'

interface Props {
  title: string
  subText?: string
  onClose: () => void
  onSuccess: () => void
  successButtonText?: string
}

export const Dialog = (props: Props) => {
  const { title, subText, onClose, onSuccess, successButtonText } = props

  const dialogContentProps = {
    type: DialogType.normal,
    title,
    subText,
  } as IDialogContentProps

  const handleSuccess = () => {
    onSuccess()
    onClose()
  }

  return (
    <FabricDialog
      hidden={false}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={handleSuccess}
          text={successButtonText ? successButtonText : 'Confirm'}
        />
        <DefaultButton onClick={onClose} text="Cancel" />
      </DialogFooter>
    </FabricDialog>
  )
}
