import _ from 'lodash'
import React from 'react'
import * as timeago from 'timeago.js'
import { Stack } from 'office-ui-fabric-react'

import { DownloadAction } from '../../components/DownloadAction'
import { RevisionsList } from '../../components/RevisionsList'
import { ColumnDef } from '../../components/Table'
import { AssetRevision, Asset } from '../../store/assets/types'
import { FileType } from '../../lib/fileUtils'

type Props = {
  asset: Asset
  revisions: AssetRevision[]
}

export const AssetsRevisionList = (props: Props) => {
  const { asset, revisions } = props

  const tableItems = _.map(revisions, revision => {
    return {
      key: revision.id.toString(),
      version: revision.version,
      created: revision.createdAt,
      createdAgo: timeago.format(revision.createdAt),
      actions: (
        <DownloadAction
          id={revision.id}
          uploadUri={revision.uploadUri}
          fileName={`${asset?.name} - v${revision.version}`}
          fileType={FileType.Zip}
        />
      ),
    }
  })
  type TItem = (typeof tableItems)[0]

  const orderedItems = _.orderBy(tableItems, 'version', 'desc')

  const columns: ColumnDef<TItem>[] = [
    { key: 'version', name: 'Version', width: 60 },
    { key: 'created', name: 'Created', fieldName: 'createdAgo' },
    { key: 'actions', name: 'Actions', width: 60 },
  ]

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <RevisionsList columns={columns} tableItems={orderedItems} />
    </Stack>
  )
}
