import {
  IStyle,
  IconButton,
  Stack,
  memoizeFunction,
} from 'office-ui-fabric-react'
import React from 'react'
import { SidebarType } from '../store/ui/types'

export { SidebarType } from '../store/ui/types'

export enum SidebarSide {
  Left = 'left',
  Right = 'right',
}

interface IProps {
  side: SidebarSide
  type: SidebarType
  width: number
  showContent: boolean
  onToggle?: () => void
  children?: JSX.Element | (JSX.Element | undefined | boolean)[]
}

const commonToggleStyle = {
  onLight: {
    root: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      width: 40,
      height: 40,
      lineHeight: 40,
      backgroundColor: '#F3F3F3',
      borderRadius: 0,
      selectors: {
        '& .ms-Icon': {
          color: '#0078D4',
        },
      },
    },
    rootHovered: {
      backgroundColor: '#FAF9F8',
      selectors: {
        '& .ms-Icon': {
          color: '#0078D4',
        },
      },
    },
  },
}

const styles = {
  wrapper: {
    default: {
      root: {
        backgroundColor: '#F3F2F1',
        borderRight: '1px solid #EDEBE9',
        width: 214,
        minWidth: 214,
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
      } as IStyle,
    },
    wide: {
      root: {
        width: 410,
        minWidth: 410,
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
      } as IStyle,
    },
    smallScreen: {
      root: {
        width: 'calc(100vw - 40px)',
        minWidth: 'calc(100vw - 40px)',
        position: 'absolute',
        overflowX: 'hidden',
        overflowY: 'auto',
      } as IStyle,
    },
  },
}

const toggleStylesPool = {
  left: {
    default: {
      root: {
        right: -40,
        ...commonToggleStyle.onLight.root,
      } as IStyle,
      rootHovered: {
        right: -40,
        ...commonToggleStyle.onLight.rootHovered,
      } as IStyle,
    },
    navigation: {
      root: {
        right: -40,
        ...commonToggleStyle.onLight.root,
      } as IStyle,
      rootHovered: {
        right: -40,
        ...commonToggleStyle.onLight.rootHovered,
      } as IStyle,
    },
  },
  right: {
    root: {
      left: -40,
      ...commonToggleStyle.onLight.root,
    } as IStyle,
    rootHovered: {
      right: -40,
      ...commonToggleStyle.onLight.rootHovered,
    } as IStyle,
  },
}

const getContainerStyle = memoizeFunction(
  (
    width: number,
    side: SidebarSide,
    type: SidebarType,
    showContent: boolean,
  ) => {
    let containerStyle: { [key in string]: any } = {
      position: 'relative',
      backgroundColor: '#F3F2F1',
    }

    if (width < 768) {
      containerStyle = {
        position: 'absolute',
        zIndex: 2,
        top: 81,
        left: 0,
        width: 'calc(100vw - 40px)',
        height: 'calc(100vh - 80px)',
        backgroundColor: '#F3F2F1',
        boxShadow:
          'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px',
      }

      if (!showContent) {
        containerStyle.zIndex = 1
        containerStyle.width = 0
      }

      if (type === SidebarType.Navigation) {
        containerStyle.top = 40
        containerStyle.height = 'calc(100vh - 40px)'
      }

      if (side === SidebarSide.Right) {
        containerStyle.left = 'auto'
        containerStyle.right = 0
      }
    }
    return containerStyle
  },
)

export const Sidebar = (props: IProps) => {
  const { side, type, width, showContent } = props

  const toggleStyles =
    side === SidebarSide.Left
      ? type === SidebarType.Navigation
        ? toggleStylesPool.left.navigation
        : toggleStylesPool.left.default
      : toggleStylesPool.right

  let wrapperStyles =
    type === SidebarType.Navigation
      ? styles.wrapper.default
      : styles.wrapper.wide
  if (width < 768) {
    wrapperStyles = styles.wrapper.smallScreen
  }

  const iconTitle = showContent ? 'Hide Menu' : 'Show Menu'
  let iconName = showContent ? 'ClosePaneMirrored' : 'OpenPaneMirrored'
  if (side === SidebarSide.Right) {
    iconName = showContent ? 'ClosePane' : 'OpenPane'
  }

  return (
    <div style={getContainerStyle(width, side, type, showContent)}>
      {props.onToggle && (
        <IconButton
          title={iconTitle}
          styles={toggleStyles}
          iconProps={{ iconName }}
          onClick={() => props.onToggle!()}
        />
      )}
      {showContent && (
        <Stack
          styles={wrapperStyles}
          disableShrink
          data-is-scrollable={true}
          verticalFill
        >
          {props.children}
        </Stack>
      )}
    </div>
  )
}
